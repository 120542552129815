import React from 'react';
import './style.scss';

export const DescriptionSection = ({ description }) => {
    return (
        <div className="description-section">
            <h2 className="label">Descripción</h2>
            <p className="description">{description}</p>
        </div>
    )
}
