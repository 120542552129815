import { fetchWrapper } from "../Utils/fetchWrapper.js";

const baseUrl = `${process.env.REACT_APP_EDOWIE_API}ServicePromocode`;

export interface IServicePromocodeService {
    add(serviceId: number, serviceType: number, discount: number, codes: string[], userId:number): Promise<any>;
    getAllByUserId(userId: number): Promise<any>;
    validate(serviceId: number, serviceType: number, promocode: string): Promise<any>;
    markPromocodeAsUsed(userId: number, serviceId: number, serviceType: number, promocode: string): Promise<any>;
    delete(servicePromocodeId: number): Promise<any>;
};

export class ServicePromocodeService implements IServicePromocodeService {

    public add(serviceId: number, serviceType: number, discount: number, codes: string[], userId:number): Promise<string> {
        return fetchWrapper.post(`${baseUrl}/${userId}`, { serviceId: serviceId, serviceType: serviceType, discount: discount, codes: codes });
    }

    public getAllByUserId(userId: number): Promise<any> {
        return fetchWrapper.get(`${baseUrl}/${userId}/promocodes`);
    }

    public validate(serviceId: number, serviceType: number, promocode: string): Promise<any> {
        return fetchWrapper.get(`${baseUrl}/${serviceType}/${serviceId}/promocodes/${promocode}/validate`)
    }

    public markPromocodeAsUsed(userId: number, serviceId: number, serviceType: number, promocode: string): Promise<any> {
        return fetchWrapper.post(`${baseUrl}/${userId}/promocodes/${promocode}/mark-as-used`, { serviceId: serviceId, serviceType: serviceType});
    }

    public delete(servicePromocodeId: number): Promise<any> {
        return fetchWrapper.delete(`${baseUrl}/${servicePromocodeId}`);
    }
}