import React, { useCallback, useEffect, useMemo, useState } from 'react';
import './style.scss';
import { Form, Formik } from 'formik';
import { getFormInitialValues, getUserLoggedIn } from '../../../Utils/utils';
import { FieldGroup, FieldItem, InputFieldItem, SubmitButton } from '../../Shared/FormHelpers';
import { channelService } from '../../../services/channelService';
import LoadingSpinner from '../../Shared/LoadingSpinner';
import { Status } from '../../../Utils/types';
import { Message } from '../../Shared/Message';
import useTimeout from '../../../hooks/useTimeout';

const fieldNames = {
    channel: 'channel',
};

export const Channels = () => {
    const channelServiceClient = useMemo(() => new channelService(), []);
    const [channel, setChannel] = useState([]);
    const [loading, setLoading] = useState(true);
    const [status, setStatus] = useState('');
    const [error, setError] = useState('');
    const createTimeout = useTimeout();

    const userLoggedIn = getUserLoggedIn();

    const getChannel = useCallback(async () => {
        const channelResponse = await channelServiceClient.getChannel(userLoggedIn.userId);

        if (channelResponse.success) {
            setChannel(channelResponse.value);
            setLoading(false);
        }

    }, [channelServiceClient, userLoggedIn.userId]);

    useEffect(() => {
        const fetchData = async () => {
            getChannel();
        };
        fetchData();

    }, [channelServiceClient, getChannel]);

    const saveChannel = async (values) => {
        setStatus('');

        var response = await channelServiceClient.save(userLoggedIn.userId, values.channel);

        if (response.success) {
            setStatus(Status.Saved);
            createTimeout(async() => {
                setStatus('');
                getChannel();
            }, 1000);
        } else {
            setStatus(Status.HasError);
            setError(response.error);
        }
    }

    const deactivate = async () => {
        setStatus('');
        var response = await channelServiceClient.deactivate(userLoggedIn.userId, channel.channelId);

        if (response.success) {
            setStatus(Status.Saved);
            createTimeout(async() => {
                setStatus('');
                getChannel();
            }, 1000);
        } else {
            setStatus(Status.HasError);
            setError(response.error);
        }
    }

    const showMessage = [Status.Saved, Status.HasError].includes(status);

    return (
        <>
            {loading ? <LoadingSpinner /> : (
            <div className="manage-service-channels">
                {channel.stateId !== 1 ? ( 
                    <>
                        <h2 className="without-channels-title">Agregá el link a tu chat por subscripción para mostrarlo en tu perfil público</h2>
                        <div className="manage-service-channels-form">
                            <div className="row">
                                <div className="col-lg-3"></div>
                                <div className="col-lg-6">
                                    <Formik
                                        onSubmit={saveChannel}
                                        initialValues={getFormInitialValues(fieldNames)}
                                    >
                                        {({ setFieldValue, isSubmitting, isValid, dirty }) => (
                                            <Form>
                                                <fieldset className="form-fields">
                                                    <FieldGroup>
                                                        <FieldItem className="field-item">
                                                            <FieldGroup>
                                                                <InputFieldItem
                                                                    type="text"
                                                                    fieldName={fieldNames.channel}
                                                                    id={fieldNames.channel}
                                                                    label={''}
                                                                    placeholder={'Agregá el link a tu chat por subscripción para mostrarlo en tu perfil público'}
                                                                    className="field-item--100"
                                                                    required
                                                                />
                                                            </FieldGroup>
                                                        </FieldItem>
                                                        <FieldItem className="field-item">
                                                            <FieldGroup>
                                                                <div className="save-button save-channel-button">
                                                                    <SubmitButton isSubmitting={isSubmitting}>
                                                                        {'Guardar'}
                                                                    </SubmitButton>
                                                                </div>
                                                            </FieldGroup>
                                                        </FieldItem>
                                                    </FieldGroup>
                                                </fieldset>
                                                {showMessage && (
                                                <fieldset>
                                                    <Message
                                                        type={status === Status.Saved ? 'success' : 'error'}
                                                        message={
                                                            status === Status.Saved
                                                                ? '¡Operación procesada con éxito!'
                                                                : error}
                                                    />
                                                </fieldset>
                                            )}
                                            </Form>
                                        )}
                                    </Formik>
                                </div>
                                <div className="col-lg-3"></div>
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <div>
                            <h2 className="channels-title">Chat por subscripción agregado:</h2>
                            <h2 className="channels-link">{channel.link}</h2>
                        </div>
                        <div className="channels-deactivate">
                            <button className="channels-deactivate-button" onClick={() => deactivate()}>Desactivar</button>
                        </div>
                    </>
                )}
            </div>)}
        </>
    )
}