import React, { useRef, useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './FileUpload.scss'
import axios from 'axios'
import { ProgressBar } from "react-bootstrap";

const chunkSize = 1048576 * 25;//its 3MB, increase the number measure in mb

const FileUpload = ({ files, setFiles, removeFile, fileIcon, uploadTitle, allowedExtensions,
    allowMultipleFile, showAllowedExtensionsTitle, referenceId, username, disabled,
    handleUploadedFile, handleIsLoadingFile }) => {

    const edowieApi = process.env.REACT_APP_EDOWIE_API;
    const fileInput = useRef(null)
    const [showProgress, setShowProgress] = useState(false);
    const [progress, setProgress] = useState(0);
    const [counter, setCounter] = useState(1)
    const [fileToBeUpload, setFileToBeUpload] = useState({})
    const [beginingOfTheChunk, setBeginingOfTheChunk] = useState(0)
    const [endOfTheChunk, setEndOfTheChunk] = useState(chunkSize)
    const [fileGuid, setFileGuid] = useState("")
    const [fileSize, setFileSize] = useState(0)
    const [chunkCount, setChunkCount] = useState(0)

    useEffect(() => {
        if (fileSize > 0) {
            
            setShowProgress(true);
            fileUpload(counter);
            setShowProgress(progress !== 100);

            if (progress !== 100) {
                if (handleIsLoadingFile !== undefined) {
                    handleIsLoadingFile(true);
                }
            }

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [fileToBeUpload, progress]);


      const fileUpload = () => {
        var exists = files.filter(file => file.name === fileGuid);
        if (exists.length === 0) {
            setFiles([...files, { name: fileGuid, isUploading: true }]);
        }

        setCounter(counter + 1);
        if (counter <= chunkCount) {
            var chunk = fileToBeUpload.slice(beginingOfTheChunk, endOfTheChunk);
            uploadChunk(chunk)
        }
    }

    const uploadChunk = async (chunk) => {
        try {
            const response = await axios.post(
                edowieApi + 'file/UploadChunks',
                chunk ,
                {
                    params: {
                        id: counter,
                        fileName: fileGuid,
                    },
                    headers: { "Content-Type": "application/json" },
                }
            );

            const data = response.data;
            if (response.status === 200) {
                setBeginingOfTheChunk(endOfTheChunk);
                setEndOfTheChunk(endOfTheChunk + chunkSize);
                if (counter === chunkCount) {
                    console.log("Process is complete, counter", counter);
                    await uploadCompleted();
                } else {
                    var percentage = (counter / chunkCount) * 100;
                    setProgress(percentage);
                }
            } else {
                console.log("Error Occurred:", data.errorMessage);
            }
        } catch (error) {
            console.log("error", error);
        }
    }

    const uploadCompleted = async () => {
        var formData = new FormData();
        formData.append("fileName", fileGuid);
        const response = await axios.post(
            edowieApi + 'file/UploadComplete',
            {},
            {
                params: {
                    fileName: fileGuid,
                },
                data: formData,
            }
        );
        const data = response.data;
        if (response.status === 200) {
            setProgress(100);
            setShowProgress(false);

            var exists = files.filter(file => file.name === fileGuid);
            if (exists.length > 0) {
                exists[0].isUploading = false;
                exists[0].id = data;
                exists[0].canDelete = true;
            } else {
                setFiles([...files, { name: fileGuid, isUploading: false, id: data, canDelete: true }]);
            }

            handleUploadedFile(data);
            if (handleIsLoadingFile !== undefined) {
                handleIsLoadingFile(false);
            }
        }
    }

    const getFileContext = (e) => {
        resetChunkProperties();
        const _file = e.target.files[0];
        setFileSize(_file.size)
        const _totalCount = _file.size % chunkSize === 0 ? _file.size / chunkSize : Math.floor(_file.size / chunkSize) + 1; // Total count of chunks will have been upload to finish the file
        setChunkCount(_totalCount)
        setFileToBeUpload(_file)
        setFileGuid(_file.name)

        e.target.value = null;
    }

    const resetChunkProperties = () => {
        setCounter(1)
        setBeginingOfTheChunk(0)
        setEndOfTheChunk(chunkSize)
    }

    const handleClick = () => {
        fileInput.current.click()
    }

    const progressInstance = (
        <ProgressBar animated now={progress} label={`${progress.toFixed(2)}%`} />
      );
 
    return (
        <>
            <div className="file-card">

                <div className="file-inputs mb-0">
                    <input type="file" onChange={getFileContext} disabled={(!allowMultipleFile && files.length > 0) || disabled || showProgress}
                        accept={allowedExtensions} ref={fileInput} />
                    <button type="button" onClick={() => handleClick()} disabled={(!allowMultipleFile && files.length > 0) || disabled || showProgress}>
                        <i>
                            <FontAwesomeIcon icon={fileIcon} />
                        </i>
                        {uploadTitle}
                    </button>
                </div>
                <div className='progress-section' style={{ display: showProgress ? "block" : "none" }}>
                    {progressInstance}
                </div>
                {showAllowedExtensionsTitle &&
                <>
                    <p className="main">Supported files</p>
                    <p className="info">PDF, JPG, PNG</p>
                </>
                }
            </div>
        </>
    )
}

export default FileUpload
