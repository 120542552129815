import React, { useEffect } from 'react';
import { NavMenu } from '../Menu/NavMenu';
import './style.scss';
import { Header } from './Header';
import { EdowieSection } from './Sections/EdowieSection';
import { BlogPreviewSection } from './Sections/BlogPreviewSection';
import { UserSection } from './Sections/UserSection';
import { TestimonialSection } from './Sections/TestimonialSection';
import { Footer } from '../Shared/Footer/index';
import { sessionManager } from '../../sessionManager';
import { useNavigate } from "react-router-dom";

export const LandingPage = () => {
    const supportsMultipleLanguages = process.env.REACT_APP_SUPPORTS_MULTIPLE_LANGUAGE;

    const manager = new sessionManager();
    const isUserLoggedIn = manager.isUserLoggedIn();
    const navigate = useNavigate();

    useEffect(() => {
        if (isUserLoggedIn) {
            navigate(`/home`);
        } else {
            
        }
    }, [isUserLoggedIn, navigate]);

    return (
        <>
            <NavMenu showNavigation={true} showLanguageSelector={supportsMultipleLanguages === 'true'} />
            <div className='landing-page'>
                <Header></Header>
                <EdowieSection></EdowieSection>
                <BlogPreviewSection></BlogPreviewSection>
                <UserSection></UserSection>
                <TestimonialSection></TestimonialSection>
                <Footer></Footer>
            </div>
        </>
    )
}