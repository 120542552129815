import { useTranslation } from "react-i18next"
import { NavMenu } from "../../Menu/NavMenu";
import './style.scss';
import { Form, Formik } from "formik";
import LoadingSpinner from "../../Shared/LoadingSpinner";
import { getCountries, getFormInitialValues } from "../../../Utils/utils";
import { useEffect, useMemo, useState } from "react";
import { CheckboxFieldItem, FieldGroup, FieldItem, SelectFieldItem, SubmitButton } from "../../Shared/FormHelpers";
import { InjectAppServices } from "../../../services/dependency-injection";
import { useParams } from "react-router-dom";
import { Message } from "../../Shared/Message";
import { DELAY_BEFORE_REDIRECT, Status } from "../../../Utils/types";
import useTimeout from "../../../hooks/useTimeout";
import { contentService } from "../../../services/contentService";
import { groupExperienceService } from "../../../services/groupExperienceService";

const fieldNames = {
    applyAll: 'applyAll',
    country: 'country',
    discount: 'discount',
};

const discounts = [
    { key: '5', value: '5' },
    { key: '10', value: '10' },
    { key: '15', value: '15' },
    { key: '20', value: '20' },
    { key: '25', value: '25' },
    { key: '30', value: '30' },
    { key: '35', value: '35' },
    { key: '40', value: '40' },
    { key: '45', value: '45' },
    { key: '50', value: '50' },
    { key: '55', value: '55' },
    { key: '60', value: '60' },
    { key: '65', value: '65' },
    { key: '70', value: '70' },
    { key: '75', value: '75' },
    { key: '80', value: '80' },
    { key: '85', value: '85' },
    { key: '90', value: '90' },
    { key: '95', value: '95' },
    { key: '100', value: '100' },
]

export const Discount = InjectAppServices(
    ({
        dependencies: { countryDiscountServiceClient, sessionManager, serviceDiscountServiceClient, configurationServiceClient }
    }) => {

    const contentServiceClient = useMemo(() => new contentService(), []);
    const groupExperienceServiceClient = useMemo(() => new groupExperienceService(), []);
    const { t, i18n } = useTranslation(['discount', 'common']);
    const createTimeout = useTimeout();

    const { serviceType, serviceId } = useParams();
    const [loading, setLoading] = useState(true);
    const [applyAll, setApplyAll] = useState(false);
    const [countryDiscounts, setCountryDiscounts] = useState([]);
    const [configuration, setConfiguration] = useState({});
    const [status, setStatus] = useState('');
    const [error, setError] = useState('');
    const [service, setService] = useState({});

    const defaultOption = { key: '', value: t('selectAnOption', {ns: 'common'}) };

    useEffect(() => {
        const fetchData = async () => {

            if (serviceType === "1" || serviceType === "2") {
                var contentResponse = await contentServiceClient.getContentDetailsById(serviceId, serviceType);
                if (contentResponse.success) {
                    setService(contentResponse.value);
                }
            } else {
                var groupExperienceResponse = await groupExperienceServiceClient.getGroupExperienceById(serviceId);
                if (groupExperienceResponse.success) {
                    setService(groupExperienceResponse.value);
                }
            }

            const response = await countryDiscountServiceClient.getAll();
            var countriesFiltered = [];
            var countries = getCountries(i18n.language);

            response.forEach(c => {
                const countryFiltered = countries.filter(co => co.key.toLowerCase() === c.countryCode.toLowerCase())[0];

                if (countryFiltered) {
                    countriesFiltered.push(countryFiltered);
                }
            });

            setCountryDiscounts(countriesFiltered);
            
            configurationServiceClient.getByKey('DiscountService:MinimumServiceAmount')
                .then(response => {
                    setConfiguration(response);
                })

            setLoading(false);
        };
        fetchData();

    }, [countryDiscountServiceClient, i18n.language, configurationServiceClient, serviceId, serviceType, contentServiceClient, groupExperienceServiceClient]);

    const handleApplyAllChange = async (e, setFieldValue) => {
        setApplyAll(!applyAll);
        setFieldValue(fieldNames.country, '');
    };

    const saveDiscount = (values) => {
        setStatus('');
        const applyAll = values.applyAll === "" ? false : values.applyAll
        serviceDiscountServiceClient.add(serviceId, serviceType, applyAll, values.country, values.discount, sessionManager.getUserLoggedIn().userId)
        .then(() => {
            setStatus(Status.Saved);
                createTimeout(() => {
                    window.location.href = `/manage-services/${serviceType === "1" || serviceType === "2" ? 1 : 3}`;
                }, DELAY_BEFORE_REDIRECT);
        })
        .catch((error) => {
            setStatus(Status.HasError);
            setError(error);
        });
    }

    function validateAmount(value) {
        let error;
        if (!value) {
            error = t('requiredField', {ns: 'discount'});
        } else {
            const price = service.price
            const amount = price - ((price *parseInt(value)) / 100);

            if (amount < parseInt(configuration.value)) {
                const minimumAmount = parseFloat(configuration.value).toFixed(2);
                const total = amount.toFixed(2);
                error = t('minimumServiceAmountError', {ns: 'discount', minimumAmount, total});
            }
        }

        return error;
    } 

    const showMessage = [Status.Saved, Status.HasError].includes(status);

    return (
        <>
            <NavMenu showNavigation={true} currentPage={'discount'} />
            <div className="discount">
                <div className="discount-card">
                    <div className="discount-header">
                        <a href="manage-services" className="discount-back">
                            <i className="ti-arrow-left"></i>
                        </a>
                        <h4>
                            {t('title', {ns: 'discount'})} - {t(`${serviceType === '1' ? 'courseTitle' : serviceType === '2' ? 'resourceTitle' : 'groupExperienceTitle'}`, {ns: 'discount'})}: {service.title}
                        </h4>
                    </div>
                    {loading ? <LoadingSpinner /> : (
                    <>
                        <div className="discount-legend ">
                            <p>{t('subtitle', {ns: 'discount'})}</p>
                        </div>
                        <div className="discount-form ">
                            <div className="row">
                                <div className="col-lg-12">
                                    <Formik
                                        onSubmit={saveDiscount}
                                        initialValues={getFormInitialValues(fieldNames)}
                                    >
                                        {({ setFieldValue, isSubmitting, isValid, dirty }) => (
                                            <Form>
                                                <fieldset className="form-fields">
                                                    <FieldGroup>
                                                        <FieldItem className="field-item">
                                                            <FieldGroup>
                                                                <CheckboxFieldItem
                                                                    fieldName={fieldNames.applyAll}
                                                                    key={fieldNames.applyAll}
                                                                    label={t('applyDiscountAllLabel', {ns: 'discount'})}
                                                                    id={fieldNames.isFree}
                                                                    onChange={(e) => {
                                                                        handleApplyAllChange(e, setFieldValue);
                                                                    }}
                                                                />
                                                            </FieldGroup>
                                                        </FieldItem>
                                                        <FieldItem className="field-item">
                                                            <FieldGroup>
                                                                <SelectFieldItem
                                                                    fieldName={fieldNames.country}
                                                                    id={fieldNames.country}
                                                                    label={`${applyAll ? '' : '*'} ${t('discountBuyersCountryLabel', {ns: 'discount'})}`}
                                                                    defaultOption={defaultOption}
                                                                    values={countryDiscounts}
                                                                    className="field-item--100"
                                                                    required={!applyAll}
                                                                    disabled={applyAll}
                                                                />
                                                            </FieldGroup>
                                                        </FieldItem>
                                                        <FieldItem className="field-item">
                                                            <FieldGroup>
                                                                <SelectFieldItem
                                                                    fieldName={fieldNames.discount}
                                                                    id={fieldNames.discount}
                                                                    label={`*${t('offPercentageLabel', {ns: 'discount'})}`}
                                                                    defaultOption={defaultOption}
                                                                    values={discounts}
                                                                    className="field-item--100"
                                                                    required={true}
                                                                    validate={validateAmount}
                                                                />
                                                            </FieldGroup>
                                                        </FieldItem>
                                                        <FieldItem className="field-item">
                                                            <FieldGroup>
                                                                <div className="save-button">
                                                                    <SubmitButton isSubmitting={isSubmitting}>
                                                                        {t('applyButton', {ns: 'discount'})}
                                                                    </SubmitButton>
                                                                </div>
                                                            </FieldGroup>
                                                        </FieldItem>
                                                    </FieldGroup>
                                                </fieldset>
                                                {showMessage && (
                                                    <fieldset>
                                                    <Message
                                                        type={status === Status.Saved ? 'success' : 'error'}
                                                        message={
                                                            status === Status.Saved
                                                                ? t('successfullyMessage', {ns: 'common'})
                                                                : error}
                                                        />
                                                    </fieldset>
                                                )}
                                            </Form>
                                        )}
                                    </Formik>
                                </div>
                            </div>
                        </div>
                    </>
                    )}
                </div>
            </div>
        </>
    )
},
)