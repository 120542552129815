import React, { useState } from 'react';
import './style.scss';
import moment from 'moment'
import 'moment/locale/es';

export const DetailsSection = ({ level, updatedDate, expanded }) => {
    const [showSection, setShowSection] = useState(expanded ?? true);

    return (
        <div className="details-section">
            <div className="card-details">
                <div className="card-header">
                    <h5>
                        <button className="collapse-expand-button" onClick={() => setShowSection(!showSection)}>Detalles</button>
                    </h5>
                </div>
                {showSection && (
                    <div>
                        <div className="card-body">
                            <span className="item-label">Nível</span>
                            <span className="item-content">{level}</span>
                        </div>
                        <div className="card-body">
                            <span className="item-label">Ultima Fecha Modificación</span>
                            <span className="item-content">{moment(updatedDate).format("DD/MM/YYYY HH:MM")}</span>
                        </div>
                    </div>
                )}
            </div>
        </div>  
    )
}