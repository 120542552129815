import React from 'react';
import { NavMenu } from '../Menu/NavMenu';
import './style.scss';
import { SectionItem } from './Sections/index';
import { WelcomeEdowieSection } from './Sections/WelcomeEdowieSection';
import { WhoUseServiceSection } from './Sections/WhoUseServiceSection';
import { YourUseServiceSection } from './Sections/YourUseServiceSection';
import { YourContentConductSection } from './Sections/YourContentConductSection';
import { OtherLegalTermsSection } from './Sections/OtherLegalTermsSection';
import { AboutThisAgreementSection } from './Sections/AboutThisAgreementSection';
import { useTranslation } from 'react-i18next';

export const TermsAndConditions = () => {
    const supportsMultipleLanguages = process.env.REACT_APP_SUPPORTS_MULTIPLE_LANGUAGE;

    const { t } = useTranslation(['terms_and_conditions', 'common']);

    return (
        <>
            <NavMenu showNavigation={false} showLanguageSelector={supportsMultipleLanguages === 'true'} />
            <div className='terms-and-conditions'>
                <div className='terms-and-conditions-body'>
                    <div className='row'>
                        <div className="col-xl-12 col-xxl-12 col-lg-12">
                            <h2 className="terms-and-conditions-title">{t('title')}</h2>
                            <div className='terms-and-conditions-card'>
                                <SectionItem title={t('welcomeEdowieSection.title')}>
                                    <WelcomeEdowieSection></WelcomeEdowieSection>
                                </SectionItem>
                                <SectionItem title={t('whoCanUseServiceSection.title')}>
                                    <WhoUseServiceSection></WhoUseServiceSection>
                                </SectionItem>
                                <SectionItem title={t('yourUseServiceSection.title')}>
                                    <YourUseServiceSection></YourUseServiceSection>
                                </SectionItem>
                                <SectionItem title={t('yourContentConductSection.title')}>
                                    <YourContentConductSection></YourContentConductSection>
                                </SectionItem>
                                <SectionItem title={t('otherLegalTerms.title')}>
                                    <OtherLegalTermsSection></OtherLegalTermsSection>
                                </SectionItem>
                                <SectionItem title={t('aboutAgreementSection.title')}>
                                    <AboutThisAgreementSection></AboutThisAgreementSection>
                                </SectionItem>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 register-back-button">
                            <a href="/register/">{t('backButton', {ns: 'common'})}</a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}