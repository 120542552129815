import { ProfileType } from "../../../../../Utils/types";
import { InjectAppServices } from "../../../../../services/dependency-injection";
import { AmbassadorButton } from "./AmbassadorButton";
import { BalanceButton } from "./BalanceButton";
import { ChangeProfileButton } from "./ChangeProfileButton";

export const Item = InjectAppServices(
    ({
        item,
        handleChangeProfile,
        handleChangeAmbassador,
        handleUpdateBalanceInformation
    }) => {

        return (
            <div className="row"> 
                <div className='col-lg-1'>
                    <div className='users-section-grid-image-column'>
                        <div className='users-section-grid-image-column-header'>
                            <img src={item.profileImage} alt="profile"className="users-section-grid-image-column-header-user-photo"></img>
                        </div>
                    </div>
                </div>
                <div className='col-lg-5'>
                    <div className="users-section-grid-user-details-column">
                        <div className="users-section-grid-user-details-column-container">
                            <div className="users-section-grid-user-details-column-container-username">
                                {item.username}
                            </div>
                            <>
                                <div className="users-section-grid-user-details-column-container-person">
                                    <div className="users-section-grid-user-details-column-container-person-item">
                                        <div>{'Persona:'}</div>
                                        <div className="users-section-grid-user-details-column-container-person-item-value">{item.lastAndFirstName}</div>
                                    </div>
                                    <div className="users-section-grid-user-details-column-container-person-item">
                                        <div>{'Email:'}</div>
                                        <div className="users-section-grid-user-details-column-container-person-item-value">{item.email}</div>
                                    </div>
                                </div>
                            </>
                        </div>
                    </div>
                </div>
                <div className='col-lg-3'>
                    <div className="users-section-grid-user-balance-column">
                        <div className="users-section-grid-user-balance-column-container">
                            <div className="users-section-grid-user-balance-column-container-balance">
                                Balance: {item.balance}
                            </div>
                            <>
                                <div className="users-section-grid-user-balance-column-container-commission">
                                    <div className="users-section-grid-user-balance-column-container-commission-item">
                                        <div>{'Comisión:'}</div>
                                        <div className="users-section-grid-user-balance-column-container-commission-item-value">{item.commission}</div>
                                    </div>
                                    <div className="users-section-grid-user-balance-column-container-commission-item">
                                        <div>{'Mínimo retiro:'}</div>
                                        <div className="users-section-grid-user-balance-column-container-commission-item-value">{item.minimumWithdrawal}</div>
                                    </div>
                                </div>
                            </>
                        </div>
                    </div>
                </div>
                <div className='col-lg-3'>
                    <div className='users-section-grid-user-buttons-column'>
                        <ChangeProfileButton 
                            profile={item.profile} 
                            userId={item.userId} 
                            handleChangeProfile={handleChangeProfile}
                        ></ChangeProfileButton>
                        {item.profile === ProfileType.Edowier && (
                        <AmbassadorButton 
                            isAmbassador={item.isAmbassador} 
                            userId={item.userId} 
                            handleChangeAmbassador={handleChangeAmbassador}
                        ></AmbassadorButton>
                        )}
                        <BalanceButton 
                            userId={item.userId} 
                            balance={item.balance}
                            commission={item.commission}
                            minimumWithdrawal={item.minimumWithdrawal}
                            handleUpdateBalanceInformation={handleUpdateBalanceInformation}
                        >
                        </BalanceButton>
                    </div>
                </div>
            </div>
        )
    }
)