import React, { useState, useEffect, useMemo } from 'react';
import './style.scss';
import { Form, Formik } from 'formik';
import { FieldGroup, FieldItem, InputFieldItem, SelectFieldItem, SubmitButton } from '../FormHelpers/index';
import { DefaultOption } from '../../../Utils/types';
import { getFormInitialValues } from '../../../Utils/utils';
import { categoryService } from '../../../services/categoryService';
import { useTranslation } from 'react-i18next';

const fieldNames = {
    category: 'category',
    text: 'text',
};

export const Searcher = ({handleSearch, searcherBy }) => {
    const categoryServiceClient = useMemo(() => new categoryService(), []);

    const { i18n } = useTranslation(['discount', 'common']); 
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            var categoriesResponse = await categoryServiceClient.getAll();

            if (categoriesResponse.success) {
                const values = categoriesResponse.value.map(function (item) {
                    return { key: item.categoryId, value: i18n.language === 'en' ? item.nameInEnglish : item.name};
                });

                setCategories(values);
            }
        };
        fetchData();

    }, [categoryServiceClient, i18n.language]);

    const search = (values) => {
        handleSearch(values);
    }

    return (
        <>
            <div className="searcher">
                <div className="body">
                    <div className="form">
                        <div className="row">
                            <div className="col-lg-12">
                                <Formik
                                    onSubmit={search}
                                    initialValues={getFormInitialValues(fieldNames)}
                                >
                                    {({ setFieldValue, isSubmitting, isValid, dirty }) => (
                                        <Form>
                                            <fieldset className="form-fields">
                                                <FieldGroup>
                                                    <FieldItem className="field-item">
                                                        <FieldGroup>
                                                            <div className="row">
                                                                <div className="col-lg-5">
                                                                    <InputFieldItem
                                                                        type="text"
                                                                        fieldName={fieldNames.text}
                                                                        id={fieldNames.text}
                                                                        label={``}
                                                                        placeholder={`Buscar en ${searcherBy}`}
                                                                        className="field-item--100"
                                                                    />
                                                                </div>
                                                                <div className="col-lg-5">
                                                                    <SelectFieldItem
                                                                        fieldName={fieldNames.category}
                                                                        id={fieldNames.category}
                                                                        label={``}
                                                                        //required
                                                                        defaultOption={DefaultOption}
                                                                        values={categories}
                                                                        className="field-item--100"
                                                                    />
                                                                </div>
                                                                <div className="col-lg-2">
                                                                    <div className="find-button">
                                                                        <SubmitButton isSubmitting={false}>
                                                                            {'Buscar'}
                                                                        </SubmitButton>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </FieldGroup>
                                                    </FieldItem>
                                                </FieldGroup>
                                            </fieldset>
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
