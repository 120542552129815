import React, { useState, useEffect, useMemo } from 'react';
import LoadingSpinner from "../../../Shared/LoadingSpinner/index";
import { useParams } from "react-router-dom";
import { groupExperienceService } from '../../../../services/groupExperienceService';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { GroupExperienceItem } from './GroupExperienceItem/index'
import './style.scss';

const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 4,
        slidesToSlide: 4 // optional, default to 1.
    },
    tablet: {
        breakpoint: { max: 1024, min: 768 },
        items: 3,
        slidesToSlide: 3 // optional, default to 1.
    },
    mobile: {
        breakpoint: { max: 767, min: 464 },
        items: 2,
        slidesToSlide: 1 // optional, default to 1.
    },
    mobile2: {
        breakpoint: { max: 463, min: 300 },
        items: 1,
        slidesToSlide: 1 // optional, default to 1.
    }
};

export const GroupsExperiences = () => {
    const groupExperienceServiceClient = useMemo(() => new groupExperienceService(), []);
    const [loading, setLoading] = useState(true);
    const { id } = useParams();
    const [groupsExperiences, setGroupsExperiences] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const groupExperiencesResponse = await groupExperienceServiceClient.getGroupsExperiences(id);

            if (groupExperiencesResponse.success) {
                setGroupsExperiences(groupExperiencesResponse.value);
                setLoading(false);
            }
        };
        fetchData();

    }, [groupExperienceServiceClient, id]);

    return (
        <>
            <div className="groups-experiences">
                <div className="published-title">
                    <h2><b>Experiencias grupales</b> programadas.</h2>
                    <p>Listado de experiencias grupales donde te puedes inscribir.</p>
                </div>
                <div className="published-items">
                    {loading ? <LoadingSpinner /> : (
                        <Carousel
                            responsive={responsive}
                            autoPlay={false}
                            swipeable={true}
                            draggable={true}
                            showDots={true}
                            infinite={false}
                            partialVisible={false}
                            dotListClass="custom-dot-list-style"
                        >
                            {groupsExperiences.map((groupExperience) => (
                                <GroupExperienceItem key={groupExperience.groupExperienceId} id={groupExperience.groupExperienceId} coverImage={groupExperience.coverImage}
                                    title={groupExperience.title} isFree={groupExperience.isFree} price={groupExperience.isFree}
                                    description={groupExperience.description} subcategory={groupExperience.subcategory}
                                    numberOfInscriptions={groupExperience.numberOfRegistered}>
                                </GroupExperienceItem>
                            ))}

                        </Carousel>
                    )}
                </div>
            </div>

        </>
    )
}
