import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { NavMenu } from '../Menu/NavMenu';
import { UserCard } from './UserCard/index'
import { AddPublication } from './AddPublication/index'
import { userService } from '../../services/userService'
import LoadingSpinner from "../Shared/LoadingSpinner/index";
import { Filters } from './Filters/index';
import { activityService } from '../../services/activityService';
import { UpcomingEvents } from './UpcomingEvents';
import { PaginatedItems } from './PaginatedItems';
import { getUserLoggedIn } from '../../Utils/utils';

export const Home = () => {
    const itemsPerPage = parseInt(process.env.REACT_APP_ACTIVITIES_PER_PAGE);
    const activityServiceClient = useMemo(() => new activityService(), []);

    const userLoggedIn = getUserLoggedIn();

    const [state, setState] = useState({ profilePhoto: '', profile: '', username: '' });
    const [activities, setActivities] = useState([]);
    const [filter, setFilter] = useState('todo');

    let [loading, setLoading] = useState(true);

    const getActivities = useCallback(async (appliedFilter) => {
        const activitiesResponse = await activityServiceClient.getActivitiesByUserIdAndFilter(userLoggedIn.userId, appliedFilter);

        if (activitiesResponse.success) {
            setActivities(activitiesResponse.value);
        }

    },[activityServiceClient, setActivities, userLoggedIn.userId]);

    useEffect(() => {
        const fetchData = async () => {
            userService.card(userLoggedIn.userId)
                .then(response => {
                    setState(response);
                    setLoading(false);
                })
                .catch(() => {
                    setLoading(false);
                });
        };
        fetchData();

    }, [userLoggedIn.userId]);


    useEffect(() => {
        const fetchData = async () => {
            getActivities(filter);
        };
        fetchData();

    }, [filter, getActivities]);

    const handleAddPublication = async () => {
        getActivities(filter);
    }

    const handleFilter = (selectedFilter) => {
        setFilter(selectedFilter);
        getActivities(selectedFilter);
    }

    const handleFeedback = () => {
        getActivities(filter);
    }

    const handleDelete = () => {
        getActivities(filter);
    }

    return (
        <>
            {loading ? <LoadingSpinner /> : (
                <>
                    <NavMenu showNavigation={true} currentPage={'muro'} />
                    <div className="home row">
                        <div className="sidebar-left col-xs-12 col-lg-3">
                            <UserCard card={state}></UserCard>
                        </div>
                        <div className="sidebar-right col-xs-12 col-lg-6">
                            <div className="container">
                                <AddPublication profilePhoto={state.profilePhoto} username={userLoggedIn.username}
                                    handleAddPublication={() => handleAddPublication()}></AddPublication>
                                <Filters handleFilter={(applyFilter) => handleFilter(applyFilter)}></Filters>
                                <PaginatedItems itemsPerPage={itemsPerPage} items={activities}
                                                handleDelete={() => handleDelete()}
                                                handleFeedback={() => handleFeedback()}></PaginatedItems>
                            </div>
                        </div>
                        <div className="col-xs-12 col-lg-3">
                            <UpcomingEvents ></UpcomingEvents>
                        </div>
                    </div>
                </>
            )
            }
        </>
    )
}
