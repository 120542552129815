import React, { useState, useEffect, useMemo } from 'react';
import LoadingSpinner from "../Shared/LoadingSpinner/index";
import { NavMenu } from '../Menu/NavMenu';
import './style.scss';
import { useParams } from "react-router-dom";
import { profileService } from '../../services/profileService';
import { Header } from './Header/index';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { Contents } from './Tabs/Contents/index';
import { GroupsExperiences } from './Tabs/GrupalExperiences/index';
import { Channels } from './Tabs/Channels/index';
import { ParticularExperiences } from './Tabs/ParticularExperiences/index';
import { Publications } from './Tabs/Publications/index';
import { Information } from './Tabs/Information/index';

export const TeacherProfile = () => {
    const profileServiceClient = useMemo(() => new profileService(), []);
    const [loading, setLoading] = useState(true);
    const { id, selectedTab } = useParams();
    const [state, setState] = useState();
    const [activeTab, setActiveTab] = useState(selectedTab === undefined ? "1" : selectedTab);

    useEffect(() => {
        const fetchData = async () => {
            const teacherProfileResponse = await profileServiceClient.teacherProfile(id);

            if (teacherProfileResponse.success) {
                setState(teacherProfileResponse.value);
                setLoading(false);
            }
        };
        fetchData();

    }, [profileServiceClient, id]);

    const selectTab = (tabId) => {
        if (activeTab !== tabId) {
            setActiveTab(tabId);
        }
    }

    return (
        <>
            {loading ? <LoadingSpinner /> : (
                <>
                    <NavMenu showNavigation={true} currentPage={'teacher-profile'} />
                    <div className="teacher-profile">
                        <Header teacherProfile={state} handleMoreInformationClick={() => selectTab("6")}></Header>
                        <div className="tabs">
                            <Nav tabs>
                                <NavItem>
                                    <NavLink className={(activeTab === "1" ? "active nav-link" : "nav-link")} href="#"
                                        onClick={() => selectTab("1")}>Inicio</NavLink>
                                </NavItem>
                                {state.hasContents && (
                                    <NavItem>
                                        <NavLink className={(activeTab ==="2" ? "active nav-link" : "nav-link")} href="#"
                                            onClick={() => selectTab("2")}>Contenido</NavLink>
                                    </NavItem>
                                )}
                                {state.hasParticularExperienceActive && (
                                    <NavItem>
                                        <NavLink className={(activeTab ==="3" ? "active nav-link" : "nav-link")} href="#"
                                            onClick={() => selectTab("3")}>Particular</NavLink>
                                    </NavItem>
                                )}
                                {state.hasGrupalExperiences && (
                                    <NavItem>
                                        <NavLink className={(activeTab ==="4" ? "active nav-link" : "nav-link")} href="#"
                                            onClick={() => selectTab("4")}>Grupal</NavLink>
                                    </NavItem>
                                )}
                                {state.hasChannel && (
                                <NavItem>
                                    <NavLink className={(activeTab === "5" ? "active nav-link" : "nav-link")} href="#"
                                        onClick={() => selectTab("5")}>Canales</NavLink>
                                </NavItem>
                                )}
                                <NavItem>
                                    <NavLink className={(activeTab === "6" ? "active nav-link" : "nav-link")} href="#"
                                        onClick={() => selectTab("6")}>Información</NavLink>
                                </NavItem>
                            </Nav>
                        </div>
                        <div className="tab-content">
                            <TabContent activeTab={activeTab}>
                                <TabPane tabId="1">
                                    <Publications userId={id}></Publications>
                                </TabPane>
                                <TabPane tabId="2">
                                    <Contents></Contents>
                                </TabPane>
                                <TabPane tabId="3">
                                    {activeTab === "3" && (
                                        <ParticularExperiences></ParticularExperiences>
                                    )}
                                </TabPane>
                                <TabPane tabId="4">
                                    {activeTab === "4" && (
                                        <GroupsExperiences></GroupsExperiences>
                                    )}
                                </TabPane>
                                <TabPane tabId="5">
                                    {activeTab === "5" && (
                                        <Channels></Channels>
                                    )}
                                </TabPane>
                                <TabPane tabId="6">
                                    {activeTab === "6" && (
                                        <Information aboutMe={state.aboutMe} showNameInPublicProfile={state.showNameInPublicProfile} name={state.firstAndLastName}
                                            flagUrl={state.flagUrl} joinEdowie={state.access} timeZone={state.timeUse}
                                            facebook={state.facebook} instagram={state.instagram} linkedin={state.linkedin}
                                            youTubeChannel={state.youTubeChannel} webSite={state.webSite}>
                                        </Information>
                                    )}
                                </TabPane>
                            </TabContent>
                        </div>
                    </div>
                </>
                )
            }
        </>
    )
}