import { DashboardItemType, ProfileType } from "../../../../Utils/types";
import { InjectAppServices } from "../../../../services/dependency-injection";
import './style.scss';

export const ItemCard = InjectAppServices(
    ({ item }) => {

        const getUrlForType = (itemType) => {
            switch (itemType) {
                case DashboardItemType.Users:
                    return `/users/${ProfileType.User}`;
                case DashboardItemType.Edowiers:
                    return `/users/${ProfileType.Edowier}`;
                case DashboardItemType.Contents:
                    return '/contents';
                case DashboardItemType.Buys:
                    return '/buys';
                default:
                    return '';
            }
        }

        return (
            <a href={getUrlForType(item.type)}>
                <div className="dashboard-section_sidebar-right-main-section-item-card">
                    <div className="item-vard-name">{item.name}</div>
                    <div className="item-vard-value">{item.value}</div>
                </div>
            </a>
        )
})