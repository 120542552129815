import React, { useState, useEffect, useMemo } from 'react';
import './style.scss';
import { PurchaseSummary } from '../PurchaseSummary/index';
import { contentService } from '../../../../../services/contentService';
import { extractParameter } from '../../../../../Utils/utils';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import queryString from 'query-string';
import { Payment } from '../Payment/index';
import { ServiceType } from '../../../../../Utils/types';
import { InjectAppServices } from '../../../../../services/dependency-injection';
import { Promocode } from '../../../../Shared/Promocode';

export const BuySection = InjectAppServices(
    (
        { isFree, price, description, items, userId, contentId, contentType, wasAdquired, contentUserId, discount,
            userCountry, hasPromocodesAvailable, handleSuccessPurchase, dependencies: { sessionManager, servicePromocodeServiceClient } }
    ) => {
    const contentServiceClient = useMemo(() => new contentService(), []);

    const { promocode } = useParams();
    
    
    const isUserLoggedIn = sessionManager.isUserLoggedIn();
    const location = useLocation();
    const navigate = useNavigate();

    const paymentIntentId = extractParameter(location, queryString.parse, 'payment_intent') || '';
    const successFreePurchase = extractParameter(location, queryString.parse, 'successFreePurchase') || '';

    const [externalPaymentId, setExternalPaymentId] = useState(paymentIntentId);
    const [canBuy, setCanBuy] = useState(!wasAdquired && userId !== contentUserId);
    const [readyToPay, setReadyToPay] = useState(false);
    const [state, setState] = useState({price: price, total: price, discount: 0, isFree: isFree, discountAmout: 0});
    const [applyPromocode, setApplyPromocode] = useState(promocode !== undefined );

    const successfulPurchase = paymentIntentId !== '' || successFreePurchase === 'true';

    useEffect(() => {
        const fetchData = async () => {
            var amount = price;

            if (promocode) {
                servicePromocodeServiceClient.validate(contentId, contentType, promocode)
                .then(async response => {
    
                    if (response.isValid) {
                        const discountAmout = (parseFloat(price * response.discount))/parseFloat(100);
                        amount = price - discountAmout;
                        setState({price: price, total: amount, discount: 0, isFree: isFree, discountAmout: discountAmout});
                    }

                    if (externalPaymentId !== "" || successFreePurchase === 'true') {
                        if (wasAdquired !== true) {
                            var paymentResponse = await contentServiceClient.buyContent(externalPaymentId, amount, userId, contentId, parseInt(contentType));
                            if (paymentResponse.success) {
                                setExternalPaymentId('');
                                handleSuccessPurchase();
                                servicePromocodeServiceClient.markPromocodeAsUsed(userId, contentId, contentType, promocode);
                                setCanBuy(false);
                            }
                        }
                    }  
                })
            } else {
                if (discount && (discount.applyAll || discount.countryDiscountCode === userCountry)) {
                    const discountAmout = (parseFloat(price * discount.discount))/parseFloat(100);
                    amount = price - discountAmout;
                    setState({price: price, total: amount, discount: discount.discount, isFree: isFree, discountAmout: discountAmout, applyAll: discount.applyAll});
                } else {
                    setState({price: price, total: amount, discount: 0, isFree: isFree});
                }

                if (externalPaymentId !== "" || successFreePurchase === 'true') {
                    if (wasAdquired !== true) {
                        var paymentResponse = await contentServiceClient.buyContent(externalPaymentId, amount, userId, contentId, parseInt(contentType));
                        if (paymentResponse.success) {
                            setExternalPaymentId('');
                            handleSuccessPurchase();
                            setCanBuy(false);
                        }
                    }
                }     
            }
        };
        fetchData();

    }, [externalPaymentId, successFreePurchase, contentId, contentServiceClient, contentType, 
        handleSuccessPurchase, price, userId, wasAdquired, discount, isFree, userCountry,
        promocode, servicePromocodeServiceClient]);

    const handleBuy = () => {
        setCanBuy(false);

        if (isUserLoggedIn) {
            if (!isFree) {
                setReadyToPay(true);
            } else {
                var path = window.location.pathname + '?successFreePurchase=true';
                navigate(path);
            }
        } else {
            navigate('/register');
        }
    }

    const handleValidatePromocode = (value) => {
        var amount = price;

        if (value.isValid) {
            const discountAmout = (parseFloat(price * value.discount))/parseFloat(100);
            amount = price - discountAmout;
            setState({price: price, total: amount, discount: value.discount, isFree: isFree, promocode: value.code, discountAmout: discountAmout});
            setApplyPromocode(true);
        } else {
            setState({price: price, total: amount, discount: 0, isFree: isFree, promocode: ''});
            setApplyPromocode(false);
        }
    }

    const handleNoValidatedPromocode = () => {
        var amount = price;

        if (discount && (discount.applyAll || discount.countryDiscountCode === userCountry)) {
            const discountAmout = (parseFloat(price * discount.discount))/parseFloat(100);
            amount = price - discountAmout;
            setState({price: price, total: amount, discount: discount.discount, isFree: isFree, promocode: '', discountAmout: discountAmout});
            setApplyPromocode(false);
        } else {
            setState({price: price, total: amount, discount: 0, isFree: isFree, promocode: ''});
            setApplyPromocode(false);
        }
    }

    return (
        <>
            <div className="buy-content-card">
                <div className="card-details">
                    {!state.isFree && hasPromocodesAvailable && (
                        <Promocode serviceId={contentId} serviceType={contentType}
                                   handleValidated={(value) => { handleValidatePromocode(value); }}
                                   handleNoValidated={() => { handleNoValidatedPromocode(); }}></Promocode>
                    )}
                    {state.isFree && (
                        <div className='price-container'>
                            <h1 className="price">{'Gratis!'}</h1>
                        </div>
                    )}
                    {!state.isFree && (<div className='price-container'>
                        {parseInt(contentType) === ServiceType.Course ? (
                            <h2 className="price-label">Precio Curso:</h2>
                        ) : (
                            <h2 className="price-label">Precio Recurso:</h2>
                        )}
                        <span className="price-without-discount">{`USD ${state.price.toFixed(2)}`}</span>
                    </div>)}

                    {!state.isFree && state.discount > 0 && (
                            <div className='price-with-discount-container'>
                                <div>
                                    {applyPromocode ? (
                                        <span className="price-with-discount-legend">{`Descuento promocion: ${state.discount}%`}</span>
                                    ) : (
                                        <span className="price-with-discount-legend">{`Descuento ${state.applyAll ? 'general' : 'por país'}: ${state.discount}%`}</span>
                                    )}
                                </div>
                                <div>
                                    <span className="price-without-discount">{`USD ${state.discountAmout.toFixed(2)}`}</span>
                                </div>
                            </div>
                    )}

                    {!state.isFree && (
                        <div className='total-price-container'>
                            <h1 className="total-price-label">{`Total:`}</h1>
                            <h1 className="total-price">{`USD ${state.total.toFixed(2)}`}</h1>
                        </div>
                    )}

                    <h4 className="description">{description}</h4>
                    {items.map((item) => (
                        <h4 key={item.id} className="checks-item"><i className="ti-check"></i>{item.name}</h4>
                    ))}

                    {canBuy && (
                        <button className="buy-button" onClick={() => handleBuy()}>Comprar</button>
                    )}
                </div>
            </div>
            {readyToPay && (
                <Payment price={state.total} returnUrl={`buy-content/${contentId}/${contentType }/${applyPromocode ? state.promocode ?? '' : ''}`}></Payment>
            )}
            {successfulPurchase && (
                <PurchaseSummary message={`Felicitaciones!. La compra ha sido exitosa. Los ${parseInt(contentType) === ServiceType.Course ? 'cursos' : 'recursos'} comprados pueden verse en la sección de la biblioteca.`}></PurchaseSummary>
            )}
        </>
    )
}
)