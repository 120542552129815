import React, { useState, useEffect, useMemo, useCallback } from 'react';
import LoadingSpinner from "../../Shared/LoadingSpinner/index";
import './style.scss';
import { particularExperienceService } from '../../../services/particularExperienceService';
import { sessionManager } from '../../../sessionManager'
import { InactiveStep } from './Steps/InactiveStep';
import { GeneralConditionsStep } from './Steps/GeneralConditionsStep';
import { ParticularExperienceStep } from '../../../Utils/types';
import { ThemesStep } from './Steps/ThemesStep';
import { Steper } from '../../Shared/Steper';
import { AvailabilityStep } from './Steps/AvailabilityStep';

export const ParticularExperience = () => {
    const particularExperienceServiceClient = useMemo(() => new particularExperienceService(), []);
    const manager = new sessionManager();
    const [loading, setLoading] = useState(true);
    const [particularExperience, setParticularExperience] = useState({stateId: 0});
    const userLoggedIn = JSON.parse(manager.getUserSession());

    const [currentStep, setCurrentStep] = useState(0);

    const getParticularExperience = useCallback(async () => {
        const response = await particularExperienceServiceClient.getByTeacherId(userLoggedIn.userId);

            if (response.success) {
                setParticularExperience(response.value);

                var currentStep = 0;
                switch (particularExperience.stateId)
                {
                    case 0:
                        currentStep = ParticularExperienceStep.Inactive;
                        break;
                    case 1:
                        currentStep = ParticularExperienceStep.GeneralConditions;
                        break;
                    case 2:
                        currentStep = ParticularExperienceStep.Themes;
                        break;
                    case 3:
                    case 4:
                    case 5:
                        currentStep = ParticularExperienceStep.Availability;
                        break;
                    default:
                        currentStep = ParticularExperienceStep.Inactive;
                        break;

                }
                setCurrentStep(currentStep);
                setLoading(false);
            }
    },[particularExperienceServiceClient, userLoggedIn.userId, particularExperience.stateId]);

    useEffect(() => {
        const fetchData = async () => {
            getParticularExperience();
        };
        fetchData();

    }, [particularExperienceServiceClient, getParticularExperience, userLoggedIn.userId]);

    const handleActivate = async () => {
        getParticularExperience();
    }

    const handleSaveGeneralConditions = async () => {
        getParticularExperience();
    }

    const saveTheme= async () => {
        setLoading(true);
        getParticularExperience();
    }

    const backToGeneralConditions = () => {
        setCurrentStep(ParticularExperienceStep.GeneralConditions);
    }

    const saveThemesStep = () => {
        getParticularExperience();
    }

    const backToThemes = () => {
        setCurrentStep(ParticularExperienceStep.Themes);
    }

    const saveAvailabilityStep = () => {
        getParticularExperience();
    }

    const deactivate = () => {
        getParticularExperience();
    }

    const steps = [
        {
            label: 'manage_services.particular.stepper.activate_step',
            icon: '',
            canClick: false,
            active: particularExperience.stateId === 0,
            completed: particularExperience.stateId > 0
        },
        {
            label: 'manage_services.particular.stepper.general_conditions_step',
            icon: '',
            canClick: false,
            active: particularExperience.stateId === 1,
            completed: particularExperience.stateId > 1
        },
        {
            label: 'manage_services.particular.stepper.themes_step',
            icon: '',
            canClick: false,
            active: particularExperience.stateId === 2,
            completed: particularExperience.stateId > 2
        },
        {
            label: 'manage_services.particular.stepper.availability_step',
            icon: '',
            canClick: false,
            active: particularExperience.stateId === 3,
            completed: particularExperience.stateId > 3
        },
      ];

    return (
        <>
            <Steper handleOptionChange={(step) => setCurrentStep(step)} steps={steps}></Steper>
            {loading ? <LoadingSpinner /> : (
                <div className="manage-service-particular-experience">
                    {currentStep === ParticularExperienceStep.Inactive && (
                        <InactiveStep handleActivate={handleActivate}></InactiveStep>
                    )}
                    {currentStep === ParticularExperienceStep.GeneralConditions && (
                        <GeneralConditionsStep handleSaveGeneralConditions={handleSaveGeneralConditions} particularExperience={particularExperience}></GeneralConditionsStep>
                    )}
                    {currentStep === ParticularExperienceStep.Themes && (
                        <ThemesStep handleSaveTheme={saveTheme} themes={particularExperience.themes}
                                    hasAvailability={particularExperience.hasAvailability} 
                                    handleBack={backToGeneralConditions}
                                    handleSaveThemesStep={saveThemesStep}></ThemesStep>
                    )}
                    {currentStep === ParticularExperienceStep.Availability && (
                        <AvailabilityStep handleBack={backToThemes} handleSaveAvailabilityStep={saveAvailabilityStep}
                                          particularExperience={particularExperience} handleDeactivate={deactivate}></AvailabilityStep>
                    )}
                </div>
            )}
        </>
    )
}