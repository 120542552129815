import { useTranslation } from "react-i18next"
import { NavMenu } from "../Menu/NavMenu";
import './style.scss';
import { FavoritesItems } from "./Items";
import LoadingSpinner from "../Shared/LoadingSpinner";
import { useEffect, useState } from "react";
import { getUserLoggedIn } from "../../Utils/utils";
import { ServiceType } from "../../Utils/types";
import { favoriteService } from "../../services/favoriteService";

export const Favorite = () => {
    const { t } = useTranslation(['favorite']);
    const [loading, setLoading] = useState(true);
    const [courses, setCourses] = useState([]);
    const [resources, setResources] = useState([]);
    const [groups, setGroups] = useState([]);

    const userLoggedIn = getUserLoggedIn();

    useEffect(() => {
        const fetchData = async () => {
            favoriteService.getAllByUserId(userLoggedIn.userId)
                .then(response => {
                    setCourses(response.filter(i => i.serviceType === ServiceType.Course));
                    setResources(response.filter(i => i.serviceType === ServiceType.Resource));
                    setGroups(response.filter(i => i.serviceType === ServiceType.GrupalExperience));
                    setLoading(false);
                })
                .catch(() => {
                    setLoading(false);
                });
        };
        fetchData();

    }, [userLoggedIn.userId]);
    

    const handleRemove = () => {
        window.location.reload(false);
        // setLoading(true);
        // favoriteService.getAllByUserId(userLoggedIn.userId)
        //         .then(response => {
        //             setCourses(response.filter(i => i.serviceType === ServiceType.Course));
        //             setResources(response.filter(i => i.serviceType === ServiceType.Resource));
        //             setGroups(response.filter(i => i.serviceType === ServiceType.GrupalExperience));
        //             setLoading(false);
        //         })
        //         .catch(() => {
        //             setLoading(false);
        //         });
    }

    return (
        <>
            <NavMenu showNavigation={true} currentPage={'favorites'} />
            <div className="favorite">
                <div className="favorite-section">
                    <h2 className="favorite-section-title">{t('courses.title', {ns: 'favorite'})}</h2>
                    {loading ? <LoadingSpinner /> : (
                        <FavoritesItems items={courses} handleRemove={() => handleRemove()}></FavoritesItems>
                    )}
                </div>
                <div className="favorite-section">
                    <h2 className="favorite-section-title">{t('resources.title', {ns: 'favorite'})}</h2>
                    {loading ? <LoadingSpinner /> : (
                        <FavoritesItems items={resources} handleRemove={() => handleRemove()}></FavoritesItems>
                    )}
                </div>
                <div className="favorite-section">
                    <h2 className="favorite-section-title">{t('groups.title', {ns: 'favorite'})}</h2>
                    {loading ? <LoadingSpinner /> : (
                        <FavoritesItems items={groups} handleRemove={() => handleRemove()}></FavoritesItems>
                    )}
                </div>
            </div>
        </>
    )
}