import React, { useState, useEffect, useMemo, useCallback } from 'react';
import './style.scss';
import { QualificationService } from '../../../../services/qualificationService';
import LoadingSpinner from "../../../Shared/LoadingSpinner/index";
import { StarItem } from '../../../Shared/StarItem/index';
import { CreateUpdateReview } from './CreateUpdateReview/index';
import { ReviewList } from '../../../Shared/ReviewsList/index';
import { ReviewStars } from '../../../Shared/ReviewStars/index';

export const Qualification = ({serviceId, serviceType, userId}) => {
    const qualificationServiceClient = useMemo(() => new QualificationService(), []);

    const [stars, setStars] = useState([
        {quantity: 0, avg: 0},
        {quantity: 0, avg: 0}, 
        {quantity: 0, avg: 0},
        {quantity: 0, avg: 0},
        {quantity: 0, avg: 0}]);
    const [averageQualifications, setAverageQualifications] = useState(0);
    const [totalReview, setTotalReviews] = useState(0);
    const [qualificationByUser, setQualificationByUser] = useState({});
    const [loading, setLoading] = useState(true);
    const [allReviews, setAllReviews] = useState(0);


    const getAllQualificationsByServiceIdAndServiceType = useCallback(async () => {
        const qualificationsResponse = await qualificationServiceClient.getAllByServiceIdAndServiceType(serviceId, serviceType);

        if (qualificationsResponse.success) {
            var qualifications = qualificationsResponse.value;

            setAllReviews(qualificationsResponse.value);

            var reviews = 0;
            var totalQualifications = 0;

            var starArray = [
                {quantity: 0, avg: 0},
                {quantity: 0, avg: 0}, 
                {quantity: 0, avg: 0},
                {quantity: 0, avg: 0},
                {quantity: 0, avg: 0}];

            qualifications.forEach(item => {
                starArray[item.qualification - 1].quantity = starArray[item.qualification - 1].quantity + 1;
                totalQualifications = item.qualification + totalQualifications;
                reviews++;
            });

            starArray.forEach(item => {
                item.avg =  (item.quantity / (reviews === 0 ? 1 : reviews)) * 100;
            });

            setStars(starArray);
            var avg = totalQualifications / (reviews === 0 ? 1 : reviews);
            setAverageQualifications(parseFloat(avg).toFixed(1));
            setTotalReviews(reviews);

            var qualificationByUser = qualifications.filter((q) => q.userId === userId)[0];
            setQualificationByUser(qualificationByUser ?? {qualificationId: 0 , userId: userId, serviceId: serviceId, serviceType: serviceType});

            setLoading(false);
        }
    },[qualificationServiceClient, serviceId, serviceType, userId]);

    useEffect(() => {
        const fetchData = async () => {
            getAllQualificationsByServiceIdAndServiceType();
        };
        fetchData();

    }, [getAllQualificationsByServiceIdAndServiceType]);

    const handleSaveQualifications = () => {
        getAllQualificationsByServiceIdAndServiceType();
    }

    return (
        <>
        {loading ? <LoadingSpinner /> : (
            <div className="qualification-section">
                <div className='row'>
                    <div className="col-xl-4 qualification-value">
                        <h2>{averageQualifications}</h2>
                        <div className="qualification-star">
                            <ReviewStars averageQualifications={averageQualifications}></ReviewStars>
                            <h4 className="reviews">Basado en {totalReview} Reseña/s.</h4>
                        </div>
                    </div>
                    <div className="col-xl-4">
                        <div className="qualification-for-star">
                            <StarItem starNumber={5} starPercentage={stars[4].avg}></StarItem>
                            <StarItem starNumber={4} starPercentage={stars[3].avg}></StarItem>
                            <StarItem starNumber={3} starPercentage={stars[2].avg}></StarItem>
                            <StarItem starNumber={2} starPercentage={stars[1].avg}></StarItem>
                            <StarItem starNumber={1} starPercentage={stars[0].avg}></StarItem>
                        </div>
                    </div>
                    <div className="col-xl-4">
                        <div className='qualification-review-form'>
                            <CreateUpdateReview qualification={qualificationByUser} handleSaveQualifications={handleSaveQualifications}></CreateUpdateReview>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className="col-xl-12">
                        <ReviewList averageQualifications={averageQualifications} reviews={allReviews}></ReviewList>
                    </div>
                </div>
            </div>
            )}
        </>
    )
}