import { Formik } from "formik";
import { DefaultOption, Durations, OfferFirstFreeTo, OfferServiceTo, TimeAdvanceCancel, TimeAdvanceContract } from "../../../../../../Utils/types"
import { FieldGroup, FieldItem, InputFieldItem, SelectFieldItem, TextAreaFieldItem } from "../../../../../Shared/FormHelpers/index"
import { useEffect, useState } from "react";
import { hireFieldNames} from '../../index';
import './style.scss';
import { getFormInitialValues } from "../../../../../../Utils/utils";

export const Summary = ({ particularExperience, themeId, topicId, description, event }) => {
    const [generalConditions, setGeneralConditions] = useState({});
    const [themes, setThemes] = useState([]);
    const [topics, setTopics] = useState([]);

    const mapTheme = (data) => {
        return { key: data.themeId, value: data.subcategory };
    }

    const mapTopic = (data) => {
        return { key: data.topicId, value: data.topic };
    }

    useEffect(() => {
        const fetchData = async () => {
            setGeneralConditions(
                {
                    duration: Durations.filter(o => o.key === particularExperience.duration.toString())[0].value,
                    offerFirstFreeTo: OfferFirstFreeTo.filter(o => o.key === particularExperience.offerFirstFreeTo.toString())[0].value.toUpperCase(),
                    timeAdvanceCancel: TimeAdvanceCancel.filter(o => o.key === particularExperience.advanceCancellationPeriod.toString())[0].value,
                    timeAdvanceContract: TimeAdvanceContract.filter(o => o.key === particularExperience.advanceHirePeriod.toString())[0].value,
                    offerServiceTo: OfferServiceTo.filter(o => o.key === particularExperience.offerServiceTo.toString())[0].value.toUpperCase(),
                }
            );

            var themeFiltered = particularExperience.themes.filter(t => t.themeId.toString() === themeId);
            if (themeFiltered.length > 0) {
                setTopics(themeFiltered[0].topics.map(mapTopic));
            } else {
                setTopics([]);
            }

            setThemes(particularExperience.themes.map(mapTheme));

        };
        fetchData();

    }, [particularExperience, themeId]);

    const _getFormInitialValues = () => {
        let initialValues = getFormInitialValues(hireFieldNames);

        initialValues = {
            event: event.replaceAll("'", ""),
            topicId: topicId,
            description: description,
            themeId: themeId,
        };

        return initialValues;
    };

    return (
        <div className="summary-details-section details-section">
            <div className="hire">
                <h2 className="title">Precio Experiencia</h2>
                <h1 className="price">USD {particularExperience.price}</h1>

                <h4 className="option">
                    <i className="ti-check"></i>
                    Primera experiencia gratuita a {generalConditions.offerFirstFreeTo}
                </h4>
                <h4 className="option">
                    <i className="ti-check mr-2 text-white"></i>
                    Duración de la experiencia: {generalConditions.duration}
                </h4>
                <h4 className="option">
                    <i className="ti-check mr-2 text-white"></i>
                    Plazo para que el edowier cancele: hasta {generalConditions.timeAdvanceCancel} antes
                </h4>
                <h4 className="option">
                    <i className="ti-check mr-2 text-white"></i>
                    Plazo para que el usuario contrate: hasta {generalConditions.timeAdvanceContract} antes
                </h4>
                <h4 className="option">
                    <i className="ti-check mr-2 text-white"></i>
                    Ofrece experiencia a {generalConditions.offerServiceTo}
                </h4>
                <Formik initialValues={_getFormInitialValues()} >
                    {() => (
                         <fieldset className="form-fields">
                            <FieldGroup>
                                <FieldItem className="field-item">
                                    <FieldGroup>
                                        <SelectFieldItem
                                            fieldName={hireFieldNames.themeId}
                                            id={hireFieldNames.themeId}
                                            defaultOption={DefaultOption}
                                            values={themes}
                                            disabled={true}
                                            className="field-item--100"
                                        />
                                        <SelectFieldItem
                                            fieldName={hireFieldNames.topicId}
                                            id={hireFieldNames.topicId}
                                            defaultOption={DefaultOption}
                                            values={topics}
                                            disabled={true}
                                            className="field-item--100"
                                        />
                                        <TextAreaFieldItem
                                            type="textarea"
                                            fieldName={hireFieldNames.description}
                                            placeholder={''}
                                            className="field-item--100"
                                            disabled={true}
                                        />
                                        <InputFieldItem
                                            type="text"
                                            fieldName={hireFieldNames.event}
                                            id={hireFieldNames.event}
                                            className="field-item--100"
                                            disabled={true}
                                        />
                                    </FieldGroup>
                                </FieldItem>
                            </FieldGroup>
                        </fieldset>
                    )}
                </Formik>
            </div>
        </div>
    )
}