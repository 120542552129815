import { useTranslation } from "react-i18next";

export const WhoUseServiceSection = () => {
    const { t } = useTranslation(['terms_and_conditions']);
    const ageRequirementsSection = t('whoCanUseServiceSection.ageRequirementsSection');
    const permissionFatherMotherGuardianSection = t('whoCanUseServiceSection.permissionFatherMotherGuardianSection');
    const companiesSection = t('whoCanUseServiceSection.companiesSection');

    return (
        <>
            <p className="section-subtitle">{ageRequirementsSection.title}</p>
            <p className="section-legend">
                {ageRequirementsSection.legend}
            </p>
            <p className="section-subtitle pt-15">{permissionFatherMotherGuardianSection.title}</p>
            <p className="section-legend">
                {permissionFatherMotherGuardianSection.legend1}
            </p>
            <p className="section-legend pt-05">
                {permissionFatherMotherGuardianSection.legend2}
            </p>
            <p className="section-subtitle pt-15">{companiesSection.title}</p>
            <p className="section-legend">
                {companiesSection.legend}
            </p>
        </>
    )
}