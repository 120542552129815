import React from 'react';
import './style.scss';
import DisabledStar from '../../../general/images/star-disable.png';
import Star from '../../../general/images/star.png';

export const ReviewStars = ({averageQualifications}) => {

    var avg = parseFloat(averageQualifications).toFixed(0);
    var stars = [1, 2, 3, 4, 5];

    return (
        <div className="stars">
            {stars.map(function(star, i){
                if (star <= avg) {
                    return <img key={i} src={Star} alt="star" className="star" />
                } else {
                    return <img key={i} src={DisabledStar} alt="star" className="star" />
                }
                
            })}
        </div>
    )
}