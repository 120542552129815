import React, { useState, useEffect, useMemo, useCallback } from 'react';
import LoadingSpinner from "../../Shared/LoadingSpinner/index";
import './style.scss';
import { contentService } from '../../../services/contentService';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { ContentItem } from './ContentItem/index';
import { Trans, useTranslation } from 'react-i18next';
import { InjectAppServices } from '../../../services/dependency-injection';

const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 4,
        slidesToSlide: 4 // optional, default to 1.
    },
    tablet: {
        breakpoint: { max: 1024, min: 768 },
        items: 3,
        slidesToSlide: 3 // optional, default to 1.
    },
    mobile: {
        breakpoint: { max: 767, min: 464 },
        items: 2,
        slidesToSlide: 1 // optional, default to 1.
    },
    mobile2: {
        breakpoint: { max: 463, min: 300 },
        items: 1,
        slidesToSlide: 1 // optional, default to 1.
    }
};

export const Contents = InjectAppServices(
    ({  type,
        dependencies: { sessionManager }
    }) => {
    const { t } = useTranslation(['manage_services']);

    const contentServiceClient = useMemo(() => new contentService(), []);
    const [loading, setLoading] = useState(true);
    const [contents, setContents] = useState([]);
    const userLoggedIn = sessionManager.getUserLoggedIn();

    const getContents = useCallback(async () => {
        var contentsResponse = null;

        if (type === 'course') {
            contentsResponse = await contentServiceClient.getCourses(userLoggedIn.userId);
        } else {
            contentsResponse = await contentServiceClient.getResources(userLoggedIn.userId);
        }

        if (contentsResponse.success) {
            setContents(contentsResponse.value);
            setLoading(false);
        }
    }, [contentServiceClient, setContents, setLoading, userLoggedIn.userId, type]);

    useEffect(() => {
        const fetchData = async () => {
            getContents();
        };
        fetchData();

    }, [getContents]);

    const handleDelete = async () => {
        setLoading(true);
        getContents();
    }

    return (
        <>
            <div className="contents">
                <div className="title">
                    <h2>
                        {<Trans
                            t={t}
                            i18nKey={`contents.${type}s.title`}
                            components={{ bold: <strong /> }}
                        />}
                    </h2>
                </div>
                <div className="contents-container">
                    <p className="sub-title">{t(`contents.${type}s.subtitle`)}</p>
                    {loading ? <LoadingSpinner /> : (
                        <Carousel
                            responsive={responsive}
                            autoPlay={false}
                            swipeable={true}
                            draggable={true}
                            showDots={true}
                            infinite={false}
                            partialVisible={false}
                            dotListClass="custom-dot-list-style"
                        >
                            {contents.map((content) => (
                                <ContentItem key={content.id} contentType={type}
                                    id={content.id}
                                    subcategory={content.subcategory} coverImage={content.coverImage.path} isFree={content.isFree}
                                    price={content.price} title={content.title} description={content.description} 
                                    hasDiscount={content.hasDiscount} handleDeleteContent={() => handleDelete()}
                                 >
                                </ContentItem>
                            ))}

                        </Carousel>
                    )}
                </div>
                <div className="row">
                    <div className="col-lg-12 col-sm-12 publish-button">
                        <a href={`/manage-services/${type === 'course' ? 'course' : 'resource'}`} >{t('postButton')}</a>
                    </div>
                </div>
            </div>
        </>
    );
},
)
