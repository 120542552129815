import React from 'react';
import './style.scss'
import { useTranslation } from 'react-i18next';

export const UserCard = ({ card }) => {
    const { t } = useTranslation(['home']);
    const userCard = t('userCard');

    return (
        <div className="admin-user-card">
            <div className="admin-user-card-body">
                <a href="/my-profile">
                    <figure className="avatar ml-auto mr-auto mb-0 mt-2 w90">
                        <img src={card.profilePhoto} className="user-photo-big" alt='user' />
                    </figure>
                </a>
                <h2 className="admin-user-card-body-username">{card.username}</h2>
                <h4 className="admin-user-card-body-perfil">{card.profile}</h4>
                <div className="clearfix"></div>
                <div className="mt-3">
                    <a href={`/messages`} title={userCard.messagesTooltip} className="admin-user-card-body-buttons admin-user-card-body-messages">
                        {card.hasUnreadConversation && (<span className="dot-count bg-warning"></span>)}
                        <i className="text-current ti-comment-alt font-sm"></i>
                    </a>
                    <a href={`/relations/${card.userId}`} title={userCard.myNetworkTooltip} className="admin-user-card-body-buttons">
                        <i className="feather-users font-sm"></i></a>
                    <a href={`/diary`} title={userCard.myAgendaTooltip} className="admin-user-card-body-buttons agenda">
                        {card.hasFutureEvents && (<span className="dot-count bg-warning"></span>)} 
                        <i className="feather-tv font-sm"></i>
                    </a>
                </div>
            </div>
        </div>
    )
}
