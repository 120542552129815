import { useTranslation } from "react-i18next";
import LoadingSpinner from "../Shared/LoadingSpinner";
import { useEffect, useMemo, useState } from "react";
import { NavMenu } from "../Menu/NavMenu";
import './style.scss';
import { NotificationService } from "../../services/notificationService";
import { getUserLoggedIn } from "../../Utils/utils";
import { PaginatedItems } from './PaginatedItems/index';

export const MyNotifications = () => {
    const notificationServiceClient = useMemo(() => new NotificationService(),[]);
    const itemsPerPage = parseInt(process.env.REACT_APP_ACTIVITIES_PER_PAGE);
    const { t } = useTranslation();
    const [notifications, setNotifications] = useState([]);
    const [loading, setLoading] = useState(true);

    const userLoggedIn = getUserLoggedIn(0);

    useEffect(() => {
        const fetchData = async () => {
            const response = await notificationServiceClient.getNotificationsByUserId(userLoggedIn.userId);

            if (response.success) {
                setNotifications(response.value);
                setLoading(false);
            }
        };
        fetchData();

    }, [userLoggedIn.userId, notificationServiceClient]);

    return (
        <>
        {loading ? <LoadingSpinner /> : (
            <>
                <NavMenu showNavigation={true} currentPage={'my-notifications'} />  
                <div className="my-notifications">
                    <div className="row">
                        <div className="col-sm-2"></div>
                        <div className="col-sm-8">
                            <div className="my-notifications-header">
                                <h3 className="my-notifications-title">{t('my_notifications.title')}</h3>
                            </div>
                            <PaginatedItems itemsPerPage={itemsPerPage} items={notifications}>   
                            </PaginatedItems>
                        </div>
                        <div className="col-sm-2"></div>
                    </div>
                </div>
            </>
        )}
        </>
    )
}