import React, { useState, useEffect, useMemo, useCallback } from 'react';
import LoadingSpinner from "../../Shared/LoadingSpinner/index";
import './style.scss';
import { groupExperienceService } from '../../../services/groupExperienceService';
import { sessionManager } from '../../../sessionManager';
import Carousel from 'react-multi-carousel';
import { GroupExperienceItem } from './GroupExperienceItem';
import { ResponsiveCarouselOption } from '../../../Utils/types';
import { Trans, useTranslation } from 'react-i18next';

export const GroupExperiences = ({ type }) => {
    const { t } = useTranslation(['manage_services']);
    const groupExperienceServiceClient = useMemo(() => new groupExperienceService(), []);
    const manager = new sessionManager();
    const [loading, setLoading] = useState(true);
    const [groupExperiences, setGroupExperiences] = useState([]);
    const userLoggedIn = JSON.parse(manager.getUserSession());
    
    const getGroupExperiences = useCallback(async () => {
        var response = await groupExperienceServiceClient.getGroupsExperiences(userLoggedIn.userId);

        if (response.success) {
            setGroupExperiences(response.value);
            setLoading(false);
        }
    }, [groupExperienceServiceClient, setGroupExperiences, setLoading, userLoggedIn.userId]);

    useEffect(() => {
        const fetchData = async () => {
            getGroupExperiences();
        };
        fetchData();

    }, [getGroupExperiences]);

    const deleteGroupExperience = () => {
        getGroupExperiences();
    }

    return (
        <>
            <div className="group-experiences">
                <div className="group-experiences-title">
                    <h2>
                        {<Trans
                            t={t}
                            i18nKey={`groupExperiences.title`}
                            components={{ bold: <strong /> }}
                        />} 
                    </h2>
                </div>
                <div className="group-experiences-container">
                    <p className="group-experiences-container-subtitle">{t(`groupExperiences.subtitle`)}</p>
                    {loading ? <LoadingSpinner /> : (
                        <Carousel
                            responsive={ResponsiveCarouselOption}
                            autoPlay={false}
                            swipeable={true}
                            draggable={true}
                            showDots={true}
                            infinite={false}
                            partialVisible={false}
                            dotListClass="custom-dot-list-style"
                        >
                            {groupExperiences.map((groupExperience) => (
                                <GroupExperienceItem key={groupExperience.groupExperienceId} 
                                                     groupExperience={groupExperience}
                                                     handleDelete={() => deleteGroupExperience()}>
                                </GroupExperienceItem>
                            ))}

                        </Carousel>
                    )}
                </div>
                <div className="row">
                    <div className="col-lg-12 col-sm-12 group-experiences-create-button">
                        <a href={`/manage-services/group-experience`} >{t('createButton')}</a>
                    </div>
                </div>
            </div>
        </>
    )
}