import { Field, Form, Formik } from 'formik';
import { InjectAppServices } from '../../../services/dependency-injection';
import './style.scss';
import { FieldGroup, FieldItem } from '../FormHelpers';
import { getFormInitialValues } from '../../../Utils/utils';
import { useTranslation } from 'react-i18next';
import { Message } from '../Message';
import { useState } from 'react';

const fieldNames = {
    promocode: 'promocode',
  };

const PromocodeFieldItem = ({
    fieldName,
    label,
    placeholder,
    validationError,
    disabled,
    ...rest
  }) => {
  
    return (
      <>
        <FieldItem className="field-item">
            <FieldGroup>
                <FieldItem className={`field-item field-item--70 buy-section-promocode-code`} fieldName={fieldNames.promocode}>
                    <label className='buy-section-promocode-code-label' htmlFor={fieldNames.promocode}>{label}</label>
                    <Field
                        type={'text'}
                        name={fieldNames.promocode}
                        id={fieldNames.promocode}
                        placeholder={placeholder}
                        //validate={validate}
                        {...rest}
                    />
                </FieldItem>
                <FieldItem className="field-item field-item--30 buy-section-promocode-apply">
                    <button type="submit" className="buy-section-promocode-apply-button" disabled={disabled}>
                        {'Aplicar'}
                    </button>
                </FieldItem>
            </FieldGroup>
            <FieldGroup>
                <FieldItem className={`field-item field-item--100`}>
                    {validationError && (
                        <div className="error-form">
                            <Message message={validationError} type={'error'} />
                        </div>
                    )}
                </FieldItem>
            </FieldGroup>
        </FieldItem>
      </>
    );
  };

export const Promocode = InjectAppServices(
    ({
        serviceId,
        serviceType,
        handleValidated,
        handleNoValidated,
        dependencies: { servicePromocodeServiceClient }
     }) => {

    const { t } = useTranslation(['validations']);

    const [validationError, setValidationError] = useState('');

    const onSubmit = async (value) => {
        servicePromocodeServiceClient.validate(serviceId, serviceType, value.promocode)
            .then(response => {

                if (response.isValid) {
                    setValidationError('');
                    handleValidated(response);
                } else {
                    setValidationError(t('promocodeIncorrect', {ns: 'validations'}));
                    handleNoValidated();
                }
            })
      };

      function validate(value) {
        if (!value) {
            //setValidationError(t('requiredField', {ns: 'validations'}));
            handleNoValidated();
        } 
    } 

    return (
        <div className='buy-section-promocode'>
            <Formik
                onSubmit={onSubmit}
                initialValues={getFormInitialValues(fieldNames)}
            >
            {() => (
                <Form>
                    <fieldset className="form-fields">
                        <FieldGroup>
                            <PromocodeFieldItem label={'Ingrese su codigo promocional'} placeholder={'Ingrese aquí codigo promocional'}
                                                validationError={validationError} validate={validate}></PromocodeFieldItem>
                        </FieldGroup>
                    </fieldset>
                </Form>
            )}
            </Formik>
        </div>
    )
})