import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';
import './style.scss';

export const EdowieDialog = (
    { 
        show, 
        dialogClass, 
        handleClose, 
        title, 
        children, 
        handleAccept ,
        disabledButtons
    }) => {

    const { t } = useTranslation(['common']);

    return (
        <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            className={`dialog ${dialogClass}`}
        >
            <Modal.Header closeButton className="header">
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {children}
            </Modal.Body>
            <Modal.Footer>
                <Button className="submit" onClick={() => handleAccept()} disabled={disabledButtons}>
                    {t('acceptButton')}
                </Button>
                <Button className="close" onClick={handleClose} disabled={disabledButtons}>
                    {t('cancelButton')}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}