import { useTranslation } from 'react-i18next';
import OneOnOne from '../../../../general/images/1 on 1.jfif';
import CursoOnline from '../../../../general/images/curso_online.jpg';
import Grupal from '../../../../general/images/grupal.jfif';
import './style.scss';

export const BlogPreviewSection = () => {
    const { t } = useTranslation(['landing_page']);
    const blogPreviewSection = t('blogPreviewSection');
    const contents = t('blogPreviewSection.contents');
    const particular = t('blogPreviewSection.particular');
    const groups = t('blogPreviewSection.groups');

    return (
        <section className="blog-preview-section" id="compartir">
            <div className="blog-preview-section-container">
                <div className="row blog-preview-section-container-header">
                    <div className="col-lg-8 col-xl-6">
                        <div className="header-container">
                            <h3 className="header-title">{blogPreviewSection.title}</h3>
                            <p className="header-description">{blogPreviewSection.description}</p>
                        </div>
                    </div>
                </div>
                <div className="row blog-preview-section-container-body">
                    <div className="col-lg-4 feature-section">
                        <div className="feature-card">
                            <img className="card-img-top" src={CursoOnline} alt="..." />
                            <div className="feature-card-body">
                                <div className="badge">{contents.tag}</div>
                                <a className="feature-card-body-link" href="/contents">
                                    <h2 className="link-title">{contents.title}</h2>
                                </a>
                                <p className="link-description">{contents.description}</p>
                                <div className="feature-card-button">
                                    <a className="button explore-button" href="/contents">{contents.button}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 feature-section">
                        <div className="feature-card">
                            <img className="card-img-top" src={OneOnOne} alt="..." />
                            <div className="feature-card-body">
                                <div className="badge">{particular.tag}</div>
                                <a className="feature-card-body-link" href={`/particular-experiences`}>
                                    <h2 className="link-title">{particular.title}</h2>
                                </a>
                                <p className="link-description">{particular.description}</p>
                                <div className="feature-card-button">
                                    <a className="button explore-button" href={`/particular-experiences`}>{particular.button}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 feature-section">
                        <div className="feature-card">
                            <img className="card-img-top" src={Grupal} alt="..." />
                            <div className="feature-card-body">
                                <div className="badge">{groups.tag}</div>
                                <a className="feature-card-body-link" href={`/group-experiences`}>
                                    <h2 className="link-title">{groups.title}</h2>
                                </a>
                                <p className="link-description">{groups.description}</p>
                                <div className="feature-card-button">
                                    <a className="button explore-button" href={`/group-experiences`}>{groups.button}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="register-button-container">
                    <a className="button register-button" href="/register/">{blogPreviewSection.register}</a>
                </div>
            </div>
        </section>
    )
}