import moment from "moment";
import './style.scss';
import { getUserLoggedIn } from "../../../../Utils/utils";
import { Trans } from "react-i18next";

export const Message = ({message}) => {
    const userLoggedIn = getUserLoggedIn();

    return (
        <div className="chat-message">
            <div className="chat-message-date">
                <span>
                    <Trans
                        i18nKey='messages.date_message'
                        values={{ date: new Date(message.date) }}
                    />
                </span>
            </div>
                
            {message.messages.map((message, index) => (
                <div key={`m${index}`} className='row'>
                    <div className='col-lg-6'>
                        {message.userId !== userLoggedIn.userId && (
                            <div className="chat-message-base chat-message-destination">
                                <div className="text-left">
                                    {message.message}
                                </div>
                                <div className="text-left pr-2">
                                    {moment(message.date).format("HH:mm")}
                                </div>
                            </div>
                        )}
                    </div>
                    <div className='col-lg-6'>
                        {message.userId === userLoggedIn.userId && (
                            <div className="chat-message-base chat-message-origin">
                                <div className="text-left">
                                    {message.message}
                                </div>
                                <div className="text-left pr-2">
                                    {moment(message.date).format("HH:mm")}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
        ))}
        </div>
    )
}