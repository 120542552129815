import React, { useState, useEffect, useMemo, useCallback } from 'react';
import './style.scss';
import { NavMenu } from '../../Menu/NavMenu';
import { Searcher } from '../../Shared/Searcher/index';
import { contentService } from '../../../services/contentService';
import LoadingSpinner from "../../Shared/LoadingSpinner/index";
import { PaginatedItems } from './PaginatedItems/index'
import { sessionManager } from '../../../sessionManager';
import { SeacherType } from '../../../Utils/types';
import { useTranslation } from 'react-i18next';

export const Contents = () => {
    const itemsPerPage = process.env.REACT_APP_CONTENTS_PER_PAGE;
    const { t } = useTranslation(['menu']);

    const contentServiceClient = useMemo(() => new contentService(), []);
    const manager = useMemo(() => new sessionManager(), []);
    const [loading, setLoading] = useState(true);
    const [contents, setContents] = useState([]);
    const [searchCriteria, setSearchCriteria] = useState({ categoryId: 0, searchText: ''});
    const [currentPage, setCurrentPage] = useState(0);
    const [forcePage, setForcePage] = useState(0);
    const isUserLoggedIn = manager.isUserLoggedIn();
    const userLoggedIn = JSON.parse(manager.getUserSession());
    const userId = isUserLoggedIn ? userLoggedIn.userId : 0;

    const getContents = useCallback(async () => {
        var contentsResponse = await contentServiceClient.getContentsByCategoryIdAndText(userId, searchCriteria.categoryId, searchCriteria.searchText, currentPage, itemsPerPage);

        if (contentsResponse.success) {
            setContents(contentsResponse.value);
            setLoading(false);
        }
    }, [contentServiceClient, setContents, setLoading, userId, searchCriteria.categoryId, searchCriteria.searchText, currentPage, itemsPerPage]);

    useEffect(() => {
        const fetchData = async () => {
            getContents();
        };
        fetchData();

    }, [getContents]);

    const handleSearch = (values) => {
        setCurrentPage(0);
        setForcePage(0);
        setSearchCriteria({ categoryId: values.category, searchText: values.text });
        setLoading(true);
    }

    const handlePageClick = (event) => {
        setCurrentPage((event.selected * itemsPerPage) % contents.totalItems);
        setForcePage(event.selected);
        setLoading(true);
      };

    return (
        <>
            <NavMenu showNavigation={true} currentPage={'contents'} />
            <div className="contents-menu">
                <h1 className='contents-menu-title'>{t('contents.title', {ns: 'menu'})}</h1>
                <Searcher handleSearch={(values) => { handleSearch(values); }} searcherBy={SeacherType.contents}></Searcher>
                {loading ? <LoadingSpinner /> : (
                    <div className="row card-content">
                        <PaginatedItems items={contents.items} totalItems={contents.totalItems} itemsPerPage={itemsPerPage} 
                                        currentPage={forcePage} handlePageClick={handlePageClick}></PaginatedItems>
                    </div>
                )}
            </div>
        </>
    )
}
