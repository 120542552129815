import { faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DELAY_BEFORE_REDIRECT, ProfileType, Status } from '../../../../../../Utils/types';
import { EdowieDialog } from '../../../../../Shared/Dialog';
import { useState } from 'react';
import { InjectAppServices } from '../../../../../../services/dependency-injection';
import { Message } from '../../../../../Shared/Message';
import useTimeout from '../../../../../../hooks/useTimeout';

export const ChangeProfileButton = InjectAppServices(
    ({ 
        profile,
        userId,
        dependencies: { administratorServiceClient },
        handleChangeProfile
    }) => {

        const createTimeout = useTimeout();
        const [showModal, setShowModal] = useState(false);
        const [status, setStatus] = useState('');
        const [error, setError] = useState('');

        const handleSubmit = () => {
            const profileToChange = profile === ProfileType.User ? ProfileType.Edowier : ProfileType.User;
            administratorServiceClient.changeProfile(userId, profileToChange)
            .then(response => {
                setStatus(Status.Saved);
                createTimeout(async() => {
                    setStatus('');
                    handleChangeProfile();
                    setShowModal(false);
                }, DELAY_BEFORE_REDIRECT);
                
            })
            .catch((error) => {
                setStatus(Status.HasError);
                setError(error);
            });
        }

        const handleClose = () => {
            setShowModal(false);
        }

        const showMessage = [Status.Saved, Status.HasError].includes(status);

        return (
            <>
                <button 
                    className="users-section-grid-user-buttons-column-change-profile"
                    title={profile === ProfileType.Edowier ? 'Dejar de ser Edowier' : 'Ser Edowier'}
                    onClick={() => setShowModal(true)}
                >
                    <FontAwesomeIcon icon={faUser} />
                </button>
                <EdowieDialog 
                    show={showModal} 
                    handleAccept={() => handleSubmit()} 
                    handleClose={() => handleClose()}
                    title={profile === ProfileType.Edowier ? 'Dejar de ser Edowier' : 'Ser Edowier'}
                    dialogClass={'change-profile-modal'}>
                    <div className="change-profile-modal-content">
                        {profile === ProfileType.Edowier ? 
                        'El usuario dejará de ser Edowier. Desea continuar?.' : 
                        'El usuario pasará a ser Edowier. Desea continuar?.'}
                    </div>
                    {showMessage && (
                        <Message
                            type={status === Status.Saved ? 'success' : 'error'}
                            message={
                                status === Status.Saved
                                    ? '¡Operación procesada con éxito!'
                                    : error}
                        />
                    )}
                </EdowieDialog>
            </>
        )
    }
)