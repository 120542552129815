import React, { useState, useEffect, useMemo } from 'react';
import { useParams } from "react-router-dom";
import './style.scss';
import { channelService } from '../../../../services/channelService';
import LoadingSpinner from "../../../Shared/LoadingSpinner/index";

export const Channels = () => {
    const channelServiceClient = useMemo(() => new channelService(), []);
    const [loading, setLoading] = useState(true);
    const { id } = useParams();
    const [channel, setChannel] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const channelResponse = await channelServiceClient.getChannel(id);

            if (channelResponse.success) {
                setChannel(channelResponse.value);
                setLoading(false);
            }
        };
        fetchData();

    }, [channelServiceClient, id]);

    return (
        <>
            {loading ? <LoadingSpinner /> : (
                <div className="channels">
                    <h2 className="title">Te invito a conocer mi chat por subscripción</h2>
                    <h2 className="link"><a href={channel.link} target="_blank" rel="noreferrer" >{channel.link}</a></h2>
                </div>
            )}
        </>
    )
}
