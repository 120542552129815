
import "./style.scss";
import React, { useState } from "react";
import { Dropdown, ButtonGroup } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import FlagEn from '../../../general/images/english-flag.svg'
import FlagEs  from '../../../general/images/spanish-flag.svg'
import { useTranslation } from "react-i18next";

export const LanguageSelector = () => {
    const { i18n } = useTranslation();

    const options = [
        {
          value: "es",
          label: (
            <div className="dropdown-menu-language-container">
                <img src={FlagEs} alt="" />
                <span>ES</span>
            </div>
          )
        }, //es
        {
          value: "en",
          label: (
            <div className="dropdown-menu-language-container">
                <img src={FlagEn} lt="" alt="flag" />
                <span>EN</span>
            </div>
          )
        } //en
      ];
    
    const [language, setLanguage] = useState(i18n.language === 'en' ? options[1].label : options[0].label);

    const handlclick = (n) => {
        setLanguage(options[n].label);
        i18n.changeLanguage(options[n].value);
    }

    return (
        <div className="language-selector">
            <Dropdown as={ButtonGroup}>
                <Dropdown.Toggle className="language-selector-dropdown-toggle">
                    {language}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                <Dropdown.Item onClick={() => handlclick(0)}>
                    {options[0].label}
                </Dropdown.Item>
                <Dropdown.Item onClick={() => handlclick(1)}>
                    {options[1].label}
                </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </div>
    )
}