import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { messageService } from '../../../services/messageService';
import './style.scss';
import { getUserLoggedIn } from '../../../Utils/utils';
import LoadingSpinner from '../../Shared/LoadingSpinner';
import moment from 'moment';
import { Message } from './Message';
import { MessageForm } from './MessageForm';
import useTimeout from '../../../hooks/useTimeout';

export const Chat = ({ recipient }) => {
    const messageServiceClient = useMemo(() => new messageService(), []);
    const userLoggedIn = getUserLoggedIn();
    const messageRef = useRef();

    const [loading, setLoading] = useState(true);
    const [messagesGrouped, setMessagesGrouped] = useState([]);
    const createTimeout = useTimeout();

    const recipientId = recipient ? recipient.userId : 0;

    const map = (items) => {
        const groups = [
            ['date', 'messages']
        ],
        result = items.reduce((r, o) => {
            groups
                .reduce((level, [key, children]) => {
                    let group = level.find(q => moment(q[key]).format("dddd, DD MMMM YYYY") === moment(o[key]).format("dddd, DD MMMM YYYY"));
                    if (!group) level.push(group = { [key]: o[key], [children]: [] });
                    return group[children];
                }, r)
                .push(o);
            return r;
        }, []);

        return result
    }

    const getMessagesByUserIdAndDestination = useCallback(async (origin, destination) => {
        const response = await messageServiceClient.getMessagesByUserIdAndDestination(origin, destination);

        if (response.success) {
            setMessagesGrouped(map(response.value));
            setLoading(false);
        }

    },[messageServiceClient]);

    useEffect(() => {
        const fetchData = async () => {

            if (recipientId > 0) {
                getMessagesByUserIdAndDestination(userLoggedIn.userId, recipientId);
            }

            createTimeout(() => {
                if (messageRef && messageRef.current) {
                    messageRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
            }}, 100);
        };
        fetchData();

    }, [messageServiceClient, getMessagesByUserIdAndDestination, userLoggedIn.userId, recipientId, createTimeout]);

    const sendMessage = async(values) => {

        if (values.message !== '') {
            var response = await messageServiceClient.sendMessage(userLoggedIn.userId, recipientId, values.message);

            if (response.success) {
                getMessagesByUserIdAndDestination(userLoggedIn.userId, recipientId);

                createTimeout(() => {
                    if (messageRef && messageRef.current) {
                        messageRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
                }}, 100);
            }
        }
    }

    return (
        <div ref={messageRef} >
        {recipient ? (
            loading ? <LoadingSpinner /> : (
                <>
                    <div className="send-messages-current-user-section">
                        <div className="send-messages-current-user-section-user-photo">
                                <img src={recipient.profileImage.path} alt="user"/>
                        </div>
                        <div className="send-messages-current-user-section-username">
                            <b>{recipient.username}</b>
                        </div>
                    </div>
                    <div className='chat'>
                        {messagesGrouped.map((message, index) => (
                            <Message key={index} keyItem={index} message={message}></Message>
                        ))}
                        <MessageForm hanldeSendMessage={(values) => sendMessage(values)}></MessageForm>
                    </div>
                    
                </>)
            ) : (
            <div className="send-messages-current-user-section">
                <h4 className='chat-without-recipient'>Seleccione una conversación</h4>
            </div>
        )}
        </div>
    )
}