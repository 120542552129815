import { getToken } from "../Utils/utils";

export class QualificationService {

    async addQualification(userId, serviceId, serviceType, values) {
        try {

            const edowieApi = process.env.REACT_APP_EDOWIE_API;
            const token = await getToken();
            const requestOptions = {
                method: 'POST',
                headers: { 
                    'Authorization': `bearer ${token}`,
                    'Content-Type': 'application/json' 
                },
                body: JSON.stringify(
                    {
                        "serviceId": serviceId,
                        "serviceType": serviceType,
                        "userId": userId,
                        "description": values.description,
                        "qualification": values.qualification
                    })
            };

            const response = await fetch(edowieApi + `qualification/${serviceId}/${serviceType}/qualification`, requestOptions);

            return { success: response.status === 200 }

        } catch (error) {
            return { success: false, error: error };
        }
    }

    async updateQualification(qualificationId, serviceType, values) {
        try {

            const edowieApi = process.env.REACT_APP_EDOWIE_API;
            const token = await getToken();
            const requestOptions = {
                method: 'PUT',
                headers: { 
                    'Authorization': `bearer ${token}`,
                    'Content-Type': 'application/json' 
                },
                body: JSON.stringify(
                    {
                        "serviceType": serviceType,
                        "description": values.description,
                        "qualification": values.qualification
                    })
            };

            const response = await fetch(edowieApi + `qualification/${qualificationId}/qualification`, requestOptions);

            return { success: response.status === 200 }

        } catch (error) {
            return { success: false, error: error };
        }
    }

    async getAllByServiceIdAndServiceType(serviceId, serviceType) {
        try {

            const edowieApi = process.env.REACT_APP_EDOWIE_API;
            const token = await getToken();
            const requestOptions = {
                headers: { 'Authorization': `bearer ${token}`, },
            };

            const response = await fetch(edowieApi + `qualification/${serviceId}/${serviceType}/qualifications`, requestOptions);
            const data = await response.json();

            return { success: true, value: data }

        } catch (error) {
            return { success: false, error: error };
        }
    }
}