import { Form, Formik } from 'formik';
import './style.scss';
import { FieldGroup, FieldItem, InputFieldItem } from '../../../Shared/FormHelpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { getFormInitialValues } from '../../../../Utils/utils';

const fieldNames = {
    message: 'message'
};

export const MessageForm = ({ hanldeSendMessage }) => {
    const { t } = useTranslation();

    const sendMessage = (values, { setFieldValue }) => {
        hanldeSendMessage(values);
        setFieldValue(fieldNames.message, '');
    };

    return (
        <div className="row">
            <div className="col-lg-12">
                <Formik
                    initialValues={getFormInitialValues(fieldNames)}
                    onSubmit={sendMessage}
                >
                    {() => (
                        <Form className='chat-message-form'>
                            <fieldset className="form-fields">
                                <FieldGroup> 
                                    <FieldItem className="field-item">
                                        <FieldGroup>
                                            <div className='row'>
                                                <div className='col-lg-11'>
                                                    <InputFieldItem
                                                        type="text"
                                                        fieldName={fieldNames.message}
                                                        id={fieldNames.message}
                                                        placeholder={t('messages.placeholder_message')} 
                                                    />
                                                </div>
                                                <div className='col-lg-1 send-message-section'>
                                                    <FieldItem className='field-item'>
                                                        <button className='send-button' title={t('messages.send_message_tooltip')}
                                                                type='submit' >
                                                            <i>
                                                                <FontAwesomeIcon icon={faArrowRight} />
                                                            </i>
                                                        </button>
                                                    </FieldItem>
                                                </div>
                                            </div>
                                        </FieldGroup>
                                    </FieldItem>
                                </FieldGroup>
                            </fieldset>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    )
}
