import { getToken } from "../Utils/utils";

export class channelService {

    async getChannel(teacherProfileId) {
        try {

            const edowieApi = process.env.REACT_APP_EDOWIE_API;
            const token = await getToken();
            const requestOptions = {
                headers: { 
                    'Authorization': `bearer ${token}`,
                }
            };

            const response = await fetch(edowieApi + 'channel/' + teacherProfileId.toString() + '/channel', requestOptions);
            const data = await response.json();

            return { success: true, value: data }

        } catch (error) {
            return { success: false, error: error };
        }
    }

    async save(userId, channel) {
        try {

            const edowieApi = process.env.REACT_APP_EDOWIE_API;
            const token = await getToken();
            const requestOptions = {
                method: 'POST',
                headers: { 
                    'Authorization': `bearer ${token}`,
                    'Content-Type': 'application/json' 
                },
                body: JSON.stringify({ "link": channel }),
            };

           await fetch(`${edowieApi}channel/${userId}/channel`, requestOptions);

            return { success: true }

        } catch (error) {
            return { success: false, error: error };
        }
    }

    async deactivate(userId, channelId) {
        try {

            const edowieApi = process.env.REACT_APP_EDOWIE_API;
            const token = await getToken();
            const requestOptions = {
                method: 'DELETE',
                headers: { 
                    'Authorization': `bearer ${token}`,
                    'Content-Type': 'application/json' 
                },
            };

           await fetch(`${edowieApi}channel/${userId}/channel/${channelId}`, requestOptions);

            return { success: true }

        } catch (error) {
            return { success: false, error: error };
        }
    }
}