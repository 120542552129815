import React, { useState } from 'react';
import './style.scss';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { InformationSection } from '../../../Shared/Contents/InformationSection/index';
import { DetailsSection } from '../../../Shared/Contents/DetailsSection/index';
import { ContentsSection } from '../../../Shared/Contents/ContentsSection/index';
import { Qualification } from '../Qualification/index';
import { UserSection } from '../../../Shared/Services/UserSection/index';
import { DescriptionSection } from '../../../Shared/Services/DescriptionSection/index';

export const TabsSection = ({content, contentType, userId, selectedFile, playTime}) => {

    const [activeTab, setActiveTab] = useState("2");

    const selectTab = (tabId) => {
        if (activeTab !== tabId) {
            setActiveTab(tabId);
        }
    }

    return (
        <>
            <div className="view-content-tabs">
                <div className="tabs">
                    <Nav tabs>
                        <NavItem>
                            <NavLink className={(activeTab === "1" ? "active nav-link" : "nav-link")} href="#"
                                onClick={() => selectTab("1")}>Detalle</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink className={(activeTab === "2" ? "active nav-link" : "nav-link")} href="#"
                                onClick={() => selectTab("2")}>Calificación</NavLink>
                        </NavItem>
                    </Nav>
                </div>
                <div className="tab-content">
                    <TabContent activeTab={activeTab}>
                        <TabPane tabId="1">
                            {activeTab === "1" && (
                                <>
                                    <UserSection serviceUserId={content.userId} userId={userId} 
                                        serviceLevel={content.level} serviceProfileImage={content.profileImage}
                                        serviceUsername={content.username}>
                                    </UserSection>
                                    <DescriptionSection description={content.description}>
                                    </DescriptionSection>
                                    <InformationSection description={content.description} expanded={false}>
                                    </InformationSection>
                                    <DetailsSection level={content.level} updatedDate={content.updatedDate} expanded={false} >
                                    </DetailsSection>
                                    <ContentsSection contentType={parseInt(contentType)} contents={content.files} contentDuration={content.courseDuration} expanded={false}>
                                    </ContentsSection>
                                </>
                            )}
                        </TabPane>
                        <TabPane tabId="2">
                            {activeTab === "2" && (
                                <Qualification serviceId={content.contentId} serviceType={contentType} userId={userId}></Qualification>
                            )}
                        </TabPane>
                    </TabContent>
                </div>
            </div>
        </>
    )
}