import './style.scss';
import { useTranslation } from 'react-i18next';

export const Steper = ({steps, handleOptionChange}) => {
    const { t } = useTranslation();

    const handleClick = (step, index) => {
        if (step.canClick) {
            handleOptionChange(index);
        }
    }
    return (
        <section className='theme-container'>
            <div className="theme-container-steper">
                <ul className="theme-steper-counter">
                    {steps.map((step, index) => (
                    <li key={index} className={step.active ? 'active' : step.completed ? 'completed' : ''} onClick={() => handleClick(step, index)}>
                        <span>
                        <span className={step.icon} />
                        {t(step.label)}
                        </span>
                    </li>
                    ))}
                </ul>
            </div>
        </section>
    )
}