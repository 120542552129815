import Carousel from "react-multi-carousel";
import { FavoriteItem } from './Item/index';
import { ResponsiveCarouselOption } from "../../../Utils/types";

export const FavoritesItems = ({ items, handleRemove }) => {

    return (
        <Carousel
            responsive={ResponsiveCarouselOption}
            autoPlay={false}
            swipeable={true}
            draggable={true}
            showDots={true}
            infinite={false}
            partialVisible={false}
            dotListClass="custom-dot-list-style"
        >
            {items.map((item, index) => (
                <FavoriteItem key={index} item={item} handleRemove={() => handleRemove()}>
                </FavoriteItem>
            ))}

        </Carousel>
        );
}