import React, { useState, useMemo } from 'react';
import './style.scss';
import { FieldGroup, FieldItem, ConfigurationFieldItem, SubmitButton } from '../../../Shared/FormHelpers/index';
import { Form, Formik } from 'formik';
import { getFormInitialValues } from '../../../../Utils/utils';
import { sessionManager } from '../../../../sessionManager';
import { Message } from '../../../Shared/Message/index';
import useTimeout from '../../../../hooks/useTimeout/index';
import { Status, DELAY_BEFORE_REDIRECT } from '../../../../Utils/types';
import { profileService } from '../../../../services/profileService';

const fieldNames = {
    automaticallyAcceptFollowerRequests: 'automaticallyAcceptFollowerRequests',
    showNameInPublicProfile: 'showNameInPublicProfile',
    followersOnlyMessagingEnable: 'followersOnlyMessagingEnable',
};

export const Configuration = ({ profile }) => {
    const profileServiceClient = useMemo(() => new profileService(), []);
    const manager = new sessionManager();
    const userLoggedIn = JSON.parse(manager.getUserSession());

    const [status, setStatus] = useState('');
    const [error, setError] = useState('');

    const createTimeout = useTimeout();

    const saveConfiguration = async (values) => {
        setStatus('');

        var response = await profileServiceClient.updateConfiguraton(values.automaticallyAcceptFollowerRequests, values.showNameInPublicProfile, values.followersOnlyMessagingEnable, userLoggedIn.userId);

        if (response.success) {
            setStatus(Status.Saved);
            createTimeout(() => {
                setStatus('');
            }, DELAY_BEFORE_REDIRECT);
        } else {
            setStatus(Status.HasError);
            setError(response.error);
        }
    }

    const _getFormInitialValues = () => {
        let initialValues = getFormInitialValues(fieldNames);

        if (profile !== null) {
            initialValues = { ...profile };
        }

        return initialValues;
    };

    const handleAutomaticallyAcceptFollowerRequestsChange = async (e, setFieldValue) => {
        setFieldValue(fieldNames.automaticallyAcceptFollowerRequests, '');
    };

    const handleShowNameInPublicProfileChange = async (e, setFieldValue) => {
        setFieldValue(fieldNames.showNameInPublicProfile, '');
    };

    const handleFollowersOnlyMessagingEnableFreeChange = async (e, setFieldValue) => {
        setFieldValue(fieldNames.followersOnlyMessagingEnable, '');
    };

    const showMessage = [Status.Saved, Status.HasError].includes(status);

    return (
        <>
            <div className="edit-profile">
                <div className="form">
                    <div className="row">
                        <div className="col-lg-12">
                            <Formik
                                onSubmit={saveConfiguration}
                                initialValues={_getFormInitialValues()}
                            >
                                {({ setFieldValue, isSubmitting, isValid, dirty }) => (
                                    <Form>
                                        <fieldset className="form-fields">
                                            <FieldGroup>
                                                <FieldItem className="field-item">
                                                    <FieldGroup>
                                                        <ConfigurationFieldItem
                                                            fieldName={fieldNames.automaticallyAcceptFollowerRequests}
                                                            key={fieldNames.automaticallyAcceptFollowerRequests}
                                                            label={'Aceptar automáticamente solicitudes de seguidores'}
                                                            id={fieldNames.isFree}
                                                            onChange={(e) => {
                                                                handleAutomaticallyAcceptFollowerRequestsChange(e, setFieldValue);
                                                            }}
                                                        />
                                                    </FieldGroup>
                                                </FieldItem>
                                                <FieldItem className="field-item">
                                                    <FieldGroup>
                                                        <ConfigurationFieldItem
                                                            fieldName={fieldNames.showNameInPublicProfile}
                                                            key={fieldNames.showNameInPublicProfile}
                                                            label={'Mostrar nombre en perfil público'}
                                                            id={fieldNames.isFree}
                                                            onChange={(e) => {
                                                                handleShowNameInPublicProfileChange(e, setFieldValue);
                                                            }}
                                                        />
                                                    </FieldGroup>
                                                </FieldItem>
                                                <FieldItem className="field-item">
                                                    <FieldGroup>
                                                        <ConfigurationFieldItem
                                                            fieldName={fieldNames.followersOnlyMessagingEnable}
                                                            key={fieldNames.followersOnlyMessagingEnable}
                                                            label={'Habilitar mensajería sólo a seguidores (para edowiers)'}
                                                            id={fieldNames.isFree}
                                                            onChange={(e) => {
                                                                handleFollowersOnlyMessagingEnableFreeChange(e, setFieldValue);
                                                            }}
                                                        />
                                                    </FieldGroup>
                                                </FieldItem>
                                                <FieldItem className="field-item">
                                                    <FieldGroup>
                                                        <div className="save-button">
                                                            <SubmitButton isSubmitting={isSubmitting}>
                                                                {'Guardar'}
                                                            </SubmitButton>
                                                        </div>
                                                    </FieldGroup>
                                                </FieldItem>
                                            </FieldGroup>
                                        </fieldset>
                                        {showMessage && (
                                            <fieldset>
                                                <Message
                                                    type={status === Status.Saved ? 'success' : 'error'}
                                                    message={
                                                        status === Status.Saved
                                                            ? '¡Operación procesada con éxito!'
                                                            : error}
                                                />
                                            </fieldset>
                                        )}
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}