import { faAward } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { EdowieDialog } from '../../../../../Shared/Dialog';
import { useState } from 'react';
import { DELAY_BEFORE_REDIRECT, Status } from '../../../../../../Utils/types';
import { Message } from '../../../../../Shared/Message';
import { InjectAppServices } from '../../../../../../services/dependency-injection';
import useTimeout from '../../../../../../hooks/useTimeout';

export const AmbassadorButton = InjectAppServices(
    ({ 
        isAmbassador,
        userId,
        dependencies: { administratorServiceClient },
        handleChangeAmbassador
    }) => {

        const createTimeout = useTimeout();
        const [showModal, setShowModal] = useState(false);
        const [status, setStatus] = useState('');
        const [error, setError] = useState('');

        const handleSubmit = () => {
            administratorServiceClient.ambassador(userId, !isAmbassador)
            .then(response => {
                setStatus(Status.Saved);
                createTimeout(async() => {
                    setStatus('');
                    handleChangeAmbassador();
                    setShowModal(false);
                }, DELAY_BEFORE_REDIRECT);
                
            })
            .catch((error) => {
                setStatus(Status.HasError);
                setError(error);
            });
        }

        const handleClose = () => {
            setShowModal(false);
        }

        const showMessage = [Status.Saved, Status.HasError].includes(status);

        return (
            <>
                <button 
                    className={`users-section-grid-user-buttons-column-change-profile ${!isAmbassador ? 'users-section-grid-user-buttons-column-change-profile-disabled' : ''}`}
                    title={isAmbassador ? 'Dejar de ser Embajador' : 'Ser Embajador'}
                    onClick={() => setShowModal(true)}>
                    <FontAwesomeIcon icon={faAward} />
                </button>
                <EdowieDialog 
                    show={showModal} 
                    handleAccept={() => handleSubmit()} 
                    handleClose={() => handleClose()}
                    title={isAmbassador ? 'Dejar de ser Embajador' : 'Ser Embajador'}
                    dialogClass={'ambassador-modal'}>
                    <div className="ambassador-modal">
                        {isAmbassador ? 
                        'El Edowier dejará de ser Embajador. Desea continuar?.' : 
                        'El Edowier pasará a ser Embajador. Desea continuar?.'}
                    </div>
                    {showMessage && (
                        <Message
                            type={status === Status.Saved ? 'success' : 'error'}
                            message={
                                status === Status.Saved
                                    ? '¡Operación procesada con éxito!'
                                    : error}
                        />
                    )}
                </EdowieDialog>
            </>
        )
    }
)