import React, { useState } from 'react';
import './style.scss';

export const DateOfClassesSection = ({ classes, expanded }) => {
    const [showSection, setShowSection] = useState(expanded ?? true);

    return (
        <div className="group-experience-date-of-classes-section">
            <div className="card-details">
                <div className="card-header">
                    <h5>
                        <button className="collapse-expand-button" onClick={() => setShowSection(!showSection)}>Fecha de las clases</button>
                    </h5>
                </div>
                {showSection && (
                    <div>
                        {classes.map((item) => (
                        <div key={item.title} className="card-body">
                            <span className="item-label">{item.title}</span>
                            <span className="item-content">{item.startDate}</span>
                        </div>
                        ))}

                    </div>
                )}
            </div>
        </div>  
    )
}