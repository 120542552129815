import { useTranslation } from 'react-i18next';
import { InjectAppServices } from '../../services/dependency-injection';
import { NavMenu } from '../Menu/NavMenu';
import './style.scss';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Events } from './Events';
import { getCurrentTime, translateCountry } from '../../Utils/utils';

export const Diary = InjectAppServices(
    ({
        dependencies: { sessionManager }
    }) => {

        const { t, i18n } = useTranslation(['diary']);
        moment.locale(i18n.language);
        const userLoggedIn = sessionManager.getUserLoggedIn();
        const [currentDate, setCurrentDate] = useState(moment(getCurrentTime(userLoggedIn.offset)));
        
        useEffect(() => {
            const interval = setInterval(async () => {
                setCurrentDate(moment(getCurrentTime(userLoggedIn.offset)));
              },1000);
              
              return () => clearInterval(interval);
              // eslint-disable-next-line react-hooks/exhaustive-deps
            }, []);

        return (
            <>
                <NavMenu showNavigation={true} currentPage={'diary'} />
                <div className='diary'>
                    <h2 className="diary-title">
                        {t('title', {ns: 'diary'})}
                    </h2>
                    <div className='diary-container'>
                        <Events periodEvent='EventosPasados'></Events>
                        <div className='row'>
                            <hr></hr>
                        </div>
                        <div className='row'>
                            <div className='col-lg-4'>
                                <div className="profile-country-container">
                                    <img className="profile-country-container-flag" src={userLoggedIn.flag} alt='flag' />
                                    <p className="profile-country-container-country">{translateCountry(i18n.language, userLoggedIn.countryCode.toLowerCase())}</p>
                                    <span className="profile-country-container-time-use">({userLoggedIn.timeUse})</span>
                                </div>
                            </div>
                            <div className='current-date-section col-lg-8'>
                                <h1 className='diary-container-current-date'>{currentDate.format('LL')}</h1>
                                <h1 className='diary-container-current-date'>{currentDate.format('LTS')}</h1>
                            </div>
                        </div>
                        <div className='row'>
                            <hr></hr>
                        </div>
                        <Events periodEvent='EventosFuturos'></Events>
                    </div>
                </div>
            </>
        )
    }
)