import { fetchWrapper } from "../Utils/fetchWrapper.js";

const baseUrl = `${process.env.REACT_APP_EDOWIE_API}Configuration`;

export interface IConfigurationService {
    getByKey(key: string): Promise<any>;
};

export class ConfigurationService implements IConfigurationService {

    public getByKey(key: string): Promise<any> {
        return fetchWrapper.get(`${baseUrl}/${key}`);
    }
}