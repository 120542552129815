import React ,{ useState, useEffect } from 'react';
import './style.scss'
import { NavMenu } from '../Menu/NavMenu';
import { useParams } from "react-router-dom";
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { RelationType } from '../../Utils/types';
import { RelationUsers } from './RelationUsers';
import { sessionManager } from '../../sessionManager';
import { userService } from '../../services/userService'
import LoadingSpinner from "../Shared/LoadingSpinner/index";

export const Relations = () => {
    const manager = new sessionManager();

    let  { userId, selectedTab } = useParams();
    const userLoggedIn = JSON.parse(manager.getUserSession());

    const [user, setUser] = useState({});
    const [activeTab, setActiveTab] = useState(selectedTab === undefined ? "1" : selectedTab);
    const [loading, setLoading] = useState(true);

    const selectTab = (tabId) => {
        if (activeTab !== tabId) {
            setActiveTab(tabId);
        }
    }

    useEffect(() => {
        const fetchData = () => {
            userService.card(userId)
                .then(response => {
                    setUser(response);
                    setLoading(false)
                });
        };
        fetchData();

    }, [userId]);

    return (
        <>
        {loading ? <LoadingSpinner /> : (
            <> 
                <NavMenu showNavigation={true} currentPage={''} />  
                <div className='relations'>
                    <div className='relations-header'>
                        {parseInt(userId) === userLoggedIn.userId ? (
                            <h2 className='relations-title'>Mi Red</h2>
                        ) : (
                            <h2 className='relations-title'>{`Red de ${user.username}`}</h2>
                        )}
                    </div>
                    <div className="relations-tabs">
                        <Nav tabs>
                            <NavItem>
                                <NavLink className={(activeTab === "1" ? "active nav-link" : "nav-link")} href="#"
                                    onClick={() => selectTab("1")}>Siguiendo</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink className={(activeTab === "2" ? "active nav-link" : "nav-link")} href="#"
                                    onClick={() => selectTab("2")}>Seguidores</NavLink>
                            </NavItem>
                            {parseInt(userId) === userLoggedIn.userId && (
                            <NavItem>
                                <NavLink className={(activeTab === "3" ? "active nav-link" : "nav-link")} href="#"
                                    onClick={() => selectTab("3")}>Solicitudes pendientes</NavLink>
                            </NavItem>
                            )}
                        </Nav>
                    </div>
                    <div className="relations-tab-content">
                        <TabContent activeTab={activeTab}>
                            <TabPane tabId="1">
                                {activeTab === "1" && (
                                    <RelationUsers relationType={RelationType.Following} userId={parseInt(userId)}></RelationUsers>
                                )}
                            </TabPane>
                            <TabPane tabId="2">
                                {activeTab === "2" && (
                                    <RelationUsers relationType={RelationType.Followers} userId={parseInt(userId)}></RelationUsers>
                                )}
                            </TabPane>
                            {parseInt(userId) === userLoggedIn.userId && (
                            <TabPane tabId="3">
                                {activeTab === "3" && (
                                    <RelationUsers relationType={RelationType.Pending} userId={parseInt(userId)}></RelationUsers>
                                )}
                            </TabPane>
                            )}
                        </TabContent>
                    </div>
                </div>
            </>
        )}
        </>
    )
}