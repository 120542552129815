import React, { useMemo, useEffect, useState } from "react";
import './style.scss';
import { useParams } from "react-router-dom";
import { profileService } from '../../services/profileService';
import { relationService } from '../../services/relationService';
import { sessionManager } from '../../sessionManager'
import LoadingSpinner from "../Shared/LoadingSpinner/index";
import { NavMenu } from '../Menu/NavMenu';

export const UserProfile = () => {
    const profileServiceClient = useMemo(() => new profileService(), []);
    const relationServiceClient = useMemo(() => new relationService(), []);
    const manager = new sessionManager();

    const userLoggedIn = JSON.parse(manager.getUserSession());
    const isUserLoggedIn = manager.isUserLoggedIn();

    const [isFollower, setIsFollower] = useState(false);
    const [isFollowing, setIsFollowing] = useState(false);
    const [isPending, setIsPending] = useState(false);
    const [loading, setLoading] = useState(true);
    const [userProfile, setUserProfile] = useState(true);

    const { id } = useParams();
    const userId = isUserLoggedIn ? userLoggedIn.userId : 0;

    useEffect(() => {
        const fetchData = async () => {
            const userProfileResponse = await profileServiceClient.userProfile(id);

            if (userProfileResponse.success) {
                setUserProfile(userProfileResponse.value);

                if (isUserLoggedIn) {
                    var relationResponse = await relationServiceClient.isFollowing(userId, userProfile.userId);

                    if (relationResponse.success) {
                        setIsFollowing(relationResponse.value);
                    }

                    relationResponse = await relationServiceClient.isFollowing(userProfile.userId, userId);

                    if (relationResponse.success) {
                        setIsFollower(relationResponse.value);
                    }

                    relationResponse = await relationServiceClient.isPending(userId, userProfile.userId);

                    if (relationResponse.success) {
                        setIsPending(relationResponse.value);
                    }
                }

                setLoading(false);
            }
        };
        fetchData();

    }, [profileServiceClient, relationServiceClient, isUserLoggedIn, id, userProfile.userId, userId]);

    const follow = async () => {
        var folowResponse = await relationServiceClient.follow(userLoggedIn.userId, userProfile.userId);

        if (folowResponse.success) {
            var relationResponse = await relationServiceClient.isFollowing(userLoggedIn.userId, userProfile.userId);

            if (relationResponse.success) {
                setIsFollowing(relationResponse.value);
            }

            relationResponse = await relationServiceClient.isPending(userLoggedIn.userId, userProfile.userId);

            if (relationResponse.success) {
                setIsPending(relationResponse.value);
            }
        }
    }

    const unfollow = async () => {
        var unfollowResponse = await relationServiceClient.unfollow(userLoggedIn.userId, userProfile.userId);

        if (unfollowResponse.success) {
            setIsFollowing(false);
            setIsPending(false);
        }
    }

    return (
        <>
        {loading ? <LoadingSpinner /> : (
            <>
                <NavMenu showNavigation={true} currentPage={'teacher-profile'} />
                <div className="user-profile">
                    <div className="user-profile-container">
                        <div className="cover-image-container">
                            <img src={userProfile.coverImage} className="cover-image" alt='cover' />
                        </div>
                        {/* <div className="gradiant-bottom"></div> */}
                        <div className="user-information">
                            <div className="row">
                                <div className="col-xs-12 col-lg-10">
                                    <div className="d-flex">
                                        <div>
                                            <div className="card-user-photo">
                                                <figure className="avatar">
                                                    <img src={userProfile.profileImage} alt="user" className="user-photo" />
                                                </figure>
                                            </div>
                                            <h4 className="username">{userProfile.username}</h4>

                                            {userProfile.showNameInPublicProfile && (
                                            <h4 className="user-first-last-name">{userProfile.firstAndLastName}</h4>
                                            )}

                                            <div className="user-country">
                                                <img className="flag" src={userProfile.flagUrl} alt='flag' />
                                                <p className="country-name">{userProfile.country}</p>
                                            </div>

                                            <div className="user-about-me">
                                                <h4 className="user-about-me-title">Sobre Mí</h4>
                                                <p className="user-about-me-description">{userProfile.aboutMe}</p>
                                            </div>
                                        </div>
                                        <div>
                                        <div className="mt-3">
                                                <div className="relations-user-profile">
                                                    {!isFollower ? (
                                                        <>
                                                            <div className="details ml-2 mr-3">
                                                                <div className="relation-quantity">{userProfile.followers}</div>
                                                                <div className="relation-title">Seguidores</div>
                                                            </div>
                                                            <div className="details">
                                                                <div className="relation-quantity">{userProfile.followed}</div>
                                                                <div className="relation-title">Seguidos</div>
                                                            </div>
                                                            </>
                                                    ) : (
                                                        <>
                                                            <a className="details ml-2 mr-3" href={`/relations/${userProfile.userId}/2`}>
                                                                <div className="relation-quantity">{userProfile.followers}</div>
                                                                <div className="relation-title">Seguidores</div>
                                                            </a>
                                                            <a className="details" href={`/relations/${userProfile.userId}/1`}>
                                                                <div className="relation-quantity">{userProfile.followed}</div>
                                                                <div className="relation-title">Seguidos</div>
                                                            </a>
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xs-12 col-lg-2 user-profile-buttons">
                                    {isUserLoggedIn ? (
                                        <>
                                            {!loading && userLoggedIn.userId !== userProfile.userId && (
                                            <div className="user-profile-buttons-relation">
                                                <button className="relation-button"
                                                    onClick={() => {
                                                        if (isFollowing || isPending) {
                                                            unfollow();
                                                        } else {
                                                            follow();
                                                        }
                                                    }}>{isFollowing ? 'Dejar de seguir' : isPending ? 'Pendiente' : 'Seguir'}
                                                </button>
                                            </div>
                                            )}
                                        </>
                                        ) : (
                                            <></>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>  
            </>
        )}
        </>
    )
}