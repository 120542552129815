import { useTranslation } from 'react-i18next';
import { ServiceType } from '../../../../Utils/types';
import './style.scss';
import { favoriteService } from '../../../../services/favoriteService';
import { getUserLoggedIn } from '../../../../Utils/utils';

export const FavoriteItem = ({ item, handleRemove }) => {
    const { t } = useTranslation(['favorite']);

    const url = (item.serviceType === ServiceType.Course || item.serviceType === ServiceType.Resource) ?
        `/buy-content/${item.referenceId}/${item.serviceType}` :
        `/enroll-group-experience/${item.referenceId}`;

    const tagClass = item.serviceType === ServiceType.Course ?
        'favorite-item-card-body-course-tag' :
        item.serviceType === ServiceType.Resource ?
        'favorite-item-card-body-resource-tag' :
        'favorite-item-card-body-group-tag';


    const userLoggedIn = getUserLoggedIn();

    const deleteToFavorites = (userId, referenceId, serviceType) => {
        favoriteService.deleteFavorites(userId, referenceId, serviceType)
            .then(
                handleRemove()
            );
    }
    
    return (
        <div className="favorite-item">
            <div className='favorite-item-card'>
                <div className="favorite-item-card-image">
                    <a className='' href={url}>
                        <img src={item.coverImage.path} alt={item.title}/>
                    </a>
                </div>
                <div className="favorite-item-card-body">
                    <span className={`favorite-item-card-body-tag ${tagClass}`}>{item.subcategory}</span>
                    {!item.isFree && (
                    <span className="favorite-item-card-body-price">
                        <span className="favorite-item-card-body-price-legend">$</span>{item.price}
                    </span>
                    )}
                    {item.isFree && (
                    <span className="favorite-item-card-body-is-free">
                        <span className="favorite-item-card-body-is-free-legend">{t('freeMessage')}</span>
                    </span>
                    )}
                    <h4 className="favorite-item-card-body-title">
                        <a href={url} className="favorite-item-card-body-title-anchor">
                            {item.title}
                        </a>
                    </h4>
                    <h6 className="favorite-item-card-body-description">
                        {item.description}
                    </h6>
                    <div className="favorite-item-card-body-button">
                        <button title={t('removeFavoritesMessage')} className="favorite-item-card-body-button-remove" onClick={() => { deleteToFavorites(userLoggedIn.userId, item.referenceId, item.serviceType); }}>
                            <i className="feather-bookmark favorite-item-card-body-button-remove-icon"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}