import { getToken } from "../Utils/utils";

export class NoteService {

    async addNote(userId, contentFileId, values) {
        try {

            const edowieApi = process.env.REACT_APP_EDOWIE_API;
            const token = await getToken();
            const requestOptions = {
                method: 'POST',
                headers: { 
                    'Authorization': `bearer ${token}`,
                    'Content-Type': 'application/json' 
                },
                body: JSON.stringify(
                    {
                        "contentFileId": contentFileId,
                        "userId": userId,
                        "title": values.title,
                        "description": values.description,
                        "playTime": `${values.playTime}`,
                        "pageNumber": values.pageNumber
                    })
            };

            const response = await fetch(edowieApi + `note/${contentFileId}/note`, requestOptions);

            return { success: response.status === 200 }

        } catch (error) {
            return { success: false, error: error };
        }
    }

    async deleteNote(noteId) {
        try {

            const edowieApi = process.env.REACT_APP_EDOWIE_API;
            const token = await getToken();
            const requestOptions = {
                method: 'DELETE',
                headers: { 
                    'Authorization': `bearer ${token}`,
                    'Content-Type': 'application/json' 
                }
            };

            const response = await fetch(edowieApi + `note/${noteId}/note`, requestOptions);

            return { success: response.status === 200 }

        } catch (error) {
            return { success: false, error: error };
        }
    }

    async getAllByUserIdAndContentId(userId, contentFileId) {
        try {

            const edowieApi = process.env.REACT_APP_EDOWIE_API;
            const token = await getToken();
            const requestOptions = {
                headers: { 'Authorization': `bearer ${token}`, },
            };

            const response = await fetch(edowieApi + `Note/${userId}/${contentFileId}/notes`, requestOptions);
            const data = await response.json();

            return { success: true, value: data }

        } catch (error) {
            return { success: false, error: error };
        }
    }
}