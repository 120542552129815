import React, { useState } from 'react';
import './style.scss';
import { useTranslation } from 'react-i18next';

const options = [
    { label: 'filter.all', id: 'todo', value: 'todo' },
    { label: 'filter.myActivities', id: 'misActividades', value: 'misActividades' },
    { label: 'filter.myPublications', id: 'misPublicaciones', value: 'misPublicaciones' },
    { label: 'filter.followedActivities', id: 'actividadesSeguidos', value: 'actividadesSeguidos' },
    { label: 'filter.followedPosts', id: 'publicacionesSeguidos', value: 'publicacionesSeguidos' },
];

export const Filters = ({ handleFilter }) => {
    const { t } = useTranslation(['home']);
    const filter = t('filter');

    const [appliedFilter, setAppliedFilter] = useState(true);
    const [appliedOption, setAppliedOption] = useState('todo');
    const [titleAppliedFilter, setTitleAppliedFilter] = useState('filter.all');

    const applyFilter = async() => {
        setAppliedFilter(true);
        var option = options.filter(o => o.value === appliedOption)[0];
        setTitleAppliedFilter(option.label);
        handleFilter(option.value);
    }

    const onChangeValue = (event) => {
        setAppliedOption(event.target.value);
    };

    return (
        <>
            <div className="filters">
                <div className="container">
                    <div className="item">
                        {appliedFilter ? (
                            <div className="applied-filters">
                                <div className="legend">
                                    <span>{filter.filterApplied}</span>
                                    <span className="selected-filter">{t(titleAppliedFilter)}</span>
                                </div>
                                <div className="filter">
                                    <button type="button" className="btn button" onClick={() => { setAppliedFilter(false); }}>{filter.filter}</button>
                                </div>
                            </div>
                        ) : (
                            <div className="apply-filters">
                                <div>
                                    <p><b>{filter.show}:</b></p>
                                </div>
                                <div className="options-and-button">
                                    <div className="options">
                                            {options.map((option) => (
                                                <div className="form-check" key={option.id}>
                                                    <input className="form-check-input" type="radio" name="actividadesAMostrar"
                                                        id={option.id} value={option.value} checked={appliedOption === option.value}
                                                        onChange={(e) => onChangeValue(e)}></input>
                                                <label className="form-check-label">{t(option.label)}</label>
                                            </div>
                                        ))}
                                    </div>
                                        <div className="apply">
                                            <button type="button" className="btn button" onClick={() => { applyFilter() }}>{filter.apply}</button>
                                        </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}
