import React, { useState, useMemo, useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import './style.scss';
import { particularExperienceService } from '../../../../../services/particularExperienceService';
import { extractParameter, getUserLoggedIn } from '../../../../../Utils/utils';
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { DELAY_BEFORE_REDIRECT } from '../../../../../Utils/types';
import { Summary } from './Summary';
import useTimeout from '../../../../../hooks/useTimeout';
import { Payment } from './Payment';
import { PurchaseSummary } from './PurchaseSummary';
import LoadingSpinner from '../../../../Shared/LoadingSpinner/index';
import { NavMenu } from '../../../../Menu/NavMenu';
import { Message } from '../../../../Shared/Message';

export const ParticularExperiencePay = () => {
    const particularExperienceServiceClient = useMemo(() => new particularExperienceService(), []);
    const location = useLocation();
    const navigate = useNavigate();

    const userLoggedIn = getUserLoggedIn();
    const createTimeout = useTimeout();

    const { id } = useParams();
    const paymentIntentId = extractParameter(location, queryString.parse, 'payment_intent') || '';
    const successFreePurchase = extractParameter(location, queryString.parse, 'successFreePurchase') || '';
    const availabilityId = extractParameter(location, queryString.parse, 'availabilityId');
    const themeId = extractParameter(location, queryString.parse, 'themeId');
    const topicId = extractParameter(location, queryString.parse, 'topicId');
    const topic = extractParameter(location, queryString.parse, 'topic');
    const eventDate = extractParameter(location, queryString.parse, 'eventDate');

    const externalPaymentId = paymentIntentId;
    const successfulPurchase = paymentIntentId !== '' || successFreePurchase === 'true';

    const [particularExperience, setParticularExperience] = useState(null);
    const [loading, setLoading] = useState(true);
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            if (externalPaymentId !== "" || successFreePurchase === 'true') {
                const response = await particularExperienceServiceClient.hire(userLoggedIn.userId, id, availabilityId, themeId, topicId, topic, eventDate, false, externalPaymentId);

                if (response.success) {
                    createTimeout(() => {
                        navigate(`/teacher-profile/${id}/3`);
                    }, DELAY_BEFORE_REDIRECT);
                } else {
                    setHasError(true);
                }
            }
        };
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            const particularExperienceResponse = await particularExperienceServiceClient.getByTeacherId(id);

            if (particularExperienceResponse.success) {
                setParticularExperience(particularExperienceResponse.value);
                setLoading(false);
            }
        };
        fetchData();

    }, [particularExperienceServiceClient, id]);

    return (
        <>
            {(loading) ? <LoadingSpinner /> : (
                <>
                    <NavMenu showNavigation={true} currentPage={'teacher-profile'} />
                    <div className='particular-experience-pay-section'>
                        <div className="header"><h2>Finalizar contratación</h2></div>
                        <div className="particular-experience-pay">
                            <div className="particular-experience-pay-payment">
                                <Payment price={particularExperience.price} returnUrl={`particular-experience-pay/${particularExperience.userId}/?availabilityId=${availabilityId}&themeId=${themeId}&topicId=${topicId}&topic=${topic}&eventDate='${eventDate}'`}></Payment>
                                {successfulPurchase && (
                                    <PurchaseSummary message={`Felicitaciones!. Contrataste tu experiencia particular. El evento reservado puede verse en la sección de la agenda.`}></PurchaseSummary>
                                )}
                                {hasError &&  (
                                    <fieldset>
                                        <Message
                                            type={'error'}
                                            message={'Ha ocurrido un error al intentar contratar la experiencia particular.'}
                                        />
                                    </fieldset>
                                )}
                            </div>
                            <div className='particular-experience-pay-payment-details'>
                                <Summary particularExperience={particularExperience} themeId={themeId} topicId={topicId}
                                        description={topic} event={eventDate}></Summary>
                            </div>   
                        </div>
                        <div className='particular-experience-pay-buttons'>
                            <a className='button back-button' href={`/teacher-profile/${id}/3`}>Atrás</a>
                        </div> 
                    </div>
                </>
            )}
        </>
    )
}
