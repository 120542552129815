import moment from "moment";
import { InjectAppServices } from "../../../../../../../services/dependency-injection";
import { useTranslation } from "react-i18next";

const enProperties = {
    currencySign: "accounting"
  };

export const Item = InjectAppServices(
    ({
        item
    }) => {
        const { t } = useTranslation(['my_purchases']);

        return (
            <div className="row"> 
                <div className='col-lg-2'>
                    <div className='my-purchases-history-grid-date-column'>
                        <div className='my-purchases-history-grid-date-column-header'>{moment(item.date).format('LLL')}</div>
                    </div>
                </div>
                <div className='col-lg-7'>
                    <div className="my-purchases-history-grid-details-column">
                        <div className="my-purchases-history-grid-details-column-container">
                            <div className="my-purchases-history-grid-details-column-container-header">
                                {item.transaction} - {item.service}
                            </div>
                            <>
                            <div className="my-purchases-history-grid-details-column-container-information-section">
                                <div className="my-purchases-history-grid-details-column-container-information-section-item">
                                    <div>{t('buyerColumn',{ns: 'balance'})}:</div>
                                    <a href={`user-profile/${item.buyerId}`}>
                                        <div className="my-purchases-history-grid-details-column-container-information-section-item-value">{item.buyer}</div>
                                    </a>
                                </div>
                                <div className="my-purchases-history-grid-details-column-container-information-section-item">
                                    <div>{t('edowierColumn',{ns: 'balance'})}:</div>
                                    <a href={`teacher-profile/${item.edowierId}`}>
                                        <div className="my-purchases-history-grid-details-column-container-information-section-item-value">{item.edowier}</div>
                                    </a>
                                </div>
                            </div>
                            </>
                        </div>
                    </div>
                </div>
                <div className='col-lg-3'>
                    <div className='my-purchases-history-grid-date-column'>
                        <div className={`my-purchases-history-grid-date-column-header ${item.transaction === 'Retiro' ? 'negative' : ''}`}>
                            {`USD ${item.amount.toLocaleString("en-US", enProperties)}`}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
)