import { getToken } from "../Utils/utils";

export class messageService {

    async getRecipientsByUserId(userId) {
        try {

            const edowieApi = process.env.REACT_APP_EDOWIE_API;
            const token = await getToken();
            const requestOptions = {
                method: 'GET',
                headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': `bearer ${token}`,
                },
            };

            const response = await fetch(`${edowieApi}message/${userId}/recipients`, requestOptions);
            const data = await response.json();

            return { success: true, value: data }

        } catch (error) {
            return { success: false, error: error };
        }
    }

    async getMessagesByUserIdAndDestination(userId, destination) {
        try {

            const edowieApi = process.env.REACT_APP_EDOWIE_API;
            const token = await getToken();
            const requestOptions = {
                method: 'GET',
                headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': `bearer ${token}`,
                },
            };

            const response = await fetch(`${edowieApi}message/${userId}/${destination}/messages`, requestOptions);
            const data = await response.json();

            return { success: true, value: data }

        } catch (error) {
            return { success: false, error: error };
        }
    }

    async sendMessage(origin, destination, message) {
        try {

            const edowieApi = process.env.REACT_APP_EDOWIE_API;
            const token = await getToken();
            const requestOptions = {
                method: 'POST',
                headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': `bearer ${token}`,
                },
                body: JSON.stringify({ "destinationUserId": destination, "message": message }),
            };

            const response = await fetch(`${edowieApi}message/${origin}/send-message`, requestOptions);

            return { success: response.ok }

        } catch (error) {
            return { success: false, error: error };
        }
    }

    async markAsRead(userId) {
        try {

            const edowieApi = process.env.REACT_APP_EDOWIE_API;
            const token = await getToken();
            const requestOptions = {
                method: 'POST',
                headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': `bearer ${token}`,
                },
            };

            const response = await fetch(`${edowieApi}message/${userId}/messages/markasread`, requestOptions);

            return { success: response.ok }

        } catch (error) {
            return { success: false, error: error };
        }
    }
}
