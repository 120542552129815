import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from "@fullcalendar/interaction";
import allLocales from '@fullcalendar/core/locales-all';
import moment from 'moment'
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { hireFieldNames } from '..';
import { useFormikContext } from 'formik';
import './style.scss';
import { getCurrentTime } from '../../../../../Utils/utils';
import { InjectAppServices } from '../../../../../services/dependency-injection';

export const Availability = InjectAppServices(
    ({ 
        currentDate, 
        duration,
        advanceHirePeriod, 
        calendarReference, 
        handleAvailabilitySelected, 
        handleSelectedEventDate,
        handleChangeDate,
        dependencies: { sessionManager, diaryServiceClient }
    }) => {

    const { setFieldValue } = useFormikContext();
    const [events, setEvents] = useState(true);

    const { id } = useParams();

    const userLoggedIn = sessionManager.getUserLoggedIn();

    const mapEvent = useCallback((data) => {
        var startDate = moment(data.startDate).add(userLoggedIn.offset, 'minutes')._d;
        var endDate = moment(data.endDate).add(userLoggedIn.offset, 'minutes')._d;

        return { start: startDate, end: endDate, title: data.reserved, id: data.eventId, type: data.type };
    }, [userLoggedIn.offset])
    
    const getEvents = useCallback(async (date) => {
        var week = getWeek(date);
        diaryServiceClient.getEventsByUserIdAndDates(id, week[0], week[1])
            .then(response => {
                setEvents(response.map(mapEvent));
            });
    }, [diaryServiceClient, id, mapEvent]);

    useEffect(() => {
        const fetchData = async () => {
            getEvents(currentDate);
        };
        fetchData();

    }, [getEvents, currentDate]);

    const handleEventClick = (arg) => { 
        if (moment(arg.event.start).isBefore(moment(getCurrentTime(userLoggedIn.offset)).add(advanceHirePeriod, 'm').toDate()) || arg.event.title === 'true') {
            return;
        }

        var eventsSelected = document.getElementsByClassName('fc-event-selected');

        for (let item of eventsSelected) {
            item.classList.remove('fc-event-selected')
        }

        arg.el.firstChild.firstChild.classList.add('fc-event-selected');

        var eventDate = moment(arg.event.start).format('MMMM DD YYYY - HH:mm');
        setFieldValue(hireFieldNames.event, eventDate);

        handleAvailabilitySelected(arg.event.id);
        handleSelectedEventDate(moment(arg.event.start).format('DD/MM/YYYY - HH:mm'));
    }

    const getWeek = (date) => {
        var current = date; //new Date(fecha);

        const week = [];
        const weekFormat = [];

        if (current.getDay() === 0) {
            current.setDate(((current.getDate() - 7) + 1));
        } else {
            current.setDate(((current.getDate() - current.getDay()) + 1));
        }

        week.push(new Date(current));
        current.setDate(current.getDate() + 6);
        week.push(new Date(current));

        week.forEach((w) => {
            weekFormat.push(moment(w).format('YYYY-MM-DD'));
        });

        return weekFormat;
    }

    function renderEventContent(eventInfo) {
        var type = eventInfo.event.extendedProps.type;

        return (
            <>
                {moment(eventInfo.event.start).isBefore(moment(getCurrentTime(userLoggedIn.offset)).add(advanceHirePeriod, 'm').toDate()) ? (
                    <div className={'fc-past-event'}>
                        <b>{eventInfo.timeText}</b>
                    </div>
                ) :
                (eventInfo.event.title === "true" || type === 'Experiencia Grupal') ? (
                    <div className={'fc-reserved-event'}>
                        <b>{eventInfo.timeText}</b>
                    </div>
                ) : 
                (
                    <div className={'fc-available-event'}>
                        <>
                            <div className='available-event'>
                                <b>{eventInfo.timeText}</b>
                            </div>
                        </>
                    </div>
                )}
            </>
        )
    }

    const goToPreviousWeek = () => {
        let calendarApi = calendarReference.current.getApi();
        calendarApi.prev();

        var date = calendarApi.currentData.dateProfile.currentRange.end;
        handleChangeDate(date);
        getEvents(date);
    }

    const goToNextWeek = () => {
        let calendarApi = calendarReference.current.getApi();
        calendarApi.next();

        var date = calendarApi.currentData.dateProfile.currentRange.end;
        handleChangeDate(date);
        getEvents(date);
    }

    return (
        <FullCalendar
            id={'fullCalendar'}
            ref={calendarReference}
            plugins={[timeGridPlugin, interactionPlugin]}
            initialView="timeGridWeek"
            slotDuration={`00:${duration}`}
            allDaySlot={false}
            events={events}
            eventClick={handleEventClick}
            locales={allLocales} locale={'es'}
            headerToolbar={{
                left: 'prev,next',
                center: 'title',
                right: "",
            }}
            eventContent={renderEventContent}
            customButtons={{
                prev: {click: () => goToPreviousWeek()},
                next: {click: () => goToNextWeek()},
              }}
        />
    )
})