import { useTranslation } from 'react-i18next';
import './style.scss';
import * as Icon from 'react-bootstrap-icons';

export const UserSection = () => {
    const { t } = useTranslation(['landing_page']);
    const userSection = t('userSection');
    const followYourFavoriteInfluencers = t('userSection.followYourFavoriteInfluencers');
    const comprehensiveLearningTools = t('userSection.comprehensiveLearningTools');
    const socialNetwork = t('userSection.socialNetwork');
    const contentLibrary = t('userSection.contentLibrary');
    
    return (
        <section className="user-section" id="usuario">
            <div className="user-section-container">
                    <div className="row user-section-container-body">
                        <div className="col-lg-4 section-left">
                            <h3 className="title">{userSection.title}</h3>
                            <h4 className="description">{userSection.description}</h4>
                        </div>
                        <div className="col-lg-8">
                            <div className="row row-cols-1 row-cols-md-2 features">
                                <div className="feature">
                                    <div className="feature-icon">
                                        <Icon.Collection></Icon.Collection>
                                    </div>
                                    <h3 className="title">{followYourFavoriteInfluencers.title}</h3>
                                    <p className="description">{followYourFavoriteInfluencers.description}</p>
                                </div>
                                <div className="feature">
                                    <div className="feature-icon">
                                        <Icon.Tools></Icon.Tools>
                                    </div>
                                    <h3 className="title">{comprehensiveLearningTools.title}</h3>
                                    <p className="description">T{comprehensiveLearningTools.description}</p>
                                </div>
                                <div className="feature">
                                    <div className="feature-icon">
                                        <Icon.Hearts></Icon.Hearts>
                                    </div>
                                    <h3 className="title">{socialNetwork.title}</h3>
                                    <p className="description">{socialNetwork.description}</p>
                                </div>
                                <div className="feature">
                                    <div className="feature-icon">
                                        <Icon.Server></Icon.Server>
                                    </div>
                                    <h3 className="title">{contentLibrary.title}</h3>
                                    <p className="description">{contentLibrary.description}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="user-section-container-buttons">
                        <a className="button register-button" href="/register/">{userSection.register}</a>
                    </div>
                </div>

            </section>
    )
}