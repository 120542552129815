import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import 'bootstrap/dist/css/bootstrap.css';
import './style.scss';
import { ReviewStars } from '../../../Shared/ReviewStars/index';
import { InjectAppServices } from '../../../../services/dependency-injection';
import { useTranslation } from 'react-i18next';
import EmabajadorIcon from '../../../../general/images/embajador.png'

const Price = InjectAppServices(
    (
        { isFree, price, discount, contentId, contentType, dependencies: { ipInfoServiceClient } }
    ) => {

    const { t } = useTranslation(['menu']);
    const [userCountryCode, setUserCountryCode] = useState('');

    const [state, setState] = useState({price: price, total: price, discount: 0});

    useEffect(() => {
        const fetchData = async () => {
            ipInfoServiceClient.getCountryCode()
                .then(response => {
                    setUserCountryCode(response.value.toLowerCase());

                    if (discount && (discount.applyAll || discount.countryDiscountCode === response.value.toLowerCase())) {
                        const discountAmout = (parseFloat(price * discount.discount))/parseFloat(100);
                        const amount = price - discountAmout;
                        setState({price: price, total: amount, discount: discount.discount});
                    }

                });
        };
        fetchData();

    }, [ipInfoServiceClient, discount, price]);

    return (
        <div className='price-card'>
            {isFree ? (
                <h3 className="free">{t('contents.freeMessage')}</h3>
            ) : (
                discount && (discount.applyAll || discount.countryDiscountCode === userCountryCode) ? (
                    <>
                        <span className="price-without-discount">{"USD "  + state.price.toFixed(2)}</span>
                        <h3 className="price-with-discount">{`USD ${state.total.toFixed(2)} - ${state.discount}% OFF`}</h3>
                    </>
                ) : (
                    <h3 className="price-with-discount">{"USD "  + state.price.toFixed(2)}</h3>
                )
            )}
            <a href={`buy-content/${contentId}/${contentType}`}>
                <div className="button">
                    {t('contents.buyButton')}
                </div>
            </a>
        </div>
    )
})

const Items = InjectAppServices(
    ({ currentItems }) => {

    return (
        <>
            {currentItems &&
            currentItems.map((content) => (
              <div key={content.contentId} className="col-xl-6 col-lg-12">
                  <div className="card-body">
                      <div className="row">
                          <div className="col-sm-4">
                              <div className="card-image">
                                  <a href={`buy-content/${content.contentId}/${content.contentType}`}>
                                      <img src={content.coverImage.path} alt='cover' />
                                  </a>
                              </div>
                          </div>
                          <div className="col-sm-8">
                              <div className="card-details">
                                  <div className="row">
                                      <div className="col-sm-9 pr-0">
                                          <div className="tags">
                                              {content.tags.split(',').map((tag) => (
                                                  <span key={tag} className={`tag ${content.contentType === "1" ? 'course-tag' : 'resource-tag'}`}>
                                                      {tag}
                                                  </span>
                                              ))}
                                          </div>
                                      </div>
                                      <div className="col-sm-3">
                                          <div className="qualification">
                                              <ReviewStars averageQualifications={content.averageReview}></ReviewStars>
                                              <p className="reviews">({content.reviews})</p>
                                          </div>
                                      </div>
                                  </div>
                                  <h4 className="title">
                                      <a href={`buy-content/${content.contentId}/${content.contentType}`}>{content.title}</a>
                                  </h4>
                                  <div className="row">
                                      <div className="col-12">
                                          <span className="item">{content.quantityFiles} {content.quantityFilesLabel}</span>
                                          {content.contentType === "1" && (
                                              <>
                                                  <span className="dot"></span>
                                                  <span className="item">{content.durationContent}</span>
                                              </>
                                          )}
                                          <span className="dot"></span>
                                          <span className="item">Nivel: {content.level}</span>
                                          <span className="dot"></span>
                                          <span className="item">Hace {content.time}</span>
                                      </div>
                                  </div>
                                  <h6 className="description">{content.description}</h6>
                                  <a href={`/teacher-profile/${content.userId}`} className="card-details-user-profile">
                                      <img src={content.profileImage.path} alt='user' />
                                      <span>{content.username}</span>
                                        {content.userIsAmbassador && (
                                            <img src={EmabajadorIcon} className="ambassador" title="Embajador" alt='Embajador'></img>
                                        )}
                                  </a>
                                  <div className="row">
                                      <div className="col-7">
                                          <h6 className="acquired-quantity">Adquirido por {content.acquiredQuantity} usuario/s.</h6>
                                      </div>
                                      <div className="col-5 free-price">
                                        <Price isFree={content.isFree} price={content.price} 
                                               discount={content.discount} contentId={content.contentId} 
                                               contentType={content.contentType}></Price>
                                         
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          ))}
        </>
    );
  }
)

export const PaginatedItems = InjectAppServices(
    ({  items, totalItems, itemsPerPage, currentPage, handlePageClick
    }) => {
    const pageCount = Math.ceil(totalItems / itemsPerPage);
  
    return (
      <>
        <Items currentItems={items} />
        <ReactPaginate
          nextLabel=">"
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          marginPagesDisplayed={2}
          pageCount={pageCount}
          previousLabel="<"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakLabel="..."
          breakClassName="page-item"
          breakLinkClassName="page-link"
          containerClassName="pagination"
          activeClassName="active"
          renderOnZeroPageCount={null}
          forcePage={currentPage}
        />
      </>
    );
  },
)