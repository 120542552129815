import { Trans, useTranslation } from "react-i18next";
import { InjectAppServices } from "../../../services/dependency-injection";
import './style.scss';
import LoadingSpinner from "../../Shared/LoadingSpinner";
import Carousel from "react-multi-carousel";
import { ServiceItem } from "./ServiceItem";
import { ResponsiveCarouselOption } from "../../../Utils/types";
import { useEffect, useState } from "react";

export const ServicePromocodes = InjectAppServices(
    ({
        dependencies: { sessionManager, servicePromocodeServiceClient}
    }) => {
    const { t } = useTranslation(['manage_services']);
    const [loading, setLoading] = useState(true);
    const [services, setServices] = useState([]);
    const userLoggedIn = sessionManager.getUserLoggedIn();

    useEffect(() => {
        const fetchData = async () => {
            servicePromocodeServiceClient.getAllByUserId(userLoggedIn.userId)
                .then(response => {
                    setServices(response);
                    setLoading(false);
                })
        };
        fetchData();

    }, [servicePromocodeServiceClient, userLoggedIn.userId]);

    const handleRemovePromocodes = () => {
        setLoading(true);
        servicePromocodeServiceClient.getAllByUserId(userLoggedIn.userId)
                .then(response => {
                    setServices(response);
                    setLoading(false);
                })
    }

    return (
        <div className="service-promocodes">
            <div className="service-promocodes-title">
                    <h2>
                        {<Trans
                            t={t}
                            i18nKey={`promocodes.title`}
                            components={{ bold: <strong /> }}
                        />}
                    </h2>
                </div>
                <div className="service-promocodes-container">
                    <p className="service-promocodes-container-sub-title">{t(`promocodes.subtitle`)}</p>
                    {loading ? <LoadingSpinner /> : (
                        <Carousel
                            responsive={ResponsiveCarouselOption}
                            autoPlay={false}
                            swipeable={true}
                            draggable={true}
                            showDots={true}
                            infinite={false}
                            partialVisible={false}
                            dotListClass="custom-dot-list-style"
                        >
                            {services.map((service) => (
                                <ServiceItem key={service.serviceId} service={service} handleRemovePromocodes={() => { handleRemovePromocodes(); }}>
                                </ServiceItem>
                            ))}

                        </Carousel>
                    )}
                </div>
        </div>
    )
})