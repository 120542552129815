import React, { useState, useEffect, useMemo } from 'react';
import './style.scss';
import { relationService } from '../../../../services/relationService';
import { sessionManager } from '../../../../sessionManager';

export const UserSection = ({ serviceUserId, serviceProfileImage, serviceUsername, serviceLevel, userId }) => {
    const relationServiceClient = useMemo(() => new relationService(), []);
    const manager = new sessionManager();

    const isUserLoggedIn = manager.isUserLoggedIn();
    const [isPending, setIsPending] = useState(false);
    const [isFollowing, setIsFollowing] = useState(false);
    const [loading, setLoading] = useState(true);

    const showRelationButton = serviceUserId !== userId && isUserLoggedIn;

    useEffect(() => {
        const fetchData = async () => {

            if (isUserLoggedIn) {
                    var relationResponse = await relationServiceClient.isFollowing(userId, serviceUserId);

                    if (relationResponse.success) {
                        setIsFollowing(relationResponse.value);
                    }

                    relationResponse = await relationServiceClient.isPending(userId, serviceUserId);

                    if (relationResponse.success) {
                        setIsPending(relationResponse.value);
                    }
            }

            setLoading(false);
        };
        fetchData();

    }, [relationServiceClient, setIsFollowing, userId, serviceUserId, isUserLoggedIn]);

    const follow = async () => {
        var folowResponse = await relationServiceClient.follow(userId, serviceUserId);

        if (folowResponse.success) {
            var relationResponse = await relationServiceClient.isFollowing(userId, serviceUserId);

            if (relationResponse.success) {
                setIsFollowing(relationResponse.value);
            }

            relationResponse = await relationServiceClient.isPending(userId, serviceUserId);

            if (relationResponse.success) {
                setIsPending(relationResponse.value);
            }
        }
    }

    const unfollow = async () => {
        var unfollowResponse = await relationServiceClient.unfollow(userId, serviceUserId);

        if (unfollowResponse.success) {
            setIsFollowing(false);
            setIsPending(false);
        }
    }

    return (
        <div className="user-section">
            <a href={`/teacher-profile/${serviceUserId}`}>
                <img src={serviceProfileImage.path} className="profile-image" alt='user' />
                <span className="username">{serviceUsername}</span>
            </a>
            <span className="separator"></span>
            <span className="level">Nivel: <b>{serviceLevel}</b></span>
            {!loading && showRelationButton && (
                <>
                <span className="separator"></span>
                <button className="relation-button"
                    onClick={() => {
                        if (isFollowing || isPending) {
                            unfollow();
                        } else {
                            follow();
                        }
                    }}>{isFollowing ? 'Dejar de seguir' : isPending ? 'Pendiente' : 'Seguir'}
                    </button>
                </>
            )}
        </div>
    )
}
