import React, { useEffect, useMemo } from 'react';
import { useCookies } from "react-cookie";
import { useNavigate } from 'react-router-dom';
import { profileService } from '../../../services/profileService';
import { userService } from '../../../services/userService';
import { useParams } from "react-router-dom";
import { sessionManager } from '../../../sessionManager';
import { getUserLoggedIn } from '../../../Utils/utils';

export const BeEdowier = () => {
    const profileServiceClient = useMemo(() => new profileService(), []);
    const manager = useMemo(() => new sessionManager(), []);
    
    const userLoggedIn = getUserLoggedIn();

    const { userId } = useParams();
    const [, setCookie] = useCookies();
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            const response = await profileServiceClient.beEdowier(userId);

            if (response.success) {
                userService.card(userId)
                    .then(response => {
                        var userData = {token: userLoggedIn.token, ...response};
                        manager.setUserSession(JSON.stringify(userData));
                        setCookie("Perfil", response.profile ,{ domain: '.edowie.com', path: '/' });

                        navigate('/home');
                    })
            }
        };

        fetchData();
    }, [navigate, profileServiceClient, setCookie, userId, manager, userLoggedIn.token]);

    return (
        <></>
    )
}