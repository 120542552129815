import React, { useState, useEffect, useMemo } from 'react';
import './style.scss';
import { FieldGroup, FieldItem, TextAreaFieldItem, SubmitButton, InputFieldItem } from '../../../Shared/FormHelpers/index';
import { Form, Formik } from 'formik';
import { getFormInitialValues } from '../../../../Utils/utils';
import FileUpload from '../../../Shared/FileUpload/FileUplodad';
import FileList from '../../../Shared/FileList/FileList';
import { faFileImage } from '@fortawesome/free-solid-svg-icons'
import { sessionManager } from '../../../../sessionManager';
import { Message } from '../../../Shared/Message/index';
import useTimeout from '../../../../hooks/useTimeout/index';
import { ProfileType, Status } from '../../../../Utils/types';
import { profileService } from '../../../../services/profileService';

const fieldNames = {
    aboutMe: 'aboutMe',
    instagram: 'instagram',
    facebook: 'facebook',
    linkedin: 'linkedin',
    youTubeChannel: 'youTubeChannel',
    webSite: 'webSite',
};

export const EditProfile = ({ profile, handleEditProfile }) => {
    const profileServiceClient = useMemo(() => new profileService(), []);
    const manager = new sessionManager();
    const userLoggedIn = JSON.parse(manager.getUserSession());

    const [profileImage, setProfileImage] = useState([]);
    const [profileImageId, setProfileImageId] = useState([]);
    const [isProfileImageLoading, setIsProfileImageLoading] = useState(false);

    const [coverImage, setCoverImage] = useState([]);
    const [coverImageId, setCoverImageId] = useState([]);
    const [isCoverImageFileLoading, setIsCoverImageFileLoading] = useState(false);

    const [status, setStatus] = useState('');
    const [error, setError] = useState('');

    const createTimeout = useTimeout();

    useEffect(() => {
        const fetchData = async () => {
            initializesFiles(profile);
        };
        fetchData();

    }, [profile]);

    const removeProfileImage = (fileToRemove) => {
        setProfileImage(profileImage.filter(file => file.name !== fileToRemove.name));
        setProfileImageId(profileImageId.filter(file => file !== fileToRemove.id));
    }

    const removeCoverImage = (fileToRemove) => {
        setCoverImage(coverImage.filter(file => file.name !== fileToRemove.name));
        setCoverImageId(coverImageId.filter(file => file !== fileToRemove.id));
    }

    const saveProfile = async (values) => {
        setStatus('');

        var response = await profileServiceClient.updateProfile(values, coverImageId, profileImageId, userLoggedIn.userId);

        if (response.success) {
            setStatus(Status.Saved);
            createTimeout(() => {
                setStatus('');
                window.location.reload(false);
            }, 1000);
        } else {
            setStatus(Status.HasError);
            setError(response.error);
        }
    }

    const _getFormInitialValues = () => {
        let initialValues = getFormInitialValues(fieldNames);

        if (profile !== null) {
            initialValues = { ...profile };
        }

        return initialValues;
    };

    const initializesFiles = (profile) => {

        /* Profile Image */
        if (profile.profilePhoto.fileId > 0) {
            setProfileImage([{
                name: profile.profilePhoto.name,
                isUploading: false,
                id: profile.profilePhoto.fileId,
                canDelete: true
            }]);

            setProfileImageId([profile.profilePhoto.fileId]);
        }

        /* Cover Image */
        if (profile.coverImage.fileId > 0) {
            setCoverImage([{
                name: profile.coverImage.name,
                isUploading: false,
                id: profile.coverImage.fileId,
                canDelete: true
            }]);

            setCoverImageId([profile.coverImage.fileId]);
        }
    }

    const showMessage = [Status.Saved, Status.HasError].includes(status);

    return (
        <>
            <div className="edit-profile">
                <div className="edit-profile-card">
                    <div className="title">
                        <h2 className="about-me"><b>Sobre Mí</b></h2>
                        <p className="subtitle">Escriba una información breve sobre usted para que otros usuarios puedan conocerlo... No ingrese datos de contacto.</p>
                    </div>
                    <div className="recommendations">
                        <p><b>Recomendaciones:</b></p>
                        <ul>
                            <li>
                                <span className="size"></span>
                                <span>El tamaño recomendado para la <b>foto de perfil</b> es de <b>190x190</b></span>
                            </li>
                            <li>
                                <span className="size"></span>
                                <span>El tamaño recomendado para la <b>foto de portada</b> es de <b>800x200</b></span>
                            </li>
                        </ul>
                    </div>
                    <div className="form">
                        <div className="row">
                            <div className="col-lg-12">
                                <Formik
                                    onSubmit={saveProfile}
                                    initialValues={_getFormInitialValues()}
                                >
                                    {({ setFieldValue, isSubmitting, isValid, dirty }) => (
                                        <Form>
                                            <fieldset className="form-fields">
                                                <FieldGroup>
                                                    <FieldItem className="field-item">
                                                        <FieldGroup>
                                                            <TextAreaFieldItem
                                                                type="textarea"
                                                                fieldName={fieldNames.aboutMe}
                                                                id={fieldNames.aboutMe}
                                                                label={`Sobre mi`}
                                                                placeholder={'Descripción detallada de lo que aprenderá quien adquiera el curso'}
                                                                className="field-item--100"
                                                            />
                                                        </FieldGroup>
                                                    </FieldItem>

                                                    {userLoggedIn.profile === ProfileType.Edowier && (
                                                        <>
                                                            <FieldItem className="field-item">
                                                                <div className="social-neteworks-title">
                                                                    <h2 className="social-neteworks"><b>Redes sociales y/o sitio web</b></h2>
                                                                </div>
                                                            </FieldItem>
                                                            <FieldItem className="field-item">
                                                                <FieldGroup>
                                                                    <InputFieldItem
                                                                        type="text"
                                                                        fieldName={fieldNames.instagram}
                                                                        id={fieldNames.instagram}
                                                                        label={`Usuario de Instagram`}
                                                                        placeholder={'Usuario de Instagram'}
                                                                        className="field-item--50 field-item--pr-10"
                                                                    />
                                                                    <InputFieldItem
                                                                        type="text"
                                                                        fieldName={fieldNames.facebook}
                                                                        id={fieldNames.facebook}
                                                                        label={`Usuario de Facebook`}
                                                                        placeholder={'Usuario de Facebook'}
                                                                        className="field-item--50"
                                                                    />
                                                                </FieldGroup>
                                                            </FieldItem>
                                                            <FieldItem className="field-item">
                                                                <FieldGroup>
                                                                    <InputFieldItem
                                                                        type="text"
                                                                        fieldName={fieldNames.linkedin}
                                                                        id={fieldNames.linkedin}
                                                                        label={`Usuario de Linkedin`}
                                                                        placeholder={'Usuario de Linkedin'}
                                                                        className="field-item--50 field-item--pr-10"
                                                                    />
                                                                    <InputFieldItem
                                                                        type="text"
                                                                        fieldName={fieldNames.youTubeChannel}
                                                                        id={fieldNames.youTubeChannel}
                                                                        label={`Canal de Youtube`}
                                                                        placeholder={'Canal de Youtube'}
                                                                        className="field-item--50"
                                                                    />
                                                                </FieldGroup>
                                                            </FieldItem>
                                                            <FieldItem className="field-item">
                                                                <FieldGroup>
                                                                    <InputFieldItem
                                                                        type="text"
                                                                        fieldName={fieldNames.webSite}
                                                                        id={fieldNames.webSite}
                                                                        label={`Sitio web propio`}
                                                                        placeholder={'http://www.example.com'}
                                                                        className="field-item--50 field-item--pr-10"
                                                                    />
                                                                </FieldGroup>
                                                            </FieldItem>
                                                        </>
                                                    )}


                                                    <FieldItem className="field-item">
                                                        <FieldGroup>
                                                            <div className="row">
                                                                <div className="col-lg-2"></div>
                                                                <div className="col-lg-4">
                                                                    <FileUpload files={coverImage} setFiles={setCoverImage}
                                                                        removeFile={removeCoverImage} fileIcon={faFileImage}
                                                                        uploadTitle={'Imagen de Portada'} allowedExtensions={".png, .jpg, .jpeg"}
                                                                        allowMultipleFile={false}
                                                                        handleUploadedFile={(fileId) => { setCoverImageId([...coverImageId, fileId]) }}
                                                                        handleIsLoadingFile={(isUploading) => {
                                                                            setIsCoverImageFileLoading(isUploading);
                                                                        }}
                                                                    />
                                                                    <FileList files={coverImage} removeFile={removeCoverImage} />
                                                                </div>
                                                                <div className="col-lg-4">
                                                                    <FileUpload files={profileImage} setFiles={setProfileImage}
                                                                        removeFile={removeProfileImage} fileIcon={faFileImage}
                                                                        uploadTitle={'Imagen de Perfíl'} allowedExtensions={".png, .jpg, .jpeg"}
                                                                        allowMultipleFile={false}
                                                                        handleUploadedFile={(fileId) => { setProfileImageId([...profileImageId, fileId]) }}
                                                                        handleIsLoadingFile={(isUploading) => {
                                                                            setIsProfileImageLoading(isUploading);
                                                                        }}
                                                                    />
                                                                    <FileList files={profileImage} removeFile={removeProfileImage} />
                                                                </div>
                                                                <div className="col-lg-2"></div>
                                                            </div>

                                                        </FieldGroup>
                                                    </FieldItem>
                                                    <FieldItem className="field-item">
                                                        <FieldGroup>
                                                            <div className="save-button">
                                                                <SubmitButton isSubmitting={isSubmitting} disabled={isProfileImageLoading || isCoverImageFileLoading}>
                                                                    {'Guardar'}
                                                                </SubmitButton>
                                                            </div>
                                                        </FieldGroup>
                                                    </FieldItem>
                                                </FieldGroup>
                                            </fieldset>
                                            {showMessage && (
                                                <fieldset>
                                                    <Message
                                                        type={status === Status.Saved ? 'success' : 'error'}
                                                        message={
                                                            status === Status.Saved
                                                                ? '¡Operación procesada con éxito!'
                                                                : error}
                                                    />
                                                </fieldset>
                                            )}
                                        </Form>
                                    )}
                                </Formik>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

