import React, { useState, useEffect, useMemo } from 'react';
import './style.scss';
import { Status, DELAY_BEFORE_REDIRECT, DefaultOption } from '../../../../Utils/types';
import { CountriesSelectFieldItem, FieldGroup, FieldItem, InputFieldItem, SelectFieldItem, SubmitButton } from '../../../Shared/FormHelpers/index';
import { Form, Formik } from 'formik';
import { getFormInitialValues } from '../../../../Utils/utils';
import { profileService } from '../../../../services/profileService';
import { sessionManager } from '../../../../sessionManager';
import useTimeout from '../../../../hooks/useTimeout/index';
import { Message } from '../../../Shared/Message/index';
import { validateUsername } from '../../../../Utils/validations';
import { useTranslation } from 'react-i18next';
import { setLanguage } from '../../../../i18n/i18n';
import { InjectAppServices } from '../../../../services/dependency-injection';

const fieldNames = {
    username: 'username',
    country: 'country',
    timeUse: 'timeUse',
    languageId: 'languageId',
};

export const EditAccount = InjectAppServices(
    ({ 
        profile, 
        handleChangeAccount,
        dependencies: { countryServiceClient }
    }) => {
    const { t } = useTranslation();
    const supportsMultipleLanguages = process.env.REACT_APP_SUPPORTS_MULTIPLE_LANGUAGE;

    const profileServiceClient = useMemo(() => new profileService(), []);
    const manager = new sessionManager();
    const userLoggedIn = JSON.parse(manager.getUserSession());

    const [status, setStatus] = useState('');
    const [error, setError] = useState('');
    const [selected, setSelected] = useState(profile.countryCode);
    const [timezones, setTimezones] = useState([]);
    const [country, setCountry] = useState({});
    const [loadingForCountry, setLoadingForCountry] = useState(false);
    const countryCode = profile.countryCode ?? 'ar';

    const createTimeout = useTimeout();

    useEffect(() => {
        const fetchData = async () => {
            countryServiceClient.getCountryAndTimezonesByCode(countryCode)
                .then(response => {

                    const values = response.timezones.map(function (item) {
                        return { key: item.timezone, value: item.timezone };
                    });

                    setCountry({
                        countryCode: response.countryCode,
                        country: response.name,
                        flagUrl: response.flagUrl
                    });

                    setTimezones(values);
                });
        };
        fetchData();

    }, [countryServiceClient, countryCode]);

    const languages = [
        { key: 1, value: t('spanish', {ns: 'common'}) },
        { key: 2, value: t('english', {ns: 'common'})},
    ]

    const _getFormInitialValues = () => {
        let initialValues = getFormInitialValues(fieldNames);

        if (profile !== null) {
            initialValues = { ...profile };
        }

        return initialValues;
    };

    const saveAccount = async (values) => {
        setStatus('');

        var response = await profileServiceClient.updateAccount(values.username, country.countryCode, country.country, country.flagUrl, values.timeUse, values.languageId, userLoggedIn.userId);

        if (response.success) {
            setStatus(Status.Saved);
            setLanguage(values.languageId === 1 ? 'es' : 'en');

            userLoggedIn.language = values.languageId === 1 ? 'es' : 'en';
            userLoggedIn.flag = country.flagUrl;
            userLoggedIn.country = country.country;
            userLoggedIn.timeUse  = values.timeUse;
            manager.setUserSession(JSON.stringify(userLoggedIn));

            createTimeout(() => {
                setStatus('');
                handleChangeAccount();
            }, DELAY_BEFORE_REDIRECT);
        } else {
            setStatus(Status.HasError);
            setError(response.error);
        }
    }

    const onSelect = async (code) => {
        setSelected(code);
        setLoadingForCountry(true);

        countryServiceClient.getCountryAndTimezonesByCode(code)
            .then(response => {

                const values = response.timezones.map(function (item) {
                    return { key: item.timezone, value: item.timezone };
                });

                setCountry({
                    countryCode: response.countryCode,
                    country: response.name,
                    flagUrl: response.flagUrl
                });

            setTimezones(values);
            setLoadingForCountry(false);
        });
    }

    const handleChange = async (code, setFieldValue) => {
        setFieldValue(fieldNames.timeUse, '');
    };

    const preventCopyPaste = (e) => {
        e.preventDefault()
    }

    const showMessage = [Status.Saved, Status.HasError].includes(status);

    return (
        <>
            <div className="edit-account">
                <div className="form">
                    <div className="row">
                        <div className="col-lg-12">
                            <Formik
                                onSubmit={saveAccount}
                                initialValues={_getFormInitialValues()}
                            >
                                {({ setFieldValue, isSubmitting, isValid, dirty }) => (
                                    <Form>
                                        <fieldset className="form-fields">
                                            <FieldGroup>
                                                <FieldItem className="field-item">
                                                    <FieldGroup>
                                                        <InputFieldItem
                                                            type="text"
                                                            fieldName={fieldNames.username}
                                                            id={fieldNames.username}
                                                            label={`*Usuario`}
                                                            required
                                                            placeholder={'Usuario'}
                                                            validator={validateUsername}
                                                            onPaste={(e) => preventCopyPaste(e)}  
                                                            className="field-item--50"
                                                        />
                                                    </FieldGroup>
                                                </FieldItem>
                                                <FieldItem className="field-item">
                                                    <FieldGroup>
                                                        <CountriesSelectFieldItem
                                                            type="text"
                                                            fieldName={fieldNames.country}
                                                            id={fieldNames.country}
                                                            label={`*País`}
                                                            required
                                                            placeholder={'Usuario'}
                                                            className="field-item--50"
                                                            searchable={true}
                                                            //onSelect={onSelect}
                                                            onSelect={code => {
                                                                onSelect(code);
                                                                handleChange(code, setFieldValue);
                                                            }}
                                                            selected={selected}
                                                            fontSize={13}
                                                        />
                                                    </FieldGroup>
                                                </FieldItem>
                                                <FieldItem className="field-item">
                                                    <FieldGroup>
                                                        <SelectFieldItem
                                                            fieldName={fieldNames.timeUse}
                                                            id={fieldNames.timeUse}
                                                            label={`*Zona Horaria`}
                                                            defaultOption={DefaultOption}
                                                            required
                                                            values={timezones}
                                                            className="field-item--50"
                                                            disabled={loadingForCountry}
                                                        />
                                                    </FieldGroup>
                                                </FieldItem>
                                                {supportsMultipleLanguages === 'true' && (
                                                <FieldItem className="field-item">
                                                    <FieldGroup>
                                                        <SelectFieldItem
                                                            fieldName={fieldNames.languageId}
                                                            id={fieldNames.languageId}
                                                            label={`*Idioma`}
                                                            defaultOption={DefaultOption}
                                                            required
                                                            values={languages}
                                                            className="field-item--50"
                                                        />
                                                    </FieldGroup>
                                                </FieldItem>
                                                )}
                                                <FieldItem className="field-item">
                                                    <FieldGroup>
                                                        <div className="save-button">
                                                            <SubmitButton isSubmitting={isSubmitting}>
                                                                {'Guardar'}
                                                            </SubmitButton>
                                                        </div>
                                                    </FieldGroup>
                                                </FieldItem>
                                            </FieldGroup>
                                        </fieldset>
                                        {showMessage && (
                                            <fieldset>
                                                <Message
                                                    type={status === Status.Saved ? 'success' : 'error'}
                                                    message={
                                                        status === Status.Saved
                                                            ? '¡Operación procesada con éxito!'
                                                            : error}
                                                />
                                            </fieldset>
                                        )}
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
})