import React from 'react';
import { ServiceType } from '../../../../Utils/types';
import './style.scss';
import { ReviewStars } from '../../../Shared/ReviewStars/index';

export const ContentItem = ({ content }) => {
    return (
        <>
            <div className="content-item">
                <div className="cover-image">
                    <a href={`/view-content/${content.contentType}/${content.contentId}`}>
                        <img src={content.coverImage.path} alt='cover' />
                    </a>
                </div>
                <div className="details">
                    <span className={`tag ${parseInt(content.contentType) === ServiceType.Course ? "course" : "resource"}-tag`}>{content.subcategory}</span>
                    {!content.isFree ? (
                        <span className="price">
                            <span>usd</span>{content.paymentAmount.toFixed(2)}
                        </span>) : (
                        <span className="price">
                            <span>Gratis!</span>
                        </span>
                    )}
                    <h4 className="content-title">
                        <a href={`/view-content/${content.contentType}/${content.contentId}`}>{content.title}</a>
                    </h4>
                    <h6 className="content-description">{content.description}</h6>
                    <div className="user-section">
                        <div className="user">
                            <a href={`/teacher-profile/${content.userId}`}>
                                <img src={content.profileImage.path} className="user-profile-image" alt='user' />
                            </a>
                            <div className="user-details">
                                <span className="username">{content.username}</span>
                                <div className="qualification">
                                    <ReviewStars averageQualifications={content.averageReview}></ReviewStars>
                                    <p className="reviews">{content.reviews} Reseña/s.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
