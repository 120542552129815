import { getToken } from "../Utils/utils";

export class activityService {

    async getActivitiesByUserIdAndFilter(userId, filter) {
        try {
            const token = await getToken();
            const edowieApi = process.env.REACT_APP_EDOWIE_API;
            const requestOptions = {
                headers: { 'Authorization': `bearer ${token}`, },
            };

            const response = await fetch(`${edowieApi}activity/${userId}/filter/${filter}`, requestOptions);

            if (response.status === 403) {
                return  { success: false, status: response.status };
            }

            const data = await response.json();

            return { success: true, value: data }

        } catch (error) {
            return { success: false, error: error };
        }
    }

    async feedback(activityId, userId, like) {
        try {

            const edowieApi = process.env.REACT_APP_EDOWIE_API;
            const token = await getToken();
            const requestOptions = {
                method: !like ? 'DELETE' : 'POST',
                headers: { 
                    'Authorization': `bearer ${token}`,
                    'Content-Type': 'application/json' 
                },
                body: JSON.stringify({ activityId: activityId, userId: userId })
            };

            const response = await fetch(`${edowieApi}activity/${activityId}/feedback`, requestOptions);

            return { success: response.status === 200 }

        } catch (error) {
            return { success: false, error: error };
        }
    }

    async delete(activityId) {
        try {

            const edowieApi = process.env.REACT_APP_EDOWIE_API;
            const token = await getToken();
            const requestOptions = {
                method: 'DELETE',
                headers: { 
                    'Authorization': `bearer ${token}`,
                    'Content-Type': 'application/json' 
                },
            };

            const response = await fetch(`${edowieApi}activity/${activityId}/publication`, requestOptions);

            return { success: response.status === 200 }

        } catch (error) {
            return { success: false, error: error };
        }
    }

    async getAttachmentsByActivityId(activityId) {
        try {

            const edowieApi = process.env.REACT_APP_EDOWIE_API;
            const token = await getToken();
            const requestOptions = {
                headers: { 
                    'Authorization': `bearer ${token}`,
                },
            };

            const response = await fetch(`${edowieApi}activity/${activityId}/attachments`, requestOptions);

            const data = await response.json();

            return { success: true, value: data }

        } catch (error) {
            return { success: false, error: error };
        }
    }

    async insert(userId, message, attachments) {
        try {

            const edowieApi = process.env.REACT_APP_EDOWIE_API;
            const token = await getToken();
            const requestOptions = {
                method: 'POST',
                headers: { 
                    'Authorization': `bearer ${token}`,
                    'Content-Type': 'application/json' 
                },
                body: JSON.stringify({ userId: userId, message: message, files: attachments })
            };

            const response = await fetch(`${edowieApi}activity/publication`, requestOptions);

            return { success: response.status === 200 }

        } catch (error) {
            return { success: false, error: error };
        }
    }

    async getPublicationsByUserId(edowierId, userId) {
        try {

            const edowieApi = process.env.REACT_APP_EDOWIE_API;
            const token = await getToken();
            const requestOptions = {
                headers: { 
                    'Authorization': `bearer ${token}`,
                },
            };

            const response = await fetch(`${edowieApi}activity/${edowierId}/${userId}/publications`, requestOptions);

            const data = await response.json();

            return { success: true, value: data }

        } catch (error) {
            return { success: false, error: error };
        }
    }

    async pin(activityId) {
        try {

            const edowieApi = process.env.REACT_APP_EDOWIE_API;
            const token = await getToken();
            const requestOptions = {
                method: 'POST',
                headers: { 
                    'Authorization': `bearer ${token}`,
                    'Content-Type': 'application/json' 
                }
            };

            const response = await fetch(`${edowieApi}activity/${activityId}/pin`, requestOptions);

            return { success: response.status === 200 }

        } catch (error) {
            return { success: false, error: error };
        }
    }

    async unpin(activityId) {
        try {

            const edowieApi = process.env.REACT_APP_EDOWIE_API;
            const token = await getToken();
            const requestOptions = {
                method: 'POST',
                headers: { 
                    'Authorization': `bearer ${token}`,
                    'Content-Type': 'application/json' 
                }
            };

            const response = await fetch(`${edowieApi}activity/${activityId}/unpin`, requestOptions);

            return { success: response.status === 200 }

        } catch (error) {
            return { success: false, error: error };
        }
    }

    async getUserLikesByActivityId(activityId) {
        try {

            const edowieApi = process.env.REACT_APP_EDOWIE_API;
            const token = await getToken();
            const requestOptions = {
                headers: { 'Authorization': `bearer ${token}`, },
            };

            const response = await fetch(`${edowieApi}activity/${activityId}/likes`, requestOptions);
            const data = await response.json();

            return { success: true, value: data }

        } catch (error) {
            return { success: false, error: error };
        }
    }
}
