import React, { useMemo, useState, useEffect, useCallback } from 'react';
import './style.scss';
import { activityService } from '../../../../services/activityService';
import LoadingSpinner from "../../../Shared/LoadingSpinner/index";
import { PublicationItem } from './PublicationItem';
import { sessionManager } from '../../../../sessionManager';

export const Publications = ({ userId }) => {
    const activityServiceClient = useMemo(() => new activityService(), []);
    const manager = new sessionManager();
    const userLoggedIn = JSON.parse(manager.getUserSession());
    const isUserLoggedIn = manager.isUserLoggedIn();

    const [loading, setLoading] = useState(true);
    const [publications, setPublications] = useState([]);

    var userLoggedInId = isUserLoggedIn ? userLoggedIn.userId : 0;

    const getPublications = useCallback(async () => {
        const response = await activityServiceClient.getPublicationsByUserId(userId, userLoggedInId);

            if (response.success) {
                setPublications(response.value);
                setLoading(false);
            }
    },[activityServiceClient, setPublications, setLoading, userId, userLoggedInId]);

    useEffect(() => {
        const fetchData = async () => {
            getPublications();
        };
        fetchData();

    }, [getPublications]);

    const handleFeedback = () => {
        getPublications();
    }

    const handleDelete = () => {
        getPublications();
    }

    const handlePinned = () => {
        getPublications();
    }

    return (
        <>
            <div className="publications">
            {loading ? <LoadingSpinner /> : (
                publications.length === 0 ? (
                <h2 className="no-data">No hay publicaciones para mostrar.</h2>
                ) : (
                    <>
                        <div className="publications-title">
                            <h2>Publicaciones</h2>
                        </div>
                        <div className='row'>
                            <div className='col-lg-2'></div>
                            <div className='col-lg-8'>
                                <div className='publications-container'>
                                    <ul className="publications-list">
                                        {publications.map((publication) => (
                                            <PublicationItem publication={publication} key={publication.activityId}
                                                             handleGiveFeedback={() => handleFeedback()}
                                                             handleDeletePublication={() => handleDelete()}
                                                             handlePin={() => handlePinned()}></PublicationItem>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                            <div className='col-lg-2'></div>
                        </div>
                    </>
                )
            )}
            </div>
        </>
    )
}
