import { getToken } from "../Utils/utils";

export class particularExperienceService {
    
    async getByTeacherId(teacherId) {
        try {

            const edowieApi = process.env.REACT_APP_EDOWIE_API;
            const token = await getToken();
            const requestOptions = {
                headers: { 'Authorization': `bearer ${token}`, },
            };

            const response = await fetch(edowieApi + 'particularexperience/' + teacherId.toString(), requestOptions);
            const data = await response.json();

            return { success: true, value: data }

        } catch (error) {
            return { success: false, error: error };
        }
    }

    async activate(edowierId) {
        try {

            const edowieApi = process.env.REACT_APP_EDOWIE_API;
            const token = await getToken();
            const requestOptions = {
                method: 'POST',
                headers: { 
                    'Authorization': `bearer ${token}`,
                    'Content-Type': 'application/json' 
                },
            };

            const response = await fetch(`${edowieApi}particularexperience/${edowierId}/activate`, requestOptions);

            return { success: response.status === 200 }

        } catch (error) {
            return { success: false, error: error };
        }
    }

    async saveGeneralConditions(edowierId, values) {
        try {

            const edowieApi = process.env.REACT_APP_EDOWIE_API;
            const token = await getToken();
            const requestOptions = {
                method: 'POST',
                headers: { 
                    'Authorization': `bearer ${token}`,
                    'Content-Type': 'application/json' 
                },
                body: JSON.stringify(
                    {
                        "aboutMyParticularExperience": values.aboutMyParticularExperience,
                        "price": values.price,
                        "duration": values.duration,
                        "offerFirstFreeTo": values.offerFirstFreeTo,
                        "advanceCancellationPeriod": values.advanceCancellationPeriod,
                        "advanceHirePeriod": values.advanceHirePeriod,
                        "offerServiceTo": values.offerServiceTo,
                    })
            };

            const response = await fetch(`${edowieApi}particularexperience/${edowierId}/save-general-conditions`, requestOptions);

            return { success: response.status === 200 }

        } catch (error) {
            return { success: false, error: error };
        }
    }

    async saveTheme(edowierId, themeId, values, topics) {
        try {

            const edowieApi = process.env.REACT_APP_EDOWIE_API;
            const token = await getToken();
            const requestOptions = {
                method: 'POST',
                headers: { 
                    'Authorization': `bearer ${token}`,
                    'Content-Type': 'application/json' 
                },
                body: JSON.stringify(
                    {
                        "themeId": themeId,
                        "categoryId": values.categoryId,
                        "subcategoryId": values.subcategoryId,
                        "topics": topics,
                    })
            };

            const response = await fetch(`${edowieApi}particularexperience/${edowierId}/save-theme`, requestOptions);

            return { success: response.status === 200 }

        } catch (error) {
            return { success: false, error: error };
        }
    }

    async getThemesByParticularExperienceId(particularExperienceId) {
        try {

            const edowieApi = process.env.REACT_APP_EDOWIE_API;
            const token = await getToken();
            const requestOptions = {
                headers: { 'Authorization': `bearer ${token}`, },
            };

            const response = await fetch(`${edowieApi}particularexperience/${particularExperienceId}/themes`, requestOptions);
            const data = await response.json();

            return { success: true, value: data }

        } catch (error) {
            return { success: false, error: error };
        }
    }

    async deleteTheme(particularExperienceId, themeId) {
        try {

            const edowieApi = process.env.REACT_APP_EDOWIE_API;
            const token = await getToken();
            const requestOptions = {
                method: 'DELETE',
                headers: { 
                    'Authorization': `bearer ${token}`,
                    'Content-Type': 'application/json' 
                },
            };

            const response = await fetch(`${edowieApi}particularexperience/${particularExperienceId}/themes/${themeId}/delete-theme`, requestOptions);

            return { success: response.status === 200 }

        } catch (error) {
            return { success: false, error: error };
        }
    }

    async saveThemesStep(edowierId) {
        try {

            const edowieApi = process.env.REACT_APP_EDOWIE_API;
            const token = await getToken();
            const requestOptions = {
                method: 'POST',
                headers: { 
                    'Authorization': `bearer ${token}`,
                    'Content-Type': 'application/json' 
                },
            };

            const response = await fetch(`${edowieApi}particularexperience/${edowierId}/save-themes-step`, requestOptions);

            return { success: response.status === 200 }

        } catch (error) {
            return { success: false, error: error };
        }
    }

    async addAvailability(edowierId, startDate, duration, offset) {
        try {

            const edowieApi = process.env.REACT_APP_EDOWIE_API;
            const token = await getToken();
            const requestOptions = {
                method: 'POST',
                headers: { 
                    'Authorization': `bearer ${token}`,
                    'Content-Type': 'application/json' 
                },
                body: JSON.stringify(
                    {
                        "startDate": startDate,
                        "duration": duration,
                        "offset": offset,
                    }),
            };

            const response = await fetch(`${edowieApi}particularexperience/${edowierId}/add-availability`, requestOptions);

            return { success: response.status === 200 }

        } catch (error) {
            return { success: false, error: error };
        }
    }

    async deleteAvailability(particularExperienceId, eventId) {
        try {

            const edowieApi = process.env.REACT_APP_EDOWIE_API;
            const token = await getToken();
            const requestOptions = {
                method: 'DELETE',
                headers: { 
                    'Authorization': `bearer ${token}`,
                    'Content-Type': 'application/json' 
                },
            };

            const response = await fetch(`${edowieApi}particularexperience/${particularExperienceId}/availabilities/${eventId}`, requestOptions);

            return { success: response.status === 200 }

        } catch (error) {
            return { success: false, error: error };
        }
    }

    async saveAvailabilityStep(edowierId) {
        try {

            const edowieApi = process.env.REACT_APP_EDOWIE_API;
            const token = await getToken();
            const requestOptions = {
                method: 'POST',
                headers: { 
                    'Authorization': `bearer ${token}`,
                    'Content-Type': 'application/json' 
                },
            };

            const response = await fetch(`${edowieApi}particularexperience/${edowierId}/save-availability-step`, requestOptions);

            return { success: response.status === 200 }

        } catch (error) {
            return { success: false, error: error };
        }
    }

    async deactivate(edowierId) {
        try {

            const edowieApi = process.env.REACT_APP_EDOWIE_API;
            const token = await getToken();
            const requestOptions = {
                method: 'POST',
                headers: { 
                    'Authorization': `bearer ${token}`,
                    'Content-Type': 'application/json' 
                },
            };

            const response = await fetch(`${edowieApi}particularexperience/${edowierId}/deactivate`, requestOptions);

            return { success: response.status === 200 }

        } catch (error) {
            return { success: false, error: error };
        }
    }

    async hire(userId, edowierId, availabilityId, themeId, topicId, topic, eventDate, isFree, externalPaymentId) {
        try {

            const edowieApi = process.env.REACT_APP_EDOWIE_API;
            const token = await getToken();
            const requestOptions = {
                method: 'POST',
                headers: { 
                    'Authorization': `bearer ${token}`,
                    'Content-Type': 'application/json' 
                },
                body: JSON.stringify(
                    {
                        "edowierId": edowierId,
                        "availabilityId": availabilityId,
                        "themeId": themeId,
                        "topicId": topicId,
                        "topic": topic,
                        "eventDate": eventDate,
                        "isFree": isFree,
                        "externalPaymentId": externalPaymentId
                    }),
            };

            const response = await fetch(`${edowieApi}particularexperience/${userId}/hire`, requestOptions);

            return { success: response.status === 200 }

        } catch (error) {
            return { success: false, error: error };
        }
    }

    async getParticularExperiencesByCategoryIdAndTex(userId, categoryId, searchText, page, countsByPage) {
        try {

            const edowieApi = process.env.REACT_APP_EDOWIE_API;
            const token = await getToken();
            const requestOptions = {
                headers: { 'Authorization': `bearer ${token}`, },
            };

            const response = await fetch(`${edowieApi}particularexperience/${userId}/particular-experiences?categoryId=${categoryId}&text=${searchText}&page=${page}&countsByPage=${countsByPage}`, requestOptions);
            const data = await response.json();

            return { success: true, value: data }

        } catch (error) {
            return { success: false, error: error };
        }
    }

    async hasHiring(edowierId, userId) {
        try {

            const edowieApi = process.env.REACT_APP_EDOWIE_API;
            const token = await getToken();
            const requestOptions = {
                headers: { 'Authorization': `bearer ${token}`, },
            };

            const response = await fetch(`${edowieApi}particularexperience/${edowierId}/has-hiring/${userId}`, requestOptions);
            
            if (response.status === 403) {
                return  { success: false, status: response.status };
            }
            
            const data = await response.json();

            return { success: true, value: data }

        } catch (error) {
            return { success: false, error: error };
        }
    }

    async denunciate(eventId, denunciatedUserId, userId, message) {
        try {

            const edowieApi = process.env.REACT_APP_EDOWIE_API;
            const token = await getToken();
            const requestOptions = {
                method: 'POST',
                headers: { 
                    'Authorization': `bearer ${token}`,
                    'Content-Type': 'application/json' 
                },
                body: JSON.stringify(
                    {
                        "denunciatedUserId": denunciatedUserId,
                        "message": message,
                    }),
            };

            const response = await fetch(`${edowieApi}particularexperience/${userId}/${eventId}/denunciate`, requestOptions);

            return { success: response.status === 200 }

        } catch (error) {
            return { success: false, error: error };
        }
    }

    async cancel(eventId, edowierUserId, userId) {
        try {

            const edowieApi = process.env.REACT_APP_EDOWIE_API;
            const token = await getToken();
            const requestOptions = {
                method: 'POST',
                headers: { 
                    'Authorization': `bearer ${token}`,
                    'Content-Type': 'application/json' 
                },
                body: JSON.stringify(
                    {
                        "edowierUserId": edowierUserId,
                    }),
            };

            const response = await fetch(`${edowieApi}particularexperience/${userId}/${eventId}/cancel`, requestOptions);

            return { success: response.status === 200 }

        } catch (error) {
            return { success: false, error: error };
        }
    }
}
