import React from 'react';
import './style.scss';
import { Message } from '../../../../../Shared/Message/index';

export const PurchaseSummary = ({ message }) => {
    return (
        <div className="purchase-summary-section row">
            <div className="purchaseSummary">
                <Message
                    type={'success'}
                    message={message}
                />
            </div>
        </div>
    );
}