import React, { useState } from 'react';
import { NavMenu } from '../../Menu/NavMenu';
import './style.scss';
import { Formik, Form } from 'formik';
import { FieldGroup, FieldItem, RegisterInputFieldItem, 
         RegisterCountriesSelectFieldItem, RegisterSelectFieldItem, 
         CheckboxFieldItem, SubmitButton, RegisterEmailInputFieldItem } from '../../Shared/FormHelpers/index';
import { userService } from '../../../services/userService';
import { getFormInitialValues } from '../../../Utils/utils';
import { Status, DELAY_BEFORE_REDIRECT } from '../../../Utils/types';
import { Message } from '../../Shared/Message/index';
import useTimeout from '../../../hooks/useTimeout/index';
import { useNavigate } from "react-router-dom";
import { validateUsername } from '../../../Utils/validations';
import { useTranslation } from 'react-i18next';
import { InjectAppServices } from '../../../services/dependency-injection';

const fieldNames = {
    firstName: 'firstName',
    lastName: 'lastName',
    email: 'email',
    username: 'username',
    country: 'country',
    timezone: 'timezone',
    password: 'password',
    confirmPassword: 'confirmPassword',
    acceptTerms: 'acceptTerms',
    receiveNotificationsAndNews: 'receiveNotificationsAndNews',
};


export const Register = InjectAppServices(
    ({
        dependencies: { countryServiceClient }
    }) => {
    const supportsMultipleLanguages = process.env.REACT_APP_SUPPORTS_MULTIPLE_LANGUAGE;

    const { t } = useTranslation(['register', 'common']);

    const defaultOption = { key: '', value: t('selectAnOption', {ns: 'common'}) };

    const [selected, setSelected] = useState('');
    const [timezones, setTimezones] = useState([]);
    const [country, setCountry] = useState({});

    const [status, setStatus] = useState('');
    const [error, setError] = useState('');

    const [loadingForCountry, setLoadingForCountry] = useState(false);

    const navigate = useNavigate();
    const createTimeout = useTimeout();

    const showMessage = [Status.Saved, Status.HasError].includes(status);

    const _getFormInitialValues = () => {
        let initialValues = getFormInitialValues(fieldNames);
        initialValues = { receiveNotificationsAndNews: true };

        return initialValues;
    };

    const onSelect = async (code) => {
        setSelected(code);

        setLoadingForCountry(true);
        countryServiceClient.getCountryAndTimezonesByCode(code)
            .then(response => {
                const values = response.timezones.map(function (item) {
                    return { key: item.timezone, value: item.timezone };
                });
    
                setCountry({
                    countryCode: response.countryCode,
                    country: response.name,
                    flagUrl: response.flagUrl
                });
                setTimezones(values);
                setLoadingForCountry(false);
            });
    }

    const handleChange = async (code, setFieldValue) => {
        setFieldValue(fieldNames.timezone, '');
    };

    const register = async(values) => {
        setStatus('');

        userService.register(values, country)
            .then(response => {
                setStatus(Status.Saved);
                createTimeout(() => {
                    navigate(`/login`);
                }, DELAY_BEFORE_REDIRECT);
            })
            .catch(error => {
                setStatus(Status.HasError);
                setError(mapError(error));
            })
    }

    const preventCopyPaste = (e) => {
        e.preventDefault()
    }

    const mapError = (error) => {
        switch(error.code)
        {
            case 'EmailExists':
                return 'El email ya existe';
            case 'UsernameExists':
                return 'El usuario ya existe';
            case 'PasswordAndConfirmationPasswordAreDifferenrs':
                return 'La clave y la confirmación no coinciden';
            default:
                return '';
        }
    }

    return (
        <>
            <NavMenu showNavigation={false} showLanguageSelector={supportsMultipleLanguages === 'true'} />
            <div className="main-register register-image">
            <div className="col-xl-2"></div>
                <div className="col-xl-5"></div>
                <div className="col-xl-5 register-content">
                <div className="register-card">
                        <div className="register-card-body">
                            <h2 className="register-card-body-title">{t('createYourAccount')}</h2>
                            <div>
                                <Formik
                                    onSubmit={register}
                                    initialValues={_getFormInitialValues()}
                                >
                                    {({ setFieldValue, isSubmitting, isValid, dirty }) => ( 
                                        <Form>
                                            <fieldset className="form-fields">
                                                <FieldGroup>
                                                    <FieldItem className="field-item">
                                                        <FieldGroup>
                                                            <RegisterInputFieldItem
                                                                icon={'ti-user'}
                                                                type="text"
                                                                fieldName={fieldNames.firstName}
                                                                id={fieldNames.firstName}
                                                                required
                                                                placeholder={t('firstName')}
                                                                className="field-item--50 field-item--pr-10"
                                                            >
                                                            </RegisterInputFieldItem>
                                                            <RegisterInputFieldItem
                                                                icon={'ti-user'}
                                                                type="text"
                                                                fieldName={fieldNames.lastName}
                                                                id={fieldNames.lastName}
                                                                required
                                                                placeholder={t('lastName')}
                                                                className="field-item--50"
                                                            >
                                                            </RegisterInputFieldItem>
                                                        </FieldGroup>
                                                    </FieldItem>
                                                    <FieldItem className="field-item">
                                                        <FieldGroup>
                                                            <RegisterEmailInputFieldItem
                                                                icon={'ti-email'}
                                                                fieldName={fieldNames.email}
                                                                id={fieldNames.email}
                                                                required
                                                                placeholder={t('email')}
                                                                className="field-item--50 field-item--pr-10"
                                                            >
                                                            </RegisterEmailInputFieldItem>
                                                            <RegisterInputFieldItem
                                                                icon={'ti-user'}
                                                                type="text"
                                                                fieldName={fieldNames.username}
                                                                id={fieldNames.username}
                                                                required
                                                                validator={validateUsername}
                                                                onPaste={(e) => preventCopyPaste(e)}  
                                                                placeholder={t('username')}
                                                                className="field-item--50"
                                                            >
                                                            </RegisterInputFieldItem>
                                                        </FieldGroup>
                                                    </FieldItem>
                                                    <FieldItem className="field-item">
                                                        <FieldGroup>
                                                            <RegisterCountriesSelectFieldItem
                                                                icon={'ti-world'}
                                                                fieldName={fieldNames.country}
                                                                id={fieldNames.country}
                                                                required
                                                                placeholder={t('selectACountry')}
                                                                className="field-item--50 field-item--pr-10"
                                                                selectClassName='flag-select'
                                                                searchable={true}
                                                                fullWidth={true}
                                                                fontSize={13}
                                                                searchPlaceholder={t('searchCountry')}
                                                                selected={selected}
                                                                onSelect={code => {
                                                                    onSelect(code);
                                                                    handleChange(code, setFieldValue);
                                                                }}
                                                            >
                                                            </RegisterCountriesSelectFieldItem>
                                                            <RegisterSelectFieldItem
                                                                icon={'ti-time'}
                                                                fieldName={fieldNames.timezone}
                                                                id={fieldNames.timezone}
                                                                required
                                                                defaultOption={defaultOption}
                                                                values={timezones}
                                                                className="field-item--50"
                                                                disabled={loadingForCountry}
                                                                disabledClassName={'register-select-disabled'}
                                                            >
                                                            </RegisterSelectFieldItem>
                                                            
                                                        </FieldGroup>
                                                    </FieldItem>
                                                    <FieldItem className="field-item">
                                                        <FieldGroup>
                                                            <RegisterInputFieldItem
                                                                icon={'ti-lock'}
                                                                type="password"
                                                                fieldName={fieldNames.password}
                                                                id={fieldNames.password}
                                                                required
                                                                placeholder={t('enterPassword')}
                                                                className="field-item--50 field-item--pr-10"
                                                            >
                                                            </RegisterInputFieldItem>
                                                            <RegisterInputFieldItem
                                                                icon={'ti-lock'}
                                                                type="password"
                                                                fieldName={fieldNames.confirmPassword}
                                                                id={fieldNames.confirmPassword}
                                                                required
                                                                placeholder={t('confirmPassword')}
                                                                className="field-item--50"
                                                            >
                                                            </RegisterInputFieldItem>
                                                        </FieldGroup>
                                                    </FieldItem>
                                                    <FieldItem className="field-item">
                                                        <FieldGroup>
                                                            <CheckboxFieldItem
                                                                fieldName={fieldNames.acceptTerms}
                                                                key={fieldNames.acceptTerms}
                                                                label={t('acceptTerms')}
                                                                id={fieldNames.acceptTerms}
                                                                className={'accept-terms'}
                                                                checkRequired={true}
                                                                asLabelAsLink={true}
                                                                labelLink={'/terms-and-conditions'}
                                                                classNameLink={'terms-and-conditions-link'}
                                                            />
                                                        </FieldGroup>
                                                    </FieldItem>
                                                    <FieldItem className="field-item">
                                                        <FieldGroup>
                                                            <CheckboxFieldItem
                                                                fieldName={fieldNames.receiveNotificationsAndNews}
                                                                key={fieldNames.receiveNotificationsAndNews}
                                                                label={t('receiveNotificationsAndNews')}
                                                                id={fieldNames.receiveNotificationsAndNews}
                                                                className={'accept-terms'}
                                                                checkRequired={false}
                                                                asLabelAsLink={false}
                                                            />
                                                        </FieldGroup>
                                                    </FieldItem>
                                                    <FieldItem className="field-item">
                                                        <FieldGroup>
                                                            <div className="register-button">
                                                                <SubmitButton isSubmitting={isSubmitting}>
                                                                {t('signUp')}
                                                                </SubmitButton>
                                                            </div>
                                                        </FieldGroup>
                                                    </FieldItem>
                                                    <FieldItem>
                                                        <FieldGroup>
                                                            <h6 className="btn-login">{t('alreadyHaveAccount')}
                                                                <a href={'/login'}>{t('logIn')}</a>
                                                            </h6>
                                                        </FieldGroup>
                                                    </FieldItem>
                                                </FieldGroup>
                                            </fieldset>
                                            {showMessage && (
                                                <fieldset>
                                                <Message
                                                    type={status === Status.Saved ? 'success' : 'error'}
                                                    message={
                                                        status === Status.Saved
                                                            ? t('successfullyMessage', {ns: 'common'})
                                                            : error}
                                                    />
                                                </fieldset>
                                            )}
                                        </Form>
                                    )} 
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
})