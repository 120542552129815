import React, { useEffect } from 'react';
import { userService } from '../../services/userService'
import LoadingSpinner from "../Shared/LoadingSpinner/index";
import { useParams, useNavigate } from "react-router-dom";

export const PublicProfile = () => {
    const { username } = useParams();
    const navigate = useNavigate();
    const loading = true;
    
    useEffect(() => {
        const fetchData = () => {
            userService.getUserDetailsByUsername(username)
                .then(response => {
                    navigate(`/teacher-profile/${response.userId}`);
                })
                .catch(error => {
                    navigate(`/`);
                })
        };
        fetchData();

    }, [username, navigate]);

    return (
        <>
            {loading && <LoadingSpinner />}
        </>
    )
}
