import { fetchWrapper } from "../Utils/fetchWrapper.js";

const baseUrl = `${process.env.REACT_APP_EDOWIE_API}CountryDiscount`;

export interface ICountryDiscountService {
  getAll(): Promise<string>;
};

export class CountryDiscountService implements ICountryDiscountService {

  public getAll(): Promise<string> {
    return fetchWrapper.get(`${baseUrl}`);
  }
}