import React from 'react';
import { useCountdown } from '../../../hooks/useCountdown';
import { ShowCounter} from './ShowCounter/index';
import './style.scss';

export const CountdownTimer = ({ targetDate, offset }) => {
  const [days, hours, minutes, seconds] = useCountdown(targetDate, offset);

  return (
    <ShowCounter
      days={days}
      hours={hours}
      minutes={minutes}
      seconds={seconds}
    />
  );
};
