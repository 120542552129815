import React ,{ useState, useMemo, useEffect } from 'react';
import './style.scss'
import { RelationType } from '../../../Utils/types';
import { relationService } from '../../../services/relationService';
import { sessionManager } from '../../../sessionManager';
import LoadingSpinner from "../../Shared/LoadingSpinner/index";

export const RelationUsers = ({relationType, userId}) => {
    const relationServiceClient = useMemo(() => new relationService(), []);
    const manager = new sessionManager();
    const userLoggedIn = JSON.parse(manager.getUserSession())

    const [relations, setRelations] = useState([]);
    let [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            const relationsResponse = await relationServiceClient.getRelationsByUserIdAndRelationTypeAsync(userId, relationType);

            if (relationsResponse.success) {
                setRelations(relationsResponse.value);
                setLoading(false);
            }
        };
        fetchData();

    }, [userId, relationType, relationServiceClient]);

    const follow = async (userId, userToFollow) => {
        var folowResponse = await relationServiceClient.follow(userId, userToFollow);

        if (folowResponse.success) {
            const relationsResponse = await relationServiceClient.getRelationsByUserIdAndRelationTypeAsync(userId, relationType);

            if (relationsResponse.success) {
                setRelations(relationsResponse.value);
                setLoading(false);
            }
        }
    }

    const unfollow = async (userId, userToUnfollow) => {
        var unfollowResponse = await relationServiceClient.unfollow(userId, userToUnfollow);

        if (unfollowResponse.success) {
            const relationsResponse = await relationServiceClient.getRelationsByUserIdAndRelationTypeAsync(userId, relationType);

            if (relationsResponse.success) {
                setRelations(relationsResponse.value);
                setLoading(false);
            }
        }
    }

    const approveRequest = async (userId, userToApprove) => {
        var unfollowResponse = await relationServiceClient.approveRequest(userId, userToApprove);

        if (unfollowResponse.success) {
            const relationsResponse = await relationServiceClient.getRelationsByUserIdAndRelationTypeAsync(userId, relationType);

            if (relationsResponse.success) {
                setRelations(relationsResponse.value);
                setLoading(false);
            }
        }
    }

    const rejectRequest = async (userId, userToReject) => {
        var unfollowResponse = await relationServiceClient.rejectRequest(userId, userToReject);

        if (unfollowResponse.success) {
            const relationsResponse = await relationServiceClient.getRelationsByUserIdAndRelationTypeAsync(userId, relationType);

            if (relationsResponse.success) {
                setRelations(relationsResponse.value);
                setLoading(false);
            }
        }
    }

    return (
        <>
            {loading ? <LoadingSpinner /> : (
                <ul className='relation-users-container'>
                    <li>
                        {relationType === RelationType.Following && (
                        <span className='legend'>Sigues a {relations.length} persona/s</span>
                        )}
                        {relationType === RelationType.Followers && (
                        <span className='legend'>Te siguen {relations.length} persona/s</span>
                        )}
                        {relationType === RelationType.Pending && (
                        <span className='legend'>Tenes {relations.length} solicitud/es pendiente/s</span>
                        )}
                    </li>
                    {relations.map((relation, i) => (
                        <li className="relation-users-container-item" key={i}>
                            <div className="relation-users-container-item-details">
                                <div className="col-sm-9">
                                    <a href={`${relation.profile === 'Edowier' ? 'teacher-profile' : 'user-profile'}/${relation.userId}`} className="relation-users-container-item-details-user">
                                        <img src={relation.profileImage.path} className="user-profile-image" alt='profile' />
                                        <div className="user-information">
                                            <span className="user-information-username"><b>{relation.username}</b></span>
                                            <span className="user-information-profile">{relation.profile}</span>
                                        </div>
                                    </a>
                                </div>
                                <div className="relations-buttons col-sm-3">
                                    {userId === userLoggedIn.userId ? (
                                        <>
                                            {relation.relationType !== 2 ? (
                                                <>
                                                {userId !== relation.userId && relation.following === 0 && (
                                                <button className="button" onClick={() => follow(userId, relation.userId)}>
                                                    Seguir
                                                </button>
                                                )}
                                                {relation.relationType === RelationType.Followers && 
                                                userId === userLoggedIn.userId && (
                                                <button className="button" onClick={() => unfollow(relation.userId, userId)}>
                                                    Eliminar
                                                </button>
                                                )}
                                                {userId !== relation.userId && relation.following === 1 && 
                                                relationType === RelationType.Following && (
                                                <button className="button" onClick={() => unfollow (userId, relation.userId)}>
                                                    Dejar de seguir
                                                </button>
                                                )}
                                                {userId !== relation.userId && relation.following === 2 && (
                                                <button className="button">
                                                    Pendiente
                                                </button>
                                                )
                                                }
                                                </>
                                            ) : (
                                                <>
                                                    <button className='pending-button' title='Aceptar Solicitud' onClick={() => approveRequest(userId, relation.userId)}>
                                                        <i className='pending-button-image feather-check-circle'></i>
                                                    </button>
                                                    <button className='pending-button' title='Rechazar Solicitud' onClick={() => rejectRequest(userId, relation.userId)}>
                                                        <i className='pending-button-image feather-x-circle'></i>
                                                    </button>
                                                </>
                                            )}
                                        </>
                                    ) : (
                                        <>
                                        </>
                                    )}
                                </div>
                            </div>
                        </li>
                    ))}
                </ul>
            )}
        </>
    )
}