import { fetchWrapper } from "../Utils/fetchWrapper";

const baseUrl = `${process.env.REACT_APP_EDOWIE_API}user`;

export const userService = {
    login,
    register,
    card,
    getUserDetailsByUsername,
    activateDonations,
    deactivateDonations,
    recoverPassword,
    balance
};

function login(email, password) {
    return fetchWrapper.post(`${baseUrl}/login`, { email: email, password: password });
}

function register(values, country) {
    return fetchWrapper.post(`${baseUrl}/register`, {
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            username: values.username,
            password: values.password,
            confirmPassword: values.confirmPassword,
            country: {
                code: country.countryCode,
                country: country.country,
                flagUrl: country.flagUrl
            },
            timezone: values.timezone,
            receiveNotificationsAndNews: values.receiveNotificationsAndNews === "" ? true : values.receiveNotificationsAndNews,
        });
}

function card(userId) {
    return fetchWrapper.get(`${baseUrl}/${userId}/card`);
}
 
function getUserDetailsByUsername(username) {
    return fetchWrapper.get(`${baseUrl}/${username}/user-details`);
}

function activateDonations(userId) {
    return fetchWrapper.post(`${baseUrl}/${userId}/donations`);
}

function deactivateDonations(userId) {
    return fetchWrapper.delete(`${baseUrl}/${userId}/donations`);
}

function recoverPassword(email) {
    return fetchWrapper.post(`${baseUrl}/recover-password`, { email: email });
}

function balance(userId) {
    return fetchWrapper.get(`${baseUrl}/${userId}/balance`);
}