import React, { useState, useEffect, useMemo } from 'react';
import './style.scss';
import { NavMenu } from '../../NavMenu';
import { contentService } from '../../../../services/contentService';
import { useParams, useNavigate } from "react-router-dom";
import LoadingSpinner from "../../../Shared/LoadingSpinner/index";
import { Header } from './Header/index';
import { InformationSection } from '../../../Shared/Contents/InformationSection/index';
import { DetailsSection } from '../../../Shared/Contents/DetailsSection/index';
import { ContentsSection } from '../../../Shared/Contents/ContentsSection/index';
import { BuySection } from './BuySection/index';
import { ServiceType } from '../../../../Utils/types';
import { UserSection } from '../../../Shared/Services/UserSection/index';
import { DescriptionSection } from '../../../Shared/Services/DescriptionSection/index';
import { RequirementsSection } from '../../../Shared/Services/RequirementsSection';
import { InjectAppServices } from '../../../../services/dependency-injection';

export const BuyContent = InjectAppServices(({dependencies: { ipInfoServiceClient, sessionManager } }) => {
    const navigate = useNavigate();
    const contentServiceClient = useMemo(() => new contentService(), []);

    const isUserLoggedIn = sessionManager.isUserLoggedIn();
    const userLoggedIn = sessionManager.getUserLoggedIn();
    const { contentId, contentType } = useParams();

    const [content, setContent] = useState(null);
    const [loading, setLoading] = useState(contentId !== undefined ? true : false);
    
    const [wasAdquired, setWasAdquired] = useState(false);
    const [userCountry, setUserCountry] = useState('ar');

    const userId = isUserLoggedIn ? userLoggedIn.userId : 0;
    const profile = isUserLoggedIn ? userLoggedIn.profile : '';

    useEffect(() => {
        if (profile === 'Administrador') {
            navigate(`/view-content/${contentType}/${contentId}`);
        }

    }, [contentType, contentId, profile, navigate]);

    useEffect(() => {
        const fetchData = async () => {
            var contentResponse = await contentServiceClient.getContentDetailsById(contentId, contentType);

            if (contentResponse.success) {
                if (isUserLoggedIn) {
                    var wasAdquiredResponse = await contentServiceClient.wasAdquired(userId, contentResponse.value.contentId, contentType);
                    if (wasAdquiredResponse.success) {
                        setWasAdquired(wasAdquiredResponse.value);
                    }
                } else {
                    setWasAdquired(false);
                }

                setContent(contentResponse.value);
                setLoading(false);
            }
        };
        fetchData();

    }, [contentServiceClient, setLoading, setContent, contentId, userId, contentType, isUserLoggedIn]);

    useEffect(() => {
        const fetchData = async () => {
            ipInfoServiceClient.getCountryCode()
            .then(response => {
                setUserCountry(response.value.toLowerCase());
            });
        };
        fetchData();

    }, [ipInfoServiceClient]);
    
    const handleSuccessPurchase = async () => {
        var contentResponse = await contentServiceClient.getContentDetailsById(contentId, contentType);

        if (contentResponse.success) {

            var wasAdquiredResponse = await contentServiceClient.wasAdquired(userId, contentResponse.value.contentId, contentType);
            if (wasAdquiredResponse.success) {
                setWasAdquired(wasAdquiredResponse.value);
            }

            setContent(contentResponse.value);
        }
    }

    return (
        <>
            <NavMenu showNavigation={true} currentPage={'contents'} />
            <div className="content">
                {loading ? <LoadingSpinner /> : (
                    <div className="row">
                        <div className="col-xl-8 col-xxl-8">
                            <Header content={content} userId={userId} contentType={contentType} wasAdquired={wasAdquired}>
                            </Header>
                            <UserSection serviceUserId={content.userId} userId={userId} 
                                serviceLevel={content.level} serviceProfileImage={content.profileImage}
                                serviceUsername={content.username}>
                            </UserSection>
                            <DescriptionSection description={content.detailedDescription}>
                            </DescriptionSection>
                            <InformationSection description={content.description}>
                            </InformationSection>
                            <DetailsSection level={content.level} updatedDate={content.updatedDate} >
                            </DetailsSection>
                            <ContentsSection contents={content.files} contentDuration={content.courseDuration} contentType={parseInt(contentType)}>
                            </ContentsSection>
                            {parseInt(contentType) === ServiceType.Course && (
                            <RequirementsSection requirements={content.requirements}>
                            </RequirementsSection>
                            )}
                        </div>
                        <div className="col-xl-4 col-xxl-4">
                            <BuySection items={[{ id: 1, name: 'Vistas Ilimitadas' }, { id: 2, name: 'Consultas vía Chat' }]}
                                price={content.price} isFree={content.isFree} description={content.description} userId={userId}
                                contentId={content.contentId} contentType={contentType} wasAdquired={wasAdquired} contentUserId={content.userId}
                                discount={content.discount} userCountry={userCountry} hasPromocodesAvailable={content.hasPromocodesAvailable}
                                handleSuccessPurchase={() => handleSuccessPurchase() }>
                            </BuySection>
                        </div>
                    </div>
                )}
            </div>
        </>
    )
})
