import { useRef, useState } from "react";
import { InjectAppServices } from "../../../../../../../services/dependency-injection";
import { DELAY_BEFORE_REDIRECT, Status } from "../../../../../../../Utils/types";
import { EdowieDialog } from "../../../../../../Shared/Dialog";
import { getFormInitialValues } from "../../../../../../../Utils/utils";
import { Message } from "../../../../../../Shared/Message";
import { Form, Formik } from "formik";
import { FieldGroup, FieldItem, InputFieldItem } from "../../../../../../Shared/FormHelpers";
import useTimeout from "../../../../../../../hooks/useTimeout";

const fieldNames = {
    commission: 'commission',
    balance: 'balance',
    minimumWithdrawal: 'minimumWithdrawal',
};

export const UpdateBalanceInformationModal = InjectAppServices(
    ({ 
        show,
        userId,
        commission,
        balance,
        minimumWithdrawal,
        handleUpdateBalanceInformation,
        handleClose,
        dependencies: { administratorServiceClient },
    }) => {

        const formRef = useRef();
        const [status, setStatus] = useState('');
        const [error, setError] = useState('');
        const [isSaving, setIsSaving] = useState(false);
        const createTimeout = useTimeout(); 

        const _getFormInitialValues = () => {
            let initialValues = getFormInitialValues(fieldNames);
            initialValues = { commission: commission, balance: balance, minimumWithdrawal: minimumWithdrawal };

            return initialValues;
        };

        const updateBalanceInformation = (values) => {
            setIsSaving(true);
            administratorServiceClient.updateBalanceInformation(userId, values.commission, values.balance, values.minimumWithdrawal)
                .then(response => {
                    setStatus(Status.Saved);
                    createTimeout(async() => {
                        setStatus('');
                        handleUpdateBalanceInformation();
                    }, DELAY_BEFORE_REDIRECT);
                })
                .catch(error => {
                    setStatus(Status.HasError);
                    setError(error);
            });
        }

        const handleSubmit = () => {
            if (formRef.current) {
                formRef.current.handleSubmit()
            }
        }

        const showMessage = [Status.Saved, Status.HasError].includes(status);

        return (
            <>
                <EdowieDialog 
                    show={show} 
                    handleAccept={() => handleSubmit()} 
                    handleClose={() => handleClose()}
                    title='Información de balance'
                    dialogClass={'update-balance-information-modal'}
                    disabledButtons={isSaving}>
                        <div className="form">
                            <div className="row">
                                <div className="col-lg-12">
                                    <Formik innerRef={formRef}
                                        onSubmit={updateBalanceInformation}
                                        initialValues={_getFormInitialValues()}
                                    >
                                        {({ isSubmitting, handleSubmit }) => (
                                            <Form>
                                                <fieldset className="form-fields">
                                                    <FieldGroup>
                                                        <FieldItem className="field-item">
                                                            <FieldGroup>
                                                                <InputFieldItem
                                                                    type="number"
                                                                    fieldName={fieldNames.commission}
                                                                    id={fieldNames.commission}
                                                                    label={`*Comisión (%)`}
                                                                    placeholder={`Comisión (%)`}
                                                                    className="field-item--100"
                                                                    required
                                                                    maxLength="150"
                                                                />
                                                            </FieldGroup>
                                                        </FieldItem>
                                                        <FieldItem className="field-item">
                                                            <FieldGroup>
                                                                <InputFieldItem
                                                                    type="number"
                                                                    fieldName={fieldNames.balance}
                                                                    id={fieldNames.balance}
                                                                    label={`*Balance`}
                                                                    placeholder={`Balance`}
                                                                    className="field-item--100"
                                                                    required
                                                                    maxLength="150"
                                                                />
                                                            </FieldGroup>
                                                        </FieldItem>
                                                        <FieldItem  className="field-item">
                                                            <FieldGroup>
                                                                <InputFieldItem
                                                                    type="number"
                                                                    fieldName={fieldNames.minimumWithdrawal}
                                                                    id={fieldNames.minimumWithdrawal}
                                                                    label={`*Mínimo retiro (USD)`}
                                                                    placeholder={`Mínimo retiro (USD)`}
                                                                    className="field-item--100"
                                                                    required
                                                                    maxLength="150"
                                                                />
                                                            </FieldGroup>
                                                        </FieldItem>
                                                    </FieldGroup>
                                                </fieldset>
                                                {showMessage && (
                                                    <fieldset>
                                                        <Message
                                                            type={status === Status.Saved ? 'success' : 'error'}
                                                            message={
                                                                status === Status.Saved
                                                                    ? '¡Operación procesada con éxito!'
                                                                    : error}
                                                        />
                                                    </fieldset>
                                                )}
                                            </Form>
                                        )}
                                    </Formik>
                                </div>
                            </div>
                        </div>
                </EdowieDialog>
            </>
        )
    }
)