import { ErrorMessage, Field, Form, Formik } from "formik";
import { NavMenu } from "../../Menu/NavMenu"
import './style.scss';
import '../../../general/styles/general.css'
import '../../../general/styles/feather.css'
import '../../../general/styles/themify-icons.css'
import { userService } from "../../../services/userService";
import { DELAY_BEFORE_REDIRECT, Status } from "../../../Utils/types";
import { useNavigate } from "react-router-dom";
import { Message } from "../../Shared/Message";
import { useState } from "react";
import useTimeout from "../../../hooks/useTimeout";

export const Forgot = () => {
    const navigate = useNavigate();
    const createTimeout = useTimeout();

    const [status, setStatus] = useState('');
    const [error, setError] = useState('');

    const showMessage = [Status.Saved, Status.HasError].includes(status);

    const submitForm = async(values) => {
        setStatus('');
        userService.recoverPassword(values["email"]).then(response => {
            setStatus(Status.Saved);
            createTimeout(() => {
                navigate(`/login`);
            }, DELAY_BEFORE_REDIRECT);
        }).catch((error) => {
            setStatus(Status.HasError);
            setError(error);
        });
    };

    function validateEmail(value) {
        let error;
        if (!value) {
            error = "Requerido";
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
            error = "El email es inválido";
        }
        return error;
    } 

    return (
        <>
            <NavMenu showNavigation={false} />
            <div className="forgot forgot-background">
                <div className="col-xl-2"></div>
                <div className="col-xl-5"></div>
                <div className="col-xl-5 d-flex  overflow-hidden">
                    <div className="card forgot-card">
                        <div className="forgot-card-body">
                            <h2 className="forgot-card-body-title">Recuperar Clave</h2>
                            <div>
                                <Formik
                                    initialValues={{
                                        password: '',
                                        email: '',
                                    }}
                                    onSubmit={async (values) => {
                                        submitForm(values);
                                    }}
                                >
                                    {({ errors, touched, isValidating }) => ( 
                                        <Form>
                                            <div className="form-group mr-4">
                                                <div className="icon-input mb-1 mr-4">
                                                    <i className="font-sm ti-email pr-0"></i>
                                                    <Field
                                                        className={errors.email ? "input pl-3 form-control text-grey-900 font-xss ls-1 error-message-input" : "input pl-3 form-control text-grey-900 font-xss ls-1"}
                                                        id="email"
                                                        name="email"
                                                        placeholder="Ingrese el email"
                                                        type="text"
                                                        validate={validateEmail}
                                                    />
                                                    <ErrorMessage className="error-message" name="email" component="div" />
                                                </div>
                                                <div className="form-group mr-4">
                                                    <button type="submit" className="form-control input btn-recover-password">Recuperar Clave</button>
                                                </div>
                                                <div className="">
                                                    <h6 className="btn-regiaster">¿No tienes Cuenta?
                                                        <a href={'/register'} className="fw-700 ml-1">Registrarse</a>
                                                    </h6>
                                                </div>
                                                {showMessage && (
                                                    <div>
                                                        <Message
                                                            type={status === Status.Saved ? 'success' : 'error'}
                                                            message={
                                                                status === Status.Saved
                                                                    ? '¡Operación procesada con éxito!'
                                                                    : error}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        </Form>
                                    )} 
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}