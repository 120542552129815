import React, { useState } from 'react';
import './style.scss';

export const InformationSection = ({ description, expanded}) => {
    const [showSection, setShowSection] = useState(expanded ?? true);

    return (
        <div className="information-section">
            <div className="card-details">
                <div className="card-header">
                    <h5>
                        <button className="collapse-expand-button" onClick={() => setShowSection(!showSection)}>
                            Información
                        </button>
                    </h5>
                </div>
                {showSection && (
                    <div className="p-3w" >
                        <p>{description}</p>
                    </div>
                )}
            </div>
        </div>
    )
}

