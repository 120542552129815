import { fetchWrapper } from "../Utils/fetchWrapper.js";

const baseUrl = `${process.env.REACT_APP_EDOWIE_API}Diary`;

export interface IDiaryService {
    getEventsByUserIdAndDates(userId: number, startDate: string, endDate: string): Promise<any>;
    getNextEventByUserId(userId: number): Promise<any>;
    getEventsByUserIdAndEventType(userId: number, eventType: string, page: number, eventsByPage: number): Promise<any>;
};

export class DiaryService implements IDiaryService{
    async getEventsByUserIdAndDates(userId: number, startDate: string, endDate: string) {
        return fetchWrapper.get(`${baseUrl}/${userId.toString()}/events/${startDate}/${endDate}`);
    }

    async getNextEventByUserId(userId: number) {
        return fetchWrapper.get(`${baseUrl}/${userId.toString()}/next-event`);
    }

    async getEventsByUserIdAndEventType(userId: number, eventType: string, page: number, eventsByPage: number) {
        return fetchWrapper.get(`${baseUrl}/${userId}/${eventType}/events?page=${page}&eventsByPage=${eventsByPage}`);
    }
}