import './style.scss';
import NoUser from '../../../../../general/images/no-user.jpg'

export const GroupExperienceItem = ({ id, coverImage, subcategory, title, isFree, price, description, numberOfInscriptions }) => {

    return (
        <>
            <div className="group-experience-item">
                <div className="card">
                    <div className="card-image">
                        <a href={`/enroll-group-experience/${id}`}>
                            <img src={coverImage.path} alt={title} />
                        </a>
                    </div>
                    <div className="card-details">
                        <span className="tag group-experience-tag">{subcategory}</span>
                        {!isFree ? (
                            <span className="price">
                                <span>$</span>{price}
                            </span>) : (
                            <span className="price">
                                <span>Gratis!</span>
                            </span>
                        )}
                        <h4>
                            <a href={`/enroll-group-experience/${id}`}>{title}</a>
                        </h4>
                        <h6>{description}</h6>
                        <ul className="inscripted">
                            <li>
                                <a href={`/enroll-group-experience/${id}`}>
                                    <img src={NoUser} alt="user" className="w30 d-inline-block" />
                                </a>
                            </li>
                            <li className="last-inscripted">
                                <a href="/">{numberOfInscriptions}</a>
                            </li>
                            <li className="title">
                                <a href="/">Inscriptos</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
}
