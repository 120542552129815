export const en = {
    donations: {
        title: 'Donation to {{username}}',
        choose_amount: 'Choose amount',
        custom_amount: 'Custom amount',
        amount_required: 'The amount is required',
        donate_button: 'Donate',
        successful_message: 'Operation processed successfully!',
    },
    manage_services: {
        contents: {
            course: {
                title: 'Create your course',
                subtitle: 'Within the asynchronous content, the courses are made up of 1 or more videos, which can be viewed within the platform as many times as their buyers want. The course offer may be deleted but those who purchased it will still have it in their Library.',
                header: 'Content: Courses',
                fields: {
                    category: {
                        label: 'Category in Edowie',
                    },
                    subcategory: {
                        label: 'Subcategory in Edowie',
                    },
                    title: {
                        label: 'Title',
                        placeholder: 'Title',
                    },
                    subtitle: {
                        label: 'Caption',
                        placeholder: 'Caption',
                    },
                    short_description: {
                        label: 'Short description',
                        placeholder: 'Short description',
                    },
                    detailed_description: {
                        label: 'Detailed description',
                        placeholder: 'Detailed description of what whoever purchases the course will learn',
                    },
                    requirements: {
                        label: 'Requirements',
                        placeholder: 'Requirements',
                    },
                    level: {
                        label: 'Level',
                        placeholder: 'Level',
                    },
                    is_free: {
                        label: `It's free`,
                        placeholder: `It's free`,
                    },
                    price: {
                        label: 'Price in USD',
                        placeholder: 'Price',
                    },
                    tags: {
                        label: 'Tags',
                        placeholder: 'Tags',
                    },
                    cover_image: {
                        label: 'Cover image',
                        placeholder: 'Cover image',
                    },
                    videos: {
                        label: 'Videos',
                        placeholder: 'Videos',
                        required: 'You must upload at least one video.',
                    },
                    additional_files: {
                        label: 'Additional Files',
                        placeholder: 'Additional Files',
                    },
                },
                save_button: 'Save',
                success_messages: 'Operation processed successfully!',
            },
            resource: {
                title: 'Create your resource',
                subtitle: 'Within the asynchronous content, the resources are made up of 1 or more files, which can be of the type PDF, audiobook or others, which can be viewed within the platform as many times as their buyers want. The offer of the resource may be eliminated but those who purchased it will continue to have it in their Library.',
                header: 'Content: Resources',
                fields: {
                    category: {
                        label: 'Category in Edowie',
                    },
                    subcategory: {
                        label: 'Subcategory in Edowie',
                    },
                    title: {
                        label: 'Title',
                        placeholder: 'Title',
                    },
                    short_description: {
                        label: 'Short description',
                        placeholder: 'Short description',
                    },
                    detailed_description: {
                        label: 'Detailed description',
                        placeholder: 'Detailed description of what whoever purchases the resource will learn',
                    },
                    language: {
                        label: 'Language',
                        placeholder: 'Language',
                    },
                    level: {
                        label: 'Level',
                        placeholder: 'Level',
                    },
                    is_free: {
                        label: `It's free`,
                        placeholder: `It's free`,
                    },
                    price: {
                        label: 'Price in USD',
                        placeholder: 'Price',
                    },
                    tags: {
                        label: 'Tags',
                        placeholder: 'Tags',
                    },
                    cover_image: {
                        label: 'Cover image',
                        placeholder: 'Cover image',
                    },
                    resource_type: {
                        label: 'Resource type',
                        placeholder: 'Resource type',
                    },
                    files: {
                        label: 'Files',
                        placeholder: 'Files',
                        required: 'You must upload at least one file.',
                    },
                },
                save_button: 'Save',
                success_messages: 'Operation processed successfully!',
            },
        },
        particular: {
            title: 'Particular',
            following_button: 'Following',
            back_button: 'Back',
            continue_button: 'Continue',
            deactivate_button: 'Deactivate',
            finish_button: 'Finish',
            stepper: {
                activate_step: 'Activate',
                general_conditions_step: 'General conditions',
                themes_step: 'Themes',
                availability_step: 'Availability',
            },
            activate_step: {
                title: 'Set up your particular experience service in simple steps',
                activate_button: 'Activate',
            },
            general_conditions_step: {
                title: 'General conditions for your online offer of a particular experience',
                about_me: {
                    label: 'About my particular online experience',
                    placeholder: 'Detail of the knowledge I offer to be hired for a particular experience'
                },
                price: {
                    label: 'Particular experience price in USD',
                    placeholder: 'Price',
                },
                offer_your_first_free: {
                    label: 'Offer your first free online private experience to',
                },
                cancel_particular_contracted: {
                    label: 'How much notice do I have to cancel a particular contracted experience, avoiding possible complaints?',
                },
                duration: {
                    label: 'Duration of the particular online experience',
                },
                minimum_notice: {
                    label: 'How much minimum notice do I establish that they can hire me?',
                },
                offer_my_service: {
                    label: 'I am going to offer my particular online experience service to',
                },
            },
            themes_step: {
                title: 'Complete the information necessary to communicate the knowledge you want to offer online',
                themes_label: 'THEMES',
                edit_theme_button: 'Edit theme',
                delete_theme_button: 'Delete theme',
                category: {
                    label: 'Category in Edowie',
                },
                subcategory: {
                    label: 'Subcategory in Edowie',
                },
                topic_legend: 'Topics',
                your_topics_legend: 'Your topics',
                topic_description: {
                    label: 'Topic description',
                    placeholder: 'Enter the description of the topico',
                },
                topic_details: {
                    label: 'Topic detail',
                    placeholder: 'Enter the topic detail',
                },
                add_topic_button: 'Add topic',
                delete_theme_modal: {
                    title: 'Delete theme',
                    legend: 'The theme will be removed. Are you sure?.',
                },
            },
            availability_step: {
                title: `Communicate your time availability by clicking on the cells (don't forget to press the Finish button)`,
                without_availability_title: 'Your Status: edowier hireable without availability',
                with_availability_title: 'Your Status: edowier hireable with availability',
                duration_experience: 'Duration of experience',
                references: {
                    title: 'References',
                    past_legend: 'Past',
                    available_legend: 'Available',
                    reserved_legend: 'Reserved',
                },
                change_date_label: '(Click on the date to change the week)',
                deactivate_partcular_experience_modal: {
                    title: 'Deactivate Particular Experience',
                    legend: 'Are you sure about deactivating your particular experience?',
                },
            },
        },
        group: {
            title: 'Group',
        },
        channels: {
            title: 'Channels',
        },
        donations: {
            title: 'Donations',
            activate_legend: 'Activate donations so that another user can send you money.',
            deactivate_legend: 'Disable donations',
            activate_button: 'Activate',
            deactivate_button: 'Deactivate',
            successful_message: 'Operation processed successfully!',
        },
        promocodes: {
            title: 'Promocodes',
        },
    },
    stripe: {
        pay_button: 'Pay',
        cancel_button: 'Cancel',
    },
    my_notifications: {
        title: 'My notifications',
        agreed_particular_experience: '<bold>{{username}}</bold> and <bold>{{edowierusername}}</bold> agreed on a particular experience for day <bold>{{date, MM/DD/YYYY}}</bold> at <bold>{{time}}</bold>. Minutes before starting, a notification will be sent with the link to connect.',
        registered_grupal_experience: 'You signed up for the <bold>{{groupExperience}}</bold> group experience. A few minutes before the start of each class the connection will be enabled.',
        user_registered_your_grupal_experience: 'The user <bold>{{username}}</bold> signed up for the group experience <bold>{{groupExperience}}</bold>',
        already_edowier: 'Congratulations! You are already edowier.',
        requested_follow_you: '<bold>{{username}}</bold> requested to follow you.',
        started_following_you: 'The {{profile}} <bold>{{username}}</bold> started following you.',
        acquired_course: '<bold>{{username}}</bold> acquired the <bold>{{contentName}}</bold> course',
        rated_course: '<bold>{{username}}</bold> rated the <bold>{{contentName}}</bold> course a {{rate}}',
        acquired_resource: '<bold>{{username}}</bold> acquired the <bold>{{contentName}}</bold> resource',
        rated_resource: '<bold>{{username}}</bold> rated the <bold>{{contentName}}</bold> resource a {{rate}}',
        updated_course: '<bold>{{username}}</bold> updated the <bold>{{contentName}}</bold> course',
        updated_resource: '<bold>{{username}}</bold> updated the <bold>{{contentName}}</bold> resource',
        published_course: '<bold>{{username}}</bold> published the <bold>{{contentName}}</bold> course in <bold>{{category}}</bold>',
        published_resource: '<bold>{{username}}</bold> published the <bold>{{contentName}}</bold> resource in <bold>{{category}}</bold>',
        donated: 'You donated <bold>USD {{amount}}</bold> to edowier <bold>{{username}}</bold>',
        donation: 'The {{profile}} <bold>{{username}}</bold> donated <bold>USD {{amount}}</bold> to you',
    },
    messages: {
        active_today: 'Active today',
        active_yesterday: 'Active yesterday',
        active_days_ago: 'Active {{days}} days ago',
        date_message: '{{date, dddd, MMMM Do YYYY}}',
        required_message: 'The message is required.',
        placeholder_message: 'Write a message',
        send_message_tooltip: 'Send message',
    },
    // validations: {
    //     required_field: 'The field is required',
    //     invalid_user: 'The user is invalid',
    //     invalid_email: 'The email is invalid',
    // }
};

export default en;