import React, { useState } from 'react';
import './style.scss';
import { Form, Formik } from 'formik';
import { FieldGroup, FieldItem, InputFieldItem, TextAreaFieldItem, SubmitButton } from '../../../../../../../Shared/FormHelpers/index';
import { getFormInitialValues } from '../../../../../../../../Utils/utils';
import { useTranslation } from 'react-i18next';

const fieldNames = {
    topic: 'topic',
    description: 'description'
};

export const Topics = ({topicsAdded, isEditable, handleAddRemoveTopic, showTopicsError}) => {
    const { t } = useTranslation();
    const [topics, setTopics] = useState(topicsAdded);

    const addTopic = (values, { setSubmitting, setFieldValue, setFieldTouched }) => {
        setTopics([...topics, { topic: values.topic, description: values.description}]);
        setSubmitting(false);
        setFieldValue(fieldNames.description, '');
        setFieldValue(fieldNames.topic, '');
        setTimeout(() => setFieldTouched(fieldNames.topic, false));
        setTimeout(() => setFieldTouched(fieldNames.description, false));
        handleAddRemoveTopic([...topics, { topic: values.topic, description: values.description}]);
    }

    const _getFormInitialValues = () => {
        let initialValues = getFormInitialValues(fieldNames);

        return initialValues;
    };

    const deleteTopic = (topic) => {
        let topicsFiltered = topics.filter(item => item.topic !== topic.topic);
        setTopics(topicsFiltered);
        handleAddRemoveTopic(topicsFiltered);
    }

    return (
        <div className='topics'>
            <div className="row">
                <div className="col-lg-12">
                    <div className="topics-section">
                        <h1 className="topics"><span className='topics-title'>{t('manage_services.particular.themes_step.topic_legend')}</span></h1>
                    </div> 
                </div>
            </div>
            <div className="row">
                <div className="col-lg-6">
                    <Formik
                        onSubmit={addTopic}
                        initialValues={_getFormInitialValues()}
                    >
                        {({ isSubmitting }) => (
                            <Form className='topics-form'>
                                <fieldset className="form-fields">
                                    <FieldGroup>
                                        <FieldItem className="field-item">
                                            <FieldGroup>
                                                <InputFieldItem
                                                    type="text"
                                                    fieldName={fieldNames.topic}
                                                    id={fieldNames.topic}
                                                    label={`*${t('manage_services.particular.themes_step.topic_description.label')}`}
                                                    required={true}
                                                    placeholder={t('manage_services.particular.themes_step.topic_description.placeholder')}
                                                    className="field-item--100"
                                                    disabled={!isEditable}
                                                />
                                                <TextAreaFieldItem
                                                    type="textarea"
                                                    fieldName={fieldNames.description}
                                                    id={fieldNames.description}
                                                    label={`*${t('manage_services.particular.themes_step.topic_details.label')}`}
                                                    required={true}
                                                    placeholder={t('manage_services.particular.themes_step.topic_details.placeholder')}
                                                    className="field-item--100"
                                                    disabled={!isEditable}
                                                />
                                            </FieldGroup>
                                        </FieldItem>
                                        <FieldItem className="field-item">
                                            <FieldGroup>
                                                <div className="save-button">
                                                    <SubmitButton isSubmitting={isSubmitting} disabled={!isEditable}>
                                                        {t('manage_services.particular.themes_step.add_topic_button')}
                                                    </SubmitButton>
                                                </div>
                                            </FieldGroup>
                                        </FieldItem>
                                    </FieldGroup>
                                </fieldset>
                            </Form>
                        )}
                    </Formik>
                </div>
                <div className="col-lg-6">
                    <div className='topics-grid'>
                        <div className="topics-grid-header">
                            <h4 className="topics-grid-header-title">{t('manage_services.particular.themes_step.your_topics_legend')}</h4>
                        </div>
                        <ul className='topics-grid-ul'>
                        {topics.map((topic, index) => (
                            <li className='topics-grid-li' key={index}>
                                <span>{topic.topic}</span><span className='topics-grid-spacing'></span>
                                {isEditable && (
                                <button className='topics-grid-delete' onClick={() => deleteTopic(topic)}>
                                    <i className="feather-trash-2"></i>
                                </button>
                                )}
                            </li>
                        ))}
                        </ul>
                        {showTopicsError && isEditable &&
                            <div className="topics-required"><p>Debe agregar al menos un tópico.</p></div>
                        }
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12">
                    <hr/>
                </div>
            </div>
        </div>
    )
}