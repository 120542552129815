import './style.scss';
import { faCalendarAlt, faClock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Trans } from 'react-i18next';

export const NotificationItem = ({ notification }) => {

    return (
        <>
            <li key={notification.rowNumber} className="my-notifications-body-item">
                <a className='my-notifications-body-item-link' href={`${notification.profileUser === 'Edowier' ? '/teacher-profile/' : 'user-profile/'}${notification.userId}`}>
                    <div className="my-notifications-body-item-card">
                        <img src={notification.userProfilePhoto}
                            alt={notification.username} 
                            className="my-notifications-body-item-card-user-photo"/>
                        <h6 className="my-notifications-body-item-card-details">

                            {(notification.type === 'publico-curso' || notification.type === 'publico-recurso') && (
                                <>
                                    <Trans
                                        i18nKey={`${notification.type.replace("publico-", "") === 'curso' ? 'my_notifications.published_course' : 'my_notifications.published_resource'}`}
                                        components={{ bold: <strong /> }}
                                        values={{ username: notification.username, contentName:  notification.title, category:  notification.category}}
                                    />
                                </>
                            )}

                            {(notification.type === 'actualizo-curso' || notification.type === 'actualizo-recurso') && (
                                <>
                                    <Trans
                                        i18nKey={`${notification.type.replace("actualizo-", "") === 'curso' ? 'my_notifications.updated_course' : 'my_notifications.updated_resource'}`}
                                        components={{ bold: <strong /> }}
                                        values={{ username: notification.username, contentName:  notification.title }}
                                    />
                                </>
                            )}

                            {(notification.type === 'adquirio-curso' || notification.type === 'adquirio-recurso') && (
                                <>
                                    <Trans
                                        i18nKey={`${notification.type.replace("adquirio-", "") === 'curso' ? 'my_notifications.acquired_course' : 'my_notifications.acquired_resource'}`}
                                        components={{ bold: <strong /> }}
                                        values={{ username: notification.username, contentName:  notification.title }}
                                    />
                                </>
                            )}

                            {(notification.type === 'calificacion-curso' || notification.type === 'calificacion-recurso') && (
                                <>
                                    <Trans
                                        i18nKey={`${notification.type.replace("calificacion-", "") === 'curso' ? 'my_notifications.rated_course' : 'my_notifications.rated_resource'}`}
                                        components={{ bold: <strong /> }}
                                        values={{ username: notification.username, contentName:  notification.title, rate: notification.qualification}}
                                    />
                                </>
                            )}

                            {(notification.type === 'siguiendo') && (
                                <>
                                    <Trans
                                        i18nKey='my_notifications.started_following_you'
                                        components={{ bold: <strong /> }}
                                        values={{ username: notification.username, profile:  notification.profileUser.toLowerCase()}}
                                    />
                                </>
                            )}

                            {(notification.type === 'solicitud-seguir') && (
                                <>
                                    <Trans
                                        i18nKey='my_notifications.requested_follow_you'
                                        components={{ bold: <strong /> }}
                                        values={{ username: notification.username }}
                                    />
                                </>
                            )}

                            {(notification.type === 'contrato-experiencia-particular') && (
                                <>
                                    <Trans
                                        i18nKey='my_notifications.agreed_particular_experience'
                                        components={{ bold: <strong /> }}
                                        values={{ username: `${notification.username}`, 
                                        edowierusername: `${notification.referenceUsername}`,
                                        date: new Date(notification.title.toString().split('/')[2].split(' - ')[0], notification.title.toString().split('/')[1] - 1, notification.title.toString().split('/')[0]),
                                        time: notification.title.toString().split('-')[1].substring(0, 6),
                                    }}
                                    />
                                </>
                            )}

                            {(notification.type === 'cancelo-experiencia-particular') && (
                                <>
                                    El edowier canceló la experiencia particular para <b>{notification.title.toString().substring(0, 18)}</b>.
                                </>
                            )}

                            {(notification.type === 'califico-experiencia-particular') && (
                                <>
                                    {notification.username} calificó con un {notification.qualification} tu experiencia particular.
                                </>
                            )}

                            {(notification.type === 'mensaje-nuevo') && (
                                <>
                                    El {notification.profileUser.toLowerCase()} {notification.username} te envió un nuevo mensaje.
                                </>
                            )}

                            {(notification.type === 'conferencia-link-enviado') && (
                                <>
                                    En unos minutos inicia la experiencia acordada, ya está habilitado el botón para conectar.
                                </>
                            )}

                            {(notification.type === 'finalizo-experiencia-particular') && (
                                <>
                                    Finalizó la experiencia particular acordada, ya puedes calificar o denunciar dentro de las próximas 24hs al edowier <b>{notification.referenceUsername}</b> en Mi Agenda
                                </>
                            )}

                            {(notification.type === 'incribio-experiencia-grupal') && (
                                <>
                                    <Trans
                                        i18nKey='my_notifications.user_registered_your_grupal_experience'
                                        components={{ bold: <strong /> }}
                                        values={{ username: notification.username, groupExperience: notification.title }}
                                    />
                                </>
                            )}

                            {(notification.type === 'inscribiste-experiencia-grupal') && (
                                <>
                                    <Trans
                                        i18nKey='my_notifications.registered_grupal_experience'
                                        components={{ bold: <strong /> }}
                                        values={{ groupExperience: notification.title }}
                                    />
                                </>
                            )}

                            {(notification.type === 'grupal-conferencia-link-enviado') && (
                                <>
                                    En unos minutos inicia una clase de la experiencia grupal <b>{notification.title}</b>, ya está habilitado el botón para conectar.
                                </>
                            )}
                            
                            {(notification.type === 'finalizo-clase-experiencia-grupal') && (
                                <>
                                    Finalizó la clase acordada en la experiencia grupal <b>{notification.title}</b>, ya podes calificar o denunciar dentro de las próximas 24hs al edowier en Mi Agenda
                                </>
                            )}

                            {(notification.type === 'califico-experiencia-grupal') && (
                                <>
                                    {notification.username} calificó con un {notification.qualification} tu experiencia grupal <b>{notification.title}</b>.
                                </>
                            )}

                            {(notification.type === 'edowier-activado') && (
                                <>
                                    <Trans
                                        i18nKey='my_notifications.already_edowier'
                                    />
                                </>
                            )}

                            {(notification.type === 'donaste') && (
                                <>
                                    <Trans
                                        i18nKey='my_notifications.donated'
                                        components={{ bold: <strong /> }}
                                        values={{ username: notification.referenceUsername, amount: notification.title }}
                                    />
                                </>
                            )}

                        {(notification.type === 'donacion') && (
                                <>
                                    <Trans
                                        i18nKey='my_notifications.donation'
                                        components={{ bold: <strong /> }}
                                        values={{ username: notification.username, amount: notification.title, profile:  notification.profileUser.toLowerCase() }}
                                    />
                                </>
                            )}  

                        </h6>
                        <div className="my-notifications-body-item-card-footer">
                            <span>
                            <i ><FontAwesomeIcon icon={faCalendarAlt} /></i>{`${new Date(notification.date).toLocaleDateString()} - ${new Date(notification.date).toLocaleTimeString()}`}</span>
                            <span><i ><FontAwesomeIcon icon={faClock} /></i>{notification.time}</span>
                        </div>
                    </div>
                </a>
            </li>
        </>
    );
}