import React, { useState } from 'react';
import { NavMenu } from '../../Menu/NavMenu';
import './Login.css';
import '../../../general/styles/general.css'
import '../../../general/styles/feather.css'
import '../../../general/styles/themify-icons.css'
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { EdowieErrorMessage } from '../../Shared/EdowieErrorMessage';
import { sessionManager } from '../../../sessionManager';
import { userService } from '../../../services/userService';
import { useCookies } from "react-cookie";
import { useTranslation } from 'react-i18next';
import { ProfileType } from '../../../Utils/types';

export const Login = () => {
    const { t } = useTranslation(['validations', 'login']);
    const forgotPage = process.env.REACT_APP_FORGOT_PAGE;
    const registerPage = process.env.REACT_APP_REGISTER_PAGE;
    const supportsMultipleLanguages = process.env.REACT_APP_SUPPORTS_MULTIPLE_LANGUAGE;

    const manager = new sessionManager();
    const userLoggedIn = JSON.parse(manager.getUserSession());
    const [error, setError] = useState({ error: false, message: '' });
    const [, setCookie] = useCookies(["User.Id"]);

    async function submitForm(values) {
        userService.login(values["email"], values["password"])
            .then(validate => {
                manager.setUserSession(JSON.stringify(validate));
                userService.card(validate.userId)
                    .then(userDetails => {
                        var userData = {token: validate.token, ...userDetails};
                        manager.setUserSession(JSON.stringify(userData));
                        setCookie("Perfil", userDetails.profile ,{ domain: '.edowie.com', path: '/' });

                        setCookie("User.Id", validate.userId ,{ domain: '.edowie.com', path: '/' });
                        
                        setError({ error: false, message: '' });

                        if (userDetails.profile === ProfileType.Administrator) {
                            window.location.replace('/dashboard');
                        } else {
                            window.location.replace('/home');
                        }
                    });
            })
            .catch((error) => {
                setError({ error: true, message: t('incorrectUserOrPassword') });
            });
    };

    function validateEmail(value) {
        let error;
        if (!value) {
            error = t('requiredField');
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
            error = t('invalidEmail');
        }
        return error;
    } 

    function validatePassword(value) {
        let error;
        if (!value) {
            error = t('requiredField');
        }

        return error;
    }

    if (userLoggedIn && userLoggedIn.userId > 0) {
        if (userLoggedIn.profile === ProfileType.Administrator) {
            window.location.replace('/dashboard');
        } else {
            window.location.replace('/home');
        }
    }

    return (
        <>
            <NavMenu showNavigation={false} showLanguageSelector={supportsMultipleLanguages === 'true'}/>
            <div>
            <div className="main image-login">
                <div className="col-xl-2"></div>
                <div className="col-xl-5"></div>
                <div className="col-xl-5 d-flex  overflow-hidden">
                    <div className="card shadow-none border-0 login-card">
                        <div className="card-body rounded-0 text-left">
                            <h1 className="fw-700 mb-3">{t('title', { ns: 'login' })}</h1>
                            <h1 className="fw-700 mb-3 mt-1">{t('subtitle', { ns: 'login' })}</h1>
                            <br />
                            <h2 className="fw-700 display1-size display2-md-size mb-3">{t('loginYourAccount', { ns: 'login' })}</h2>
                            <div>
                                <Formik
                                    initialValues={{
                                        password: '',
                                        email: '',
                                    }}
                                    onSubmit={async (values) => {
                                        submitForm(values);
                                    }}
                                >
                                    {({ errors, touched, isValidating }) => ( 
                                        <Form>
                                            <div className="form-group mr-4">
                                                <div className="icon-input mb-1">
                                                    <i className="font-sm ti-email pr-0"></i>
                                                    <Field
                                                        className={errors.email ? "input pl-3 form-control text-grey-900 font-xss ls-1 error-message-input" : "input pl-3 form-control text-grey-900 font-xss ls-1"}
                                                        id="email"
                                                        name="email"
                                                        placeholder={t('enterEmail', { ns: 'login' })}
                                                        type="text"
                                                        validate={validateEmail}
                                                    />
                                                    <ErrorMessage className="error-message" name="email" component="div" />
                                                </div>
                                            </div>
                                            <div className="form-group mr-4">
                                                <div className="icon-input mb-1">
                                                    <Field
                                                        className={errors.password ? "input pl-3 form-control font-xss ls-1 error-message-input" : "input pl-3 form-control text-grey-900 font-xss ls-1"}
                                                        id="password"
                                                        name="password"
                                                        placeholder={t('enterPassword', { ns: 'login' })}
                                                        autoComplete="off"
                                                        type="password"
                                                        validate={validatePassword}
                                                         />
                                                    <i className="font-sm ti-lock pr-0"></i>
                                                    <ErrorMessage className="error-message" name="password" component="div" />
                                                </div>
                                            </div>
                                            {error.error ? (
                                                <div className="mr-4 mb-2">
                                                <EdowieErrorMessage message={error.message}></EdowieErrorMessage>
                                            </div>
                                            ) : null}
                                            <div className="form-group mr-4">
                                                <a href={forgotPage} className="btn-forgot-password mb-1">{t('forgotYourPassword', { ns: 'login' })}</a>
                                            </div>
                                            <div className="form-group mr-4">
                                                <button type="submit" className="form-control input btn-ingresar">{t('logIn', { ns: 'login' })}</button>
                                            </div>
                                            <div className="">
                                                <h6 className="btn-regiaster">{t('youNotHaveAccount', { ns: 'login' })}
                                                    <a href={registerPage} className="fw-700 ml-1">{t('signUp', { ns: 'login' })}</a>
                                                </h6>
                                            </div>
                                        </Form>
                                    )} 
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </>
    );
}