import { useTranslation } from "react-i18next";
import { InjectAppServices } from "../../../../../../services/dependency-injection";
import { EdowieModal } from "../../../../../Shared/Modal";
import { groupExperienceService } from "../../../../../../services/groupExperienceService";
import { useEffect, useMemo, useState } from "react";
import './UserItem/style.scss';
import { UserItem } from "./UserItem";

export const RegistrationsModal = InjectAppServices(
    ({
        show, 
        referenceId,
        handleClose
    }) => {
        const groupExperienceServiceClient = useMemo(() => new groupExperienceService(), []);
        const { t } = useTranslation(['diary']);
        const [registrations, setRegistrations] = useState([]);

        useEffect(() => {
            const fetchData = async () => {

                if (show) {
                    const response = await groupExperienceServiceClient.getRegistrationsByGroupExperienceId(referenceId);

                    if (response.success) {
                        setRegistrations(response.value);
                    }
                }
            };
            fetchData();
        }, [groupExperienceServiceClient, referenceId, show]);

        return (
            <EdowieModal 
                show={show} 
                title={t('registrationsModal.title', {ns: 'diary'})}
                modalClass={'diary-qualify-modal'}
                handleAccept={() => handleClose()} 
                close={() => handleClose()}>
                    <div className='registration-list'>
                        <hr></hr>
                        {registrations.map(function(registration, i){
                            return (<div className="registration-list-section" key={i}>
                                <UserItem registration={registration}></UserItem>
                                <hr></hr>
                            </div>)
                            
                        })}
                    </div>
                
            </EdowieModal>
        )
    })