import React from 'react';
import { connect, Field } from 'formik';
import { concatClasses } from '../../../Utils/utils';
import { validateRequiredField, validateCheckRequired, validateEmail, combineValidations, validateMinimumPrice } from '../../../Utils/validations';
import './style.scss';
import ReactFlagsSelect from "react-flags-select";
import { useTranslation } from 'react-i18next';

function createRequiredValidation(requiredProp) {
    if (!requiredProp) {
        return () => null;
    }

    if (requiredProp === true) {
        return (value) => validateRequiredField(value);
    }

    return (value) => validateRequiredField(value, requiredProp);
}

function createMinimumValueValidation(minValue) {
    if (!minValue) {
        return () => null;
    }

    // if (requiredProp === true) {
    //     return (value) => validateRequiredField(value);
    // }

    return (value) => validateMinimumPrice(value, minValue);
}

const Message = ({ message, values = null }) => {

    const { t } = useTranslation();

    return (
        <p>{t(message)}</p>
    );
};

export const FieldGroup = ({ className, children }) => (
    <ul className={concatClasses('field-group', className)}>{children}</ul>
);

export const FieldItem = connect(
    ({
        className,
        fieldName,
        children,
        formik: { errors, touched },
        withErrors = true,
    }) => (
        <li
            className={concatClasses(
                className,
                withErrors &&
                    //(withSubmitCount ? submitCount : true) &&
                    touched[fieldName] &&
                    errors[fieldName]
                    ? 'error'
                    : '',
            )}
        >
            {children}
            {/* Boolean errors will not have message */}
            {withErrors &&
                //(withSubmitCount ? submitCount : true) &&
                touched[fieldName] &&
                errors[fieldName] &&
                errors[fieldName] !== true ? (
                <div className="error-form">
                    <Message message={errors[fieldName]} />
                </div>
            ) : null}
        </li>
    ),
);

export const InputFieldItem = ({
    className,
    fieldName,
    label,
    type,
    placeholder,
    required,
    validator,
    onCopy,
    onPaste,
    onCut,
    min,
    max,
    ...rest
}) => (
    <FieldItem
        className={concatClasses('field-item', className)}
        fieldName={fieldName}
        //withSubmitCount={withSubmitCount}
    >
        <label htmlFor={fieldName}>{label}</label>
        <Field
            type={type}
            name={fieldName}
            id={fieldName}
            placeholder={placeholder}
            onCopy={onCopy}
            onPaste={onPaste}
            onCut={onCut}
            validate={combineValidations(createRequiredValidation(required), validator)}
            {...rest}
        />
    </FieldItem>
);

export const PriceInputFieldItem = ({
    className,
    fieldName,
    label,
    placeholder,
    required,
    min,
    ...rest
}) => (
    <FieldItem
        className={concatClasses('field-item', className)}
        fieldName={fieldName}
        //withSubmitCount={withSubmitCount}
    >
        <label htmlFor={fieldName}>{label}</label>
        <Field
            type={'number'}
            name={fieldName}
            id={fieldName}
            placeholder={placeholder}
            validate={combineValidations(createRequiredValidation(required), createMinimumValueValidation(min))}
            {...rest}
        />
    </FieldItem>
);

export const TextAreaFieldItem = ({
    className,
    fieldName,
    label,
    type,
    placeholder,
    required,
    //withNameValidation,
    //withSubmitCount = true,
    //minLength,
    //withExpiryDateValidation = false,
    ...rest
}) => (
    <FieldItem
        className={concatClasses('field-item', className)}
        fieldName={fieldName}
    >
        <label htmlFor={fieldName}>{label}</label>
        <Field
            component="textarea"
            name={fieldName}
            id={fieldName}
            placeholder={placeholder}
            validate={createRequiredValidation(required)}
            {...rest}
        />
    </FieldItem>
);

export const SelectFieldItem = ({
    className,
    fieldName,
    label,
    type,
    placeholder,
    required,
    defaultOption,
    values,
    ...rest
}) => (
    <FieldItem className={concatClasses('field-item', className)} fieldName={fieldName}>
        <label htmlFor={fieldName}>{label}</label>
        <span className="dropdown-arrow"></span>
        <Field
            as="select"
            name={fieldName}
            id={fieldName}
            placeholder={placeholder}
            validate={createRequiredValidation(required)}
            {...rest}
        >
            {defaultOption ? (
                <option key={defaultOption.key} value={defaultOption.key}>
                    {defaultOption.value}
                </option>
            ) : null}
            {values.map((item) => (
                <option key={item.key} value={item.key}>
                    {item.value}
                </option>
            ))}
        </Field>
    </FieldItem>
);

export const CheckboxFieldItem = ({
    className,
    fieldName,
    label,
    checkRequired,
    id,
    onChange,
    asLabelAsLink,
    labelLink,
    classNameLink,
    ...rest
}) => (
    <FieldItem
        className={concatClasses('field-item field-item__checkbox checkbox', className)}
        fieldName={fieldName}
    >
        <Field
            type="checkbox"
            name={fieldName}
            id={id || fieldName}
            validate={(value) => checkRequired && validateCheckRequired(value)}
            onClick={onChange}
            {...rest}
        />
        <span className="checkmark" />
        {!asLabelAsLink ? (
        <label htmlFor={id || fieldName}> {label}</label>
        ) : (
            <a className={classNameLink} href={labelLink} target='_blank' rel="noreferrer">{label}</a>
        )}
    </FieldItem>
);

export const SubmitButton = ({ children, isSubmitting, className, disabled }) => {
    return (
        <>
            <button
                type="submit"
                disabled={isSubmitting || disabled}
                className={
                    'submit-button' +
                    ((isSubmitting && ' button--loading') || '') +
                    ((className && ` ${className}`) || '')
                }
            >
                {children}
            </button>
        </>
    );
};

export const CountriesSelectFieldItem = ({
    className,
    fieldName,
    label,
    searchable,
    disabled,
    placeholder,
    onSelect,
    selected,
    fontSize,
    ...rest
}) => (
    <FieldItem className={concatClasses('field-item', className)} fieldName={fieldName}>
        <label htmlFor={fieldName}>{label}</label>
        <ReactFlagsSelect
            selected={selected}
            onSelect={onSelect}
            selectedSize={fontSize}
            optionsSize={fontSize}
            placeholder={placeholder}
            searchable={searchable}
            disabled={disabled}
        />
    </FieldItem>
);

export const ConfigurationFieldItem = ({
    className,
    fieldName,
    label,
    checkRequired,
    id,
    onChange,
    ...rest
}) => (
    <FieldItem
        className={concatClasses('field-item field-item__checkbox configuration-item', className)}
        fieldName={fieldName}
    >
        <Field
            type="checkbox"
            name={fieldName}
            id={id || fieldName}
            validate={(value) => checkRequired && validateCheckRequired(value)}
            onClick={onChange}
            {...rest}
        />
        <span className="checkmark" />
        <label htmlFor={id || fieldName}> {label}</label>
    </FieldItem>
);

export const RegisterInputFieldItem = ({
    className,
    fieldName,
    label,
    type,
    placeholder,
    required,
    icon,
    validator,
    onCopy,
    onPaste,
    onCut,
    ...rest
}) => (
    <FieldItem
        className={concatClasses('field-item', className)}
        fieldName={fieldName}
    >
        <div className="icon-input mb-1">
            <i className={`font-sm ${icon}  pr-0`}></i>
            <Field
                className={"input"}
                id={fieldName}
                name={fieldName}
                placeholder={placeholder}
                type={type}
                autoComplete='off'
                onCopy={onCopy}
                onPaste={onPaste}
                onCut={onCut}
                validate={combineValidations(createRequiredValidation(required), validator)}
            />
        </div>
    </FieldItem>
);

export const RegisterEmailInputFieldItem = ({
    className,
    fieldName,
    label,
    placeholder,
    required,
    icon,
    ...rest
}) => (
    <FieldItem
        className={concatClasses('field-item', className)}
        fieldName={fieldName}
    >
        <div className="icon-input mb-1">
            <i className={`font-sm ${icon}  pr-0`}></i>
            <Field
                className={"input"}
                id={fieldName}
                name={fieldName}
                placeholder={placeholder}
                type={'text'}
                autoComplete='off'
                validate={combineValidations(createRequiredValidation(required), validateEmail)}
            />
        </div>
    </FieldItem>
);

export const RegisterCountriesSelectFieldItem = ({
    className,
    fieldName,
    label,
    searchable,
    disabled,
    placeholder,
    onSelect,
    selected,
    fontSize,
    icon,
    fullWidth,
    selectClassName,
    searchPlaceholder,
    ...rest
}) => (
    <FieldItem className={concatClasses('field-item', className)} fieldName={fieldName}>
        <div className="icon-input mb-1">
            <i className={`font-sm ${icon}  pr-0`}></i>
            <ReactFlagsSelect
                selected={selected}
                onSelect={onSelect}
                selectedSize={fontSize}
                optionsSize={fontSize}
                placeholder={placeholder}
                searchable={searchable}
                disabled={disabled}
                fullWidth={fullWidth}
                searchPlaceholder={searchPlaceholder}
                className={`${selectClassName}`}
            />
        </div>
    </FieldItem>
);

export const RegisterSelectFieldItem = ({
    className,
    fieldName,
    label,
    type,
    placeholder,
    required,
    defaultOption,
    values,
    icon,
    disabledClassName,
    disabled,
    ...rest
}) => (
    <FieldItem className={concatClasses('field-item', className)} fieldName={fieldName}>
        <div className="icon-input mb-1">
            <i className={`font-sm ${icon}  pr-0`}></i>
            {/* <span className="dropdown-arrow"></span> */}
            <Field
                className={`select ${disabled ? disabledClassName : ''}`}
                as="select"
                name={fieldName}
                id={fieldName}
                placeholder={placeholder}
                validate={createRequiredValidation(required)}
                disabled={disabled}
                {...rest}
            >
                {defaultOption ? (
                    <option key={defaultOption.key} value={defaultOption.key}>
                        {defaultOption.value}
                    </option>
                ) : null}
                {values.map((item) => (
                    <option key={item.key} value={item.key}>
                        {item.value}
                    </option>
                ))}
            </Field>
        </div>
    </FieldItem>
);

