import { useTranslation } from "react-i18next";
import { InjectAppServices } from "../../../../../services/dependency-injection";
import { useCallback, useEffect, useState } from "react";
import moment from "moment";
import { PaginatedItems } from "./PaginatedItems";

export const Transactions = InjectAppServices(
    ({
        dependencies: { sessionManager, transactionServiceClient }
    }) => {
        const itemsPerPage = parseInt(process.env.REACT_APP_DIARY_EVENTS_PER_PAGE);
        const { i18n } = useTranslation(['balance']);
        const userLoggedIn = sessionManager.getUserLoggedIn();
        const [transactions, setTransactions] = useState([]);
        const [currentPage, setCurrentPage] = useState(0);
        const [forcePage, setForcePage] = useState(0);
        const [loading, setLoading] = useState(true);

        moment.locale(i18n.language);

        const mapTransaction = useCallback((data) => {
            var date = moment(data.date + 'Z').add((-1) * moment().utcOffset(), 'minutes').add(userLoggedIn.offset, 'minutes')._d;
    
            return { 
                date: date,
                transaction: data.transaction, 
                stateId: data.stateId,
                service: data.service, 
                buyer: data.buyer,
                edowier: data.edowier,
                buyerId: data.buyerId,
                edowierId: data.edowierId,
                amount: data.amount
            };

        }, [userLoggedIn.offset])

        useEffect(() => {
            const fetchData = async () => {
                transactionServiceClient.getTransactionsByUserId(userLoggedIn.userId, currentPage, itemsPerPage)
                .then(response => {
                    setTransactions({items: response.items.map(mapTransaction), totalItems: response.totalItems});
                    setLoading(false);
                });
            };
            fetchData();

        }, [currentPage, itemsPerPage, mapTransaction, transactionServiceClient, userLoggedIn.userId]);

        const handlePageClick = (event) => {
            setForcePage(event.selected);
            setCurrentPage((event.selected * itemsPerPage) % transactions.totalItems);
            setLoading(true);
        };

        return (
            <>
                {!loading && (
                <PaginatedItems items={transactions.items} totalItems={transactions.totalItems}
                                itemsPerPage={itemsPerPage} currentPage={forcePage}
                                handlePageClick={handlePageClick}>
                </PaginatedItems>)}
            </>
        )
    }
)