import React from 'react';
import './style.scss';
import moment from 'moment'
import 'moment/locale/es';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faLinkedin, faSquareInstagram, faYoutubeSquare } from '@fortawesome/free-brands-svg-icons'
import { faGlobe } from '@fortawesome/free-solid-svg-icons';

export const Information = ({ 
    aboutMe, 
    showNameInPublicProfile, 
    name, 
    flagUrl, 
    timeZone, 
    joinEdowie, 
    instagram,
    facebook,
    linkedin,
    youTubeChannel,
    webSite
}) => {
    moment.locale('es');

    return (
        <>
            <div className="information">
                <div className="row">
                    <div className="col-xl-8 mt-2">
                        <div className="sobre-mi">
                            <h4>Sobre Mí</h4>
                            <p>{aboutMe}</p>
                        </div>
                    </div>
                    <div className="col-xl-4 mt-2">
                        <div className="user">
                            {showNameInPublicProfile && (
                                <h4>{name}</h4>
                            )}
                            <div className="country">
                                <img className="flag" src={flagUrl} alt='flag' />
                                <span className="time-zone">{timeZone}</span>
                            </div>
                            <h4 className="join-edowie">{`Se unío en ${moment(new Date(joinEdowie)).format('MMMM yyyy')}`}</h4>
                            <div className="social-networks">
                                {instagram && (<a href={`https://www.instagram.com/${instagram}/`} target="_blank" rel="noreferrer" className='icon' 
                                   title={instagram}>
                                    <i>
                                        <FontAwesomeIcon icon={faSquareInstagram} />
                                    </i>
                                </a>)}
                                {facebook && (<a href={`https://facebook.com/${facebook}`} target="_blank" rel="noreferrer" className='icon'
                                    title={facebook}>
                                    <i>
                                        <FontAwesomeIcon icon={faFacebook} />
                                    </i>
                                </a>)}
                                {linkedin && (<a href={`https://www.linkedin.com/in/${linkedin}`} target="_blank" rel="noreferrer" className='icon'
                                    title={linkedin}>
                                    <i>
                                        <FontAwesomeIcon icon={faLinkedin} />
                                    </i>
                                </a>)}
                                {youTubeChannel && (<a href={`https://www.youtube.com/${youTubeChannel}`} target="_blank" rel="noreferrer" className='icon'
                                    title={youTubeChannel}>
                                    <i>
                                        <FontAwesomeIcon icon={faYoutubeSquare} />
                                    </i>
                                </a>)}
                                {webSite && (<a href={webSite} target="_blank" rel="noreferrer" className='icon'
                                    title={webSite}>
                                    <i>
                                        <FontAwesomeIcon icon={faGlobe} />
                                    </i>
                                </a>)}
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </>
    )
}
