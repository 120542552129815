import React from 'react';
import './style.scss'
import '../style.scss'
import '../../../general/styles/general.css'
import { useTranslation } from 'react-i18next';

export const UserCard = ({ card }) => {
    const { t } = useTranslation(['home']);
    const userCard = t('userCard');

    return (
        <div className="user-card">
            <div className="body">
                <a href="/my-profile">
                    <figure className="avatar ml-auto mr-auto mb-0 mt-2 w90">
                        <img src={card.profilePhoto} className="user-photo-big" alt='user' />
                    </figure>
                </a>
                <h2 className="username">{card.username}</h2>
                <h4 className="perfil">{card.profile}</h4>
                <div className="clearfix"></div>
                <div className="mt-3">
                    <a href={`/messages`} title={userCard.messagesTooltip} className="buttons messages">
                        {card.hasUnreadConversation && (<span className="dot-count bg-warning"></span>)}
                        <i className="text-current ti-comment-alt font-sm"></i>
                    </a>
                    <a href={`/relations/${card.userId}`} title={userCard.myNetworkTooltip} className="buttons">
                        <i className="feather-share-2 font-sm"></i></a>
                    <a href={`/diary`} title={userCard.myAgendaTooltip} className="buttons agenda">
                        {card.hasFutureEvents && (<span className="dot-count bg-warning"></span>)} 
                        <i className="feather-calendar font-sm"></i>
                    </a>
                </div>
                <ul className="list-inline border-0 mt-3">
                    <li className="list-inline-item red">
                        <a href={`/relations/${card.userId}/1`}>
                            <h4 className="fw-700 font-md">{card.following}
                                <span>{userCard.followingMessage}</span>
                            </h4>
                        </a>
                    </li>
                    <li className="list-inline-item red">
                        <a href={`/relations/${card.userId}/2`}>
                            <h4 className="fw-700 font-md">{card.followers}
                                <span>{userCard.followersMessage}</span>
                            </h4>
                        </a>
                    </li>
                    <li className="list-inline-item red">
                        <a href={`/relations/${card.userId}/3`}>
                            <h4 className="fw-700 font-md">{card.pending}
                                <span>{userCard.pendingsMessage}</span>
                            </h4>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    )
}
