import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import {
    PaymentElement,
    Elements,
    useStripe,
    useElements,
} from '@stripe/react-stripe-js';
import './style.scss';
import { EdowieErrorMessage } from '../EdowieErrorMessage';
import { getUserLoggedIn } from '../../../Utils/utils';
import { useTranslation } from 'react-i18next';

const CheckoutForm = ({ amount, returnUrl, disabled, showCancel, handleCancel }) => {
    const { t } = useTranslation();
    const stripe = useStripe();
    const elements = useElements();

    const [errorMessage, setErrorMessage] = useState(null);

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (elements == null) {
            return;
        }

        // Trigger form validation and wallet collection
        const { error: submitError } = await elements.submit();
        if (submitError) {
            // Show error to your customer
            setErrorMessage(submitError.message);
            return;
        }

        // Create the PaymentIntent and obtain clientSecret from your server endpoint
        const edowieApi = process.env.REACT_APP_EDOWIE_API;
        const res = await fetch(`${edowieApi}stripe/create-payment-intent/${amount * 100}`);

        const { clientSecret } = await res.json();
        const edowieApp = process.env.REACT_APP_EDOWIE_APP;

        const { error } = await stripe.confirmPayment({
            //`Elements` instance that was used to create the Payment Element
            elements,
            clientSecret,
            confirmParams: {
                return_url: `${edowieApp}/${returnUrl}`,
            },
        });

        if (error) {
            // This point will only be reached if there is an immediate error when
            // confirming the payment. Show error to your customer (for example, payment
            // details incomplete)
            setErrorMessage(error.message);
        } else {
            // Your customer will be redirected to your `return_url`. For some payment
            // methods like iDEAL, your customer will be redirected to an intermediate
            // site first to authorize the payment, then redirected to the `return_url`.
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <PaymentElement />
            <div className="payment-section">
                <div className='row'>
                    <div className={`${showCancel ? 'col-sm-6' : 'col-sm-12'}`}>
                        <button type="submit" className="pay-button" disabled={!stripe || !elements || disabled}>
                            {t('stripe.pay_button')}
                        </button>
                    </div>
                    {showCancel && (
                    <div className='col-sm-6'>
                        <button className="cancel-button" disabled={!stripe || !elements || disabled} 
                                onClick={() => handleCancel()}>
                            {t('stripe.cancel_button')}
                        </button>
                    </div>
                    )}
                </div>
                
                
            </div>
            {/* Show error message to your customers */}
            {errorMessage && <EdowieErrorMessage message={errorMessage}></EdowieErrorMessage>}
        </form>
    );
};

const stripePublicKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY;
const stripePromise = loadStripe(stripePublicKey);


const PaymentForm = ({ amount, returnUrl, disabled, showCancel, handleCancel }) => {
    const options = {
        locale: getUserLoggedIn().language,
        mode: 'payment',
        amount: 1000,
        currency: 'usd',
        //wallet: false,
        // Fully customizable with appearance API.
        appearance: {
            /*...*/
        },
    };

    return (
        <Elements stripe={stripePromise} options={options}>
            <CheckoutForm amount={amount} returnUrl={returnUrl} disabled={disabled} showCancel={showCancel} handleCancel={handleCancel} />
        </Elements>
    );
};

export default PaymentForm;