import React, { useState, useEffect, useMemo, useCallback } from 'react';
import './style.scss';
import { NavMenu } from '../../Menu/NavMenu';
import { Searcher } from '../../Shared/Searcher/index';
import LoadingSpinner from "../../Shared/LoadingSpinner/index";
import { sessionManager } from '../../../sessionManager';
import { SeacherType } from '../../../Utils/types';
import { groupExperienceService } from '../../../services/groupExperienceService';
import { PaginatedItems } from './PaginatedItems';

export const GroupExperiences = () => {
    const itemsPerPage = process.env.REACT_APP_CONTENTS_PER_PAGE;
    const groupExperienceServiceClient = useMemo(() => new groupExperienceService(), []); 
    const manager = useMemo(() => new sessionManager(), []);
    const [loading, setLoading] = useState(true);
    const [groupExperiences, setGroupExperiences] = useState([]);
    const [searchCriteria, setSearchCriteria] = useState({ categoryId: 0, searchText: ''});
    const [currentPage, setCurrentPage] = useState(0);
    const [forcePage, setForcePage] = useState(0);
    const isUserLoggedIn = manager.isUserLoggedIn();
    const userLoggedIn = JSON.parse(manager.getUserSession());
    const userId = isUserLoggedIn ? userLoggedIn.userId : 0;

    const getGroupExperiences = useCallback(async () => {
        var response = await groupExperienceServiceClient.getGroupExperiencesByCategoryIdAndTex(userId, searchCriteria.categoryId, searchCriteria.searchText, currentPage, itemsPerPage);

        if (response.success) {
            setGroupExperiences(response.value);
            setLoading(false);
        }
    }, [groupExperienceServiceClient, setGroupExperiences, setLoading, userId, searchCriteria.categoryId, searchCriteria.searchText, currentPage, itemsPerPage]);

    useEffect(() => {
        const fetchData = async () => {
            getGroupExperiences();
        };
        fetchData();

    }, [getGroupExperiences]);

    const handleSearch = (values) => {
        setCurrentPage(0);
        setForcePage(0);
        setSearchCriteria({ categoryId: values.category, searchText: values.text });
        setLoading(true);
    }

    const handlePageClick = (event) => {
        setCurrentPage((event.selected * itemsPerPage) % groupExperiences.totalItems);
        setForcePage(event.selected);
        setLoading(true);
      };

    return (
        <>
            <NavMenu showNavigation={true} currentPage={'group'} />
            <div className="group-experiences-menu">
                <h1 className='group-experiences-menu-title'>Únete a los talleres grupales dictados por nuestros Edowiers sobre temáticas específicas y amplia tus conocimientos.</h1>
                <Searcher handleSearch={(values) => { handleSearch(values); }} searcherBy={SeacherType.groupExperiences}></Searcher>
                {loading ? <LoadingSpinner /> : (
                    <div className="row card-group-experience">
                        <PaginatedItems items={groupExperiences.items} totalItems={groupExperiences.totalItems} itemsPerPage={itemsPerPage} 
                                        currentPage={forcePage} handlePageClick={handlePageClick}></PaginatedItems>
                    </div>
                )}
            </div>
        </>
    )
}