
export class subcategoryService {

    async getAllByCategoryId(categoryId) {
        try {

            const edowieApi = process.env.REACT_APP_EDOWIE_API;

            const response = await fetch(`${edowieApi}subcategory/${categoryId}/subcategories`);

            const data = await response.json();

            return { success: true, value: data }

        } catch (error) {
            return { success: false, error: error };
        }
    }
}
