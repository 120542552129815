import { useCallback, useEffect, useState } from "react";
import { InjectAppServices } from "../../../services/dependency-injection";
import { ProfileType } from "../../../Utils/types";
import { useNavigate, useParams } from "react-router-dom";
import { NavMenu } from "../../Menu/NavMenu";
import './style.scss'
import LoadingSpinner from "../../Shared/LoadingSpinner";
import { PaginatedItems } from "./PaginatedItems";

export const Users = InjectAppServices(
    ({
        dependencies: { sessionManager, administratorServiceClient }
    }) => {
        const itemsPerPage = parseInt(process.env.REACT_APP_DIARY_EVENTS_PER_PAGE);
        const { userProfile } = useParams();
        const navigate = useNavigate();
        const userLoggedIn = sessionManager.getUserLoggedIn();
        const profile = userLoggedIn.profile;

        const [currentPage, setCurrentPage] = useState(0);
        const [forcePage, setForcePage] = useState(0);
        const [users, setUsers] = useState([]);
        const [loading, setLoading] = useState(true);

        const getUsers = useCallback(async () => {
            administratorServiceClient.getUsersByProfile(userProfile, currentPage, itemsPerPage)
            .then(response => {
                setUsers(response);
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            });

        }, [administratorServiceClient, userProfile, currentPage, itemsPerPage]);

        useEffect(() => {
            if (profile !== ProfileType.Administrator) {
                navigate(`/home`);
            } else {
                getUsers();
            }

        }, [navigate, profile, getUsers]);

        if (loading) {
            return <LoadingSpinner />
        }

        const handlePageClick = (event) => {
            setForcePage(event.selected);
            setCurrentPage((event.selected * itemsPerPage) % users.totalItems);
            setLoading(true);
        };
        
        const handleChangeProfile = () => {
            setLoading(true);
            getUsers();
        };

        const handleChangeAmbassador = () => {
            setLoading(true);
            getUsers();
        }

        const handleUpdateBalanceInformation = () => {
            setLoading(true);
            getUsers();
        }
        
        return (
            <div>
                <NavMenu showNavigation={true} currentPage={'users'} />
                <div className="users-section">
                    <div>
                        <div className="col-xl-12">
                            <h3 className="users-section-title">Usuarios</h3>
                        </div>
                    </div>
                    <div>
                    {users.totalItems > 0 ? (
                    <PaginatedItems items={users.items} totalItems={users.totalItems}
                                    itemsPerPage={itemsPerPage} currentPage={forcePage}
                                    handlePageClick={handlePageClick}
                                    handleChangeProfile={handleChangeProfile}
                                    handleChangeAmbassador={handleChangeAmbassador}
                                    handleUpdateBalanceInformation={handleUpdateBalanceInformation}>
                    </PaginatedItems>) : (
                        <span className="no-data-to-show">{'No hay información para mostrar'}</span>
                    )}
                    </div>
                </div>
            </div>
        )
    }
)