import { useState } from "react";
import { InjectAppServices } from "../../../../../../../services/dependency-injection";
import { EdowieModal } from "../../../../../../Shared/Modal";
import { particularExperienceService } from "../../../../../../../services/particularExperienceService";
import { DELAY_BEFORE_REDIRECT, Status } from "../../../../../../../Utils/types";
import useTimeout from "../../../../../../../hooks/useTimeout";
import { Message } from "../../../../../../Shared/Message";
import { useTranslation } from "react-i18next";

export const CancelModal = InjectAppServices(
    ({
        show, 
        userId, 
        edowierUserId,
        eventId,
        handleCancel,
        handleClose
    }) => {
        const particularExperienceServiceClient = new particularExperienceService();
        const createTimeout = useTimeout();
        const { t } = useTranslation(['diary']);
        const [status, setStatus] = useState('');
        const [error, setError] = useState('');

        const saveCancel = async() => {
            var response = await particularExperienceServiceClient.cancel(eventId, edowierUserId, userId);
    
            if (response.success) {
                setStatus(Status.Saved);
                createTimeout(async() => {
                    setStatus('');
                    handleCancel();
                }, DELAY_BEFORE_REDIRECT);
            }
            else {
                setStatus(Status.HasError);
                setError(response.error);
            }
        }

        const showMessage = [Status.Saved, Status.HasError].includes(status);

        return (
            <EdowieModal 
                show={show} 
                title={t('cancelModal.title', {ns: 'diary'})}
                modalClass={'diary-qualify-modal'}
                handleAccept={() => saveCancel()} 
                close={() => handleClose()}>
                    <div className="activity-item-content">
                        {`${t(`cancelModal.question`, {ns: 'diary'})}`}
                        {showMessage && (
                            <Message
                                type={status === Status.Saved ? 'success' : 'error'}
                                message={
                                    status === Status.Saved
                                        ? t(`successfullyMessage`, {ns: 'common'})
                                        : error}
                            />
                        )}
                    </div>
                
            </EdowieModal>
        )
    }
)