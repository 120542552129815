import React, { useState } from 'react';
import './style.scss';

export const DetailsSection = ({ level, numberOfClasses, minutesDurationClasses, 
                                 withoutRegistrationLimit, registrationLimit, expanded }) => {
    const [showSection, setShowSection] = useState(expanded ?? true);

    return (
        <div className="group-experience-details-section">
            <div className="card-details">
                <div className="card-header">
                    <h5>
                        <button className="collapse-expand-button" onClick={() => setShowSection(!showSection)}>Detalles</button>
                    </h5>
                </div>
                {showSection && (
                    <div>
                        <div className="card-body">
                            <span className="item-label">Nível</span>
                            <span className="item-content">{level}</span>
                        </div>
                        <div className="card-body">
                            <span className="item-label">Cantidad de clases</span>
                            <span className="item-content">{withoutRegistrationLimit ? 'Sin límite' : numberOfClasses}</span>
                        </div>
                        <div className="card-body">
                            <span className="item-label">Duración de las clases</span>
                            <span className="item-content">{minutesDurationClasses}</span>
                        </div>
                        <div className="card-body">
                            <span className="item-label">Límite de inscriptos</span>
                            <span className="item-content">{registrationLimit}</span>
                        </div>
                    </div>
                )}
            </div>
        </div>  
    )
}