import { useCallback, useEffect, useState } from "react";
import { InjectAppServices } from "../../../services/dependency-injection";
import moment from 'moment'
import { useTranslation } from "react-i18next";
import { PaginatedItems } from "./PaginatedItems";

export const Events = InjectAppServices(
    ({
        periodEvent,
        dependencies: { sessionManager, diaryServiceClient }
    }) => {

        const itemsPerPage = parseInt(process.env.REACT_APP_DIARY_EVENTS_PER_PAGE);
        const { i18n } = useTranslation(['diary']);
        const userLoggedIn = sessionManager.getUserLoggedIn();
        const [events, setEvents] = useState([]);
        const [currentPage, setCurrentPage] = useState(0);
        const [forcePage, setForcePage] = useState(0);
        const [loading, setLoading] = useState(true);
        moment.locale(i18n.language);

        const mapEvent = useCallback((data) => {
            var startEventDate = moment(data.startDate + 'Z').add((-1) * moment().utcOffset(), 'minutes').add(userLoggedIn.offset, 'minutes')._d;
            var endEventDate = moment(data.endDate + 'Z').add((-1) * moment().utcOffset(), 'minutes').add(userLoggedIn.offset, 'minutes')._d;
    
            return { 
                title: data.title,
                start: startEventDate, 
                end: endEventDate,
                eventId: data.eventId, 
                type: data.type,
                registeredQuantity: data.registeredQuantity,
                edowierUsername: data.edowierUsername,
                edowierUserId: data.edowierUserId,
                username: data.username,
                userId: data.userId,
                conferenceLink: data.conferenceLink,
                classNumber: data.classNumber,
                totalClasses: data.totalClasses,
                referenceId: data.referenceId,
                hasQualification: data.hasQualification,
                hasComplaint: data.hasComplaint
            };

        }, [userLoggedIn.offset])

        const groupEvents = (items) => {
            const groups = [
                ['start', 'events']
            ],
            result = items.reduce((r, o) => {
                groups
                    .reduce((level, [key, children]) => {
                        let group = level.find(q => moment(q[key]).format("L") === moment(o[key]).format("L"));
                        if (!group) level.push(group = { [key]: o[key], [children]: [] });
                        return group[children];
                    }, r)
                    .push(o);
                return r;
            }, []);
    
            return result
        }
        
        const getEvents = useCallback(async () => {
            diaryServiceClient.getEventsByUserIdAndEventType(userLoggedIn.userId, periodEvent, currentPage, itemsPerPage)
                .then(response => {
                    setEvents({items: groupEvents(response.items.map(mapEvent)), totalItems: response.totalItems});
                    setLoading(false);
                });

        }, [diaryServiceClient, userLoggedIn.userId, mapEvent, periodEvent, currentPage, itemsPerPage]);

        useEffect(() => {
            const interval = setInterval(async () => {
                setLoading(true);
                getEvents();
              },60*5000);
              return () => clearInterval(interval);
              // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);

        useEffect(() => {
            const fetchData = async () => {
                getEvents();
            };
            fetchData();
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [getEvents]);

        const handlePageClick = (event) => {
            setForcePage(event.selected);
            setCurrentPage((event.selected * itemsPerPage) % events.totalItems);
            setLoading(true);
          };

        const handleQualify = () => {
            setLoading(true);
            getEvents();
        }

        const handleDenunciate = () => {
            setLoading(true);
            getEvents();
        }

        const handleCancel = () => {
            setLoading(true);
            getEvents();
        }

        return (
            <>
                {!loading && (
                <PaginatedItems items={events.items} totalItems={events.totalItems}
                                itemsPerPage={itemsPerPage} currentPage={forcePage}
                                periodEvent={periodEvent} handlePageClick={handlePageClick}
                                handleQualify={handleQualify} handleDenunciate={handleDenunciate}
                                handleCancel={handleCancel}>
                </PaginatedItems>)}
            </>
        )
    }
)