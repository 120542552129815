import React, { useState, useEffect, useMemo } from 'react';
import './style.scss';
import { FieldGroup, FieldItem, InputFieldItem, TextAreaFieldItem, SubmitButton } from '../../../Shared/FormHelpers/index';
import { Form, Formik } from 'formik';
import { getFormInitialValues } from '../../../../Utils/utils';
import { Status, DELAY_BEFORE_REDIRECT } from '../../../../Utils/types';
import { Message } from '../../../Shared/Message/index';
import { NoteService } from '../../../../services/noteService';
import useTimeout from '../../../../hooks/useTimeout/index';
import LoadingSpinner from "../../../Shared/LoadingSpinner/index";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const fieldNames = {
    title: 'title',
    description: 'description',
    playTime: 'playTime',
    pageNumber: 'pageNumber'
};

export const TooltipCustom = ({ id, children, tooltip }) => {
    return (<OverlayTrigger
                overlay={<Tooltip id={id}>{tooltip}</Tooltip>}
                placement="top"
                delayShow={300}
                delayHide={150}
            >
                {children}
            </OverlayTrigger>)
};

export const NotesSection = ({userId, contentFileId, isVideo, playTime}) => {
    const noteServiceClient = useMemo(() => new NoteService(), []);
    const createTimeout = useTimeout();

    const [notes, setNotes] = useState([]);
    const [loading, setLoading] = useState(true);
    const [addNote, setAddNote] = useState(false);
    const [status, setStatus] = useState('');
    const [error, setError] = useState('');

    useEffect(() => {
        const fetchData = async () => {
        var notesResponse = await noteServiceClient.getAllByUserIdAndContentId(userId, contentFileId);

        if (notesResponse.success) {
            setNotes(notesResponse.value);
            setLoading(false);
        }
        };
        fetchData();

    }, [noteServiceClient, setLoading, setNotes, contentFileId, userId]);

    const saveNote = async(values) => {
        setStatus('');

        var addNoteResponse = await noteServiceClient.addNote(userId, contentFileId, values);

        if (addNoteResponse.success) {
            setStatus(Status.Saved);
            createTimeout(async() => {
                setStatus('');
                
                var notesResponse = await noteServiceClient.getAllByUserIdAndContentId(userId, contentFileId);

                if (notesResponse.success) {
                    setNotes(notesResponse.value);
                    setLoading(false);
                    setAddNote(false);
                }

            }, DELAY_BEFORE_REDIRECT);
        } else {
            setStatus(Status.HasError);
            setError(addNoteResponse.error);
        }
    }

    const deleteNote = async(noteId) => {
        setStatus('');

        var deleteNoteResponse = await noteServiceClient.deleteNote(noteId);

        if (deleteNoteResponse.success) {
            var notesResponse = await noteServiceClient.getAllByUserIdAndContentId(userId, contentFileId);

            if (notesResponse.success) {
                setNotes(notesResponse.value);
                setLoading(false);
            }
        } else {
            setStatus(Status.HasError);
            setError(deleteNoteResponse.error);
        }
    }

    const _getFormInitialValues = () => {
        let initialValues = getFormInitialValues(fieldNames);

        initialValues = {...{
            title: '',
            description: '',
            playTime: playTime,
            pageNumber: ''}};

        return initialValues;
    };

    const showMessage = [Status.Saved, Status.HasError].includes(status);

    return (
        <>
        {loading ? <LoadingSpinner /> : (
            !addNote ? (
                <div className='file-viewer-notes'>
                <div className='file-viewer-notes-header'>
                    <h4 className='file-viewer-notes-header-title'>Notas</h4>
                </div>
                <div className="file-viewer-notes-details">
                    <ul>
                    {notes.map((note) => (
                        <li key={note.title}>
                            <div>
                                <div className="item">
                                    <TooltipCustom tooltip={note.description} id={note.noteId}>
                                    <span className="item-title-content ">{note.title}</span>
                                    </TooltipCustom>
                                    <span className="separator"></span>
                                    <span>{note.playTime === '' ? note.pageNumber : note.playTime}</span>
                                    <button onClick={() => deleteNote(note.noteId)}>
                                        <i className='delete-notes feather-trash-2'></i>
                                    </button>
                                </div>
                            </div>
                        </li>
                    ))}
                    </ul>
                </div>
                <div className='file-viewer-notes-add-notes'>
                    <button onClick={() => setAddNote(true)}>Agregar Nota</button>
                </div>
                </div>) : (
                <div className="notes">
                    <>
                        <div className="form">
                        <div className="row">
                            <div className="col-lg-12">
                                <Formik
                                    onSubmit={saveNote}
                                    initialValues={_getFormInitialValues()}
                                >
                                    {({ setFieldValue, isSubmitting, isValid, dirty }) => (
                                        <Form>
                                            <fieldset className="form-fields">
                                                <FieldGroup>
                                                    <FieldItem className="field-item">
                                                        <FieldGroup>
                                                            <InputFieldItem
                                                                type="text"
                                                                fieldName={fieldNames.title}
                                                                id={fieldNames.title}
                                                                label={`*Título`}
                                                                required
                                                                placeholder={'Título'}
                                                                className="field-item--100"
                                                            />
                                                        </FieldGroup>
                                                    </FieldItem>
                                                    <FieldItem className="field-item">
                                                        <FieldGroup>
                                                            <TextAreaFieldItem
                                                                type="textarea"
                                                                fieldName={fieldNames.description}
                                                                id={fieldNames.description}
                                                                label={`*Nota`}
                                                                required
                                                                placeholder={'Nota'}
                                                                className="field-item--100"
                                                            />
                                                        </FieldGroup>
                                                    </FieldItem>
                                                    {isVideo ? (
                                                    <FieldItem className="field-item">
                                                        <FieldGroup>
                                                            <InputFieldItem
                                                                type="text"
                                                                fieldName={fieldNames.playTime}
                                                                id={fieldNames.playTime}
                                                                label={`*Tiempo de Reproducción`}
                                                                required
                                                                disabled={true}
                                                                placeholder={'Tiempo de Reproducción'}
                                                                className="field-item--10ß0"
                                                            />
                                                        </FieldGroup>
                                                    </FieldItem>) : (
                                                    <FieldItem className="field-item">
                                                        <FieldGroup>
                                                            <InputFieldItem
                                                                type="text"
                                                                fieldName={fieldNames.pageNumber}
                                                                id={fieldNames.pageNumber}
                                                                label={`*Número de Página`}
                                                                required
                                                                placeholder={'Número de Página'}
                                                                className="field-item--100"
                                                            />
                                                        </FieldGroup>
                                                    </FieldItem>
                                                    )}
                                                    <FieldItem className="field-item">
                                                        <FieldGroup>
                                                            <div className='buttons'>
                                                                <button className="close" onClick={() => setAddNote(false)}>
                                                                    Cancelar
                                                                </button>
                                                                <SubmitButton isSubmitting={isSubmitting}>
                                                                    {'Guardar'}
                                                                </SubmitButton>
                                                            </div>
                                                        </FieldGroup>
                                                    </FieldItem>
                                                </FieldGroup>
                                            </fieldset>
                                            {showMessage && (
                                                <fieldset>
                                                    <Message
                                                        type={status === Status.Saved ? 'success' : 'error'}
                                                        message={
                                                            status === Status.Saved
                                                                ? '¡Operación procesada con éxito!'
                                                                : error}
                                                    />
                                                </fieldset>
                                            )}
                                        </Form>
                                    )}
                                </Formik>
                            </div>

                        </div>
                    </div>
                    </>
                </div>)
        )}
        </>
    );
}