import React, { useState } from 'react';

export const SectionItem = ({ title, children }) => {
    const [showSection, setShowSection] = useState(true);

    return (
        <>
            <div className='section'>
            <h2 className="section-title">
                <button className="btn-link" onClick={() => setShowSection(!showSection)}>{title}</button>
            </h2>
            {showSection && (
                <div>
                {children}
                </div>
            )}
            </div>
        </>
    )
}