import moment from 'moment'
import { sessionManager } from '../sessionManager';
import countries_es from '../i18n/es/countries.json';
import countries_en from '../i18n/en/countries.json';
import transaction_state_es from '../i18n/es/transaction_state.json';
import transaction_state_en from '../i18n/en/transaction_state.json';

export const concatClasses = (...args: any[]) => {
    return args.filter((x) => x).join(' ');
};

export const getFormInitialValues = (fieldNames: object) =>
    Object.keys(fieldNames).reduce(
        (accumulator, currentValue) => ({ ...accumulator, [currentValue]: '' }),
        {},
    );

export function extractParameter(
    location: any,
    parseQueryStringFunction: any,
    paramLowercase: string,
    paramUppercase?: string,
) {
    const parsedQuery = location && location.search && parseQueryStringFunction(location.search);
    return (
        (parsedQuery &&
            (parsedQuery[paramLowercase] || (!!paramUppercase && parsedQuery[paramUppercase]))) ||
        null
    );
}

export const getUTCTime = () => {
    var dayUtcDate = moment()._d.getUTCDate();
    var yearUtcDate = moment()._d.getUTCFullYear();
    var monthUtcDate = moment()._d.getUTCMonth();
    var hoursUtcDate = moment()._d.getUTCHours();
    var minutesUtcDate = moment()._d.getUTCMinutes();

    var currentUtcDate = new Date(yearUtcDate, monthUtcDate, dayUtcDate, hoursUtcDate, minutesUtcDate);
    
    return currentUtcDate;

}

export const getCurrentTime = (offset) => {
    var dayUtcDate = moment()._d.getUTCDate();
    var yearUtcDate = moment()._d.getUTCFullYear();
    var monthUtcDate = moment()._d.getUTCMonth();
    var hoursUtcDate = moment()._d.getUTCHours();
    var minutesUtcDate = moment()._d.getUTCMinutes();
    var secondsUtcDate = moment()._d.getUTCSeconds();

    var currentUtcDate = new Date(yearUtcDate, monthUtcDate, dayUtcDate, hoursUtcDate, minutesUtcDate, secondsUtcDate);
    currentUtcDate.setTime(currentUtcDate.getTime() + offset * 60 * 1000);

    return currentUtcDate;

}

export const getToken = () => {
    
    const manager = new sessionManager();

    const isUserLoggedIn = manager.isUserLoggedIn();

    if (isUserLoggedIn) {
        const userLoggedIn = JSON.parse(manager.getUserSession());
        return userLoggedIn.token;
    } else {

        const nonLoggedInUserToken = JSON.parse(manager.getNonLoggedInUserToken());
        return nonLoggedInUserToken.token;
    }
}

export const getUserLoggedIn = () => {
    const manager = new sessionManager();
    const isUserLoggedIn = manager.isUserLoggedIn();

    return isUserLoggedIn ? JSON.parse(manager.getUserSession()) : {userId: 0, profile: ''};
}

export const getCountries = (language) => {
    const data = language === 'es' ? countries_es : countries_en;
    const countries = Object.keys(data).map(function (key) {
      return { key: key, value: data[key] };
    });
  
    return countries.sort((a, b) =>
      a.value.localeCompare(b.value, undefined, { sensitivity: 'base' }),
    );
  };

export const translateCountry = (language, countryCode) => {
    const data = language === 'es' ? countries_es : countries_en;
    const countries = Object.keys(data).map(function (key) {
      return { key: key, value: data[key] };
    });

    return countries.filter(c => c.key === countryCode)[0].value;
}

export const translateTransactionState = (language, stateId) => {
    const data = language === 'es' ? transaction_state_es : transaction_state_en;
    const states = Object.keys(data).map(function (key) {
      return { key: key, value: data[key] };
    });

    return states.filter(c => c.key === stateId.toString())[0].value;
}
