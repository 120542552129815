import ReactPaginate from 'react-paginate';
import 'bootstrap/dist/css/bootstrap.css';
import moment from 'moment';
import { PeriodEvent } from '../../../../Utils/types';
import { Event } from './Event';
import './style.scss';

export const PaginatedItems = (
  { 
    items, 
    itemsPerPage, 
    periodEvent,
    totalItems, 
    currentPage, 
    handlePageClick,
    handleQualify,
    handleDenunciate,
    handleCancel,
  }) => {
    const pageCount = Math.ceil(totalItems / itemsPerPage);
  
    return (
      <>
        {items.map((event, index) => (
            <div key={index} className={`${periodEvent === PeriodEvent.pastEvents ? 'diary-container-past-events' : ''}`}>
                <div className="row"> 
                    <div className='col-lg-2'>
                        <div className='diary-container-event-section-date-container'>
                            <div className='diary-container-event-section-date-container-date'>{moment(event.start).format('L')}</div>
                        </div>
                    </div>
                    <div className='col-lg-10'>
                        {event.events.map((event, index) => (
                            <Event key={index} event={event} periodEvent={periodEvent} 
                                   handleQualify={handleQualify} handleDenunciate={handleDenunciate}
                                   handleCancel={handleCancel}></Event>
                        ))}
                    </div>
                </div>
                <div className='row'>
                    <hr></hr>
                </div>
            </div>
        ))}
        <ReactPaginate
          nextLabel=">"
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          marginPagesDisplayed={2}
          pageCount={pageCount}
          forcePage={currentPage}
          previousLabel="<"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakLabel="..."
          breakClassName="page-item"
          breakLinkClassName="page-link"
          containerClassName="pagination"
          activeClassName="active"
          renderOnZeroPageCount={null}
        />
      </>
    );
  }