import { Trans, useTranslation } from 'react-i18next';
import './style.scss';

export const NotificationItem = ({ notification }) => {
    const { t } = useTranslation(['menu']);

    return (
        <>
        <div className="notification-card" key={notification.rowNumber}>
            <a href={`${notification.profileUser === 'Edowier' ? '/teacher-profile/' : 'user-profile/'}${notification.userId}`}>
                <img src={notification.userProfilePhoto} alt="juan.hoffman" className="notification-card-profile-photo" />
                <h6 className="notification-card-details">

                    {(notification.type === 'publico-curso' || notification.type === 'publico-recurso') && (
                        <>
                           <Trans
                                t={t}
                                i18nKey={`${notification.type.replace("publico-", "") === 'curso' ? 'notifications.publishedCourse' : 'notifications.publishedResource'}`}
                                components={{ bold: <strong /> }}
                                values={{ username: notification.username, contentName:  notification.title, category:  notification.category}}
                            />
                        </>
                    )}

                    {(notification.type === 'actualizo-curso' || notification.type === 'actualizo-recurso') && (
                        <>
                            <Trans
                                t={t}
                                i18nKey={`${notification.type.replace("actualizo-", "") === 'curso' ? 'notifications.updatedCourse' : 'notifications.updatedResource'}`}
                                components={{ bold: <strong /> }}
                                values={{ username: notification.username, contentName:  notification.title }}
                            />
                        </>
                    )}

                    {(notification.type === 'adquirio-curso' || notification.type === 'adquirio-recurso') && (
                        <>
                            <Trans
                                t={t}
                                i18nKey={`${notification.type.replace("adquirio-", "") === 'curso' ? 'notifications.acquiredCourse' : 'notifications.acquiredResource'}`}
                                components={{ bold: <strong /> }}
                                values={{ username: notification.username, contentName:  notification.title }}
                            />
                        </>
                    )}

                    {(notification.type === 'calificacion-curso' || notification.type === 'calificacion-recurso') && (
                        <>
                            <Trans
                                t={t}
                                i18nKey={`${notification.type.replace("calificacion-", "") === 'curso' ? 'notifications.ratedCourse' : 'notifications.ratedResource'}`}
                                components={{ bold: <strong /> }}
                                values={{ username: notification.username, contentName:  notification.title, rate: notification.qualification}}
                            />
                        </>
                    )}

                    {(notification.type === 'siguiendo') && (
                        <>
                            <Trans
                                t={t}
                                i18nKey={'notifications.startedFollowingYou'}
                                components={{ bold: <strong /> }}
                                values={{ username: notification.username, profile:  notification.profileUser.toLowerCase()}}
                            />
                        </>
                    )}

                    {(notification.type === 'solicitud-seguir') && (
                        <>
                            <Trans
                                t={t}
                                i18nKey={'notifications.requestedFollowyou'}
                                components={{ bold: <strong /> }}
                                values={{ username: notification.username }}
                            />
                        </>
                    )}

                    {(notification.type === 'contrato-experiencia-particular') && (
                        <>
                            <Trans
                                t={t}
                                i18nKey={'notifications.agreedParticularExperience'}
                                components={{ bold: <strong /> }}
                                values={{ username: `${notification.username}`, 
                                    edowierusername: `${notification.referenceUsername}`,
                                    date: new Date(notification.title.toString().split('/')[2].split(' - ')[0], notification.title.toString().split('/')[1] - 1, notification.title.toString().split('/')[0]),
                                    time: notification.title.toString().split('-')[1].substring(0, 6),
                                }}
                            />
                        </>
                    )}

                    {(notification.type === 'cancelo-experiencia-particular') && (
                        <>
                            El edowier canceló la experiencia particular para <b>{notification.title.toString().substring(0, 18)}</b>.
                        </>
                    )}

                    {(notification.type === 'califico-experiencia-particular') && (
                        <>
                            {notification.username} calificó con un {notification.qualification} tu experiencia particular.
                        </>
                    )}

                    {(notification.type === 'mensaje-nuevo') && (
                        <>
                            <Trans
                                t={t}
                                i18nKey={'notifications.newMessage'}
                                components={{ bold: <strong /> }}
                                values={{username: notification.username, profile:  notification.profileUser.toLowerCase()}}
                            />
                        </>
                    )}

                    {(notification.type === 'conferencia-link-enviado') && (
                        <>
                            En unos minutos inicia la experiencia acordada, ya está habilitado el botón para conectar.
                        </>
                    )}

                    {(notification.type === 'finalizo-experiencia-particular') && (
                        <>
                            Finalizó la experiencia particular acordada, ya puedes calificar o denunciar dentro de las próximas 24hs al edowier <b>{notification.referenceUsername}</b> en Mi Agenda
                        </>
                    )}

                    {(notification.type === 'incribio-experiencia-grupal') && (
                        <>
                            <Trans
                                t={t}
                                i18nKey={'notifications.userRegisteredYourGrupalExperience'}
                                components={{ bold: <strong /> }}
                                values={{ username: notification.username, groupExperience: notification.title }}
                            />
                        </>
                    )}

                    {(notification.type === 'inscribiste-experiencia-grupal') && (
                        <>
                            <Trans
                                t={t}
                                i18nKey={'notifications.registeredGrupalExperience'}
                                components={{ bold: <strong /> }}
                                values={{ groupExperience: notification.title }}
                            />
                        </>
                    )}

                    {(notification.type === 'grupal-conferencia-link-enviado') && (
                        <>
                            En unos minutos inicia una clase de la experiencia grupal <b>{notification.title}</b>, ya está habilitado el botón para conectar.
                        </>
                    )}
                    
                    {(notification.type === 'finalizo-clase-experiencia-grupal') && (
                        <>
                            Finalizó la clase acordada en la experiencia grupal <b>{notification.title}</b>, ya podes calificar o denunciar dentro de las próximas 24hs al edowier en Mi Agenda
                        </>
                    )}

                    {(notification.type === 'califico-experiencia-grupal') && (
                        <>
                            {notification.username} calificó con un {notification.qualification} tu experiencia grupal <b>{notification.title}</b>.
                        </>
                    )}

                    {(notification.type === 'edowier-activado') && (
                        <>
                            <Trans
                                t={t}
                                i18nKey={'notifications.alreadyEdowier'}
                            />
                        </>
                    )}

                    {(notification.type === 'donaste') && (
                            <>
                                <Trans
                                    t={t}
                                    i18nKey={'notifications.donated'}
                                    components={{ bold: <strong /> }}
                                    values={{ username: notification.referenceUsername, amount: notification.title }}
                                />
                            </>
                        )}

                       {(notification.type === 'donacion') && (
                            <>
                                <Trans
                                    t={t}
                                    i18nKey={'notifications.donation'}
                                    components={{ bold: <strong /> }}
                                    values={{ username: notification.username, amount: notification.title, profile:  notification.profileUser.toLowerCase() }}
                                />
                            </>
                        )} 

                </h6>
            </a>
        </div>
        </>
    );
}