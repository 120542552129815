import React, { useState } from 'react';
import './style.scss';
import { ServiceType } from '../../../../Utils/types';

const ContentItem = ({name, duration }) => {
    return (
        <div className="card-body">
            <span className="item-label">{name}</span>
            <span className="item-content">{duration}</span>
        </div>
    )
}

export const ContentsSection = ({ contentType, contents, contentDuration, expanded }) => {
    const [showSection, setShowSection] = useState(expanded ?? true);

    return (
        <div className="contents-section">
            <div className="card-details">
                <div className="card-header">
                    <h5>
                        <button className="collapse-expand-button" onClick={() => setShowSection(!showSection)}>
                            {contentType === ServiceType.Course ? (
                                <span>Contenidos del curso</span>
                            ) : (
                                <span>Contenidos del recurso</span>
                            )}
                        </button>
                    </h5>
                </div>
                {showSection && (
                    <>
                        {contents.map((content) => (
                            <ContentItem key={content.fileId} name={content.name} duration={content.duration}>
                            </ContentItem>
                        ))}

                        {contentDuration !== "" && contentDuration !== "00:00:00" && (
                            <ContentItem name={`Duración total del ${contentType === ServiceType.Course ? 'curso' : 'recurso'}`} duration={contentDuration}>
                            </ContentItem>
                        )}
                        {contents.length === 0 && (
                            <ContentItem name={"No hay contenidos para mostrar"} duration={""}>
                            </ContentItem>
                        )}
                    </>
                )}
            </div>
        </div>
    )
}
