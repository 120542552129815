import { useTranslation } from 'react-i18next';
import './style.scss';
import * as Icon from 'react-bootstrap-icons';

export const EdowieSection = () => {
    const { t } = useTranslation(['landing_page']);
    const edowieSection = t('edowieSection');
    const eShop = t('edowieSection.eShop');
    const integratedCharges = t('edowieSection.integratedCharges');
    const createYourCommunity = t('edowieSection.createYourCommunity');
    const share = t('edowieSection.share');

    return (
        <section className="edowie-section" id="edowier">
                <div className="edowie-section-container">
                    <div className="row edowie-section-container-body">
                        <div className="col-lg-4 section-left">
                            <h3 className="title">{edowieSection.title}</h3>
                            <h5 className="description">{edowieSection.description}</h5>
                        </div>
                        <div className="col-lg-8">
                            <div className="row features row-cols-1 row-cols-md-2">
                                <div className="feature">
                                    <div className="feature-icon">
                                        <Icon.ShopWindow />
                                    </div>
                                    <h3 className="title">{eShop.title}</h3>
                                    <p className="description">{eShop.description}</p>
                                </div>
                                <div className="feature">
                                    <div className="feature-icon">
                                        <Icon.Wallet2></Icon.Wallet2>
                                    </div>
                                    <h3 className="title">{integratedCharges.title}</h3>
                                    <p className="description">{integratedCharges.description}</p>
                                </div>
                                <div className="feature">
                                    <div className="feature-icon">
                                        <Icon.PeopleFill></Icon.PeopleFill>
                                    </div>
                                    <h3 className="title">{createYourCommunity.title}</h3>
                                    <p className="description">{createYourCommunity.description}</p>
                                </div>
                                <div className="feature">
                                    <div className="feature-icon">
                                        <Icon.ShareFill></Icon.ShareFill>
                                    </div>
                                    <h3 className="title">{share.title}</h3>
                                    <p className="description">{share.description}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br />
                    <div className="edowie-section-container-buttons">
                        <a className="button what-can-offer-button" href="#compartir">{edowieSection.whatCanOffer}</a>
                        <a className="button see-eshop-example" href="/JCruz.ro/" >{edowieSection.seeEShopExample}</a>
                    </div>
                </div>
            </section>
    )
}