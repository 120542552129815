import { useTranslation } from "react-i18next";
import { translateCountry } from "../../../../../Utils/utils";

export const UserItem = ({user}) => {
    const { i18n } = useTranslation();
    
    return (
        <div className="row user-like-modal-user-like-list-section-item">
            <div className="col-lg-2 user-like-modal-user-like-list-section-item-user-image">
                <figure className="avatar user-like-modal-user-like-list-section-item-user-image-user-avatar">
                    <img src={user.profilePhoto.path} alt={user.username} className='user-like-modal-user-like-list-section-item-user-image-user-avatar-user-profile' />
                </figure>
            </div>
            <div className="col-lg-10">
                <div className="user-like-modal-user-like-list-section-item-details">
                    <h6 className="user-like-modal-user-like-list-section-item-details-username">{user.username}</h6>
                    <div className="user-like-modal-user-like-list-section-item-details-timezone">
                        <img src={user.flag} className="user-like-modal-user-like-list-section-item-details-timezone-flag" alt="flag"/>
                        <div className="user-like-modal-user-like-list-section-item-details-timezone-country">
                            <h4 className="user-like-modal-user-like-list-section-item-details-timezone-country-name">{translateCountry(i18n.language, user.countryCode.toLowerCase())}</h4>
                            <h4 className="user-like-modal-user-like-list-section-item-details-timezone-country-timezone">{user.timeUse}</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}