import React, { useState, useEffect, useMemo } from 'react';
import LoadingSpinner from "../../../Shared/LoadingSpinner/index";
import { useParams } from "react-router-dom";
import { contentService } from '../../../../services/contentService';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { ContentItem } from './ContentItem/index'
import './style.scss';
import { ServiceType } from '../../../../Utils/types';

const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 4,
        slidesToSlide: 4 // optional, default to 1.
    },
    tablet: {
        breakpoint: { max: 1024, min: 768 },
        items: 3,
        slidesToSlide: 3 // optional, default to 1.
    },
    mobile: {
        breakpoint: { max: 767, min: 464 },
        items: 2,
        slidesToSlide: 1 // optional, default to 1.
    },
    mobile2: {
        breakpoint: { max: 463, min: 300 },
        items: 1,
        slidesToSlide: 1 // optional, default to 1.
    }
};

export const Contents = () => {
    const contentServiceClient = useMemo(() => new contentService(), []);
    const [loadingCourses, setLoadingCourses] = useState(true);
    const [loadingResources, setLoadingResources] = useState(true);
    const { id } = useParams();

    const [courses, setCourses] = useState([]);
    const [resources, setResources] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const coursesResponse = await contentServiceClient.getCourses(id);

            if (coursesResponse.success) {
                setCourses(coursesResponse.value);
                setLoadingCourses(false);
            }
        };
        fetchData();

    }, [contentServiceClient, id]);

    useEffect(() => {
        const fetchData = async () => {
            const resourcesResponse = await contentServiceClient.getResources(id);

            if (resourcesResponse.success) {
                setResources(resourcesResponse.value);
                setLoadingResources(false);
            }
        };
        fetchData();

    }, [contentServiceClient, id]);

    return (
        <>
            <div className="contents">
                {courses.length > 0 && (
                    <div className="courses">
                        <div className="published-title">
                            <h2 className=""><b>Cursos</b> publicados</h2>
                        </div>
                        <div className="published-items">
                            {loadingCourses ? <LoadingSpinner /> : (
                                <Carousel
                                    responsive={responsive}
                                    autoPlay={false}
                                    swipeable={true}
                                    draggable={true}
                                    showDots={true}
                                    infinite={false}
                                    partialVisible={false}
                                    dotListClass="custom-dot-list-style"
                                >
                                        {courses.map((course) => (
                                            <ContentItem key={course.id} id={course.id} coverImage={course.coverImage}
                                                        title={course.title} isFree={course.isFree} price={course.price}
                                                        description={course.description} reviews={course.reviews} contentType={ServiceType.Course}
                                                        subcategory={course.subcategory} averageReview={course.averageReview}
                                                        discount={course.discount}>
                                            </ContentItem>
                                    ))}

                                </Carousel>
                            )}
                        </div>
                    </div>
                )}
                {resources.length > 0 && (
                    <div className="resources">
                        <div className="published-title">
                            <h2 className=""><b>Recursos</b> publicados</h2>
                            </div>
                            <div className="published-items">
                            {loadingResources ? <LoadingSpinner /> : (
                                <Carousel
                                    responsive={responsive}
                                    autoPlay={false}
                                    swipeable={true}
                                    draggable={true}
                                    showDots={true}
                                    infinite={false}
                                    partialVisible={false}
                                    dotListClass="custom-dot-list-style"
                                >
                                    {resources.map((resource) => (
                                        <ContentItem key={resource.id} id={resource.id} coverImage={resource.coverImage}
                                            title={resource.title} isFree={resource.isFree} price={resource.price}
                                            description={resource.description} reviews={resource.reviews} contentType={ServiceType.Resource}
                                            subcategory={resource.subcategory} averageReview={resource.averageReview}
                                            discount={resource.discount}>
                                        </ContentItem>
                                    ))}

                                </Carousel>
                            )}
                            </div>
                    </div>
                )}
            </div>
        
        </>
    )
}
