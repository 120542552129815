import React, { useState, useEffect, useMemo } from 'react';
import { ResponsiveCarouselOption } from '../../../Utils/types';
import Carousel from "react-multi-carousel";
import { contentService } from '../../../services/contentService';
import LoadingSpinner from "../../Shared/LoadingSpinner/index";
import { ContentItem } from './ContentItem/index';
import { sessionManager } from '../../../sessionManager';
import './style.scss';

export const Contents = ({ contentType }) => {
    const contentServiceClient = useMemo(() => new contentService(), []);
    const manager = new sessionManager();
    const userLoggedIn = JSON.parse(manager.getUserSession());

    const [loading, setLoading] = useState(true);
    const [contents, setContents] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            var contentsResponse = await contentServiceClient.getAdquiredContentsByUserIdAndContentType(userLoggedIn.userId, contentType);

            if (contentsResponse.success) {
                setContents(contentsResponse.value);
                setLoading(false);
            }
        };
        fetchData();

    }, [contentServiceClient, contentType, userLoggedIn.userId]);

    return (
        <>
            {loading ? <LoadingSpinner /> : (
                <div className="library-contents">
                <Carousel
                    responsive={ResponsiveCarouselOption}
                    autoPlay={false}
                    swipeable={true}
                    draggable={true}
                    showDots={true}
                    infinite={false}
                    partialVisible={false}
                    dotListClass="custom-dot-list-style"
                >
                    {contents.map((content) => (
                        <ContentItem key={content.contentId} content={content} userId={userLoggedIn.userId}>
                        </ContentItem>
                    ))}

                    </Carousel>
                </div>
            )}
        </>
    )
}
