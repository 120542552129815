import { fetchWrapper } from "../Utils/fetchWrapper";

const baseUrl = `${process.env.REACT_APP_EDOWIE_API}favorite`;

export const favoriteService = {
    getAllByUserId,
    deleteFavorites
};

function getAllByUserId(userId) {
    return fetchWrapper.get(`${baseUrl}/${userId}/favorites`);
}

function deleteFavorites(userId, id, serviceType) {
    return fetchWrapper.delete(`${baseUrl}/${userId}/favorites`, {
        contentId: id,
        userId: userId,
        serviceType: serviceType,
    });
}