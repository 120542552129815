import { getToken } from "../Utils/utils";

export class profileService {

    async teacherProfile(teacherProfileId) {
        try {

            const edowieApi = process.env.REACT_APP_EDOWIE_API;
            const token = await getToken();
            const requestOptions = {
                headers: { 'Authorization': `bearer ${token}`, },
            };

            const response = await fetch(edowieApi + 'profile/' + teacherProfileId.toString() + '/teacher-profile', requestOptions);
            const data = await response.json();

            return { success: true, value: data }

        } catch (error) {
            return { success: false, error: error };
        }
    }

    async userProfile(userProfileId) {
        try {

            const edowieApi = process.env.REACT_APP_EDOWIE_API;
            const token = await getToken();
            const requestOptions = {
                headers: { 'Authorization': `bearer ${token}`, },
            };

            const response = await fetch(edowieApi + 'profile/' + userProfileId.toString() + '/user-profile', requestOptions);
            const data = await response.json();

            return { success: true, value: data }

        } catch (error) {
            return { success: false, error: error };
        }
    }

    async getMyProfile(userId) {
        try {

            const edowieApi = process.env.REACT_APP_EDOWIE_API;
            const token = await getToken();
            const requestOptions = {
                headers: { 'Authorization': `bearer ${token}`, },
            };

            const response = await fetch(`${edowieApi}profile/${userId}/my-profile`, requestOptions);
            const data = await response.json();

            return { success: true, value: data }

        } catch (error) {
            return { success: false, error: error };
        }
    }

    async updateProfile(values, coverImage, profileImage, userId) {
        try {
            const edowieApi = process.env.REACT_APP_EDOWIE_API;
            const token = await getToken();
            const requestOptions = {
                method: 'PUT',
                headers: { 
                    'Authorization': `bearer ${token}`,
                    'Content-Type': 'application/json' 
                },
                body: JSON.stringify(
                    {
                        "profileImage": profileImage.length > 0 ? profileImage[0].toString() : "",
                        "coverImage": coverImage.length > 0 ? coverImage[0].toString() : "",
                        "aboutMe": values.aboutMe,
                        "instagram": values.instagram,
                        "facebook": values.facebook,
                        "linkedin": values.linkedin,
                        "youTubeChannel": values.youTubeChannel,
                        "webSite": values.webSite,
                        "userId": userId
                    })
            };

            const response = await fetch(`${edowieApi}profile/${userId}/my-profile`, requestOptions);

            if (response.ok) {
                return { success: true }
            } else {
                return { success: false, error: 'Ha ocurrido un error al intentar guardar el profile.' };
            }

        } catch (error) {
            return { success: false, error: error };
        }
    }

    async updateAccount(username, countryCode, country, flagUrl, timezone, languageId, userId) {
        try {
            const edowieApi = process.env.REACT_APP_EDOWIE_API;
            const token = await getToken();
            const requestOptions = {
                method: 'PUT',
                headers: { 
                    'Authorization': `bearer ${token}`,
                    'Content-Type': 'application/json' 
                },
                body: JSON.stringify(
                    {
                        "username": username,
                        "countryCode": countryCode,
                        "country": country,
                        "flagUrl": flagUrl,
                        "timezone": timezone,
                        "languageId": languageId,
                        "userId": userId
                    })
            };

            const response = await fetch(`${edowieApi}profile/${userId}/my-account`, requestOptions);

            if (response.ok) {
                return { success: true }
            } else {
                return { success: false, error: 'Ha ocurrido un error al intentar guardar la cuenta.' };
            }

        } catch (error) {
            return { success: false, error: error };
        }
    }

    async updateConfiguraton(automaticallyAcceptFollowerRequests, showNameInPublicProfile, followersOnlyMessagingEnable, userId) {
        try {
            const edowieApi = process.env.REACT_APP_EDOWIE_API;
            const token = await getToken();
            const requestOptions = {
                method: 'PUT',
                headers: { 
                    'Authorization': `bearer ${token}`,
                    'Content-Type': 'application/json' 
                },
                body: JSON.stringify(
                    {
                        "automaticallyAcceptFollowerRequests": automaticallyAcceptFollowerRequests,
                        "showNameInPublicProfile": showNameInPublicProfile,
                        "followersOnlyMessagingEnable": followersOnlyMessagingEnable,
                        "userId": userId
                    })
            };

            const response = await fetch(`${edowieApi}profile/${userId}/configuration`, requestOptions);

            if (response.ok) {
                return { success: true }
            } else {
                return { success: false, error: 'Ha ocurrido un error al intentar guardar la configuración.' };
            }

        } catch (error) {
            return { success: false, error: error };
        }
    }

    async beEdowier(userId) {
        try {
            const edowieApi = process.env.REACT_APP_EDOWIE_API;
            const token = await getToken();
            const requestOptions = {
                method: 'PUT',
                headers: { 
                    'Authorization': `bearer ${token}`,
                    'Content-Type': 'application/json' 
                }
            };

            const response = await fetch(`${edowieApi}profile/${userId}/be-edowier`, requestOptions);

            if (response.ok) {
                return { success: true }
            } else {
                return { success: false, error: 'Ha ocurrido un error al intentar guardar el usuario.' };
            }

        } catch (error) {
            return { success: false, error: error };
        }
    }

    async donate(externalPaymentId, amount, userId, edowierId) {
        try {
            const edowieApi = process.env.REACT_APP_EDOWIE_API;
            const token = await getToken();
            const requestOptions = {
                method: 'POST',
                headers: { 
                    'Authorization': `bearer ${token}`,
                    'Content-Type': 'application/json' 
                },
                body: JSON.stringify(
                    {
                        "externalPaymentId": externalPaymentId,
                        "amount": amount,
                    })
            };

            const response = await fetch(`${edowieApi}profile/${userId}/${edowierId}/donate`, requestOptions);

            if (response.ok) {
                return { success: true }
            } else {
                return { success: false, error: 'Ha ocurrido un error al intentar guardar la donación.' };
            }

        } catch (error) {
            return { success: false, error: error };
        }
    }
}
