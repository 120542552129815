import React, { useState, useEffect, useMemo } from 'react';
import './style.scss';
import { groupExperienceService } from '../../../../../services/groupExperienceService';
import { extractParameter } from '../../../../../Utils/utils';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Payment } from './Payment';
import { PurchaseSummary } from './PurchaseSummary';
import queryString from 'query-string';
import { CountdownTimer } from '../../../../Shared/CountdownTimer/index';
import { ProfileType, ServiceType } from '../../../../../Utils/types';
import { InjectAppServices } from '../../../../../services/dependency-injection';
import { Promocode } from '../../../../Shared/Promocode';

export const EnrollSection = InjectAppServices(
    ({  isFree, 
        price, 
        withoutRegistrationLimit,
        numberOfRegistrations,
        registrationLimit,
        registrationAvailableFor,
        numberOfClasses,
        firstClass,
        userId, 
        groupExperienceId, 
        following,
        wasEnrolled, 
        discount,
        userCountry,
        hasPromocodesAvailable,
        handleSuccessPurchase,
        dependencies: { sessionManager, servicePromocodeServiceClient }
    }) => {

    const groupExperienceServiceClient = useMemo(() => new groupExperienceService(), []);

    const { promocode } = useParams();
    const userLoggedIn = sessionManager.getUserLoggedIn()
    const location = useLocation();
    const navigate = useNavigate();

    const paymentIntentId = extractParameter(location, queryString.parse, 'payment_intent') || '';
    const successFreePurchase = extractParameter(location, queryString.parse, 'successFreePurchase') || '';

    const [externalPaymentId, setExternalPaymentId] = useState(paymentIntentId);
    const [canEnroll, setCanEnroll] = useState(userLoggedIn.userId > 0 ? (userLoggedIn.userId !== userId && 
        !wasEnrolled && userLoggedIn.profile !== ProfileType.Administrator &&
        (registrationAvailableFor === 1 || (registrationAvailableFor === 2 && following)) &&
        (withoutRegistrationLimit || (!withoutRegistrationLimit && numberOfRegistrations < registrationLimit))) : false);
    const [readyToPay, setReadyToPay] = useState(false);
    const [state, setState] = useState({price: price, total: price, discount: 0, isFree: isFree, discountAmout: 0});
    const [applyPromocode, setApplyPromocode] = useState(promocode !== undefined);

    const successfulPurchase = paymentIntentId !== '' || successFreePurchase === 'true';

    useEffect(() => {
        const fetchData = async () => {
            var amount = price;

            if (promocode) {
                servicePromocodeServiceClient.validate(groupExperienceId, ServiceType.GrupalExperience, promocode)
                .then(async response => {
    
                    if (response.isValid) {
                        const discountAmout = (parseFloat(price * response.discount))/parseFloat(100);
                        amount = price - discountAmout;
                        setState({price: price, total: amount, discount: response.discount, isFree: isFree, discountAmout: discountAmout});
                    }

                    if (externalPaymentId !== "" || successFreePurchase === 'true') {
                        if (wasEnrolled !== true) {
                            var paymentResponse = await groupExperienceServiceClient.enroll(externalPaymentId, amount, userLoggedIn.userId, groupExperienceId);
                            if (paymentResponse.success) {
                                setExternalPaymentId('');
                                handleSuccessPurchase();
                                servicePromocodeServiceClient.markPromocodeAsUsed(userLoggedIn.userId, groupExperienceId, ServiceType.GrupalExperience, promocode);
                                setCanEnroll(false);
                            }
                        }
                    }
                })
            } else {
                if (discount && (discount.applyAll || discount.countryDiscountCode === userCountry)) {
                    const discountAmout = (parseFloat(price * discount.discount))/parseFloat(100);
                    amount = price - discountAmout;
                    setState({price: price, total: amount, discount: discount.discount, isFree: isFree, discountAmout: discountAmout, applyAll: discount.applyAll});
                } else {
                    setState({price: price, total: amount, discount: 0, isFree: isFree});
                }

                if (externalPaymentId !== "" || successFreePurchase === 'true') {
                    if (wasEnrolled !== true) {
                        var paymentResponse = await groupExperienceServiceClient.enroll(externalPaymentId, amount, userLoggedIn.userId, groupExperienceId);
                        if (paymentResponse.success) {
                            setExternalPaymentId('');
                            handleSuccessPurchase();
                            setCanEnroll(false);
                        }
                    }
                }
            }
        };
        fetchData();

    }, [externalPaymentId, successFreePurchase, price, wasEnrolled, groupExperienceServiceClient, 
        groupExperienceId, userLoggedIn.userId, handleSuccessPurchase, setCanEnroll, discount,
        isFree, userCountry, servicePromocodeServiceClient, promocode]);

    const handleEnrolled = () => {
        setCanEnroll(false);

        if (userLoggedIn.userId) {
            if (!isFree) {
                setReadyToPay(true);
            } else {
                var path = window.location.pathname + '?successFreePurchase=true';
                navigate(path);
            }
        } else {
            navigate('/register');
        }
    }

    const handleValidatePromocode = (value) => {
        var amount = price;

        if (value.isValid) {
            const discountAmout = (parseFloat(price * value.discount))/parseFloat(100);
            amount = price - discountAmout;
            setState({price: price, total: amount, discount: value.discount, isFree: isFree, promocode: value.code, discountAmout: discountAmout});
            setApplyPromocode(true);
        } else {
            setState({price: price, total: amount, discount: 0, isFree: isFree, promocode: ''});
        }
    }

    const handleNoValidatedPromocode = () => {
        var amount = price;
        if (discount && (discount.applyAll || discount.countryDiscountCode === userCountry)) {
            const discountAmout = (parseFloat(price * discount.discount))/parseFloat(100);
            amount = price - discountAmout;
            setState({price: price, total: amount, discount: discount.discount, isFree: isFree, promocode: '', discountAmout: discountAmout});
            setApplyPromocode(true);
        } else {
            setState({price: price, total: amount, discount: 0, isFree: isFree, promocode: ''});
        }
    }

    return (
        <>
            <div className="enroll-section-card">
                <div className="card-details">
                    {hasPromocodesAvailable && !wasEnrolled && (
                        <Promocode serviceId={groupExperienceId} serviceType={ServiceType.GrupalExperience}
                                   handleValidated={(value) => { handleValidatePromocode(value); }}
                                   handleNoValidated={() => { handleNoValidatedPromocode(); }}></Promocode>
                    )}
                    {state.isFree && (
                        <div className='price-container'>
                            <h1 className="price">{'Gratis!'}</h1>
                        </div>
                    )}
                    {!state.isFree && (<div className='price-container'>
                        <h2 className="price-label">Precio Experiencia Grupal:</h2>
                        <span className="price-without-discount">{`USD ${state.price.toFixed(2)}`}</span>
                    </div>)}

                    {!state.isFree && state.discount > 0 && (
                        <div className='price-with-discount-container'>
                            <div>
                                {applyPromocode ? (
                                    <span className="price-with-discount-legend">{`Descuento promocion: ${state.discount}%`}</span>
                                ) : (
                                    <span className="price-with-discount-legend">{`Descuento ${state.applyAll ? 'general' : 'por país'}: ${state.discount}%`}</span>
                                )}
                            </div>
                            <div>
                                <span className="price-without-discount">{`USD ${state.discountAmout.toFixed(2)}`}</span>
                            </div>
                        </div>
                    )}

                    {!state.isFree && (
                        <div className='total-price-container'>
                            <h1 className="total-price-label">{`Total:`}</h1>
                            <h1 className="total-price">{`USD ${state.total.toFixed(2)}`}</h1>
                        </div>
                    )}
                    <h4 className="checks-item"><i className="ti-check"></i>
                        {withoutRegistrationLimit === true ? 'Sin límite' : `${numberOfRegistrations}/${registrationLimit} inscriptos`}
                    </h4>
                    <h4 className="checks-item"><i className="ti-check"></i>
                        {registrationAvailableFor === 1 ? 'Todos' : 'Sólo seguidores'}
                    </h4>
                    <h4 className="checks-item"><i className="ti-check"></i>{`${numberOfClasses} Clases`}</h4>
                    <h4 className="checks-item"><i className="ti-check"></i>Consultas vía chat</h4>

                    <CountdownTimer targetDate={firstClass} offset={userLoggedIn.offset}></CountdownTimer>
                    
                    {canEnroll && (
                        <button className="buy-button" onClick={() => handleEnrolled()}>Inscribir</button>
                    )}
                </div>
            </div>
            {readyToPay && (
                <Payment price={price} returnUrl={`enroll-group-experience/${groupExperienceId}/${applyPromocode ? state.promocode ?? '' : ''}`}></Payment>
            )}
            {successfulPurchase && (
                <PurchaseSummary message={'Felicitaciones!. La inscripción ha sido exitosa. Las inscripciones a experiencias grupales puede verse en la sección de la agenda.'}></PurchaseSummary>
            )}
        </>
    )
})