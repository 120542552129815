import React, { useState, useEffect, useMemo } from 'react';
import './style.scss';
import { NavMenu } from '../../../Menu/NavMenu';
import { FieldGroup, FieldItem, InputFieldItem, TextAreaFieldItem, SelectFieldItem, CheckboxFieldItem, SubmitButton, PriceInputFieldItem } from '../../../Shared/FormHelpers/index';
import { Form, Formik } from 'formik';
import { getFormInitialValues } from '../../../../Utils/utils';
import FileUpload from '../../../Shared/FileUpload/FileUplodad';
import FileList from '../../../Shared/FileList/FileList';
import { faFileImage, faPaperclip } from '@fortawesome/free-solid-svg-icons'
import { categoryService } from '../../../../services/categoryService';
import { subcategoryService } from '../../../../services/subcategoryService';
import { contentService } from '../../../../services/contentService';
import { Message } from '../../../Shared/Message/index';
import useTimeout from '../../../../hooks/useTimeout/index';
import { useParams } from "react-router-dom";
import LoadingSpinner from "../../../Shared/LoadingSpinner/index";
import { useTranslation } from 'react-i18next';
import { InjectAppServices } from '../../../../services/dependency-injection';

const HAS_ERROR = 'HAS_ERROR';
const SAVED = 'SAVED';
export const DELAY_BEFORE_REDIRECT = 3000;

const fieldNames = {
    category: 'category',
    subcategory: 'subcategory',
    title: 'title',
    description: 'description',
    detailedDescription: 'detailedDescription',
    language: 'language',
    level: 'level',
    isFree: 'isFree',
    price: 'price',
    tags: 'tags',
    resourceType: 'resourceType'
};

const defaultOption = { key: '', value: 'Selecciona una opción' };

const languages = [
    { key: '1', value: 'Español' },
]

const levels = [
    { key: 'Introductorio', value: 'Introductorio' },
    { key: 'Intermedio', value: 'Intermedio' },
    { key: 'Avanzado', value: 'Avanzado' },
    { key: 'Experto', value: 'Experto' },
]

const resourceTypes = [
    { key: 'PDF', value: 'PDF' },
    { key: 'AUDIO', value: 'AUDIO' },
    { key: 'AUDIOLIBRO', value: 'AUDIOLIBRO' },
    { key: 'PODCAST', value: 'PODCAST' },
    { key: 'EBOOK', value: 'EBOOK' },
    { key: 'OTROS', value: 'OTROS' },
]

export const Resource = InjectAppServices(
    ({
        dependencies: { sessionManager, configurationServiceClient }
    }) => {
    const categoryServiceClient = useMemo(() => new categoryService(), []);
    const subcategoryServiceClient = useMemo(() => new subcategoryService(), []);
    const contentServiceClient = useMemo(() => new contentService(), []);
    const userLoggedIn = sessionManager.getUserLoggedIn();
    const { id } = useParams();

    const [resource, setResource] = useState(null);
    const [loading, setLoading] = useState(true);

    const [categories, setCategories] = useState([]);
    const [subcategories, setSubcategories] = useState([]);
    const [isFree, setIsFree] = useState(false);

    const [imageFiles, setImageFiles] = useState([]);
    const [imageFileIds, setImageFileIds] = useState([]);
    const [isImageFileLoading, setIsImageFileLoading] = useState(false);

    const [allowedExtensions, setAllowedExtensions] = useState('*');
    const [filesDisabled, setFilesDisabled] = useState(true);
    const [files, setFiles] = useState([]);
    const [fileIds, setFileIds] = useState([]);
    const [filesError, setFilesError] = useState(false);
    const [isFileLoading, setIsFileLoading] = useState(false);

    const [status, setStatus] = useState('');
    const [error, setError] = useState('');
    const [configuration, setConfiguration] = useState({});

    const createTimeout = useTimeout();
    const { t } = useTranslation();

    useEffect(() => {
        const fetchData = async () => {
            if (id !== undefined) {
                var resourceResponse = await contentServiceClient.getResourceByUserIdAndId(userLoggedIn.userId, id);

                if (resourceResponse.success) {
                    setResource({
                        category: resourceResponse.value.categoryId,
                        subcategory: resourceResponse.value.subcategoryId,
                        title: resourceResponse.value.title,
                        description: resourceResponse.value.description,
                        detailedDescription: resourceResponse.value.detailedDescription,
                        language: resourceResponse.value.language,
                        level: resourceResponse.value.level,
                        isFree: resourceResponse.value.isFree,
                        price: resourceResponse.value.price === 0 ? "" : resourceResponse.value.price,
                        tags: resourceResponse.value.tags,
                        coverImage: resourceResponse.value.coverImage,
                        files: resourceResponse.value.files,
                        acquired: resourceResponse.value.acquired,
                        hasDiscount: resourceResponse.value.hasDiscount,
                        hasPromocodesAvailable: resourceResponse.value.hasPromocodesAvailable
                    });

                    setIsFree(resourceResponse.value.isFree);

                    initializesFiles(resourceResponse.value);

                    const result = await subcategoryServiceClient.getAllByCategoryId(resourceResponse.value.categoryId);
                    setSubcategories(result.success ? result.value.map(function (item) {
                        return { key: item.subcategoryId, value: item.name };
                    }) : []);
                }
            }

            configurationServiceClient.getByKey('DiscountService:MinimumServiceAmount')
                .then(response => {
                    setConfiguration(response);
                });

            setLoading(false);
        };
        fetchData();

    }, [categoryServiceClient, contentServiceClient, subcategoryServiceClient, id, userLoggedIn.userId, configurationServiceClient]);

    useEffect(() => {
        const fetchData = async () => {
            var categoriesResponse = await categoryServiceClient.getAll();

            if (categoriesResponse.success) {
                const values = categoriesResponse.value.map(function (item) {
                    return { key: item.categoryId, value: item.name };
                });

                setCategories(values);
            }
        };
        fetchData();

    }, [categoryServiceClient]);

    const handleIsFreeChange = async (e, setFieldValue) => {
        setIsFree(!isFree);
        setFieldValue(fieldNames.price, '');
    };

    const removeImageFile = (fileToRemove) => {
        setImageFiles(imageFiles.filter(file => file.name !== fileToRemove.name));
        setImageFileIds(imageFileIds.filter(file => file !== fileToRemove.id));
    }

    const removeFiles = (fileToRemove) => {
        var filesFilter = files.filter(file => file.name !== fileToRemove.name);
        setFiles(filesFilter);
        setFilesError(filesFilter.length === 0);
        setFileIds(fileIds.filter(file => file !== fileToRemove.id));
    }

    const changeCategory = async (e, setFieldValue) => {
        const category = e.target.value;
        setFieldValue(fieldNames.category, category);
        const result = await subcategoryServiceClient.getAllByCategoryId(category);
        setSubcategories(result.success ? result.value.map(function (item) {
            return { key: item.subcategoryId, value: item.name };
        }) : []);
        setFieldValue(fieldNames.subcategory, '');
    };

    const changeResourceType = async (e, setFieldValue) => {
        const resourceType = e.target.value;
        setFieldValue(fieldNames.resourceType, resourceType);

        switch (resourceType) {
            case 'PDF':
            case 'EBOOK':
                setAllowedExtensions('.pdf');
                setFilesDisabled(false);
                break;
            case 'AUDIO':
            case 'AUDIOLIBRO':
            case 'PODCAST':
                setAllowedExtensions('.mp3');
                setFilesDisabled(false);
                break;
            case 'OTROS':
                setAllowedExtensions('.doc, .docx, .ppt, .pptx, .xls, .xlsx');
                setFilesDisabled(false);
                break;
            default:
                setFilesDisabled(true);
        }
    };

    const saveResourse = async(values) => {
        setFilesError(files.length === 0);
        setStatus('');

        if (files.length > 0) {
            var response;

            if (id === undefined) {
                response = await contentServiceClient.createResource(values, imageFileIds, fileIds, userLoggedIn.userId);
            } else {
                response = await contentServiceClient.updateResource(values, imageFileIds, fileIds, userLoggedIn.userId, id);
            }

            if (response.success) {
                setStatus(SAVED);
                createTimeout(() => {
                    window.location.href = `/manage-services`;
                }, DELAY_BEFORE_REDIRECT);
            } else {
                setStatus(HAS_ERROR);
                setError(response.error);
            }
        }
    }

    const _getFormInitialValues = () => {
        let initialValues = getFormInitialValues(fieldNames);

        if (resource !== null) {
            initialValues = { ...resource };
        }

        return initialValues;
    };

    const initializesFiles = (resource) => {
        /* Cover Image */
        if (resource.coverImage.fileId > 0) {
            setImageFiles([{
                name: resource.coverImage.name,
                isUploading: false,
                id: resource.coverImage.fileId,
                canDelete: !resource.acquired
            }]);

            setImageFileIds([resource.coverImage.fileId]);
        }

        /* Files */
        setFiles(resource.files.map(function (file) {
            return {
                name: file.name,
                isUploading: false,
                id: file.fileId,
                canDelete: !resource.acquired
            };
        }));

        setFileIds(resource.files.map(function (file) {
            return file.fileId;
        }));
    }

    const showMessage = [SAVED, HAS_ERROR].includes(status);

    return (
        <>
            <NavMenu showNavigation={true} currentPage={'course'} />
            <div className="middle-wrap">
                <div className="resource-card">
                    <div className="header">
                        <a href="manage-services" className="back">
                            <i className="ti-arrow-left"></i>
                        </a>
                        <h4>{t(`manage_services.contents.resource.header`)}</h4>
                    </div>
                    <div className="legend">
                        <p><strong>{t(`manage_services.contents.resource.title`)}</strong></p>
                        <p>{t(`manage_services.contents.resource.subtitle`)}</p>
                    </div>
                    {loading ? <LoadingSpinner /> : (
                    <div className="form">
                        <div className="row">
                            <div className="col-lg-12">
                                <Formik
                                    onSubmit={saveResourse}
                                    initialValues={_getFormInitialValues()}
                                >
                                {({ setFieldValue, isSubmitting, isValid, dirty }) => (
                                    <Form>
                                        <fieldset className="form-fields">
                                            <FieldGroup>
                                                <FieldItem className="field-item">
                                                    <FieldGroup>
                                                        <SelectFieldItem
                                                            fieldName={fieldNames.category}
                                                            id={fieldNames.category}
                                                            label={`*${t('manage_services.contents.resource.fields.category.label')}`}
                                                            required
                                                            defaultOption={defaultOption}
                                                            values={categories}
                                                            className="field-item--100"
                                                            onChange={(e) => {
                                                                changeCategory(e, setFieldValue);
                                                            }}
                                                        />
                                                    </FieldGroup>
                                                </FieldItem>
                                                <FieldItem className="field-item">
                                                    <FieldGroup>
                                                        <SelectFieldItem
                                                            fieldName={fieldNames.subcategory}
                                                            id={fieldNames.subcategory}
                                                            label={`*${t('manage_services.contents.resource.fields.subcategory.label')}`}
                                                            defaultOption={defaultOption}
                                                            required
                                                            values={subcategories}
                                                            className="field-item--100"
                                                        />
                                                    </FieldGroup>
                                                </FieldItem>
                                                <FieldItem className="field-item">
                                                    <FieldGroup>
                                                        <InputFieldItem
                                                            type="text"
                                                            fieldName={fieldNames.title}
                                                            id="title"
                                                            label={`*${t('manage_services.contents.resource.fields.title.label')}`}
                                                            required
                                                            placeholder={t('manage_services.contents.resource.fields.title.placeholder')}
                                                            className="field-item--100"
                                                            maxLength="150"
                                                        />
                                                    </FieldGroup>
                                                </FieldItem>
                                                <FieldItem className="field-item">
                                                    <FieldGroup>
                                                        <InputFieldItem
                                                            type="text"
                                                            fieldName={fieldNames.description}
                                                            id="description"
                                                            label={`*${t('manage_services.contents.resource.fields.short_description.label')}`}
                                                            required
                                                            placeholder={t('manage_services.contents.resource.fields.short_description.placeholder')}
                                                            className="field-item--100"
                                                            maxLength="250"
                                                        />
                                                    </FieldGroup>
                                                </FieldItem>
                                                <FieldItem className="field-item">
                                                    <FieldGroup>
                                                        <TextAreaFieldItem
                                                            type="textarea"
                                                            fieldName={fieldNames.detailedDescription}
                                                            id="detailedDescription"
                                                            label={`*${t('manage_services.contents.resource.fields.detailed_description.label')}`}
                                                            required
                                                            placeholder={t('manage_services.contents.resource.fields.detailed_description.placeholder')}
                                                            className="field-item--100"
                                                            maxLength="5000"
                                                        />
                                                    </FieldGroup>
                                                </FieldItem>
                                                <FieldItem className="field-item">
                                                    <FieldGroup>
                                                        <SelectFieldItem
                                                            fieldName={fieldNames.level}
                                                            id={fieldNames.level}
                                                            label={`*${t('manage_services.contents.resource.fields.level.label')}`}
                                                            defaultOption={defaultOption}
                                                            values={levels}
                                                            required
                                                            className="field-item--100"
                                                        />
                                                    </FieldGroup>
                                                </FieldItem>
                                                    <FieldItem className="field-item">
                                                        <FieldGroup>
                                                            <SelectFieldItem
                                                                fieldName={fieldNames.language}
                                                                id={fieldNames.language}
                                                                label={`${t('manage_services.contents.resource.fields.language.label')}`}
                                                                defaultOption={defaultOption}
                                                                values={languages}
                                                                className="field-item--100"
                                                            />
                                                        </FieldGroup>
                                                </FieldItem>
                                                <FieldItem className="field-item">
                                                    <FieldGroup>
                                                        <CheckboxFieldItem
                                                            fieldName={fieldNames.isFree}
                                                            key={fieldNames.isFree}
                                                            label={t('manage_services.contents.resource.fields.is_free.label')}
                                                            id={fieldNames.isFree}
                                                            onChange={(e) => {
                                                                handleIsFreeChange(e, setFieldValue);
                                                            }}
                                                        />
                                                    </FieldGroup>
                                                </FieldItem>
                                                <FieldItem className="field-item">
                                                    <FieldGroup>
                                                        <PriceInputFieldItem
                                                            fieldName={fieldNames.price}
                                                            id={fieldNames.price}
                                                            label={`${isFree ? '' : '*'} ${t('manage_services.contents.resource.fields.price.label', {ns: 'translation'})}`}
                                                            required={!isFree}
                                                            placeholder={t('manage_services.contents.resource.fields.price.placeholder', {ns: 'translation'})}
                                                            className="field-item--100"
                                                            min={parseInt(configuration.value)}
                                                            disabled={isFree || (resource !== null && (resource.hasDiscount || resource.hasPromocodesAvailable))}
                                                        />
                                                    </FieldGroup>
                                                </FieldItem>
                                                <FieldItem className="field-item">
                                                    <FieldGroup>
                                                        <InputFieldItem
                                                            type="text"
                                                            fieldName={fieldNames.tags}
                                                            id={fieldNames.tags}
                                                            label={t('manage_services.contents.resource.fields.tags.label')}
                                                            placeholder={t('manage_services.contents.resource.fields.tags.placeholder')}
                                                            className="field-item--100"
                                                            maxLength="250"
                                                        />
                                                    </FieldGroup>
                                                </FieldItem>
                                                <FieldItem className="field-item">
                                                    <FieldGroup>
                                                        <div className="row">
                                                            <div className="col-lg-6 cover-image">
                                                                <FileUpload files={imageFiles} setFiles={setImageFiles}
                                                                    removeFile={removeImageFile} fileIcon={faFileImage}
                                                                    uploadTitle={t('manage_services.contents.resource.fields.cover_image.label')}
                                                                    allowedExtensions={".png, .jpg, .jpeg"}
                                                                    allowMultipleFile={false}
                                                                    handleUploadedFile={(fileId) => { setImageFileIds([...imageFileIds, fileId]) }}
                                                                    handleIsLoadingFile={(isUploading) => {
                                                                        setIsImageFileLoading(isUploading);
                                                                    }}
                                                                />
                                                                <FileList files={imageFiles} removeFile={removeImageFile} />
                                                            </div>
                                                            <div className="col-lg-6">
                                                                <FieldItem className="field-item">
                                                                    <FieldGroup>
                                                                        <SelectFieldItem
                                                                            fieldName={fieldNames.resourceType}
                                                                            id={fieldNames.resourceType}
                                                                            label={t('manage_services.contents.resource.fields.resource_type.label')}
                                                                            defaultOption={defaultOption}
                                                                            values={resourceTypes}
                                                                            className="field-item--100"
                                                                            onChange={(e) => {
                                                                                changeResourceType(e, setFieldValue);
                                                                            }}
                                                                        />
                                                                    </FieldGroup>
                                                                </FieldItem>
                                                                <FileUpload files={files} setFiles={setFiles}
                                                                    removeFile={removeFiles} fileIcon={faPaperclip}
                                                                    uploadTitle={t('manage_services.contents.resource.fields.files.label')} allowedExtensions={allowedExtensions}
                                                                    allowMultipleFile={true} disabled={filesDisabled }
                                                                    handleUploadedFile={(fileId) => {
                                                                        setFileIds([...fileIds, fileId]);
                                                                        setFilesError(false);
                                                                    }}
                                                                    handleIsLoadingFile={(isUploading) => {
                                                                        setIsFileLoading(isUploading);
                                                                    }}
                                                                />
                                                                <FileList files={files} removeFile={removeFiles} />
                                                                {filesError &&
                                                                    <div className="video-required error-form"><p>{t('manage_services.contents.resource.fields.files.required')}</p></div>
                                                                }
                                                            </div>
                                                        </div>

                                                    </FieldGroup>
                                                </FieldItem>
                                                <FieldItem className="field-item">
                                                    <FieldGroup>
                                                        <div className="save-button">
                                                            <SubmitButton isSubmitting={isSubmitting && !filesError} disabled={isFileLoading || isImageFileLoading}>
                                                            {t('manage_services.contents.resource.save_button')}
                                                            </SubmitButton>
                                                        </div>
                                                    </FieldGroup>
                                                </FieldItem>
                                            </FieldGroup>
                                        </fieldset>
                                        {showMessage && (
                                            <fieldset>
                                            <Message
                                                type={status === SAVED ? 'success' : 'error'}
                                                message={
                                                    status === SAVED
                                                        ? t('manage_services.contents.resource.success_messages')
                                                        : error}
                                                />
                                            </fieldset>
                                                )}
                                            </Form>
                                        )}
                                    </Formik>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    )
})