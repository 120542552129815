import ReactDom from "react-dom";
import './style.scss';
import { faWindowClose } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from "react-i18next";

export const EdowieModal = ({ show, modalClass, close, title, children, handleAccept }) => {
    const { t } = useTranslation(['common']);
    return ReactDom.createPortal(
        <>
            <div
                className={`modal-container ${show ? "show" : ""} `}
                onClick={() => close()}
            >
                <div className={`${modalClass ?? ''} modal`} onClick={(e) => e.stopPropagation()}>
                    <header className="modal_header">
                        <h2 className="modal_header-title">{title}</h2>
                        <button className="close" onClick={() => close()}>
                            <i><FontAwesomeIcon icon={faWindowClose} /></i>
                        </button>
                    </header>
                    <main className="modal_content">{children}</main>
                    <footer className="modal_footer">
                        <button className="close" onClick={() => close()}>
                            {t('cancelButton')}
                        </button>
                        <button className="submit" onClick={() => handleAccept()}>{t('acceptButton')}</button>
                    </footer>
                </div>
            </div>

        </>,
        document.getElementById("modal")
    );
};