import { Home } from "./components/Home/index";
import { Login } from "./components/Login/Login/Login";
import { Register } from "./components/Login/Register/index";
import { ManageServices } from "./components/ManageServices/index";
import { TeacherProfile } from "./components/TeachersProfile/index";
import { Course } from "./components/ManageServices/Contents/Course/index";
import { PublicProfile } from "./components/PublicProfile/index";
import { Resource } from "./components/ManageServices/Contents/Resource/index";
import { MyProfile } from "./components/MyProfile/index";
import { Contents } from "./components/Menu/Contents/index";
import { BuyContent } from "./components/Menu/Contents/BuyContent/index";
import { Library } from "./components/Library/index";
import { ViewContent } from "./components/Library/ViewContent/index";
import { Relations } from "./components/Relations/index";
import { TermsAndConditions } from "./components/TermsAndConditions/index";
import { UserProfile } from "./components/UserProfile";
import { LandingPage } from './components/LandingPage/index';
import { Exit } from "./components/Login/Exit";
import { BeEdowier } from "./components/MyProfile/BeEdowier";
import { ParticularExperiences } from "./components/Menu/ParticularExperiences";
import { GroupExperience } from "./components/ManageServices/GroupExperiences/GroupExperience";
import { GroupExperiences } from "./components/Menu/GroupExperiences";
import { ParticularExperiencePay } from "./components/TeachersProfile/Tabs/ParticularExperiences/Pay";
import { EnrollGroupExperience } from "./components/Menu/GroupExperiences/EnrollGroupExperience";
import { Donations } from "./components/Donations";
import { MyNotifications } from "./components/MyNotifications/index";
import { Messages } from "./components/Messages";
import { Forgot } from "./components/Login/Forgot";
import { Favorite } from "./components/Favorite";
import { Discount } from "./components/ManageServices/Discount";
import { Promocodes } from "./components/ManageServices/Promocodes";
import { Diary } from "./components/Diary";
import { Users } from "./components/Administrator/Users";
import { Dashboard } from "./components/Administrator/Dashboard";

const AppRoutes = [
    {
        path: '/login',
        element: <Login />
    },
    {
        path: '/register',
        element: <Register />
    },
    {
        path: '/exit',
        element: <Exit />
    },
    {
        path: '/home',
        element: <Home />
    },
    {
        path: '/teacher-profile/:id/:selectedTab?',
        element: <TeacherProfile />
    },
    {
        path: '/manage-services/:selectedTab?',
        element: <ManageServices />
    },
    {
        path: '/manage-services/course/:id?',
        element: <Course />
    },
    {
        path: '/manage-services/resource/:id?',
        element: <Resource />
    },
    {
        path: '/manage-services/group-experience/:id?',
        element: <GroupExperience />
    },
    {
        path: ':username',
        element: <PublicProfile />
    },
    {
        path: '/my-profile/:selectedTab?',
        element: <MyProfile />
    },
    {
        path: '/contents',
        element: <Contents />
    },
    {
        path: '/buy-content/:contentId/:contentType/:promocode?',
        element: <BuyContent />
    },
    {
        path: '/library',
        element: <Library />
    },
    {
        path: '/view-content/:contentType/:contentId',
        element: <ViewContent />
    },
    {
        path: '/relations/:userId/:selectedTab?',
        element: <Relations />
    },
    {
        path: '/terms-and-conditions',
        element: <TermsAndConditions />
    },
    {
        path: '/user-profile/:id',
        element: <UserProfile />
    },
    {
        path: '/be-edowier/:userId',
        element: <BeEdowier />
    },
    {
        path: '',
        element: <LandingPage />
    },
    {
        path: '/particular-experiences',
        element: <ParticularExperiences></ParticularExperiences>
    },
    {
        path: '/group-experiences',
        element: <GroupExperiences></GroupExperiences>
    },
    {
        path: '/particular-experience-pay/:id',
        element: <ParticularExperiencePay></ParticularExperiencePay>
    },
    {
        path: '/enroll-group-experience/:id/:promocode?',
        element: <EnrollGroupExperience></EnrollGroupExperience>
    },
    {
        path: '/donations/:userId',
        element: <Donations></Donations>
    },
    {
        path: '/my-notifications',
        element: <MyNotifications></MyNotifications>
    },
    {
        path: '/messages/:userId?',
        element: <Messages></Messages>
    },
    {
        path: '/forgot',
        element: <Forgot></Forgot>
    },
    {
        path: '/favorite',
        element: <Favorite></Favorite>
    },
    {
        path: '/discount/:serviceType/:serviceId',
        element: <Discount></Discount>
    },
    {
        path: '/promocodes/:serviceType/:serviceId',
        element: <Promocodes></Promocodes>
    },
    {
        path: '/diary',
        element: <Diary></Diary>
    },
    {
        path: '/users/:userProfile',
        element: <Users></Users>
    },
    {
        path: '/dashboard',
        element: <Dashboard></Dashboard>
    }
];

export default AppRoutes;
