import React from 'react';
import './style.scss';
import { ReviewStars } from '../ReviewStars/index';

export const ReviewList = ({reviews, averageQualifications}) => {
    return (
        <div className='review-list'>
            <hr></hr>
            {reviews.map(function(review, i){
                return (<div className="review-list-section" key={i}>
                    <div className="row review-list-section-item">
                        <div className="col-xs-6 col-lg-1 user">
                            <figure className="avatar user-avatar">
                                <img src={review.profilePhoto.path} alt={review.username} className='review-user-profile' />
                            </figure>
                        </div>
                        <div className="col-xs-6 col-lg-11">
                            <div className="review">
                                <h6 className="username">{review.username}</h6>
                                <h6 className="date">{review.date}</h6>
                                <ReviewStars averageQualifications={review.qualification}></ReviewStars>
                                <p className="description">{review.description}</p>
                            </div>
                        </div>
                    </div>
                    <hr></hr>
                </div>)
                
            })}
        </div>
    )
}