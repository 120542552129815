import React from 'react';
import './style.scss';
import PaymentForm from "../../../../Shared/Stripe/paymentForm";

export const Payment = ({ price, returnUrl }) => {
    return (
        <div className="payment-method-card">
            <div className="payment-method-card-details">
                <PaymentForm amount={price} returnUrl={returnUrl} ></PaymentForm>
            </div>
        </div>
    )
}