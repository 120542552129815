import moment from "moment";
import { InjectAppServices } from "../../../../../../../services/dependency-injection";
import { useTranslation } from "react-i18next";
import { translateTransactionState } from "../../../../../../../Utils/utils";

const enProperties = {
    currencySign: "accounting"
  };

export const Item = InjectAppServices(
    ({
        item
    }) => {
        const { t, i18n } = useTranslation(['balance']);

        const getStateClass = (stateId) => {
            if (stateId === 1) {
                return "pending"
            }

            if (stateId === 2) {
                return "accredited"
            }

            if (stateId === 3) {
                return "denounced"
            }

            if (stateId === 4) {
                return "cancelled"
            }
        }

        return (
            <div className="row"> 
                <div className='col-lg-2'>
                    <div className='balance-transactions-details-section-date-section'>
                        <div className='balance-transactions-details-section-date-section-title'>{moment(item.date).format('LLL')}</div>
                    </div>
                </div>
                <div className='col-lg-5'>
                    <div className="balance-transactions-details-section">
                        <div className="balance-transactions-details-section-container">
                            <div className="balance-transactions-details-section-container-title">
                                {item.transaction} - {item.service}
                            </div>
                            <>
                            {item.transaction === 'Retiro' ? (
                                <div className="balance-transactions-details-section-container-information-section">
                                <div className="balance-transactions-details-section-container-information-section-item">
                                    <div>{t('userColumn',{ns: 'balance'})}:</div>
                                    <a href={`user-profile/${item.buyerId}`}>
                                        <div className="balance-transactions-details-section-container-information-section-item-value">{item.buyer}</div>
                                    </a>
                                </div>
                            </div>
                            ) : (
                            <div className="balance-transactions-details-section-container-information-section">
                                <div className="balance-transactions-details-section-container-information-section-item">
                                    <div>{t('buyerColumn',{ns: 'balance'})}:</div>
                                    <a href={`user-profile/${item.buyerId}`}>
                                        <div className="balance-transactions-details-section-container-information-section-item-value">{item.buyer}</div>
                                    </a>
                                </div>
                                <div className="balance-transactions-details-section-container-information-section-item">
                                    <div>{t('edowierColumn',{ns: 'balance'})}:</div>
                                    <a href={`teacher-profile/${item.edowierId}`}>
                                        <div className="balance-transactions-details-section-container-information-section-item-value">{item.edowier}</div>
                                    </a>
                                </div>
                            </div>
                            )}
                            </>
                        </div>
                    </div>
                </div>
                <div className='col-lg-2'>
                    <div className='balance-transactions-details-section-date-section'>
                        <div className={`balance-transactions-details-section-date-section-title ${getStateClass(item.stateId)}`}>{translateTransactionState(i18n.language, item.stateId)}</div>
                    </div>
                </div>
                <div className='col-lg-3'>
                    <div className='balance-transactions-details-section-date-section'>
                        <div className={`balance-transactions-details-section-date-section-title ${item.transaction === 'Retiro' || item.transaction === 'Ajuste' ? 'negative' : ''}`}>
                            {item.transaction === 'Retiro' ? 
                             `(USD ${item.amount.toLocaleString("en-US", enProperties)})` : 
                             item.transaction === 'Ajuste' ? 
                             `(USD ${(-1 * item.amount).toLocaleString("en-US", enProperties)})` : 
                             `USD ${item.amount.toLocaleString("en-US", enProperties)}`}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
)