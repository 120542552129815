import { Notifications } from '../../Shared/Notifications';
import { useTranslation } from 'react-i18next';
import { userService } from '../../../services/userService';
import { NotificationService } from '../../../services/notificationService';
import NoUserLoggedIn from '../../../general/images/no-user.jpg'
import React, { useState, useEffect, useMemo } from 'react';
import { sessionManager } from '../../../sessionManager';
import { LanguageSelector } from '../../Shared/LanguageSelector';
import './style.scss';
import { ProfileType } from '../../../Utils/types';

export const NavButtons = ({ currentPage, showLanguageSelector, isMobile }) => {
    const userLinks = [
        { href: '/home', icon: 'feather-home', text: 'home', page: 'muro', needUserLoggedIn: true },
        { href: '/contents', icon: 'feather-tv', text: 'content', page: 'contents', needUserLoggedIn: false },
        { href: '/particular-experiences', icon: 'feather-user', text: 'particular', page: 'particular', needUserLoggedIn: false },
        { href: '/group-experiences', icon: 'feather-users', text: 'group', page: 'grupal', needUserLoggedIn: false },
        { href: '/library', icon: 'feather-book-open', text: 'library', page: 'library', needUserLoggedIn: true}];

    const administratorLinks = [
        //{ href: '/users', icon: 'feather-users', text: 'users', page: 'users', needUserLoggedIn: true }
    ];

    const notificationServiceClient = useMemo(() => new NotificationService(), []);
    const manager = new sessionManager();

    const isUserLoggedIn = manager.isUserLoggedIn();
    const userLoggedIn = JSON.parse(manager.getUserSession());
    const profile = isUserLoggedIn ? userLoggedIn.profile : '';

    const [hasUnreadNotifications, setHasUnreadNotifications] = useState(false);
    const [state, setState] = useState({ profilePhoto: ''});

    const userId = isUserLoggedIn ? userLoggedIn.userId : 0;
    const links = isUserLoggedIn ? 
                  profile !== ProfileType.Administrator ? 
                  userLinks :
                  administratorLinks :
                  userLinks.filter(link => link.needUserLoggedIn === false);

    const { t } = useTranslation(['menu']);

    useEffect(() => {
        const fetchData = async () => {
            if (isUserLoggedIn) {
                userService.card(userId)
                .then(response => {
                    setState(response);
                });
            }
        };
        fetchData();

    }, [isUserLoggedIn, userId]);

    useEffect(() => {
        const fetchData = async () => {
            if (isUserLoggedIn) {
                const hasUnreadResponse = await notificationServiceClient.hasUnreadByUserId(userId);

                if (hasUnreadResponse.success) {
                    setHasUnreadNotifications(hasUnreadResponse.value);
                }
            }
        };
        fetchData();

    }, [isUserLoggedIn, userId, notificationServiceClient]);

    const handleNotificationsHover = async(e) => {
        const notificationsResponse = await notificationServiceClient.markAsRead(userId);

        if (notificationsResponse.success) {
            setHasUnreadNotifications(false);
        }
    }

    return (

        <ul className="navbar-nav flex-grow">
            {links.map((link) => (
                <li className={`${currentPage === link.page ? 'active item' : 'item'}`} key={link.page}>
                    <a href={`${link.href}`}>
                        <i className={`${link.icon} font-xl`}></i>
                        <span>{t(`${link.text}`)}</span>
                    </a>
                </li>
            ))}
            {isUserLoggedIn && (
            <li className={hasUnreadNotifications ? "" : "item"}>
                <div className="notifications">
                    {hasUnreadNotifications && (
                    <span className="dot-count"></span>
                    )}
                    <i className="feather-bell font-xl text-current"></i>
                    <div className='notifications-details' onMouseLeave={handleNotificationsHover}>
                        <Notifications recordsToShow={5}></Notifications>
                    </div>
                    <span>{t('notifications.title')}</span>
                </div>
            </li>
            )}
            {showLanguageSelector && !isMobile && (
                <li className={`language-selector-item`}>
                    <LanguageSelector></LanguageSelector>
                </li>
            )}
            <li className="item">
                <div className="menu-usuario cursor-pointer">
                    <img src={isUserLoggedIn ? state.profilePhoto : NoUserLoggedIn} alt="user" className="user-photo-small" />
                    <span className="dropdown-arrow"></span>
                    {isUserLoggedIn ? (
                        <div className="menu-usuario-content switchcolor-wrap">
                            {profile !== ProfileType.Administrator && (
                                <>
                                    <div className="row">
                                        {userLoggedIn.profile === 'Edowier' ? (
                                        <a href="/manage-services" className="nav-content-bttn open-font link" data-tab="chats">
                                            <i className="feather-shopping-bag mr-1 text-current"></i>
                                            <span>{t('user.sellProductsServices')}</span>
                                        </a>
                                        ) : (
                                            <a href={`/be-edowier/${userId}`} className="be-edowie">{t('user.beAnEdowier')}</a>
                                        )}
                                    </div>
                                    <hr />
                                </>)}
                            {profile !== ProfileType.Administrator && (
                            <div className="row">
                                <a href={`/favorite`} className="nav-content-bttn open-font link" data-tab="chats">
                                    <i className="feather-star mr-1 text-current"></i>
                                    <span>{t('user.favorites')}</span>
                                </a>
                            </div>)}
                            <div className="row">
                                <a href="/my-profile" className="nav-content-bttn open-font link" data-tab="chats">
                                    <i className="feather-user mr-1 text-current"></i>
                                    <span>{t('user.myProfile')}</span>
                                </a>
                            </div>
                            {profile !== ProfileType.Administrator && (
                            <div className="row">
                                <a href="/my-profile/5" className="nav-content-bttn open-font link" data-tab="chats">
                                    <i className="feather-user mr-1 text-current"></i>
                                    <span>{t('user.myPurchases')}</span>
                                </a>
                            </div>)}
                            {(state.profile === ProfileType.Edowier || 
                              (state.profile === ProfileType.User && state.balance > 0)) && (
                            <div className="row">
                                <a href={`/my-profile/2`} className="nav-content-bttn open-font link" data-tab="chats">
                                    <i className="feather-dollar-sign mr-1 text-current"></i>
                                    <span>{t('user.balance')}</span>
                                </a>
                            </div>
                            )}
                            <div className="row">
                                <a href="/my-profile/4" className="nav-content-bttn open-font link" data-tab="chats">
                                    <i className="feather-settings mr-1 text-current"></i>
                                    <span>{t('user.settings')}</span>
                                </a>
                            </div>
                            <div className="row">
                            <a href="/exit" className="nav-content-bttn open-font h-auto pt-2 pb-2 link">
                                    <i className="feather-log-out mr-1 text-current"></i>
                                    <span>{t('user.exit')}</span>
                                </a>
                            </div>
                        </div>
                    ) : (
                        <div className="menu-usuario-content switchcolor-wrap">
                            <div className="row">
                                <a href="/login" className="nav-content-bttn open-font h-auto pt-2 pb-2 link">
                                    <i className="feather-log-in mr-1 text-current"></i>
                                    <span>{t('user.login')}</span>
                                </a>
                            </div>
                        </div>
                    )}
                </div >
            </li >
        </ul>
    );
}