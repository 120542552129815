import React, { useState } from 'react';
import './style.scss';
import { FieldGroup, FieldItem, SelectFieldItem, TextAreaFieldItem, SubmitButton } from '../../../../Shared/FormHelpers/index';
import { DefaultOption, DELAY_BEFORE_REDIRECT, Status } from '../../../../../Utils/types';
import { Form, Formik } from 'formik';
import { getFormInitialValues } from '../../../../../Utils/utils';
import useTimeout from '../../../../../hooks/useTimeout/index';
import { Message } from '../../../../Shared/Message/index';
import { QualificationService } from '../../../../../services/qualificationService';

const fieldNames = {
    qualification: 'qualification',
    description: 'description',
};

const qualifications = [
    { key: 1, value: '1' },
    { key: 2, value: '2' },
    { key: 3, value: '3' },
    { key: 4, value: '4' },
    { key: 5, value: '5' },
]

export const CreateUpdateReview = ({qualification, handleSaveQualifications}) => {
    const qualificationServiceClient = new QualificationService();
    const createTimeout = useTimeout();

    const [status, setStatus] = useState('');
    const [error, setError] = useState('');
    const [showForm, setShowForm] = useState(qualification.qualificationId === 0);

    const _getFormInitialValues = () => {
        let initialValues = getFormInitialValues(fieldNames);

        initialValues = {...qualification};

        return initialValues;
    };

    const saveQualification = async(values) => {
        var saveQualificationResponse = null;

        if (qualification.qualificationId === 0) {
            saveQualificationResponse = await qualificationServiceClient.addQualification(qualification.userId, qualification.serviceId, qualification.serviceType, values);
        } else {
            saveQualificationResponse = await qualificationServiceClient.updateQualification(qualification.qualificationId, qualification.serviceType, values);
        }

        if (saveQualificationResponse.success) {
            setStatus(Status.Saved);
            createTimeout(async() => {
                setStatus('');
                setShowForm(false);
                handleSaveQualifications();
            }, DELAY_BEFORE_REDIRECT);
        }
        else {
            setStatus(Status.HasError);
            setError(saveQualificationResponse.error);
        }
    }

    const showMessage = [Status.Saved, Status.HasError].includes(status);

    return (
        <>
            {showForm ? (
            <div className="form">
                <div className="row">
                    <div className="col-lg-12">
                        <Formik
                            onSubmit={saveQualification}
                            initialValues={_getFormInitialValues()}
                        >
                            {({ isSubmitting, isValid, dirty }) => (
                                <Form>
                                    <fieldset className="form-fields">
                                        <FieldGroup>
                                            <FieldItem className="field-item">
                                                <FieldGroup>
                                                    <TextAreaFieldItem
                                                        type="textarea"
                                                        fieldName={fieldNames.description}
                                                        id={fieldNames.description}
                                                        label={`*Descripción`}
                                                        required
                                                        placeholder={'Descripción'}
                                                        className="field-item--100"
                                                    />
                                                </FieldGroup>
                                            </FieldItem>
                                            <FieldItem className="field-item">
                                                <FieldGroup>
                                                    <SelectFieldItem
                                                        fieldName={fieldNames.qualification}
                                                        id={fieldNames.qualification}
                                                        label={`*Calificación`}
                                                        defaultOption={DefaultOption}
                                                        values={qualifications}
                                                        required
                                                        className="field-item--100"
                                                    />
                                                </FieldGroup>
                                            </FieldItem>
                                            <FieldItem className="field-item">
                                                <FieldGroup>
                                                    <div className='buttons'>
                                                        <button className="close" onClick={() => setShowForm(false)}>
                                                            Cancelar
                                                        </button>
                                                        <SubmitButton isSubmitting={isSubmitting}>
                                                            {'Guardar'}
                                                        </SubmitButton>
                                                    </div>
                                                </FieldGroup>
                                            </FieldItem>
                                        </FieldGroup>
                                    </fieldset>
                                    {showMessage && (
                                        <fieldset>
                                            <Message
                                                type={status === Status.Saved ? 'success' : 'error'}
                                                message={
                                                    status === Status.Saved
                                                        ? '¡Operación procesada con éxito!'
                                                        : error}
                                            />
                                        </fieldset>
                                    )}
                                </Form>
                            )}
                        </Formik>
                    </div>

                </div>
            </div>
            ) : (
                <div className='update-review'>
                    <button onClick={() => setShowForm(true)}>
                        Modificar Reseña
                    </button>
                </div>
            )}
        </>
    )
}