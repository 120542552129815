import React from 'react';
import './style.scss';
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const Message = ({ message, type }) => {
    return (
        <div className={`m-b-12 wrap-message wrap-${type}`} role="alert" aria-label={type} >
            {type === 'success' ? (
                <FontAwesomeIcon icon={faCheckCircle} className="font-sm message-icon" />
            ) : (
                <FontAwesomeIcon icon={faTimesCircle} className="font-sm message-icon" />
            ) }
            <div className="content-message">
                <p>{message}</p>
            </div>
        </div>
    )
}
