import './style.scss';
import { EdowieModal } from '../../../Shared/Modal/index';
import React, { useState } from 'react';
import { contentService } from '../../../../services/contentService';
import { ServiceType } from '../../../../Utils/types';
import { useTranslation } from 'react-i18next';
import { InjectAppServices } from '../../../../services/dependency-injection';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare, faPercent, faTag, faTrash } from '@fortawesome/free-solid-svg-icons';

export const ContentItem = InjectAppServices((
    { id, coverImage, subcategory, title, isFree, price, description, reviews, 
      contentType, hasDiscount, handleDeleteContent,
      dependencies: { sessionManager, serviceDiscountServiceClient }
    }) => {
    const { t } = useTranslation(['manage_services']);
    const contentServiceClient = new contentService();
    const userLoggedIn = sessionManager.getUserLoggedIn();

    const [showModal, setShowModal] = useState(false);
    const [deletedContent, setDeletedContent] = useState(true);

    const deleteContent = async (id) => {
        var deleteResponse = null;

        if (contentType === 'course') {
            deleteResponse = await contentServiceClient.deleteCourse(userLoggedIn.userId, id);
            if (deleteResponse.success) {
                handleDeleteContent();
                setShowModal(false);
            }
        } else {
            deleteResponse = await contentServiceClient.deleteResource(userLoggedIn.userId, id);
            if (deleteResponse.success) {
                handleDeleteContent();
                setShowModal(false);
            }
        }
    }

    const deleteDiscount = (serviceId, serviceType) => {
        serviceDiscountServiceClient.delete(serviceId, serviceType, userLoggedIn.userId)
        .then(() => {
            handleDeleteContent();
        });
    }

    return (
        <>
            <div className="content-item">
                <div className="cover-image">
                    <a href={`buy-content/${id}/${contentType === "course" ? ServiceType.Course : ServiceType.Resource}`}>
                        <img src={coverImage} alt={title} />
                    </a>
                </div>
                <div className="details">
                    <span className={`tag ${contentType}-tag`}>{subcategory}</span>
                    {!isFree ? (
                        <span className="price">
                            <span>$</span>{price}
                        </span>) : (
                        <span className="price">
                            <span>{t('freeMessage')}</span>
                        </span>
                    )}
                    <h4 className="content-title">
                        <a href={`buy-content/${id}/${contentType === "course" ? ServiceType.Course : ServiceType.Resource}`}>{title}</a>
                    </h4>
                    <h6 className="content-description">{description}</h6>
                    <div className="content-buttons">
                        {!isFree && (
                            <a href={`/promocodes/${contentType === "course" ? "1" : "2"}/${id}`} className="edit-button" title={t('generateCodesTooltip', {ns: 'manage_services'})}>
                                <i>
                                    <FontAwesomeIcon icon={faTag} />
                                </i>
                            </a>
                        )}
                        {!isFree ? hasDiscount ? (
                            <button onClick={() => { setDeletedContent(false); setShowModal(true) }}
                                    title={t('deleteDiscountTooltip', {ns: 'manage_services'})} className='delete-discount-button'>
                                <i><FontAwesomeIcon icon={faPercent} /></i>
                            </button>
                        ) : (
                            <a href={`/discount/${contentType === "course" ? "1" : "2"}/${id}`} className="edit-button" title={t('addDiscountTooltip', {ns: 'manage_services'})}>
                                <i><FontAwesomeIcon icon={faPercent} /></i>
                            </a>
                        ) : null}
                        
                        <a href={`/manage-services/${contentType === "course" ? "course" : "resource"}/${id}`} className="edit-button" title={t('editTooltip')}>
                            <i><FontAwesomeIcon icon={faPenToSquare} /></i>
                        </a>
                        <button onClick={() => { setDeletedContent(true); setShowModal(true) }} id="eliminar" className="delete-button" title={t('removeTooltip')}>
                            <i><FontAwesomeIcon icon={faTrash} /></i>
                        </button>
                    </div>
                    <EdowieModal show={showModal} title={`${deletedContent ? t('contents.deletePostModalTitle', {ns: 'manage_services'}) : t('contents.deleteDiscountModalTitle', {ns: 'manage_services'})}`}
                        modalClass={'delete-content-discount-code-modal'}
                        close={() => { setShowModal(false) }} 
                        handleAccept={() => { 
                            if (deletedContent) {
                                deleteContent(id);
                            } else {
                                deleteDiscount(id, contentType === "course" ? ServiceType.Course : ServiceType.Resource);
                            }
                        }}>
                            <>
                            {deletedContent ? (
                                <div className="activity-item-content">{`${t(`contents.delete${contentType}ModalLegend`, {ns: 'manage_services'})}`}</div>
                            ) : (
                                <div className="activity-item-content">{`${t(`contents.deleteDiscountModalLegend`, {ns: 'manage_services'})}`}</div>
                            )}
                            </>
                        
                    </EdowieModal>
                </div>
            </div>
        </>
    );
},
)
