import { getToken } from "../Utils/utils";

export class relationService {

    async isFollowing(userId, userToCheck) {
        try {

            const edowieApi = process.env.REACT_APP_EDOWIE_API;
            const token = await getToken();
            const requestOptions = {
                headers: { 'Authorization': `bearer ${token}`, },
            };

            const response = await fetch(`${edowieApi}Relation/${userId}/isfollowing/${userToCheck}`, requestOptions);

            const data = await response.json();

            return { success: true, value: data }

        } catch (error) {
            return { success: false, error: error };
        }
    }

    async isPending(userId, userToCheck) {
        try {

            const edowieApi = process.env.REACT_APP_EDOWIE_API;
            const token = await getToken();
            const requestOptions = {
                headers: { 'Authorization': `bearer ${token}`, },
            };

            const response = await fetch(`${edowieApi}Relation/${userId}/ispending/${userToCheck}`, requestOptions);

            const data = await response.json();

            return { success: true, value: data }

        } catch (error) {
            return { success: false, error: error };
        }
    }

    async follow(userId, userToFollow) {
        try {

            const edowieApi = process.env.REACT_APP_EDOWIE_API;
            const token = await getToken();
            const requestOptions = {
                method: 'POST',
                headers: { 
                    'Authorization': `bearer ${token}`,
                    'Content-Type': 'application/json' 
                },
                body: {}
            };

           await fetch(`${edowieApi}Relation/${userId}/follow/${userToFollow}`, requestOptions);

            return { success: true }

        } catch (error) {
            return { success: false, error: error };
        }
    }

    async unfollow(userId, userToFollow) {
        try {

            const edowieApi = process.env.REACT_APP_EDOWIE_API;
            const token = await getToken();
            const requestOptions = {
                method: 'POST',
                headers: { 
                    'Authorization': `bearer ${token}`,
                    'Content-Type': 'application/json' 
                },
                body: {}
            };

            await fetch(`${edowieApi}Relation/${userId}/unfollow/${userToFollow}`, requestOptions);

            return { success: true }

        } catch (error) {
            return { success: false, error: error };
        }
    }

    async getRelationsByUserIdAndRelationTypeAsync(userId, relationType) {
        try {

            const edowieApi = process.env.REACT_APP_EDOWIE_API;
            const token = await getToken();
            const requestOptions = {
                headers: { 'Authorization': `bearer ${token}`, },
            };

            const response = await fetch(`${edowieApi}relation/${userId}/${relationType}/relations`, requestOptions);

            const data = await response.json();

            return { success: true, value: data }

        } catch (error) {
            return { success: false, error: error };
        }
    }

    async approveRequest(userId, userToApprove) {
        try {

            const edowieApi = process.env.REACT_APP_EDOWIE_API;
            const token = await getToken();
            const requestOptions = {
                method: 'POST',
                headers: { 
                    'Authorization': `bearer ${token}`,
                    'Content-Type': 'application/json' 
                },
                body: {}
            };

           await fetch(`${edowieApi}Relation/${userId}/approve/${userToApprove}`, requestOptions);

            return { success: true }

        } catch (error) {
            return { success: false, error: error };
        }
    }

    async rejectRequest(userId, userToReject) {
        try {

            const edowieApi = process.env.REACT_APP_EDOWIE_API;
            const token = await getToken();
            const requestOptions = {
                method: 'POST',
                headers: { 
                    'Authorization': `bearer ${token}`,
                    'Content-Type': 'application/json' 
                },
                body: {}
            };

           await fetch(`${edowieApi}Relation/${userId}/reject/${userToReject}`, requestOptions);

            return { success: true }

        } catch (error) {
            return { success: false, error: error };
        }
    }
}
