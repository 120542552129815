import i18n from "i18next";

export function validateRequiredField(
    value: any,
): true | string | null {
    const t = i18n.t;

    if (value === undefined || value === null || value === '') {
        return t('requiredField', {ns: 'validations'});;
    }

    return null;
}

export function validateCheckRequired(
    value: any,
): true | string | null {
    const t = i18n.t;

    if (!value) {
        return t('requiredField', {ns: 'validations'});
    }

    return null;
}

export function validateEmail(value) {
    let error;
    const t = i18n.t;

    if (!value) {
        error = t('requiredField', {ns: 'validations'});
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
        error = t('invalidEmail', {ns: 'validations'});
    }
    return error;
} 

export function validateUsername(value) {
    let error;
    const t = i18n.t;

    if (!value) {
        error = t('requiredField', {ns: 'validations'});
    } else if (!/^[a-z0-9_.-]{1,80}$/i.test(value)) {
        error = t('invalidUser', {ns: 'validations'});
    }
    return error;
} 

export function validateMinimumPrice(value, minValue) {
    let error;
    const t = i18n.t;

    const price = parseFloat(value);

    if (price < minValue) {
        const minimumPrice = parseFloat(minValue).toFixed(2);
        error = t('minimumPriceError', {ns: 'validations', minimumPrice});
    }

    return error;
} 

export function combineValidations(
    ...validateFunctions: [((value: any) => true | string | null) | undefined | false | null]
  ): (value: any) => true | string | null {
    return (value) => {
      for (let validate of validateFunctions) {
        const result = validate && validate(value);
        if (result) {
          return result;
        }
      }
      return null;
    };
  }

export function validateMinimumAmount(value, minValue) {
    let error;
    const t = i18n.t;

    const price = parseFloat(value);

    if (price < minValue) {
        const minimumAmount = parseFloat(minValue).toFixed(2);
        error = t('minimumAmountError', {ns: 'validations', minimumAmount});
    }

    return error;
}

export function validateMaximumAmount(value, maxValue) {
    let error;
    const t = i18n.t;

    const price = parseFloat(value);

    if (price > maxValue) {
        const maximumAmount = parseFloat(maxValue).toFixed(2);
        error = t('maximumAmountError', {ns: 'validations', maximumAmount});
    }

    return error;
}


export function createMinimumAmountValidation(minValue) {
    if (!minValue) {
        return () => null;
    }

    return (value) => validateMinimumAmount(value, minValue);
}

export function createMaximumAmountValidation(maxValue) {
    if (!maxValue) {
        return () => null;
    }

    return (value) => validateMaximumAmount(value, maxValue);
}

export function createEmailValidation() {

    return (value) => validateEmail(value);
}