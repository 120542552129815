import React, { useEffect, useState } from "react";
import { defaults } from "chart.js/auto";
import { Bar } from "react-chartjs-2";

import "./style.scss";

import { InjectAppServices } from "../../../../services/dependency-injection";
import LoadingSpinner from "../../../Shared/LoadingSpinner";

defaults.maintainAspectRatio = false;
defaults.responsive = true;

defaults.plugins.title.display = true;
defaults.plugins.title.align = "start";
defaults.plugins.title.font.size = 20;
defaults.plugins.title.color = "black";

export const SalesHistory = InjectAppServices(
    ({
      dependencies: { sessionManager, administratorServiceClient }
    }) => {

      const userLoggedIn = sessionManager.getUserLoggedIn();
      const [data, setData] = useState([]);
      const [loading, setLoading] = useState(true);

      useEffect(() => {
        const fetchData = async () => {
            administratorServiceClient.getSalesByYear(userLoggedIn.userId, 2024)
                .then(response => {
                    setData(response);
                    setLoading(false);
                })
                .catch(() => {
                    setLoading(false);
                });
        };
        fetchData();

    }, [administratorServiceClient, userLoggedIn.userId]);

        return (
            <>
            {loading ? <LoadingSpinner /> : (
              <>
                <div className="dataCard customerCard">
                <Bar
                  data={{
                    labels: ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"],
                    datasets: data
                  }}
                  options={{
                    plugins: {
                      title: {
                        text: "Historial de ventas del último año",
                      },
                    },
                  }}
                />
                </div>
              </>
            )}
            </>
        )
    }
)