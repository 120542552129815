import React, { useState } from 'react';
import { NavMenu } from '../Menu/NavMenu';
import './style.scss';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { ServiceType } from '../../Utils/types';
import { Contents } from './Contents/index';

export const Library = () => {
    const [activeTab, setActiveTab] = useState("1");

    const selectTab = (tabId) => {
        if (activeTab !== tabId) {
            setActiveTab(tabId);
        }
    }

    return (
        <>
            <NavMenu showNavigation={true} currentPage={'library'} />
            <div className="library">
                <div className="tabs">
                    <Nav tabs>
                        <NavItem>
                            <NavLink className={(activeTab === "1" ? "active nav-link" : "nav-link")} href="#"
                                onClick={() => selectTab("1")}>Todos los cursos</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink className={(activeTab === "2" ? "active nav-link" : "nav-link")} href="#"
                                onClick={() => selectTab("2")}>Todos los recursos</NavLink>
                        </NavItem>
                    </Nav>
                </div>
                <div className="tab-content">
                    <TabContent activeTab={activeTab}>
                        <TabPane tabId="1">
                            {activeTab === "1" && (
                                <Contents contentType={ServiceType.Course}></Contents>
                            )}
                        </TabPane>
                        <TabPane tabId="2">
                            {activeTab === "2" && (
                                <Contents contentType={ServiceType.Resource}></Contents>
                            )}
                        </TabPane>
                    </TabContent>
                </div>
            </div>
        </>
    )
}