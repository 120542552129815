import React, { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import AppRoutes from './AppRoutes';
import { Layout } from './components/Layout';
import './custom.css';
import { LandingPage } from './components/LandingPage';
import { getUserLoggedIn } from './Utils/utils';
import { setLanguage } from './i18n/i18n';
import { InjectAppServices } from './services/dependency-injection';

export const App = InjectAppServices(
  ({
    dependencies: { administratorServiceClient, sessionManager }
  }) => {

  const location = useLocation();

  var user = getUserLoggedIn();

  useEffect(()=>{
    const loadLanguage = async ()=>{
      try{
        const storedLanguage = user ? user.language : undefined;
        if (storedLanguage) {
          setLanguage(storedLanguage);
        }
      }catch(e){
        console.log(e)
      }
    
    }
    loadLanguage()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    useEffect(()=>{
      const generateNonLoggedInUser = async ()=>{
        try{
          
          administratorServiceClient.generateNonLoggedInUser()
          .then(response => {
            sessionManager.setNonLoggedInUserToken(JSON.stringify({ token: response.token }));
          })
          .catch(() => {
            sessionManager.setNonLoggedInUserToken(JSON.stringify({ token: '' }));
          });
        }catch(e){
          console.log(e)
        }
      
      }
      generateNonLoggedInUser()
      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[])

    const scrollState = {
      top: true,
      topThreshold: 10,
      onScroll: function () {
        if (window.scrollY > this.topThreshold) {
          this.showScroll = true;
          this.updateUI();
        } else if (window.scrollY <= this.topThreshold) {
          this.showScroll = false;
          this.updateUI();
        }
      },
      updateUI: function () {
        var footer = document.getElementById('footer');

        if (this.showScroll) {
          footer.classList.remove('footer-position-fixed');
        } else {
          footer.classList.add('footer-position-fixed');
        }
        //header.classList.toggle("shadow-sm");
      },
    };
    window.addEventListener("scroll", () => scrollState.onScroll());

  return (
    <>
      {location.pathname !== '/' ? (
      <Layout>
          <Routes>
            {AppRoutes.map((route, index) => {
              const { element, ...rest } = route;
              return <Route key={index} {...rest} element={element} exact/>;
            })}
          </Routes>
      </Layout>
      ) : (
          <LandingPage></LandingPage>
      )}
    </>
  );
})

export default InjectAppServices(App);