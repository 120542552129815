import React, { useState, useEffect, useMemo } from 'react';
import './style.scss';
import { Formik, Form } from 'formik';
import LoadingSpinner from '../../../../Shared/LoadingSpinner';
import { categoryService } from '../../../../../services/categoryService';
import { subcategoryService } from '../../../../../services/subcategoryService';
import { groupExperienceService } from '../../../../../services/groupExperienceService';
import useTimeout from '../../../../../hooks/useTimeout';
import { getFormInitialValues } from '../../../../../Utils/utils';
import { DELAY_BEFORE_REDIRECT, DefaultOption, Levels, MinutesDurationClass, NumberOfClasses, RegistrationAvailableFor, Status } from '../../../../../Utils/types';
import { CheckboxFieldItem, FieldGroup, FieldItem, InputFieldItem, PriceInputFieldItem, SelectFieldItem, SubmitButton, TextAreaFieldItem } from '../../../../Shared/FormHelpers';
import FileUpload from '../../../../Shared/FileUpload/FileUplodad';
import { faFileImage } from '@fortawesome/free-solid-svg-icons'
import FileList from '../../../../Shared/FileList/FileList';
import { Message } from '../../../../Shared/Message';
import { InjectAppServices } from '../../../../../services/dependency-injection';

const fieldNames = {
    category: 'category',
    subcategory: 'subcategory',
    title: 'title',
    description: 'description',
    detailedDescription: 'detailedDescription',
    requirements: 'requirements',
    level: 'level',
    numberOfClasses: 'numberOfClasses',
    minutesDurationClass: 'minutesDurationClass',
    isFree: 'isFree',
    price: 'price',
    registrationAvailableFor: 'registrationAvailableFor',
    noRegistrationLimit: 'noRegistrationLimit',
    registrationLimit: 'registrationLimit',
    labels: 'labels',
};

export const ConfigurationForm = InjectAppServices(
    ({ 
        id, 
        handleSave,
        dependencies: { sessionManager, configurationServiceClient }
     }) => {
    const categoryServiceClient = useMemo(() => new categoryService(), []);
    const subcategoryServiceClient = useMemo(() => new subcategoryService(), []);
    const groupExperienceServiceClient = useMemo(() => new groupExperienceService(), []);
    const userLoggedIn = sessionManager.getUserLoggedIn();

    const [groupExperience, setGroupExperience] = useState(null);
    const [loading, setLoading] = useState(id !== undefined);
    const [categories, setCategories] = useState([]);
    const [subcategories, setSubcategories] = useState([]);
    const [isFree, setIsFree] = useState(false);
    const [noRegistrationLimit, setNoRegistrationLimit] = useState(true);
    const [imageFiles, setImageFiles] = useState([]);
    const [imageFileIds, setImageFileIds] = useState([]);
    const [isImageFileLoading, setIsImageFileLoading] = useState(false);

    const [status, setStatus] = useState('');
    const [error, setError] = useState('');
    const [configuration, setConfiguration] = useState({});

    const createTimeout = useTimeout();

    useEffect(() => {
        const fetchData = async () => {
            if (id !== undefined) {
                var response = await groupExperienceServiceClient.getGroupExperienceById(id);

                if (response.success) {
                    setGroupExperience({
                        category: response.value.categoryId,
                        subcategory: response.value.subcategoryId,
                        title: response.value.title,
                        description: response.value.description,
                        detailedDescription: response.value.detailedDescription,
                        requirements: response.value.requirements,
                        level: response.value.level,
                        numberOfClasses: response.value.quantityClasses,
                        minutesDurationClass: response.value.minutesDurationClasses,
                        registrationAvailableFor: response.value.availableInscriptionFor,
                        noRegistrationLimit: response.value.noRegistrationLimit,
                        registrationLimit: response.value.registrationLimit,
                        isFree: response.value.isFree,
                        price: response.value.price === 0 ? "" : response.value.price,
                        labels: response.value.tags,
                        coverImage: response.value.coverImage,
                        published: response.value.published,
                        hasDiscount: response.value.hasDiscount,
                        hasPromocodesAvailable: response.value.hasPromocodesAvailable
                    });

                    setIsFree(response.value.isFree);
                    setNoRegistrationLimit(response.value.noLimitRegistered);

                    initializesFiles(response.value);

                    const result = await subcategoryServiceClient.getAllByCategoryId(response.value.categoryId);
                    setSubcategories(result.success ? result.value.map(function (item) {
                        return { key: item.subcategoryId, value: item.name };
                    }) : []);
                }
            }

            configurationServiceClient.getByKey('DiscountService:MinimumServiceAmount')
                .then(response => {
                    setConfiguration(response);
                });

            setLoading(false);
        };
        fetchData();

    }, [categoryServiceClient, groupExperienceServiceClient, subcategoryServiceClient, id, configurationServiceClient]);

    useEffect(() => {
        const fetchData = async () => {
            var categoriesResponse = await categoryServiceClient.getAll();

            if (categoriesResponse.success) {
                const values = categoriesResponse.value.map(function (item) {
                    return { key: item.categoryId, value: item.name };
                });

                setCategories(values);
            }
        };
        fetchData();

    }, [categoryServiceClient]);

    const initializesFiles = (groupExperience) => {
        /* Cover Image */
        if (groupExperience.coverImage.fileId > 0) {
            setImageFiles([{
                name: groupExperience.coverImage.name,
                isUploading: false,
                id: groupExperience.coverImage.fileId
            }]);

            setImageFileIds([groupExperience.coverImage.fileId]);
        }
    }

    const saveGroupExperience = async(values) => {
        setStatus('');
        var response = await groupExperienceServiceClient.saveGroupExperience(userLoggedIn.userId, id, imageFileIds, values);

        if (response.success) {
            setStatus(Status.Saved);
            createTimeout(() => {
                handleSave(response.value);
            }, DELAY_BEFORE_REDIRECT);
        } else {
            setStatus(Status.HasError);
            setError(response.error);
        }
    }

    const _getFormInitialValues = () => {
        let initialValues = getFormInitialValues(fieldNames);

        if (groupExperience !== null) {
            initialValues = { ...groupExperience };
        }

        return initialValues;
    };

    const changeCategory = async (e, setFieldValue) => {
        const category = e.target.value;
        setFieldValue(fieldNames.category, category);
        const result = await subcategoryServiceClient.getAllByCategoryId(category);
        setSubcategories(result.success ? result.value.map(function (item) {
            return { key: item.subcategoryId, value: item.name };
        }) : []);
        setFieldValue(fieldNames.subcategory, '');
    };

    const handleIsFreeChange = async (e, setFieldValue) => {
        setIsFree(!isFree);
        setFieldValue(fieldNames.price, '');
    };

    const handleNoRegistrationLimitChange = async (e, setFieldValue) => {
        setNoRegistrationLimit(!noRegistrationLimit);
        setFieldValue(fieldNames.registrationLimit, '');
    };

    const removeImageFile = (fileToRemove) => {
        setImageFiles(imageFiles.filter(file => file.name !== fileToRemove.name));
        setImageFileIds(imageFileIds.filter(file => file !== fileToRemove.id));
    }

    const showMessage = [Status.Saved, Status.HasError].includes(status);

    return (
        <>
            <div className="group-experience-form">
                <div className="group-experience-form-card">
                    <div className="group-experience-form-card-header">
                        <a href="manage-services/3" className="back">
                            <i className="ti-arrow-left"></i>
                        </a>
                        <h4>Experiencia Grupal</h4>
                    </div>
                    <div className="group-experience-form-card-legend">
                        <p><strong>Creá tu experiencia grupal</strong></p>
                        <p>Las experiencia grupales son en vivo y pueden estar compuestas por 1 o más clases. La inscripción estará disponible durante toda la experiencia grupal, hasta el final de la última clase. Los talleres que tengan inscriptos no podrán ser cancelados.</p>
                    </div>
                    {loading ? <LoadingSpinner /> : (
                    <div className="group-experience-form-card-form">
                        <div className="row">
                            <div className="col-lg-12">
                                <Formik
                                    onSubmit={saveGroupExperience}
                                    initialValues={_getFormInitialValues()}
                                >
                                    {({ setFieldValue, isSubmitting, isValid, dirty }) => (
                                        <Form>
                                            <fieldset className="form-fields">
                                                <FieldGroup>
                                                    <FieldItem className="field-item">
                                                        <FieldGroup>
                                                            <SelectFieldItem
                                                                fieldName={fieldNames.category}
                                                                id={fieldNames.category}
                                                                label={`*Categoría en Edowie`}
                                                                required
                                                                defaultOption={DefaultOption}
                                                                values={categories}
                                                                className="field-item--100"
                                                                onChange={(e) => {
                                                                    changeCategory(e, setFieldValue);
                                                                }}
                                                                disabled={groupExperience !== null ? groupExperience.published : false}
                                                            />
                                                        </FieldGroup>
                                                    </FieldItem>
                                                    <FieldItem className="field-item">
                                                        <FieldGroup>
                                                            <SelectFieldItem
                                                                fieldName={fieldNames.subcategory}
                                                                id={fieldNames.subcategory}
                                                                label={`*Subcategoría en Edowie`}
                                                                defaultOption={DefaultOption}
                                                                required
                                                                values={subcategories}
                                                                className="field-item--100"
                                                                disabled={groupExperience !== null ? groupExperience.published : false}
                                                            />
                                                        </FieldGroup>
                                                    </FieldItem>
                                                    <FieldItem className="field-item">
                                                        <FieldGroup>
                                                            <InputFieldItem
                                                                type="text"
                                                                fieldName={fieldNames.title}
                                                                id={fieldNames.title}
                                                                label={`*Título`}
                                                                required
                                                                placeholder={'Título'}
                                                                className="field-item--100"
                                                                maxLength="150"
                                                            />
                                                        </FieldGroup>
                                                    </FieldItem>
                                                    <FieldItem className="field-item">
                                                        <FieldGroup>
                                                            <InputFieldItem
                                                                type="text"
                                                                fieldName={fieldNames.description}
                                                                id={fieldNames.description}
                                                                label={`*Descripción corta`}
                                                                required
                                                                placeholder={'Descripción corta'}
                                                                className="field-item--100"
                                                                maxLength="250"
                                                            />
                                                        </FieldGroup>
                                                    </FieldItem>
                                                    <FieldItem className="field-item">
                                                        <FieldGroup>
                                                            <TextAreaFieldItem
                                                                type="textarea"
                                                                fieldName={fieldNames.detailedDescription}
                                                                id={fieldNames.detailedDescription}
                                                                label={`*Descripción detallada`}
                                                                required
                                                                placeholder={'Descripción detallada de lo que aprenderá quien se inscriba a la experiencia grupal.'}
                                                                className="field-item--100"
                                                                maxLength="5000"
                                                            />
                                                        </FieldGroup>
                                                    </FieldItem>
                                                    <FieldItem className="field-item">
                                                        <FieldGroup>
                                                            <TextAreaFieldItem
                                                                type="textarea"
                                                                fieldName={fieldNames.requirements}
                                                                id={fieldNames.requirements}
                                                                label={`Requisitos`}
                                                                placeholder={'Requisitos'}
                                                                className="field-item--100"
                                                                maxLength="5000"
                                                            />
                                                        </FieldGroup>
                                                    </FieldItem>
                                                    <FieldItem className="field-item">
                                                        <FieldGroup>
                                                            <SelectFieldItem
                                                                fieldName={fieldNames.level}
                                                                id={fieldNames.level}
                                                                label={`*Nivel`}
                                                                defaultOption={DefaultOption}
                                                                values={Levels}
                                                                required
                                                                className="field-item--100"
                                                                disabled={groupExperience !== null ? groupExperience.published : false}
                                                            />
                                                        </FieldGroup>
                                                    </FieldItem>
                                                    <FieldItem className="field-item">
                                                        <FieldGroup>
                                                            <SelectFieldItem
                                                                fieldName={fieldNames.numberOfClasses}
                                                                id={fieldNames.numberOfClasses}
                                                                label={`*Cantidad de clases`}
                                                                defaultOption={DefaultOption}
                                                                values={NumberOfClasses}
                                                                required
                                                                className="field-item--100"
                                                                disabled={groupExperience !== null ? groupExperience.published : false}
                                                            />
                                                        </FieldGroup>
                                                    </FieldItem>
                                                    <FieldItem className="field-item">
                                                        <FieldGroup>
                                                            <SelectFieldItem
                                                                fieldName={fieldNames.minutesDurationClass}
                                                                id={fieldNames.minutesDurationClass}
                                                                label={`*Minutos de duración por clase`}
                                                                defaultOption={DefaultOption}
                                                                values={MinutesDurationClass}
                                                                required
                                                                className="field-item--100"
                                                                disabled={groupExperience !== null ? groupExperience.published : false}
                                                            />
                                                        </FieldGroup>
                                                    </FieldItem>
                                                    <FieldItem className="field-item">
                                                        <FieldGroup>
                                                            <CheckboxFieldItem
                                                                fieldName={fieldNames.isFree}
                                                                key={fieldNames.isFree}
                                                                label={'Es gratis'}
                                                                id={fieldNames.isFree}
                                                                onChange={(e) => {
                                                                    handleIsFreeChange(e, setFieldValue);
                                                                }}
                                                            />
                                                        </FieldGroup>
                                                    </FieldItem>
                                                    <FieldItem className="field-item">
                                                        <FieldGroup>
                                                            <PriceInputFieldItem
                                                                fieldName={fieldNames.price}
                                                                id={fieldNames.price}
                                                                label={`${isFree ? '' : '*'} Precio en USD`}
                                                                required={!isFree}
                                                                placeholder={'Precio'}
                                                                className="field-item--100"
                                                                min={parseInt(configuration.value)}
                                                                disabled={isFree || (groupExperience !== null && (groupExperience.hasDiscount || groupExperience.hasPromocodesAvailable) && groupExperience.published)}
                                                            />
                                                        </FieldGroup>
                                                    </FieldItem>
                                                    <FieldItem className="field-item">
                                                        <FieldGroup>
                                                            <SelectFieldItem
                                                                fieldName={fieldNames.registrationAvailableFor}
                                                                id={fieldNames.registrationAvailableFor}
                                                                label={`*Disponible la inscripción para`}
                                                                defaultOption={DefaultOption}
                                                                values={RegistrationAvailableFor}
                                                                required
                                                                className="field-item--100"
                                                                disabled={groupExperience !== null ? groupExperience.published : false}
                                                            />
                                                        </FieldGroup>
                                                    </FieldItem>
                                                    <FieldItem className="field-item">
                                                        <FieldGroup>
                                                            <CheckboxFieldItem
                                                                fieldName={fieldNames.noRegistrationLimit}
                                                                key={fieldNames.noRegistrationLimit}
                                                                label={'Sin límite de inscriptos'}
                                                                id={fieldNames.noRegistrationLimit}
                                                                onChange={(e) => {
                                                                     handleNoRegistrationLimitChange(e, setFieldValue);
                                                                }}
                                                                disabled={groupExperience !== null ? groupExperience.published : false}
                                                            />
                                                        </FieldGroup>
                                                    </FieldItem>
                                                    <FieldItem className="field-item">
                                                        <FieldGroup>
                                                            <InputFieldItem
                                                                type="number"
                                                                fieldName={fieldNames.registrationLimit}
                                                                id={fieldNames.registrationLimit}
                                                                label={`${!noRegistrationLimit ? '' : '*'} Límite de inscriptos`}
                                                                required={noRegistrationLimit}
                                                                placeholder={'Límite de inscriptos'}
                                                                className="field-item--100"
                                                                disabled={!noRegistrationLimit || (groupExperience !== null ? groupExperience.published : false)}
                                                            />
                                                        </FieldGroup>
                                                    </FieldItem>
                                                    <FieldItem className="field-item">
                                                        <FieldGroup>
                                                            <InputFieldItem
                                                                type="text"
                                                                fieldName={fieldNames.labels}
                                                                id={fieldNames.labels}
                                                                label={`Etiquetas`}
                                                                placeholder={'Etiquetas'}
                                                                className="field-item--100"
                                                                maxLength="250"
                                                            />
                                                        </FieldGroup>
                                                    </FieldItem>
                                                    <FieldItem className="field-item">
                                                        <FieldGroup>
                                                            <div className="row">
                                                                <div className="col-lg-6">
                                                                    <FileUpload files={imageFiles} setFiles={setImageFiles}
                                                                        removeFile={removeImageFile} fileIcon={faFileImage}
                                                                        uploadTitle={'Imagen de Portada'} allowedExtensions={".png, .jpg, .jpeg"}
                                                                        allowMultipleFile={false}
                                                                        disabled={imageFiles.length > 0}
                                                                        handleUploadedFile={(fileId) => { 
                                                                            setImageFileIds([...imageFileIds, fileId]);
                                                                        }}
                                                                        handleIsLoadingFile={(isUploading) => {
                                                                            setIsImageFileLoading(isUploading);
                                                                        }}
                                                                    />
                                                                    <FileList files={imageFiles} removeFile={removeImageFile} />
                                                                </div>
                                                            </div>
                                                        </FieldGroup>
                                                    </FieldItem>
                                                    <FieldItem className="field-item">
                                                        <FieldGroup>
                                                            <div className="save-button">
                                                                <SubmitButton isSubmitting={isSubmitting} disabled={isImageFileLoading}>
                                                                    {'Continuar'}
                                                                </SubmitButton>
                                                            </div>
                                                        </FieldGroup>
                                                    </FieldItem>
                                                </FieldGroup>
                                            </fieldset>
                                            {showMessage && (
                                                <fieldset>
                                                <Message
                                                    type={status === Status.Saved ? 'success' : 'error'}
                                                    message={
                                                        status === Status.Saved
                                                            ? '¡Operación procesada con éxito!'
                                                            : error}
                                                    />
                                                </fieldset>
                                            )}
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    </div>
                    )}
                </div>
            </div>
        </>
    )
})