import './style.scss';
import RightImage from '../../../general/images/right.png';
import { useTranslation } from 'react-i18next';
export const Header = () => {
    const { t } = useTranslation(['landing_page']);
    const header = t('header');

    return (
        <div className="header">
            <div className="header-body">
                <div className="row header-body-container">
                    <div className="col-lg-8 col-xl-7 col-xxl-6">
                        <div className="header-body-container-title-section">
                            <h1 className="title">{header.title}</h1>
                            <h2 className="subtitle">{header.subtitle}</h2>
                            <div className="sale-buy-content-buttons-section">
                                <a className="content-button sale-content-button" href="#edowier">{header.sellCourses}</a>
                                <a className="content-button buy-content-button" href="#usuario">{header.buyCourses}</a>
                            </div>
                            <div className="login-button-section">
                                <a className="login-button" href="/login">{header.login}</a>
                                <a className="login-button" href="/register">{header.signUp}</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-5 col-xxl-6 right-image" ><img src={RightImage} alt="..."/></div>
                </div>
            </div>
        </div>
    )
}