import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { groupExperienceService } from "../../../../services/groupExperienceService";
import { NavMenu } from "../../NavMenu";
import { Header } from "./Header";
import LoadingSpinner from "../../../Shared/LoadingSpinner";
import './style.scss';
import { EnrollSection } from "./EnrollSection";
import { UserSection } from '../../../Shared/Services/UserSection/index';
import { DescriptionSection } from '../../../Shared/Services/DescriptionSection/index';
import { DetailsSection } from "../../../Shared/GroupExperience/DetailsSection";
import { DateOfClassesSection } from "../../../Shared/GroupExperience/DateOfClassesSection";
import { RequirementsSection } from "../../../Shared/Services/RequirementsSection";
import { relationService } from "../../../../services/relationService";
import { InjectAppServices } from "../../../../services/dependency-injection";

export const EnrollGroupExperience = InjectAppServices(
    ({
        dependencies: { ipInfoServiceClient, sessionManager }
    }) => {
    const groupExperienceServiceClient = useMemo(() => new groupExperienceService(), []);
    const relationServiceClient = useMemo(() => new relationService(), []);

    const { id } = useParams();
    const [groupExperience, setGroupExperience] = useState(null);
    const [loading, setLoading] = useState(id !== undefined ? true : false);
    const [wasEnrolled, setWasEnrolled] = useState(false);
    const [isFollowing, setIsFollowing] = useState(false);
    const [userCountry, setUserCountry] = useState('ar');

    const userLoggedIn = sessionManager.getUserLoggedIn();
    
    useEffect(() => {
        const fetchData = async () => {
            var response = await groupExperienceServiceClient.getDetailsById(userLoggedIn.userId, id);

            if (response.success) {
                if (userLoggedIn.userId > 0) {
                    var wasEnrolledResponse = await groupExperienceServiceClient.wasEnrolled(userLoggedIn.userId, response.value.groupExperienceId);
                    if (wasEnrolledResponse.success) {
                        setWasEnrolled(wasEnrolledResponse.value);
                    }

                    var relationResponse = await relationServiceClient.isFollowing(userLoggedIn.userId, response.value.userId);
                    if (relationResponse.success) {
                        setIsFollowing(relationResponse.value);
                    }

                } else {
                    setWasEnrolled(false);
                }

                setGroupExperience(response.value);
                setLoading(false);
            }
        };
        fetchData();

    }, [groupExperienceServiceClient, relationServiceClient, setLoading, setGroupExperience, id, userLoggedIn.userId]);

    useEffect(() => {
        const fetchData = async () => {
            ipInfoServiceClient.getCountryCode()
            .then(response => {
                setUserCountry(response.value.toLowerCase());
            });
        };
        fetchData();

    }, [ipInfoServiceClient]);

    const handleSuccessPurchase = async () => {
        var response = await groupExperienceServiceClient.getDetailsById(userLoggedIn.userId, id);

        if (response.success) {

            var wasEnrolledResponse = await groupExperienceServiceClient.wasEnrolled(userLoggedIn.userId, response.value.groupExperienceId);
            if (wasEnrolledResponse.success) {
                setWasEnrolled(wasEnrolledResponse.value);
            }

            setGroupExperience(response.value);
        }
    }

    return (
        <>
            <NavMenu showNavigation={true} currentPage={'grupal'} />
            <div className="enroll-group-experience">
                {loading ? <LoadingSpinner /> : (
                    <div className="row">
                        <div className="col-xl-8 col-xxl-8">
                            <Header groupExperience={groupExperience} userId={userLoggedIn.userId} wasEnrolled={wasEnrolled}>
                            </Header>
                            <UserSection serviceUserId={groupExperience.userId} userId={userLoggedIn.userId} 
                                serviceLevel={groupExperience.level} serviceProfileImage={groupExperience.profileImage}
                                serviceUsername={groupExperience.username}>
                            </UserSection>
                            <DescriptionSection description={groupExperience.description}>
                            </DescriptionSection>
                            <DetailsSection numberOfClasses={groupExperience.numberOfClasses} 
                                            registrationLimit={groupExperience.registrationLimit}
                                            level={groupExperience.level}
                                            minutesDurationClasses={groupExperience.minutesDurationClasses}
                                            withoutRegistrationLimit={groupExperience.withoutRegistrationLimit}></DetailsSection>
                            <DateOfClassesSection classes={groupExperience.classes}></DateOfClassesSection>
                            <RequirementsSection requirements={groupExperience.requirements}></RequirementsSection>
                        </div>
                        <div className="col-xl-4 col-xxl-4">
                            <EnrollSection isFree={groupExperience.isFree} price={groupExperience.price}
                                           description={groupExperience.description} 
                                           withoutRegistrationLimit={groupExperience.withoutRegistrationLimit}
                                           numberOfRegistrations={groupExperience.numberOfRegistrations}
                                           registrationLimit={groupExperience.registrationLimit}
                                           userId={groupExperience.userId} groupExperienceId={groupExperience.groupExperienceId}
                                           registrationAvailableFor={groupExperience.registrationAvailableFor} 
                                           numberOfClasses={groupExperience.numberOfClasses} firstClass={groupExperience.firstClass}
                                           wasEnrolled={wasEnrolled} following={isFollowing} discount={groupExperience.discount}
                                           userCountry={userCountry} hasPromocodesAvailable={groupExperience.hasPromocodesAvailable} 
                                           handleSuccessPurchase={() => handleSuccessPurchase() }>
                            </EnrollSection>
                        </div>
                    </div>
                )}
            </div>
        </>
    )
})