import { useTranslation } from "react-i18next";

export const AboutThisAgreementSection = () => {
    const { t } = useTranslation(['terms_and_conditions']);
    const changesAgreementSection = t('aboutAgreementSection.changesAgreementSection');
    const continuationAgreementSection = t('aboutAgreementSection.continuationAgreementSection');
    const divisibilitySection = t('aboutAgreementSection.divisibilitySection');
    const noWaiverSection = t('aboutAgreementSection.noWaiverSection');
    const interpretationSection = t('aboutAgreementSection.interpretationSection');
    const currentLawSection = t('aboutAgreementSection.currentLawSection');

    return (
        <>
            <p className="section-subtitle">{changesAgreementSection.title}</p>
            <p className="section-legend">
                {changesAgreementSection.legend1}
            </p>
            <p className="section-legend pt-15">
                {changesAgreementSection.legend2}
            </p>

            <p className="section-subtitle pt-15">{continuationAgreementSection.title}</p>
            <p className="section-legend">
                {continuationAgreementSection.legend}
            </p>

            <p className="section-subtitle pt-15">{divisibilitySection.title}</p>
            <p className="section-legend">
                {divisibilitySection.legend}
            </p>

            <p className="section-subtitle pt-15">{noWaiverSection.title}</p>
            <p className="section-legend">
                {noWaiverSection.legend}
            </p>

            <p className="section-subtitle pt-15">{interpretationSection.title}</p>
            <p className="section-legend">
                {interpretationSection.legend}
            </p>

            <p className="section-subtitle pt-15">{currentLawSection.title}</p>
            <p className="section-legend">
                {currentLawSection.legend}
            </p>
        </>
    )
}