
import { InjectAppServices } from "../../../../../services/dependency-injection";
import moment from 'moment'
import { useTranslation } from "react-i18next";
import { useEffect, useMemo, useState } from "react";
import { particularExperienceService } from "../../../../../services/particularExperienceService";
import { getCurrentTime } from "../../../../../Utils/utils";
import { EventType, PeriodEvent, ServiceType } from "../../../../../Utils/types";
import { PastEventButtons } from "./PastEventButtons";
import { FutureEventButtons } from "./FutureEventButtons";
import { RegistrationsModal } from "./RegistrationsModal";

export const Event = InjectAppServices(
    ({
        event,
        periodEvent,
        handleQualify,
        handleDenunciate,
        handleCancel,
        dependencies: { sessionManager }
    }) => {
        const particularExperienceServiceClient = useMemo(() => new particularExperienceService(), []);
        const { t, i18n } = useTranslation(['diary']);
        const userLoggedIn = sessionManager.getUserLoggedIn();
        const canConnect = event.conferenceLink !== null;
        const [canCancel, setCanCanel] = useState(false);
        const [canDenunciate, setCanDenunciate] = useState(false);
        const [showRegistrationsModal, setShowRegistrationsModal] = useState(false);
        const canQualify =  !event.hasQualification && userLoggedIn.userId !== event.edowierUserId;


        //ar startEventDate = moment(event.end + 'Z').add((-1) * moment().utcOffset(), 'minutes').add(userLoggedIn.offset, 'minutes')._d;

        

        moment.locale(i18n.language);

        useEffect(() => {
            const fetchData = async () => {

                if (event.type === EventType.particular) {
                    const particularExperienceResponse = await particularExperienceServiceClient.getByTeacherId(event.edowierUserId);

                    if (particularExperienceResponse.success) {
                        const advanceCancellationPeriod = particularExperienceResponse.value.advanceCancellationPeriod;
                        setCanCanel(moment(event.start).isAfter(moment(getCurrentTime(userLoggedIn.offset)).add(advanceCancellationPeriod, 'm').toDate()) && 
                                    (userLoggedIn.userId === event.edowierUserId) && event.type === EventType.particular);
                    }
                } else {
                    setCanCanel(false);
                }

                setCanDenunciate(!event.hasComplaint && 
                              (getCurrentTime(userLoggedIn.offset) <= moment(event.end).add(1440, 'minutes')._d) &&
                              (event.type === EventType.particular || 
                               (event.type === EventType.group && 
                                event.totalClasses === event.classNumber && 
                                userLoggedIn.userId !== event.edowierUserId)));
            };
            fetchData();
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);

        return (
            <div className="diary-container-event-section row">
                <div className="row">
                    <div className='col-lg-10'>
                        <div className="diary-container-event-section-container">
                            <div className="diary-container-event-section-reference-container">
                                <div className={`diary-container-event-section-reference-container-reference diary-container-event-section-reference-container-${event.type}-reference`} title={t(`${event.type}Tooltip`)}></div>
                            </div>
                            <div className="diary-container-event-section-item-container">
                                <div className="diary-container-event-section-item-container-item-title">
                                    <a href={event.type === EventType.group ? 
                                             `/enroll-group-experience/${event.referenceId}` : 
                                             `/teacher-profile/${event.edowierUserId}/3`}>
                                        {event.title}
                                    </a>
                                </div>
                                <div className="diary-container-event-section-item-container-details">
                                    <div className="diary-container-event-section-item-container-details-date">{`${moment(event.start).format('LT')} - ${moment(event.end).format('LT')}`}</div>
                                    <div className="diary-container-event-section-item-container-details-event">
                                        {event.type === EventType.group && (
                                        <>
                                            <div className="diary-container-event-section-item-container-details-event-detail">
                                                <div>{t('registrationsLabel')}:</div>
                                                <div className="diary-container-event-section-item-container-details-event-detail-value">
                                                    {event.edowierUserId === userLoggedIn.userId ? (
                                                    <button onClick={() => setShowRegistrationsModal(true)}>
                                                        {event.registeredQuantity} usuario/s
                                                    </button>
                                                    ) : (
                                                        `${event.registeredQuantity} usuario/s`
                                                    )}
                                                </div>
                                                <RegistrationsModal 
                                                    show={showRegistrationsModal} 
                                                    referenceId={event.referenceId}
                                                    handleClose={() => setShowRegistrationsModal(false)}
                                                >
                                                </RegistrationsModal>
                                            </div>
                                            <div className="diary-container-event-section-item-container-details-event-detail">
                                            <div>{t('classLabel')}:</div>
                                                <div className="diary-container-event-section-item-container-details-event-detail-value">{event.classNumber}/{event.totalClasses}</div>
                                            </div>
                                        </>
                                        )}
                                        {event.type === EventType.particular && (
                                        <div className="diary-container-event-section-item-container-details-event-detail">
                                            <div>{t('userLabel')}:</div>
                                            <a href={`user-profile/${event.userId}`}>
                                                <div className="diary-container-event-section-item-container-details-event-detail-value">{event.username}</div>
                                            </a>
                                        </div>
                                        )}
                                        <div className="diary-container-event-section-item-container-details-event-detail">
                                            <div>{t('edowierLabel')}:</div>
                                            <a href={`teacher-profile/${event.edowierUserId}`}>
                                                <div className="diary-container-event-section-item-container-details-event-detail-value">{event.edowierUsername}</div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-2'>
                    <div className="diary-container-event-section-buttons-container">
                        {periodEvent === PeriodEvent.pastEvents ? (
                            <PastEventButtons 
                                canQualify={canQualify} 
                                canDenunciate={canDenunciate}
                                handleQualify={handleQualify}
                                handleDenunciate={handleDenunciate}
                                userId={userLoggedIn.userId}
                                denunciatedUserId={event.edowierUserId === userLoggedIn.userId ? event.userId : event.edowierUserId}
                                eventId={event.eventId}
                                eventType={event.type === EventType.particular ? ServiceType.ParticularExperience : ServiceType.GrupalExperience}
                                referenceId={event.referenceId}
                            ></PastEventButtons>
                        ) : (
                            <FutureEventButtons 
                                canCancel={canCancel} 
                                canConnect={canConnect}
                                handleCancel={() => { setCanCanel(false); handleCancel();}}
                                userId={event.userId}
                                edowierUserId={event.edowierUserId}
                                eventId={event.eventId}
                                conferenceLink={event.conferenceLink}
                            >   
                            </FutureEventButtons>
                        )}
                    </div>
                    </div>
                </div>
            </div>
        )
    }
)