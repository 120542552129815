import { faDollarSign } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { UpdateBalanceInformationModal } from './UpdateBalanceInformationModal';
import { useState } from 'react';

export const BalanceButton = ({ userId, commission, balance, minimumWithdrawal, handleUpdateBalanceInformation }) => {

    const [showModal, setShowModal] = useState(false);

    return (
        <>
            <button 
                className="users-section-grid-user-buttons-column-change-profile"
                title={'Actualizar información de balance'}
                onClick={() => { setShowModal(true) }}
            >
                <FontAwesomeIcon icon={faDollarSign} />
            </button>
            <UpdateBalanceInformationModal 
                show={showModal}
                handleClose={() => setShowModal(false)}
                userId={userId}
                commission={commission}
                balance={balance}
                minimumWithdrawal={minimumWithdrawal}
                handleUpdateBalanceInformation={handleUpdateBalanceInformation}>
            </UpdateBalanceInformationModal>
        </>
    )
}