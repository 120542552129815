import { fetchWrapper } from "../Utils/fetchWrapper.js";

const baseUrl = `${process.env.REACT_APP_EDOWIE_API}Transaction`;

export interface ITransactionService {
    getTransactionsByUserId(userId: number, page: number, itemsByPage: number): Promise<any>;
    withdrawal(
        userId: number, 
        withdrawalType: number, 
        amountToWithdraw: number, 
        bankData: string,
        payoneerAccountHolderName: string,
        payoneerAccountEmail: string,
        payoneerAccountBankNumber: string,
        networkUsed: number,
        address: string): Promise<any>;
    getPurchasesByUserId(userId: number, page: number, itemsByPage: number): Promise<any>;
};

export class TransactionService implements ITransactionService{
    async getTransactionsByUserId(userId: number, page: number, itemsByPage: number) {
        return fetchWrapper.get(`${baseUrl}/${userId}/transactions?page=${page}&itemsByPage=${itemsByPage}`);
    }

    async withdrawal(
        userId: number, 
        withdrawalType: number, 
        amountToWithdraw: number, 
        bankData: string,
        payoneerAccountHolderName: string,
        payoneerAccountEmail: string,
        payoneerAccountBankNumber: string,
        networkUsed: number,
        address: string): Promise<any> {
        
            var request = {};

            switch(withdrawalType)
            {
                case 1:
                    request = { 
                        withdrawalType: withdrawalType, 
                        amountToWithdraw: amountToWithdraw, 
                        bankData: bankData };
                    break;
                case 2:
                    request = { 
                        withdrawalType: withdrawalType, 
                        amountToWithdraw: amountToWithdraw, 
                        payoneerAccountHolderName: payoneerAccountHolderName,
                        payoneerAccountEmail: payoneerAccountEmail,
                        payoneerAccountBankNumber: payoneerAccountBankNumber
                    };
                    break;
                case 3:
                    request = { 
                        withdrawalType: withdrawalType, 
                        amountToWithdraw: amountToWithdraw, 
                        networkUsed: networkUsed,
                        address: address
                    };
                    break;
            }
            return fetchWrapper.post(`${baseUrl}/${userId}/withdrawal`, request);
    }

    async getPurchasesByUserId(userId: number, page: number, itemsByPage: number) {
        return fetchWrapper.get(`${baseUrl}/${userId}/purchases?page=${page}&itemsByPage=${itemsByPage}`);
    }
}