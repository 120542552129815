import React from 'react';
import Star from '../../../general/images/star.png';
import './style.scss';

export const StarItem = ({starNumber, starPercentage}) => {
    return (
        <div className="row star-item">
            <div className="col-3">
                <img src={Star} alt="star" className="star" />
                <h4 className="star-number">{starNumber}</h4>
            </div>
            <div className="col-6">
                <div className="progress-bar">
                    <div className="bar-percentage" style={{ width: `${starPercentage}%` }} ></div>
                </div>
            </div>
            <div className="col-3">
                <h4 className="star-percentage">{starPercentage.toFixed(0)}%</h4>
            </div>
        </div>
    )
}