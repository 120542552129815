import { faStar, faUserXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from 'react';
import { QualifyModal } from './QualifyModal/index';
import { DenunciateModal } from './DenunciateModal';

export const PastEventButtons = ({
    canQualify,
    canDenunciate,
    userId,
    denunciatedUserId,
    eventType,
    eventId,
    referenceId,
    handleQualify,
    handleDenunciate
}) => {

    const [showQualificationModal, setShowQualificationModal] = useState(false);
    const [showDenunciateModal, setShowDenunciateModal] = useState(false);

    return (
        <>
            {canQualify && (
                <>
                    <button className="diary-container-event-section-buttons-container-qualify"
                            onClick={() => { setShowQualificationModal(true) }}>
                        <FontAwesomeIcon icon={faStar} />
                    </button>
                    <QualifyModal 
                        show={showQualificationModal} 
                        userId={userId} 
                        serviceId={eventId} 
                        serviceType={eventType} 
                        handleSaveQualifications={() => handleQualify()}
                        handleClose={() => setShowQualificationModal(false)}
                    >
                    </QualifyModal>
                </>
            )}

            {canDenunciate && (
                <>
                    <button className="diary-container-event-section-buttons-container-denunciate"
                            onClick={() => { setShowDenunciateModal(true) }}>
                        <FontAwesomeIcon icon={faUserXmark} />
                    </button>
                    <DenunciateModal 
                        show={showDenunciateModal} 
                        userId={userId} 
                        denunciatedUserId={denunciatedUserId} 
                        eventId={eventId}
                        referenceId={referenceId}
                        eventType={eventType}
                        handleSaveDenunciate={() => handleDenunciate()}
                        handleClose={() => setShowDenunciateModal(false)}
                    >
                    </DenunciateModal>
                </>
            )}
        </>
    )
}