import React from 'react';
import './style.scss';
import { Message } from '../../../../Shared/Message/index';

export const PurchaseSummary = ({ message }) => {
    return (
        <div className="purchase-summary-section row">
            <div className="purchaseSummary">
                <Message
                    type={'success'}
                    message={message}
                />
            </div>
            <div className="col-lg-12 col-sm-12 go-to-library-button">
                <a href={`/library`} >Ir a la biblioteca</a>
            </div>
        </div>
        
    );
}