import React, { createContext, ReactNode } from 'react';
import { ICountryDiscountService, CountryDiscountService} from './countryDiscountService';
import { sessionManager } from '../sessionManager';
import { IServiceDiscountService, ServiceDiscountService } from './serviceDiscountServices';
import { IIpInfoService, IpInfoService } from './ipInfoService';
import { ConfigurationService, IConfigurationService } from './configurationService';
import { IServicePromocodeService, ServicePromocodeService } from './servicePromocodeService';
import { ICountryService, CountryService } from './countryService';
import { IDiaryService, DiaryService } from './diaryService';
import { ITransactionService, TransactionService } from './transactionService';
import { AdministratorService, IAdministratorService } from './administratorService';

/**
 * Services able to be injected
 */
export interface AppServices {
  sessionManager: sessionManager;
  countryDiscountServiceClient: ICountryDiscountService;
  serviceDiscountServiceClient: IServiceDiscountService;
  ipInfoServiceClient: IIpInfoService;
  configurationServiceClient: IConfigurationService;
  servicePromocodeServiceClient: IServicePromocodeService;
  countryServiceClient: ICountryService;
  diaryServiceClient: IDiaryService;
  transactionServiceClient: ITransactionService;
  administratorServiceClient: IAdministratorService;
}

/**
 * Application composition root for AppServices
 */
export class AppCompositionRoot implements AppServices {
  private readonly instances: Partial<AppServices>;

  constructor(predefinedInstances: Partial<AppServices> = {}) {
    this.instances = { ...predefinedInstances };
  }

  private singleton<N extends keyof AppServices, T extends AppServices[N]>(
    name: N,
    factory: () => T,
  ): T {
    if (!this.instances[name]) {
      this.instances[name] = factory();
    }
    return this.instances[name] as T;
  }

  get sessionManager() {
    return this.singleton(
      'sessionManager',
      () =>
        new sessionManager(),
    );
  }

  get countryDiscountServiceClient() {
    return this.singleton(
      'countryDiscountServiceClient',
      () =>
        new CountryDiscountService(),
    );
  }

  get serviceDiscountServiceClient() {
    return this.singleton(
      'serviceDiscountServiceClient',
      () =>
        new ServiceDiscountService(),
    );
  }

  get ipInfoServiceClient() {
    return this.singleton(
      'ipInfoServiceClient',
      () =>
        new IpInfoService(),
    );
  }

  get configurationServiceClient() {
    return this.singleton(
      'configurationServiceClient',
      () =>
        new ConfigurationService(),
    );
  }

  get servicePromocodeServiceClient() {
    return this.singleton(
      'servicePromocodeServiceClient',
      () =>
        new ServicePromocodeService(),
    );
  }

  get countryServiceClient() {
    return this.singleton(
      'countryServiceClient',
      () =>
        new CountryService(),
    );
  }
   
  get diaryServiceClient() {
    return this.singleton(
      'diaryServiceClient',
      () =>
        new DiaryService(),
    );
  }

  get transactionServiceClient() {
    return this.singleton(
      'transactionServiceClient',
      () =>
        new TransactionService(),
    );
  }

  get administratorServiceClient() {
    return this.singleton(
      'administratorServiceClient',
      () =>
        new AdministratorService(),
    );
  }
}

const AppServicesContext = createContext({});
const AppServicesResolver = AppServicesContext.Consumer;

/**
 * Define AppServicesProvider context in order to inject dependencies in elements decorated with InjectAppServices
 * @param props
 */
export function AppServicesProvider({
  forcedServices,
  children,
}: {
  forcedServices?: Partial<AppServices>;
  children?: ReactNode;
}) {
  return (
    <AppServicesContext.Provider value={new AppCompositionRoot(forcedServices)}>
      {children}
    </AppServicesContext.Provider>
  );
}

/**
 * Decorate input component, injecting dependencies from AppServicesProvider (if it is defined)
 * @param Component
 */
export function InjectAppServices(Component: any) {
  // TODO: Use the right type for Component parameter. `() => JSX.Element` is only valid
  // for function components, not for class ones.
  return (props: any) =>
    props.dependencies ? (
      <Component {...props} />
    ) : (
      <AppServicesResolver>
        {(services) => <Component dependencies={services} {...props} />}
      </AppServicesResolver>
    );
}
