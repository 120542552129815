import axios from 'axios'
import React from 'react'
import FileItem from './../FileItem/FileItem'

const FileList = ({ files, removeFile }) => {

    const edowieApi = process.env.REACT_APP_EDOWIE_API;

    const deleteFileHandler = (_name) => {
        axios.delete(`${edowieApi}file/upload/${_name.id}`)
            .then((res) => removeFile(_name))
            .catch((err) => console.error(err));
    }
    return (
        <ul className="file-list">
            {
                files &&
                files.map(f => (<FileItem
                    key={f.name}
                    file={f}
                    deleteFile={() => deleteFileHandler(f)} />))
            }
        </ul>
    )
}

export default FileList
