import ReactPaginate from "react-paginate";
import { Item } from "./Item";

export const PaginatedItems = (
    { 
      items, 
      itemsPerPage, 
      totalItems, 
      currentPage, 
      handlePageClick,
      handleChangeProfile,
      handleChangeAmbassador,
      handleUpdateBalanceInformation
    }) => {
      const pageCount = Math.ceil(totalItems / itemsPerPage);
    
      return (
        <div className="users-section-grid">
          {items.map((item, index) => (
              <div key={index}>
                  <Item 
                    item={item} 
                    handleChangeProfile={handleChangeProfile}
                    handleChangeAmbassador={handleChangeAmbassador}
                    handleUpdateBalanceInformation={handleUpdateBalanceInformation}
                  ></Item>
                  <div className='row'>
                      <hr></hr>
                  </div>
              </div>
          ))}
          <ReactPaginate
            nextLabel=">"
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            marginPagesDisplayed={2}
            pageCount={pageCount}
            forcePage={currentPage}
            previousLabel="<"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName="active"
            renderOnZeroPageCount={null}
          />
        </div>
      );
    }