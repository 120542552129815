import React, { useState, useEffect, useMemo, useCallback } from 'react';
import './style.scss';
import { QualificationService } from '../../../services/qualificationService';
import { EdowieModal } from '../../Shared/Modal/index';
import { ReviewStars } from '../ReviewStars';
import { StarItem } from '../StarItem';
import LoadingSpinner from "../LoadingSpinner/index";
import { ReviewList } from '../ReviewsList';

export const QualificationModal = ({title, serviceId, serviceType, userId, showModal, handleClose, handleAccept}) => {
    const qualificationServiceClient = useMemo(() => new QualificationService(), []);

    const [stars, setStars] = useState([
        {quantity: 0, avg: 0},
        {quantity: 0, avg: 0}, 
        {quantity: 0, avg: 0},
        {quantity: 0, avg: 0},
        {quantity: 0, avg: 0}]);
    const [averageQualifications, setAverageQualifications] = useState(0);
    const [totalReview, setTotalReviews] = useState(0);
    const [loading, setLoading] = useState(true);
    const [allReviews, setAllReviews] = useState(0);


    const getAllQualificationsByServiceIdAndServiceType = useCallback(async () => {
        const qualificationsResponse = await qualificationServiceClient.getAllByServiceIdAndServiceType(serviceId, serviceType);

        if (qualificationsResponse.success) {
            var qualifications = qualificationsResponse.value;

            setAllReviews(qualificationsResponse.value);

            var reviews = 0;
            var totalQualifications = 0;

            var starArray = [
                {quantity: 0, avg: 0},
                {quantity: 0, avg: 0}, 
                {quantity: 0, avg: 0},
                {quantity: 0, avg: 0},
                {quantity: 0, avg: 0}];

            qualifications.forEach(item => {
                starArray[item.qualification - 1].quantity = starArray[item.qualification - 1].quantity + 1;
                totalQualifications = item.qualification + totalQualifications;
                reviews++;
            });

            starArray.forEach(item => {
                item.avg =  (item.quantity / (reviews === 0 ? 1 : reviews)) * 100;
            });

            setStars(starArray);
            var avg = totalQualifications / (reviews === 0 ? 1 : reviews);
            setAverageQualifications(parseFloat(avg).toFixed(1));
            setTotalReviews(reviews);

            setLoading(false);
        }
    },[qualificationServiceClient, serviceId, serviceType]);

    useEffect(() => {
        const fetchData = async () => {
            getAllQualificationsByServiceIdAndServiceType();
        };
        fetchData();

    }, [getAllQualificationsByServiceIdAndServiceType]);

    return (
        <EdowieModal show={showModal} title={title === undefined ? "Calificaciones" : title}
            close={handleClose} handleAccept={handleAccept}>
            <div className="qualification-modal">
                <>
                    {loading ? <LoadingSpinner /> : (
                    <div className="qualification-section">
                        <div className='row'>
                            <div className="col-xl-5 qualification-value">
                                <h2>{averageQualifications}</h2>
                                <div className="qualification-star">
                                    <ReviewStars averageQualifications={averageQualifications}></ReviewStars>
                                    <h4 className="reviews">Basado en {totalReview} Reseña/s.</h4>
                                </div>
                            </div>
                            <div className="col-xl-7">
                                <div className="qualification-for-star">
                                    <StarItem starNumber={5} starPercentage={stars[4].avg}></StarItem>
                                    <StarItem starNumber={4} starPercentage={stars[3].avg}></StarItem>
                                    <StarItem starNumber={3} starPercentage={stars[2].avg}></StarItem>
                                    <StarItem starNumber={2} starPercentage={stars[1].avg}></StarItem>
                                    <StarItem starNumber={1} starPercentage={stars[0].avg}></StarItem>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className="col-xl-12">
                                <ReviewList averageQualifications={averageQualifications} reviews={allReviews}></ReviewList>
                            </div>
                        </div>
                    </div>
                    )}
                </>
            </div>
        </EdowieModal>
    )
}