import React from 'react';
import '../../general/styles/feather.css'

export const EdowieErrorMessage = ({ message }) => {
    return (
        <div className="m-b-12 edowie-wrap-message edowie-wrap-cancel" role="alert" aria-label="cancel">
            <i className="font-sm feather-x-circle edowie-message-icon"></i>
            <div className="edowie-content-message">
                <p>{message}</p>
            </div>
        </div>
    )
}