import { useTranslation } from 'react-i18next';
import './style.scss';

export const Footer = () => {
    const year = new Date().getFullYear();
    const { t } = useTranslation(['footer']);
    
    return (
        <div id='footer' className="footer footer-position-fixed">
            <div className="d-flex soporte">
                <span>{t('support')}: </span><a href="mailto:soporte@edowie.com" className="ml-1">soporte@edowie.com</a>
            </div>
            <a href="https://www.Instagram.com/edowie.ok/" target="_blank" rel="noreferrer">
                <i className="feather-instagram instagram"></i>
            </a>
            <span className="edowie-llc">© Edowie LLC {year}</span>
        </div>
    )
}
