import { useTranslation } from "react-i18next";
import { InjectAppServices } from "../../../../services/dependency-injection";
import { EdowieModal } from "../../../Shared/Modal";
import '../style.scss';
import { UserItem } from "./UserItem";
import { useEffect, useMemo, useState } from "react";
import { activityService } from "../../../../services/activityService";

export const UserLikesModal = InjectAppServices(
    ({
        show, 
        activityId,
        handleClose
    }) => {
        const activityServiceClient = useMemo(() => new activityService(), []);
        const { t } = useTranslation(['home']);
        const [userLikes, setUserLikes] = useState([]);

        useEffect(() => {
            const fetchData = async () => {

                if (show) {
                    const response = await activityServiceClient.getUserLikesByActivityId(activityId);

                    if (response.success) {
                        setUserLikes(response.value);
                    }
                }
            };
            fetchData();
        }, [activityServiceClient, activityId, show]);

        return (
            <EdowieModal 
                show={show} 
                title={t('activities.userLikesModal.title', {ns: 'home'})}
                modalClass={'user-like-modal'}
                handleAccept={() => handleClose()} 
                close={() => handleClose()}>
                    <div className='user-like-modal-user-like-list'>
                        <hr></hr>
                        {userLikes.map(function(userLike, i){
                            return (<div className="user-like-modal-user-like-list-section" key={i}>
                                <UserItem user={userLike}></UserItem>
                                <hr></hr>
                            </div>)
                            
                        })}
                    </div>
                
            </EdowieModal>
        )
    }
)