import { NavMenu } from '../Menu/NavMenu';
import './style.scss';
import { useEffect, useMemo, useState } from "react";
import { profileService } from "../../services/profileService";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import LoadingSpinner from "../Shared/LoadingSpinner";
import { Form, Formik } from "formik";
import { extractParameter, getFormInitialValues, getUserLoggedIn } from "../../Utils/utils";
import { FieldGroup, FieldItem, InputFieldItem, SubmitButton } from "../Shared/FormHelpers";
import { DELAY_BEFORE_REDIRECT, Status } from '../../Utils/types';
import PaymentForm from "../Shared/Stripe/paymentForm";
import queryString from "query-string";
import { Message } from "../Shared/Message";
import useTimeout from "../../hooks/useTimeout";
import { Trans, useTranslation } from 'react-i18next';

const defaultAmountOptions = [
    { amount: 5 , selected: false },
    { amount: 10 , selected: false },
    { amount: 50 , selected: false },
    { amount: 100 , selected: false },
    { amount: 200 , selected: false },
    { amount: 500 , selected: false },
]

const fieldNames = {
    customAmount: 'customAmount'
}

export const Donations = () => {
    const profileServiceClient = useMemo(() => new profileService(), []);

    const { t } = useTranslation();
    const { userId } = useParams();
    const location = useLocation();
    const createTimeout = useTimeout();
    const navigate = useNavigate();

    const userLoggedIn = getUserLoggedIn();
    const [username, setUsername] = useState('');
    const [loading, setLoading] = useState(true);
    const [amount, setAmount] = useState(extractParameter(location, queryString.parse, 'amount') || 0);
    const [externalPaymentId, setExternalPaymentId] = useState(extractParameter(location, queryString.parse, 'payment_intent') || '');
    const [status, setStatus] = useState('');

    const [readyToPay, setReadyToPay] = useState(false);
    const [error, setError] = useState('');
    const [showRequiredError, setShowRequiredError] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            if (externalPaymentId !== '') {
                var paymentResponse = await profileServiceClient.donate(externalPaymentId, amount, userLoggedIn.userId, userId);
                if (paymentResponse.success) {
                    setExternalPaymentId('');
                    setStatus(Status.Saved);

                    createTimeout(() => {
                        navigate(`/teacher-profile/${userId}`);
                    }, DELAY_BEFORE_REDIRECT);
                } else {
                    setStatus(Status.HasError);
                    setError(paymentResponse.error);
                }
            }

            const response = await profileServiceClient.teacherProfile(userId);
            if (response.success) {
                setUsername(response.value.username);
            }

            setLoading(false);
        };
        fetchData();

    }, [externalPaymentId, amount, userId, profileServiceClient, userLoggedIn.userId, createTimeout, navigate]);

    const chooseAmount = (option, setFieldValue) => {
        defaultAmountOptions.forEach((amount) => amount.selected = amount.amount === option.amount);
        setAmount(option.amount);
        setFieldValue(fieldNames.customAmount, '');
    }

    const handleChange = (e, setFieldValue) => {
        defaultAmountOptions.forEach((amount) => amount.selected = false);
        setAmount(Number(e.target.value) || 0);
        setFieldValue(fieldNames.customAmount, e.target.value);
    }

    const donate = () => {
        setShowRequiredError(amount === 0);

        if (amount > 0) {
            setReadyToPay(true);
        }
    }

    const handleCancel = () => {
        setReadyToPay(false);
        setAmount(0);
    }

    const showMessage = [Status.HasError, Status.Saved].includes(status);

    return (
        <>
            {loading ? <LoadingSpinner /> : (
                <>
                    <NavMenu showNavigation={true} currentPage={'donations'} />
                    <div className="donations">
                        <h2 className="donations-title">
                            <Trans
                                i18nKey='donations.title'
                                values={{ username: `${username}`}}
                            />
                        </h2>
                        <div className="row donations-content">
                            <div className="col-sm-6 ">
                                <div className="donations-choose-amount-header">
                                    <div className="donations-choose-amount-title">
                                    <h4>{t('donations.choose_amount')}</h4>
                                    </div>
                                </div>
                                <div className="donations-choose-amount-details">
                                    <Formik
                                        initialValues={getFormInitialValues(fieldNames)}
                                        onSubmit={donate}
                                    >
                                        {({ setFieldValue, isSubmitting }) => (
                                        <>
                                            <div className="row">
                                                {defaultAmountOptions.map((option) => (
                                                    <div key={option.amount} className="col-sm-4">
                                                        <button className={`default-amount`} disabled={readyToPay} 
                                                                onClick={() => chooseAmount(option, setFieldValue)}>
                                                            <div className={`default-amount-button ${option.selected ? 'selected' : ''}`}>USD {option.amount}</div>
                                                        </button>
                                                    </div>
                                                ))}
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <Form>
                                                        <fieldset className="form-fields">
                                                            <FieldGroup>
                                                                <FieldItem className="field-item">
                                                                    <FieldGroup>
                                                                        <InputFieldItem
                                                                            type="number"
                                                                            fieldName={fieldNames.customAmount}
                                                                            id={fieldNames.customAmount}
                                                                            placeholder={`${t('donations.custom_amount')}`}
                                                                            className="field-item--100"
                                                                            onChange={(e) => {
                                                                                handleChange(e, setFieldValue);
                                                                            }}
                                                                            disabled={readyToPay}
                                                                        />
                                                                    </FieldGroup>
                                                                </FieldItem>
                                                                {showRequiredError && (
                                                                <FieldItem className="field-item">
                                                                    <FieldGroup>
                                                                        <div className="error-form"><p>{t('donations.amount_required')}</p></div>
                                                                    </FieldGroup>
                                                                </FieldItem>
                                                                )}
                                                                {!readyToPay && (
                                                                <FieldItem className="field-item">
                                                                    <FieldGroup>
                                                                        <div className="donations-save-button">
                                                                            <SubmitButton disabled={readyToPay}>
                                                                                {t('donations.donate_button')}
                                                                            </SubmitButton>
                                                                        </div>
                                                                    </FieldGroup>
                                                                </FieldItem>
                                                                )}
                                                            </FieldGroup>
                                                        </fieldset>
                                                    </Form>
                                                </div>
                                            </div>
                                        </>
                                        )}
                                    </Formik>
                                </div>
                            </div>
                            <div className="col-sm-6 ">
                                <div className="payment-method-card">
                                    <div className="payment-method-card-details">
                                        <PaymentForm amount={amount} disabled={!readyToPay} showCancel={true} 
                                                    handleCancel={() => handleCancel()}
                                                    returnUrl={`donations/${userId}?amount=${amount}`}>
                                        </PaymentForm>
                                        {showMessage && (
                                        <Message
                                            type={status === Status.Saved ? 'success' : 'error'}
                                            message={
                                                status === Status.Saved
                                                    ? t('donations.successful_message')
                                                    : error}
                                        />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    )
}