import { fetchWrapper } from "../Utils/fetchWrapper.js";

const baseUrl = `${process.env.REACT_APP_EDOWIE_API}Country`;

export interface ICountryService {
    getCountryAndTimezonesByCode(countryCode: string): Promise<any>;
};

export class CountryService implements ICountryService {

    public getCountryAndTimezonesByCode(countryCode: string): Promise<string> {
      return fetchWrapper.get(`${baseUrl}/${countryCode}`);
    }
  }

// export class countryService {

//     async getCountryAndTimezonesByCode(countrCode: string) {
//         try {
//             const response = await fetch(`https://restcountries.com/v3.1/alpha/${countrCode}`);
//             const data = await response.json();

//             return {
//                 success: true, value: {
//                     country: data[0].translations.spa.common,
//                     timezones: data[0].timezones,
//                     flagUrl: data[0].flags.svg
//                 }
//             }

//         } catch (error) {
//             return { success: false, error: error };
//         }
//     }
// }
