import React, { useState } from 'react';
import { NavMenu } from '../Menu/NavMenu';
import './style.scss';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { Contents } from './Contents/index';
import { useParams } from "react-router-dom";
import { ParticularExperience } from './ParticularExperiences/index';
import { GroupExperiences } from './GroupExperiences/index';
import { Channels } from './Channels';
import { Donations } from './Donations';
import { useTranslation } from 'react-i18next';
import { ServicePromocodes } from './ServicePromocodes';

export const ManageServices = () => {
    const { t } = useTranslation();

    const { selectedTab } = useParams();
    const [activeTab, setActiveTab] = useState(selectedTab === undefined ? "1" : selectedTab);

    const selectTab = (tabId) => {
        if (activeTab !== tabId) {
            setActiveTab(tabId);
        }
    }
    return (
        <>
            <NavMenu showNavigation={true} currentPage={'manage-services'} />
            <div className="manage-services">
                <div className="header">
                    <h2>{t('title', {ns: 'manage_services'})}</h2>
                </div>
                <div className="tabs">
                    <Nav tabs>
                        <NavItem>
                            <NavLink className={(activeTab === "1" ? "active nav-link" : "nav-link")} href="#"
                                onClick={() => selectTab("1")}>{t('contents.title', {ns: 'manage_services'})}</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink className={(activeTab === "2" ? "active nav-link" : "nav-link")} 
                                     href="#" onClick={() => selectTab("2")}>
                                {t('manage_services.particular.title')}
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink className={(activeTab === "3" ? "active nav-link" : "nav-link")} 
                                     href="#" onClick={() => selectTab("3")}>
                                {t('manage_services.group.title')}
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink className={(activeTab === "4" ? "active nav-link" : "nav-link")} 
                                     href="#" onClick={() => selectTab("4")}>
                                {t('manage_services.channels.title')}
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink className={(activeTab === "5" ? "active nav-link" : "nav-link")} 
                                     href="#" onClick={() => selectTab("5")}>
                                {t('manage_services.donations.title')}
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink className={(activeTab === "6" ? "active nav-link" : "nav-link")} 
                                     href="#" onClick={() => selectTab("6")}>
                                {t('manage_services.promocodes.title')}
                            </NavLink>
                        </NavItem>
                    </Nav>
                </div>
                <div className="tab-content">
                    <TabContent activeTab={activeTab}>
                        <TabPane tabId="1">
                            <Contents type={'course'}></Contents>
                            <Contents type={'resource'}></Contents>
                        </TabPane>
                        <TabPane tabId="2">
                            {activeTab === "2" && (
                                <ParticularExperience></ParticularExperience>
                            )}
                        </TabPane>
                        <TabPane tabId="3">
                            {activeTab === "3" && (
                                <GroupExperiences></GroupExperiences>
                            )}
                        </TabPane>
                        <TabPane tabId="4">
                            {activeTab === "4" && (
                                <Channels></Channels>
                            )}
                        </TabPane>
                        <TabPane tabId="5">
                            {activeTab === "5" && (
                                <Donations></Donations>
                            )}
                        </TabPane>
                        <TabPane tabId="6">
                            {activeTab === "6" && (
                                <ServicePromocodes></ServicePromocodes>
                            )}
                        </TabPane>
                    </TabContent>
                </div>
            </div>
        </>
    )
}
