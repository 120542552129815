import React, { useState } from 'react';
import './style.scss';
import { Nav, NavItem, TabContent, NavLink, TabPane } from 'reactstrap';
import { Theme } from './Theme/index';
import { DELAY_BEFORE_REDIRECT } from '../../../../../Utils/types';
import useTimeout from '../../../../../hooks/useTimeout/index';
import { particularExperienceService } from '../../../../../services/particularExperienceService';
import { sessionManager } from '../../../../../sessionManager'
import { useTranslation } from 'react-i18next';

export const ThemesStep = ({ hasAvailability, themes, handleSaveTheme, handleBack, handleSaveThemesStep }) => {
    const { t } = useTranslation();
    const particularExperienceServiceClient = new particularExperienceService();
    const manager = new sessionManager();
    const [activeTab, setActiveTab] = useState(0);
    const [isEditable, setIsEditable] = useState(themes.length === 0);
    const [currentThemes, setCurrentThemes] = useState(themes.length > 0 ? themes : [{ themeId: 0, topics: []}]);
    const createTimeout = useTimeout();

    const userLoggedIn = JSON.parse(manager.getUserSession());

    const selectTab = (tabId) => {
        if (activeTab !== tabId) {
            setActiveTab(tabId);
        }
    }

    const save = () => {
        setIsEditable(false);
        handleSaveTheme();
    }

    const cancel = () => {
        setIsEditable(false);
        const currentThemeId = currentThemes[activeTab].themeId;
        let themesFiltered = currentThemes.filter(item => item.themeId !== currentThemeId);
        setActiveTab(0);
        setCurrentThemes(themesFiltered);
        handleSaveTheme();
    }

    const addTheme = () => {
        const total = currentThemes.length;
        setCurrentThemes([...currentThemes, { themeId: 0, topics: []}]);
        setActiveTab(total);
        setIsEditable(true);
    }

    const saveThemesStep = async(values) => {
        var response = await particularExperienceServiceClient.saveThemesStep(userLoggedIn.userId);

        if (response.success) {
            createTimeout(() => {
                handleSaveThemesStep();
            }, DELAY_BEFORE_REDIRECT);
        }
    }

    const handleEditing = () => {
        setIsEditable(true);
    }

    return (
        <div className="topics-step">
            <h2 className="topics-step-title">
            {t('manage_services.particular.themes_step.title')}
            </h2>
                <>
                    <div className="tabs">
                        <Nav tabs>
                            {currentThemes.map((theme, index) => (
                                <NavItem key={index}>
                                    <NavLink className={(activeTab === index ? "active nav-link" : "nav-link")} onClick={() => selectTab(index)}>
                                    {t('manage_services.particular.themes_step.themes_label')} {index + 1}
                                    </NavLink>
                                </NavItem>
                            ))}
                            {themes.length < 3 && !isEditable && (
                                <NavItem >
                                    <NavLink className={"nav-link"} onClick={() => addTheme()} title='Agregar nueva temática'>
                                    <i className='feather-plus add-topic'></i>
                                    </NavLink>
                                </NavItem>
                            )}
                            
                        </Nav>
                    </div>
                    <div className="tab-content">
                        <TabContent activeTab={activeTab}>
                            {currentThemes.map((theme, index) => (
                                <TabPane key={index} tabId={index}>
                                    {activeTab === index && (
                                        <Theme key={index} theme={currentThemes[index]} handleSave={save} handleCancel={cancel} isEditable={isEditable}
                                               handleEditing={handleEditing} canDelete={currentThemes.length > 1 || !hasAvailability}></Theme>
                                    )}
                                </TabPane>
                            ))}
                        </TabContent>
                    </div>
                    {!isEditable && (
                    <div className='theme-step-buttons'>
                        <button className='button back-button' onClick={() => handleBack()}>{t('manage_services.particular.back_button')}</button>
                        <button className='button save-button' onClick={() => saveThemesStep()}>{t('manage_services.particular.continue_button')}</button>
                    </div>)}
                </>
        </div>
    )
}