import { Form, Formik } from "formik";
import { EdowieModal } from "../../../../../../Shared/Modal";
import { getFormInitialValues } from "../../../../../../../Utils/utils";
import { FieldGroup, FieldItem, TextAreaFieldItem } from "../../../../../../Shared/FormHelpers";
import { Message } from "../../../../../../Shared/Message";
import { DELAY_BEFORE_REDIRECT, ServiceType, Status } from "../../../../../../../Utils/types";
import useTimeout from "../../../../../../../hooks/useTimeout";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { particularExperienceService } from "../../../../../../../services/particularExperienceService";
import { groupExperienceService } from "../../../../../../../services/groupExperienceService";

const fieldNames = {
    message: 'message',
};

export const DenunciateModal = (
{ 
    show, 
    userId, 
    denunciatedUserId,
    eventType,
    referenceId,
    eventId, 
    handleSaveDenunciate,
    handleClose
}) => {
    const particularExperienceServiceClient = new particularExperienceService();
    const groupExperienceServiceClient = new groupExperienceService();
    const { t } = useTranslation(['diary', 'validations', 'common']);
    const createTimeout = useTimeout();
    const formRef = useRef();
    const [status, setStatus] = useState('');
    const [error, setError] = useState('');

    const saveDenunciate = async(values) => {

        var response = null;
        
        if (eventType === ServiceType.ParticularExperience) {
            response = await particularExperienceServiceClient.denunciate(eventId, denunciatedUserId, userId, values.message);
        } else {
            response = await groupExperienceServiceClient.denunciate(referenceId, denunciatedUserId, userId, values.message);
        }

        if (response.success) {
            setStatus(Status.Saved);
            createTimeout(async() => {
                setStatus('');
                handleSaveDenunciate();
            }, DELAY_BEFORE_REDIRECT);
        }
        else {
            setStatus(Status.HasError);
            setError(response.error);
        }
    }

    const handleSubmit = () => {
        if (formRef.current) {
            formRef.current.handleSubmit()
        }
    }

    const showMessage = [Status.Saved, Status.HasError].includes(status);

    return (
        <>
            <EdowieModal 
                show={show} 
                handleAccept={() => handleSubmit()} 
                close={() => handleClose()}
                title={t('denunciateModal.title', {ns: 'diary'})}
                modalClass={'diary-qualify-modal'}>
                    <div className="form">
                        <div className="row">
                            <div className="col-lg-12">
                                <Formik innerRef={formRef}
                                    onSubmit={saveDenunciate}
                                    initialValues={getFormInitialValues(fieldNames)}
                                >
                                    {({ isSubmitting, handleSubmit }) => (
                                        <Form>
                                            <fieldset className="form-fields">
                                                <FieldGroup>
                                                    <FieldItem className="field-item">
                                                        <FieldGroup>
                                                            <TextAreaFieldItem
                                                                type="textarea"
                                                                fieldName={fieldNames.message}
                                                                id={fieldNames.message}
                                                                label={`*${t('denunciateModal.messageLabel', {ns: 'diary'})}`}
                                                                required
                                                                placeholder={t('denunciateModal.messagePlaceholder', {ns: 'diary'})}
                                                                className="field-item--100"
                                                            />
                                                        </FieldGroup>
                                                    </FieldItem>
                                                </FieldGroup>
                                            </fieldset>
                                            {showMessage && (
                                                <fieldset>
                                                    <Message
                                                        type={status === Status.Saved ? 'success' : 'error'}
                                                        message={
                                                            status === Status.Saved
                                                                ? t(`successfullyMessage`, {ns: 'common'})
                                                                : error}
                                                    />
                                                </fieldset>
                                            )}
                                        </Form>
                                    )}
                                </Formik>
                            </div>

                        </div>
                    </div>
            </EdowieModal>
        </>
    )
}