import React, { useState } from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import FileUpload from '../../Shared/FileUpload/FileUplodad';
import FileList from '../../Shared/FileList/FileList';
import { faPlayCircle, faFileImage } from '@fortawesome/free-solid-svg-icons'
import './style.scss'
import { activityService } from '../../../services/activityService'
import { sessionManager } from '../../../sessionManager'
import { useTranslation } from 'react-i18next';

export const AddPublication = ({ profilePhoto, username, handleAddPublication }) => {
    const activityServiceClient = new activityService();
    const manager = new sessionManager();

    const [imageFiles, setImageFiles] = useState([]);
    const [imageFileIds, setImageFileIds] = useState([]);

    const [videoFiles, setVideoFiles] = useState([]);
    const [videoFileIds, setVideoFileIds] = useState([]);

    const { t } = useTranslation(['home']);
    const addPublications = t('addPublications');

    const removeVideoFile = (filename) => {
        setVideoFiles(videoFiles.filter(file => file.name !== filename.name));
        setVideoFileIds(videoFileIds.filter(file => file !== filename.id));
    }

    const removeImageFile = (filename) => {
        setImageFiles(imageFiles.filter(file => file.name !== filename.name));
        setImageFileIds(imageFileIds.filter(file => file !== filename.id));
    }

    function validateMessage(value) {
        let error;
        if (!value) {
            error = "Requerido";
        }

        return error;
    }

    async function submitForm(values) {
        const userLoggedIn = JSON.parse(manager.getUserSession());
        const response = await activityServiceClient.insert(userLoggedIn.userId, values["message"], [...videoFileIds, ...imageFileIds]);

        if (response.success) {
            handleAddPublication();
            setVideoFiles([]);
            setImageFiles([]);
            setVideoFileIds([]);
            setImageFileIds([]);
            values["message"] = '';
        }
    };

    return (
        <div className="add-publication">
            <div className="container">
                <div className="form">
                    <Formik
                        initialValues={{
                            message: '',
                        }}
                        onSubmit={async (values) => {
                            submitForm(values);
                        }}
                    >
                        {({ errors, touched, isValidating }) => (
                            <Form>
                                <div className="form-group">
                                    <img src={profilePhoto} alt="user" className="user-photo-small" />
                                    <div className="w-100">
                                        <Field
                                            className={errors.message ? "error-message-input message" : "message"}
                                            id="message"
                                            name="message"
                                            placeholder={addPublications.post}
                                            type="text"
                                            validate={validateMessage}
                                        />
                                        <ErrorMessage className="error-message" name="message" component="div" />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-lg-10 col-sm-8">
                                        <div className="file-upload-container">
                                            <div className="video-file-upload">
                                                <FileUpload files={videoFiles} setFiles={setVideoFiles}
                                                    removeFile={removeVideoFile} fileIcon={faPlayCircle}
                                                    uploadTitle={`${addPublications.video}`} allowedExtensions={".mp4"} username={username}
                                                    handleUploadedFile={(fileId) => { setVideoFileIds([...videoFileIds, fileId]) }}
                                                    allowMultipleFile={true}
                                                    disabled={videoFileIds.length === 4}
                                                />
                                                <FileList files={videoFiles} removeFile={removeVideoFile} />
                                            </div>
                                            <div className="image-file-upload">
                                                <FileUpload files={imageFiles} setFiles={setImageFiles}
                                                    removeFile={removeImageFile} fileIcon={faFileImage}
                                                    uploadTitle={`${addPublications.image}`} allowedExtensions={".png, .jpg, .jpeg"} username={username}
                                                    handleUploadedFile={(fileId) => { setImageFileIds([...imageFileIds, fileId]) }}
                                                    allowMultipleFile={true}
                                                    disabled={imageFileIds.length === 4}
                                                />
                                                <FileList files={imageFiles} removeFile={removeImageFile} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-2 col-sm-4">
                                        <div className="post-send">
                                            <button type="submit" className="btn button">{addPublications.post}</button>
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </div>
    );
}
