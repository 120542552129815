import React, { useState } from 'react';
import { Collapse, Navbar, NavbarBrand, NavbarToggler } from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.scss';
import LogoBeta from '../../general/images/edowie-logo-beta.png'
import '../../general/styles/feather.css'
import { sessionManager } from '../../sessionManager';
import { LanguageSelector } from '../Shared/LanguageSelector';
import { NavButtons } from './NavButtons';
import {isMobile} from 'react-device-detect';
import { ProfileType } from '../../Utils/types';

export const NavMenu =  ({ showNavigation, currentPage, showLanguageSelector }) => {
    const manager = new sessionManager();
    const isUserLoggedIn = manager.isUserLoggedIn();

    const [state, setState] = useState({
        collapsed: true,
        showNavigation: showNavigation,
        currentPage: currentPage
        });

    const toggleNavbar = () => {
        setState({
        collapsed: !state.collapsed,
        showNavigation: showNavigation,
        currentPage: currentPage
        });
    }

    return (
        <header>
            <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow"  light>
                <NavbarBrand 
                    tag={Link} 
                    to={`${isUserLoggedIn ? 
                           manager.getUserLoggedIn().profile !== ProfileType.Administrator ? 
                           '/home' :
                           '/dashboard' : 
                           '/'}`}><img className="logo-empresa" src={LogoBeta} alt="Logo" /></NavbarBrand>
                {showLanguageSelector && (!showNavigation || isMobile) && (
                    <LanguageSelector></LanguageSelector>
                )}
                <NavbarToggler onClick={toggleNavbar} className="mr-2" />
                {state.showNavigation &&
                    <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!state.collapsed} navbar>
                        <NavButtons currentPage={state.currentPage} showLanguageSelector={showLanguageSelector}
                                    isMobile={isMobile}></NavButtons>
                    </Collapse>
                }
        </Navbar>
        </header>
    );
}
