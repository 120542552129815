import './style.scss';
import CeoEdowie from '../../../../general/images/CEO-edowie.jpg';
import { useTranslation } from 'react-i18next';

export const TestimonialSection = () => {
    const { t } = useTranslation(['landing_page']);
    const testimonialSection = t('testimonialSection');

    return (
        <div className="testimonial-section">
            <div className="testimonial-section-container">
                <div className="row gx-5 justify-content-center">
                    <div className="col-lg-10 col-xl-7">
                        <div className="container-body">
                            <div className="title">
                                {`"${testimonialSection.phrase1}`}
                                <br />
                                {`${testimonialSection.phrase2}"`}
                            </div>
                            <div className="ceo-edowie-section">
                                <img className="ceo-edowie-section-icon" src={CeoEdowie} alt="..." />
                                <div className="ceo-edowie-section-user">
                                    Juan Cruz Rodriguez
                                    <span className="ceo-edowie-section-separator">/</span>
                                    CEO, Edowie
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}