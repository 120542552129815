import React from 'react';
import { Footer } from '../components/Shared/Footer/index';
import './Layout.scss';

export const Layout = ({ children }) => {

  return (
      <div>
          <div className="root">
          {children}
        </div>
        <Footer></Footer>
      </div>
    );
}
