import React, { forwardRef, useState, useMemo, useEffect, useRef, useCallback } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import './style.scss';
import es from "date-fns/locale/es";
import { particularExperienceService } from '../../../../services/particularExperienceService';
import LoadingSpinner from '../../../Shared/LoadingSpinner/index';
import { Formik, Form } from 'formik';
import { Hire } from './Hire';
import { Availability } from './Availability';
import { getCurrentTime, getFormInitialValues, getUserLoggedIn } from '../../../../Utils/utils';
import { relationService } from '../../../../services/relationService';
import { DELAY_BEFORE_REDIRECT } from '../../../../Utils/types';
import useTimeout from '../../../../hooks/useTimeout';
import { PurchaseSummary } from './Pay/PurchaseSummary';
import { Message } from '../../../Shared/Message';

registerLocale("es", es);

const DatePickerButton = forwardRef(({ value, onClick }, ref) => (
    <>
        <button className="date-button" onClick={onClick} ref={ref}>
            {value}
        </button>
        <span className="change-week-legend">(Haga clic en la fecha para cambiar la semana)</span>
    </>
));


export const hireFieldNames = {
    event: 'event',
    topicId: 'topicId',
    description: 'description',
    themeId: 'themeId'

};

export const ParticularExperiences = () => {
    const particularExperienceServiceClient = useMemo(() => new particularExperienceService(), []);
    const relationServiceClient = useMemo(() => new relationService(), []);

    const navigate = useNavigate();

    const userLoggedIn = getUserLoggedIn();

    const [startDate, setStartDate] = useState(getCurrentTime(userLoggedIn.offset));
    const [loading, setLoading] = useState(true);
    const [particularExperience, setParticularExperience] = useState(true);
    const [availabilityId, setAvailabilityId] = useState(0);
    const [selectedTopic, setSelectedTopic] = useState({});
    const [selectedEventDate, setSelectedEventDate] = useState({});
    const [hasHiring, setHasHiring] = useState(false);
    const [isFollowing, setIsFollowing] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [successfulPurchase, setSuccessfulPurchase] = useState(false);

    const { id } = useParams();
    const calendarComponentRef = useRef(null);
    const createTimeout = useTimeout();

    const getParticularExperience = useCallback(async () => {
        const particularExperienceResponse = await particularExperienceServiceClient.getByTeacherId(id);

        if (particularExperienceResponse.success) {
            setParticularExperience(particularExperienceResponse.value);

            var hasHiringResponse = await particularExperienceServiceClient.hasHiring(id, userLoggedIn.userId);
            if (hasHiringResponse.success) {
                setHasHiring(hasHiringResponse.value.hasHiring);
            }

            setLoading(false);
        }
    }, [id, particularExperienceServiceClient, userLoggedIn]);


    useEffect(() => {
        const fetchData = async () => {
            getParticularExperience();
        };
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            if (userLoggedIn.userId > 0) {
                var relationResponse = await relationServiceClient.isFollowing(userLoggedIn.userId, id);

                if (relationResponse.success) {
                    setIsFollowing(relationResponse.value);
                }
            }
        };
        fetchData();

    }, [relationServiceClient, setIsFollowing, userLoggedIn.userId, id]);

    const handleChangeDate = (date) => {
        setStartDate(date);
        let calendarApi = calendarComponentRef.current.getApi();
        calendarApi.gotoDate(date);
    }

    const hire = async(values) => {

        if (userLoggedIn.userId > 0) {
            const isFirstClassFree = particularExperience.offerFirstFreeTo === 3 || 
                                     (particularExperience.offerFirstFreeTo === 2 && isFollowing);

            if (isFirstClassFree && !hasHiring) {
                const response = await particularExperienceServiceClient.hire(userLoggedIn.userId, id, availabilityId, values.themeId, values.topicId, selectedTopic, selectedEventDate, true, '');

                if (response.success) {
                    setSuccessfulPurchase(true);
                    createTimeout(() => {
                        setHasHiring(true);
                        setSuccessfulPurchase(false);
                        window.location.replace(`/teacher-profile/${id}/3`);
                    }, DELAY_BEFORE_REDIRECT);
                } else {
                    setHasError(true);
                }
                
            } else {
                navigate(`/particular-experience-pay/${particularExperience.userId}/?availabilityId=${availabilityId}&themeId=${values.themeId}&topicId=${values.topicId}&topic=${selectedTopic}&eventDate='${selectedEventDate}'&hasHiring=${hasHiring}`);
            }
        } else {
            navigate('/register');
        }
    }

    return (
        <>
        {(loading) ? <LoadingSpinner /> : (
            <>
                {particularExperience.particularExperienceId > 0 ? (
                particularExperience.stateId === 5 ? (
                <div className="particular-experiences">
                    <div className="hired-concretized-experience">
                        <span>{particularExperience.reserved} experiencias contratadas / {particularExperience.reserved > 0 ? Math.round(((particularExperience.concretized * 100) / particularExperience.reserved), 0) : 0}% de experiencia concretadas</span>
                    </div>
                    <div className="description-experience">
                        <h4 className="label">{particularExperience.aboutMyParticularExperience}</h4>
                    </div>
                    <div className="date-picker-container">
                        <div className="user-information">
                            <div className="country-logged-user">
                                <div className="container">
                                    <img className="flag" src={userLoggedIn.flag} alt='flag' />
                                    <p className="country">{userLoggedIn.country}</p>
                                </div>
                            </div>
                            <span className="time-use">({userLoggedIn.timeUse})</span>
                        </div>
                        <DatePicker locale='es' selected={startDate} onChange={(date) => handleChangeDate(date)} customInput={<DatePickerButton />}
                            portalId="root-portal" showMonthDropdown showYearDropdown yearDropdownItemNumber={15}
                            scrollableYearDropdown adjustDateOnChange />
                    </div>
                    <Formik
                        onSubmit={hire}
                        initialValues={getFormInitialValues(hireFieldNames)}
                    >
                        {() => (
                            <Form>
                                <fieldset className="form-fields">
                                    <div className="row">
                                        {/* <div className='references'>
                                            <References></References>
                                        </div> */}
                                        <div className="col-sm-8 calendar">
                                            <Availability currentDate={startDate} duration={particularExperience.duration}
                                                          calendarReference={calendarComponentRef}
                                                          handleAvailabilitySelected={(id) => setAvailabilityId(id)}
                                                          advanceHirePeriod={particularExperience.advanceHirePeriod}
                                                          handleSelectedEventDate={(date) => setSelectedEventDate(date)}
                                                          handleChangeDate={(date => handleChangeDate(date))}></Availability>
                                        </div>
                                        <div className='col-sm-4'>
                                            <Hire particularExperience={particularExperience} 
                                                handleSelectedTopic={(topic) => setSelectedTopic(topic)} >
                                            </Hire>
                                            {successfulPurchase && (
                                            <PurchaseSummary message={`Felicitaciones!. Contrataste tu experiencia particular. El evento reservado puede verse en la sección de la agenda.`}></PurchaseSummary>
                                            )}
                                            {hasError &&  (
                                                <fieldset>
                                                    <Message
                                                        type={'error'}
                                                        message={'Ha ocurrido un error al intentar contratar la experiencia particular.'}
                                                    />
                                                </fieldset>
                                            )}  
                                        </div>   
                                    </div>
                                </fieldset>
                            </Form>
                        )}
                    </Formik>
                </div>
                ) : (
                    <div className="particular-experiences">
                        <div className="no-active">
                            <h2 className="title">Edowier sin disponibilidad</h2>
                        </div>
                    </div>
                )
                ) : (
                        <div className="particular-experiences">
                            <div className="no-active">
                                <h2 className="title">Edowier no activo para experiencias particulares</h2>
                        </div>
                    </div>
                )}
            </>)
            }
        </>
    )
}
