import { faVideo, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CancelModal } from './CancelModal';
import { useState } from 'react';

export const FutureEventButtons = ({
    userId,
    edowierUserId,
    eventId,
    conferenceLink,
    canCancel,
    canConnect,
    handleCancel
}) => {

    const [showCancelModal, setShowCancelModal] = useState(false);

    return (
        <>
            {canCancel && (
                <>
                    <button className="diary-container-event-section-buttons-container-cancel"
                            onClick={() => { setShowCancelModal(true) }}>
                        <FontAwesomeIcon icon={faXmark} />
                    </button>
                    <CancelModal 
                        show={showCancelModal} 
                        userId={userId} 
                        edowierUserId={edowierUserId} 
                        eventId={eventId} 
                        handleCancel={() => { handleCancel(); setShowCancelModal(false);}}
                        handleClose={() => setShowCancelModal(false)}
                    >
                    </CancelModal>
                </>
            )}

            {canConnect && (
                <a className="diary-container-event-section-buttons-container-conference" 
                    href={conferenceLink} target="_blank" rel="noreferrer">
                    <FontAwesomeIcon icon={faVideo} />
                </a>
            )}
        </>
    )
}