import React, { useCallback, useEffect, useState } from 'react';
import './style.scss';
import { getUserLoggedIn } from '../../../Utils/utils';
import { userService } from '../../../services/userService';
import { Status } from '../../../Utils/types';
import useTimeout from '../../../hooks/useTimeout';
import { Message } from '../../Shared/Message';
import LoadingSpinner from '../../Shared/LoadingSpinner';
import { useTranslation } from 'react-i18next';

export const Donations = () => {
    const { t } = useTranslation();
    const userLoggedIn = getUserLoggedIn();
    const [user, setUser] = useState([]);
    const [loading, setLoading] = useState(true);
    const [status, setStatus] = useState('');
    const [error, setError] = useState('');
    const createTimeout = useTimeout();

    const getUserDetails = useCallback(async () => {
        userService.card(userLoggedIn.userId)
            .then(response => {
                setUser(response);
                setLoading(false);
            }
        );

    }, [userLoggedIn.userId]);

    useEffect(() => {
        const fetchData = async () => {
            getUserDetails();
        };
        fetchData();

    }, [getUserDetails]);

    const activate = async() => {
        setStatus('');
        userService.activateDonations(userLoggedIn.userId)
            .then(response => {
                setStatus(Status.Saved);
                createTimeout(async() => {
                    setStatus('');
                    getUserDetails();
                }, 1000);
            })
            .catch(error => {
                setStatus(Status.HasError);
                setError(error);
            });
    }

    const deactivate = async() => {
        setStatus('');
        userService.deactivateDonations(userLoggedIn.userId)
            .then(response => {
                setStatus(Status.Saved);
                createTimeout(async() => {
                    setStatus('');
                    getUserDetails();
                }, 1000);
            })
            .catch(error => {
                setStatus(Status.HasError);
                setError(error);
            })
    }

    const showMessage = [Status.Saved, Status.HasError].includes(status);

    return (
        <>
            {loading ? <LoadingSpinner /> : (
            <div className="manage-service-donations">
                <h2 className="donations-title">
                    {`${user.allowDonations? t('manage_services.donations.deactivate_legend') : t('manage_services.donations.activate_legend')}`}
                </h2>
                <div className="donations-activate-deactivate">
                    <button className={`button donations-${user.allowDonations ? 'deactivate' : 'activate'}-button`} 
                            onClick={() => user.allowDonations ? deactivate() : activate()}>
                                {`${user.allowDonations ? t('manage_services.donations.deactivate_button') : t('manage_services.donations.activate_button')}`}
                    </button>
                </div>
                {showMessage && (
                <Message
                    type={status === Status.Saved ? 'success' : 'error'}
                    message={
                        status === Status.Saved
                            ? t('manage_services.donations.successful_message')
                            : error}
                />
                )}
            </div>)}
        </>
    )
}