import { sessionManager } from '../../../sessionManager';
import { useCookies } from "react-cookie";

export const Exit = () => {
    const manager = new sessionManager();
    const [, removeCookie] = useCookies();

    manager.setUserSession(null);
    removeCookie('Perfil', '', { path: '/', domain: '.edowie.com' });
    removeCookie("User.Id", '', { domain: '.edowie.com', path: '/' });

    window.location.href = '/';
}