import { useFormikContext } from "formik";
import { DefaultOption, Durations, OfferFirstFreeTo, OfferServiceTo, TimeAdvanceCancel, TimeAdvanceContract } from "../../../../../Utils/types"
import { FieldGroup, FieldItem, InputFieldItem, SelectFieldItem, SubmitButton, TextAreaFieldItem } from "../../../../Shared/FormHelpers"
import { useCallback, useEffect, useState } from "react";
import { hireFieldNames} from '../index';
import './style.scss';
import { sessionManager } from "../../../../../sessionManager";

export const Hire = ({ particularExperience, handleSelectedTopic, readyToPay, successfulPurchase }) => {
    const manager = new sessionManager();

    const [generalConditions, setGeneralConditions] = useState({});
    const { isSubmitting, setFieldValue, values, setFieldTouched } = useFormikContext();
    const [themes, setThemes] = useState([]);
    const [topics, setTopics] = useState([]);
    
    const userLoggedIn = JSON.parse(manager.getUserSession());

    const mapTheme = useCallback((data) => {
        return { key: data.themeId, value: data.subcategory };
    }, [])

    const mapTopic = (data) => {
        return { key: data.topicId, value: data.topic };
    }

    useEffect(() => {
        const fetchData = async () => {
            setGeneralConditions(
                {
                    duration: Durations.filter(o => o.key === particularExperience.duration.toString())[0].value,
                    offerFirstFreeTo: OfferFirstFreeTo.filter(o => o.key === particularExperience.offerFirstFreeTo.toString())[0].value.toUpperCase(),
                    timeAdvanceCancel: TimeAdvanceCancel.filter(o => o.key === particularExperience.advanceCancellationPeriod.toString())[0].value,
                    timeAdvanceContract: TimeAdvanceContract.filter(o => o.key === particularExperience.advanceHirePeriod.toString())[0].value,
                    offerServiceTo: OfferServiceTo.filter(o => o.key === particularExperience.offerServiceTo.toString())[0].value.toUpperCase(),
                }
            );

            setThemes(particularExperience.themes.map(mapTheme));
            setFieldValue(hireFieldNames.topicId, '');
            setFieldValue(hireFieldNames.description, '');
            setFieldValue(hireFieldNames.themeId, '');
            setFieldValue(hireFieldNames.event, '');

            setTimeout(() => setFieldTouched(hireFieldNames.topicId, false));
            setTimeout(() => setFieldTouched(hireFieldNames.description, false));
            setTimeout(() => setFieldTouched(hireFieldNames.themeId, false));
            setTimeout(() => setFieldTouched(hireFieldNames.event, false));
        };
        fetchData();

    }, [particularExperience.duration, particularExperience.offerFirstFreeTo, particularExperience.advanceCancellationPeriod, 
        particularExperience.advanceHirePeriod, particularExperience.offerServiceTo, particularExperience.themes, 
        mapTheme, setFieldValue, setFieldTouched]);

    const changeTheme = async (e) => {
        const theme = e.target.value;
        setFieldValue(hireFieldNames.themeId, theme);

        var themeFiltered = particularExperience.themes.filter(t => t.themeId.toString() === theme);
        if (themeFiltered.length > 0) {
            setTopics(themeFiltered[0].topics.map(mapTopic));
        } else {
            setTopics([]);
        }

        setFieldValue(hireFieldNames.topicId, '');
        setFieldValue(hireFieldNames.description, '');
    };

    const changeTopic = async (e) => {
        const topic = e.target.value;
        setFieldValue(hireFieldNames.topicId, topic);

        var themeFiltered = particularExperience.themes.filter(t => t.themeId.toString() === values.themeId);
        if (themeFiltered.length > 0) {
            const topicFiltered = themeFiltered[0].topics.filter(t => t.topicId.toString() === topic);

            if (topicFiltered.length > 0) {
                setFieldValue(hireFieldNames.description, topicFiltered[0].description);
            } else {
                setFieldValue(hireFieldNames.description, '');
            }

            handleSelectedTopic(topicFiltered[0].topic);
        } 
    };

    return (
        <div className="hire-section">
            <div className="hire">
                <h2 className="title">Precio Experiencia</h2>
                <h1 className="price">USD {particularExperience.price}</h1>

                <h4 className="option">
                    <i className="ti-check"></i>
                    Primera experiencia gratuita a {generalConditions.offerFirstFreeTo}
                </h4>
                <h4 className="option">
                    <i className="ti-check mr-2 text-white"></i>
                    Duración de la experiencia: {generalConditions.duration}
                </h4>
                <h4 className="option">
                    <i className="ti-check mr-2 text-white"></i>
                    Plazo para que el edowier cancele: hasta {generalConditions.timeAdvanceCancel} antes
                </h4>
                <h4 className="option">
                    <i className="ti-check mr-2 text-white"></i>
                    Plazo para que el usuario contrate: hasta {generalConditions.timeAdvanceContract} antes
                </h4>
                <h4 className="option">
                    <i className="ti-check mr-2 text-white"></i>
                    Ofrece experiencia a {generalConditions.offerServiceTo}
                </h4>
                    
                <FieldGroup>
                    <FieldItem className="field-item">
                        <FieldGroup>
                            <SelectFieldItem
                                fieldName={hireFieldNames.themeId}
                                id={hireFieldNames.themeId}
                                defaultOption={DefaultOption}
                                values={themes}
                                required={true}
                                className="field-item--100"
                                onChange={(e) => {
                                    changeTheme(e);
                                }}
                            />
                            <SelectFieldItem
                                fieldName={hireFieldNames.topicId}
                                id={hireFieldNames.topicId}
                                defaultOption={DefaultOption}
                                values={topics}
                                required={true}
                                className="field-item--100"
                                onChange={(e) => {
                                    changeTopic(e);
                                }}
                            />
                            <TextAreaFieldItem
                                type="textarea"
                                fieldName={hireFieldNames.description}
                                placeholder={''}
                                className="field-item--100"
                                required={true}
                                readOnly={true}
                            />
                            <InputFieldItem
                                type="text"
                                fieldName={hireFieldNames.event}
                                id={hireFieldNames.event}
                                required
                                placeholder={'Selecciona un turno disponible'}
                                className="field-item--100"
                                readOnly={true}
                            />
                            {userLoggedIn.userId !== particularExperience.userId && (
                            <SubmitButton className={'hire-button'} isSubmitting={isSubmitting}>
                                {'Contratar'}
                            </SubmitButton>
                            )}
                        </FieldGroup>
                    </FieldItem>
                </FieldGroup>
            </div>
        </div>
    )
}