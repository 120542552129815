import { useTranslation } from 'react-i18next';
import { ServiceType } from '../../../../Utils/types';
import { InjectAppServices } from '../../../../services/dependency-injection';
import './style.scss';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { EdowieModal } from '../../../Shared/Modal';
import { useState } from 'react';

export const ServiceItem = InjectAppServices(
    ({
        service,
        handleRemovePromocodes,
        dependencies: { servicePromocodeServiceClient }
    }) => {

    const { t } = useTranslation(['manage_services']);

    const [showModal, setShowModal] = useState(false);

    const deletePromocodes = () => {
        servicePromocodeServiceClient.delete(service.servicePromocodeId)
            .then((response) => {
                handleRemovePromocodes();
                }
            );
    }
    
    return (
        <div className="service-promocodes-service-item">
            <div className="service-promocodes-service-item-cover-image">
                <img src={service.coverImage.path} alt={service.title} />
            </div>
            <div className="service-promocodes-service-item-details">
                <span className={`service-promocodes-service-item-details-tag 
                                  ${service.serviceType === ServiceType.Course ? 
                                  'service-promocodes-service-item-details-course-tag' : 
                                  service.serviceType === ServiceType.Resource ? 
                                  'service-promocodes-service-item-details-resource-tag' : 
                                  'service-promocodes-service-item-details-group-tag'}`}>
                    {service.serviceType === ServiceType.Course ?
                     t('promocodes.courseTag', {ns: 'manage_services'}) :
                     service.serviceType === ServiceType.Resource ?
                     t('promocodes.resourceTag', {ns: 'manage_services'}) :
                     t('promocodes.groupTag', {ns: 'manage_services'})}
                </span>
                <h4 className="service-promocodes-service-item-details-title">
                    {service.title}
                </h4>
                <div className="service-promocodes-service-item-details-promocodes-tags">
                    {service.codes.map((code) => (
                        code.used ? (
                            <span key={code.code}
                              className={`service-promocodes-service-item-details-promocodes-tags-tag service-promocodes-service-item-details-promocodes-tags-tag-used`}>
                            {code.code}
                            </span>
                        )
                        : (
                        <CopyToClipboard key={code.code} text={code.code}>
                        <span key={code.code} title={t('copyCodeTooltip', {ns: 'promocodes'})} 
                              className={`service-promocodes-service-item-details-promocodes-tags-tag`}>
                            {code.code}
                        </span>
                        </CopyToClipboard>)
                    ))}
                </div>
                <div className='service-promocodes-service-item-details-promocodes-discount-delete-section'>
                    <div className='show-discount'>{service.discount}% OFF</div>
                    <div className='delete-button-section'>
                        <button onClick={() => { setShowModal(true) }} id="eliminar" className="delete-button-section-delete-button" title={t('removeTooltip')}>
                            <i><FontAwesomeIcon icon={faTrash} /></i>
                        </button>
                    </div>
                </div>
                <EdowieModal show={showModal} title={t('deletePromotionsCodeTitle', {ns: 'promocodes'})}
                        close={() => { setShowModal(false) }} 
                        handleAccept={() => {  deletePromocodes(); }}
                        modalClass={'delete-promotions-code-modal'}>
                    <div className="activity-item-content">{t('deletePromotionsCodeLegend', {ns: 'promocodes'})}</div>  
                </EdowieModal>
            </div>
        </div>
    )
})