import React from 'react';
import ReactPaginate from 'react-paginate';
import 'bootstrap/dist/css/bootstrap.css';
import './style.scss';
import { ReviewStars } from '../../../Shared/ReviewStars/index';
import EmabajadorIcon from '../../../../general/images/embajador.png'

function Items({ currentItems }) {
    return (
        <>
            {currentItems.length > 0 ? 
            (currentItems.map((particularExperience) => (
              <div key={particularExperience.particularExperienceId} className="col-xl-6 col-lg-12">
                  <div className="card-body">
                      <div className="row">
                        <div className="col-sm-3">
                            <div className="card-user">
                                <a href={particularExperience.link}>
                                    <div className='card-user-section'>
                                        <h2 className="card-user-username">{particularExperience.username}</h2>
                                        {particularExperience.userIsAmbassador && (
                                            <img src={EmabajadorIcon} className="ambassador" title="Embajador" alt='Embajador'></img>
                                        )}
                                    </div>
                                    <div className="card-user-timezone">
                                        <img src={particularExperience.countryFlag} className="card-user-timezone-flag" alt='flag' />
                                        <div className="card-user-timezone-country">
                                            <h4 className="country">{particularExperience.country}</h4>
                                            <h4 className="timezone">{particularExperience.timeZone}</h4>
                                        </div>
                                    </div>
                                    <figure className="card-user-profile-image">
                                        <img src={particularExperience.profilePhoto.path} alt="user" />
                                    </figure>
                                    <div className="mt-3 mb-3">
                                        <div className="card-user-relations">
                                            <div className="card-user-relations-details followers">
                                                <div className="number">{particularExperience.followers}</div>
                                                <div className="title">Seguidores</div>
                                            </div>
                                            <div className="card-user-relations-details">
                                                <div className="number">{particularExperience.following}</div>
                                                <div className="title">Seguidos</div>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                          </div>
                          <div className="col-sm-9">
                              <div className="card-particular-experience-details">
                                  <div className="row">
                                      <div className="col-sm-9 pr-0">
                                          <div className="tags">
                                              {particularExperience.tags.split(',').map((tag) => (
                                                  <span key={tag} className={`tag particular-experience-tag`}>
                                                      {tag}
                                                  </span>
                                              ))}
                                          </div>
                                      </div>
                                      <div className="col-sm-3">
                                          <div className="qualification">
                                              <ReviewStars averageQualifications={particularExperience.averageReview}></ReviewStars>
                                              <p className="reviews">({particularExperience.reviews})</p>
                                          </div>
                                      </div>
                                  </div>
                                  <div className="row">
                                        <div className="col-12">
                                            <span className="item">Duración {particularExperience.duration} min</span>
                                            <span className="dot"></span>
                                            <span className="item">{particularExperience.reserved} contratadas - {particularExperience.concretized}% concretadas</span>
                                            <span className="dot"></span>
                                            {particularExperience.stateId === 5 ? (
                                                <span className="item">Disponibles (7d): {particularExperience.available}</span>
                                            ) : (
                                                <span className="item">Sin disponibilidad</span>
                                            )}
                                        </div>
                                    </div>

                                    {(particularExperience.offerFirstFreeTo === 2 || particularExperience.offerFirstFreeTo === 3) && (
                                    <h6 className="free-class">{particularExperience.offerFirstFreeTo === 2 ? "Primera gratis a seguidores" : "Primera gratis"}</h6>
                                    )}
                                    <h4 className="about-me-particular-experience">{particularExperience.aboutMeParticularExperience}</h4>
                                    <div className="row">
                                        <div className="col-12 price">
                                            <a href={particularExperience.link}>
                                                <div className="button">USD {particularExperience.price}</div>
                                            </a>
                                        </div>
                                    </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
            ))) : (
                <div className="card-body">
                    <span className='no-data-to-show'>No hay información para mostrar</span>
                </div>
            //)}
          )}
        </>
    );
  }

export const PaginatedItems = ({ items, totalItems, itemsPerPage, currentPage, handlePageClick }) => {
    const pageCount = Math.ceil(totalItems / itemsPerPage);
  
    return (
      <>
        <Items currentItems={items} />
        <ReactPaginate
          nextLabel=">"
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          marginPagesDisplayed={2}
          pageCount={pageCount}
          previousLabel="<"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakLabel="..."
          breakClassName="page-item"
          breakLinkClassName="page-link"
          containerClassName="pagination"
          activeClassName="active"
          renderOnZeroPageCount={null}
          forcePage={currentPage}
        />
      </>
    );
  }