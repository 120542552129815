import React, { useState, useEffect, useMemo, useRef } from 'react';
import './style.scss';
import { Form, Formik } from 'formik';
import { FieldGroup, FieldItem, SelectFieldItem } from '../../../../../../Shared/FormHelpers/index';
import { categoryService } from '../../../../../../../services/categoryService';
import { subcategoryService } from '../../../../../../../services/subcategoryService';
import { particularExperienceService } from '../../../../../../../services/particularExperienceService';
import { sessionManager } from '../../../../../../../sessionManager';
import { DELAY_BEFORE_REDIRECT, DefaultOption, Status } from '../../../../../../../Utils/types';
import { getFormInitialValues } from '../../../../../../../Utils/utils';
import { Topics } from './Topics/index';
import { Message } from '../../../../../../Shared/Message';
import useTimeout from '../../../../../../../hooks/useTimeout';
import { EdowieModal } from '../../../../../../Shared/Modal';
import { useTranslation } from 'react-i18next';

const fieldNames = {
    categoryId: 'categoryId',
    subcategoryId: 'subcategoryId',
};

export const ThemeForm = ({canDelete, theme, isEditable, handleSave, handleCancel, handleEditing}) => {
    const { t } = useTranslation();
    const categoryServiceClient = useMemo(() => new categoryService(), []);
    const subcategoryServiceClient = useMemo(() => new subcategoryService(), []);
    const particularExperienceServiceClient = new particularExperienceService();
    const manager = new sessionManager();
    const userLoggedIn = JSON.parse(manager.getUserSession());

    const [status, setStatus] = useState('');
    const [error, setError] = useState('');
    const [categories, setCategories] = useState([]);
    const [subcategories, setSubcategories] = useState([]);
    const [hasTopic, setHasTopic] = useState(theme.topics.length > 0);
    const [isEditing, setIsEditing] = useState(isEditable);
    const [topics, setTopics] = useState(theme.topics);
    const [showModal, setShowModal] = useState(false);

    const createTimeout = useTimeout();
    const formRef = useRef();
    const showMessage = [Status.Saved, Status.HasError].includes(status);

    useEffect(() => {
        const fetchData = async () => {
            var categoriesResponse = await categoryServiceClient.getAll();

            if (categoriesResponse.success) {
                const values = categoriesResponse.value.map(function (item) {
                    return { key: item.categoryId, value: item.name };
                });

                setCategories(values);
            }
        };
        fetchData();

    }, [categoryServiceClient]);

    useEffect(() => {
        const fetchData = async () => {
            const response = await subcategoryServiceClient.getAllByCategoryId(theme.categoryId);

            if (response.success) {
                const values = response.value.map(function (item) {
                    return { key: item.subcategoryId, value: item.name };
                });

                setSubcategories(values);
            }
        };
        fetchData();

    }, [subcategoryServiceClient, theme.categoryId]);


    const changeCategory = async (e, setFieldValue) => {
        const category = e.target.value;
        setFieldValue(fieldNames.categoryId, category);
        const result = await subcategoryServiceClient.getAllByCategoryId(category);
        setSubcategories(result.success ? result.value.map(function (item) {
            return { key: item.subcategoryId, value: item.name };
        }) : []);
        setFieldValue(fieldNames.subcategoryId, '');
    };

    const _getFormInitialValues = () => {
        let initialValues = getFormInitialValues(fieldNames);

        if (theme.themeId > 0) {
            initialValues = { ...theme };
        }
        
        return initialValues;
    };

    const handleHasTopic = (value) => {
        setTopics(value);
        setHasTopic(value.length > 0);
    }

    const handleSubmit = () => {
        if (hasTopic) {
            if (formRef.current) {
                formRef.current.handleSubmit()
            }
        }
    }

    const saveTheme = async(values) => {
        setStatus(Status.Saving);

        var response = await particularExperienceServiceClient.saveTheme(userLoggedIn.userId, theme.themeId, values, topics);

        if (response.success) {
            setStatus(Status.Saved);
            createTimeout(() => {
                setStatus('');
                handleSave();
            }, DELAY_BEFORE_REDIRECT);
        } else {
            setStatus(Status.HasError);
            setError(response.error);
        }
    }

    const deleteTheme = async() => {
        var response = await particularExperienceServiceClient.deleteTheme(theme.particularExperienceId, theme.themeId);

        if (response.success) {
            createTimeout(() => {
                setStatus('');
                handleSave();
            }, DELAY_BEFORE_REDIRECT);
        } else {
            setStatus(Status.HasError);
           // setError(response.error);
        }
    }

    return (
        <>
        <div className='theme-form'>
            <Formik innerRef={formRef}
                onSubmit={saveTheme}
                initialValues={_getFormInitialValues()}
            >
                {({ setFieldValue, handleSubmit }) => (
                    <div className="row">
                        <div className="col-lg-6">
                            <Form>
                                <fieldset className="form-fields">
                                    <FieldGroup>
                                        <FieldItem className="field-item">
                                            <FieldGroup>
                                                <SelectFieldItem
                                                    fieldName={fieldNames.categoryId}
                                                    id={fieldNames.categoryId}
                                                    label={`*${t('manage_services.particular.themes_step.category.label')}`}
                                                    required
                                                    defaultOption={DefaultOption}
                                                    values={categories}
                                                    className="field-item--100"
                                                    disabled={!isEditing}
                                                    onChange={(e) => {
                                                        changeCategory(e, setFieldValue);
                                                    }}
                                                />
                                                <SelectFieldItem
                                                    fieldName={fieldNames.subcategoryId}
                                                    id={fieldNames.subcategoryId}
                                                    label={`*${t('manage_services.particular.themes_step.subcategory.label')}`}
                                                    defaultOption={DefaultOption}
                                                    required
                                                    values={subcategories}
                                                    className="field-item--100"
                                                    disabled={!isEditing}
                                                />
                                            </FieldGroup>
                                        </FieldItem>
                                    </FieldGroup>
                                </fieldset>
                            </Form>
                        </div>
                        <div className='col-lg-6'>
                            <div className="buttons-section">
                            {!isEditing && (
                                <>
                                    <button className='button edit-button' onClick={() => { setIsEditing(true); handleEditing();}}>{t('manage_services.particular.themes_step.edit_theme_button')}</button>
                                    {canDelete && (
                                        <>
                                            <button className='button remove-button' onClick={() => setShowModal(true)}>{t('manage_services.particular.themes_step.delete_theme_button')}</button>
                                            <EdowieModal show={showModal} title={t('manage_services.particular.themes_step.delete_theme_modal.title')} modalClass={'theme-form-modal'}
                                                close={() => { setShowModal(false) }} handleAccept={() => { deleteTheme() }}>
                                                <div className="activity-item-content">{t('manage_services.particular.themes_step.delete_theme_modal.legend')}</div>
                                            </EdowieModal>
                                        </>
                                    )}
                                </>
                            )}
                            </div>
                        </div>
                    </div>
                )}
            </Formik>
                
        </div>
        <Topics topicsAdded={theme.topics} isEditable={isEditing} handleAddRemoveTopic={handleHasTopic} showTopicsError={!hasTopic}></Topics>

        { isEditing && (
            <div className='theme-save-section'>
                <button type='button' className='theme-save-section-button theme-save-section-save-button' onClick={() => handleSubmit()}>
                    {'Guardar'}
                </button>
                <button className="theme-save-section-button theme-save-section-cancel-button" onClick={() => { setIsEditing(false); handleCancel();}}>
                    Cancelar
                </button>
            </div>
        )}
        {showMessage && (
            <Message
                type={status === Status.Saved ? 'success' : 'error'}
                message={
                    status === Status.Saved
                        ? '¡Operación procesada con éxito!'
                        : error}
            />
        )}
        </>
    )
}