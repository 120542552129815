import { useEffect, useState } from "react";
import { InjectAppServices } from "../../../services/dependency-injection";
import { NavMenu } from "../../Menu/NavMenu";
import { UserCard } from "./UserCard";
import './style.scss';
import { userService } from "../../../services/userService";
import { ItemCard } from "./ItemCard";
import LoadingSpinner from "../../Shared/LoadingSpinner";
import { SalesHistory } from "./SalesHistory";

export const Dashboard = InjectAppServices(
    ({
        dependencies: { sessionManager, administratorServiceClient }
    }) => {

        const userLoggedIn = sessionManager.getUserLoggedIn();
        const [state, setState] = useState({ profilePhoto: '', profile: '', username: '' });
        const [loading, setLoading] = useState(true);
        const [dashboard, setDashboard] = useState({ items: [] });

        useEffect(() => {
            const fetchData = async () => {
                userService.card(userLoggedIn.userId)
                    .then(response => {
                        setState(response);
                        setLoading(false);
                    })
                    .catch(() => {
                        setLoading(false);
                    });
            };
            fetchData();
    
        }, [userLoggedIn.userId]);

        useEffect(() => {
            const fetchData = async () => {
                administratorServiceClient.getDashboard(userLoggedIn.userId)
                    .then(response => {
                        setDashboard(response);
                        setLoading(false);
                    })
                    .catch(() => {
                        setLoading(false);
                    });
            };
            fetchData();
    
        }, [administratorServiceClient, userLoggedIn.userId]);

        return (
            <>
                {loading ? <LoadingSpinner /> : (
                <>
                    <NavMenu showNavigation={true} currentPage={'dashboard'} />
                    <div className="dashboard-section row">
                        <div className="dashboard-section-sidebar-left col-xs-12 col-lg-3">
                            <UserCard card={state}></UserCard>
                        </div>
                        <div className="dashboard-section-sidebar-right col-xs-12 col-lg-6">
                            <div className="dashboard-section_sidebar-right-main-section">
                                <h1 className="dashboard-section_sidebar-right-main-section-title">
                                    Dashboard
                                </h1>
                                <div className="row">
                                    {dashboard && dashboard.items.map((item) => (
                                        <div key={item.name} className="col-xs-1 col-lg-3">
                                            <ItemCard item={item}></ItemCard>
                                        </div>
                                    ))}
                                </div>
                                <div className="row">
                                    <div className="col-xs-1 col-lg-12">
                                        <SalesHistory></SalesHistory>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-lg-3">
                            Ultimas busquedas
                        </div>
                    </div>
                </>
                )}
            </>
        )
    }
)