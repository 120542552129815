import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { ReviewStars } from '../../../../Shared/ReviewStars/index';
import './style.scss';
import { contentService } from '../../../../../services/contentService';
import { ServiceType } from '../../../../../Utils/types';
import { QualificationModal } from '../../../../Shared/QualificationModal';
import { sessionManager } from '../../../../../sessionManager';

const ImageContainer = styled.div`
  background-image: url(${props => props.image});background-size: cover;background-position: center center;height: 322px;`;

export const Header = ({ content, userId, contentType, wasAdquired }) => {
    const contentServiceClient = useMemo(() => new contentService(), []);
    const manager = new sessionManager();

    const isUserLoggedIn = manager.isUserLoggedIn();
    const [inFavorities, setInFavorties] = useState(false);

    const [showModal, setShowModal] = useState(false);
    const showFavoritesButton = !wasAdquired && (userId !== content.userId) && isUserLoggedIn;

    useEffect(() => {
        const fetchData = async () => {
            if (isUserLoggedIn) {
                var hasInFavoritesResponse = await contentServiceClient.hasInFavorites(userId, content.contentId, contentType);

                if (hasInFavoritesResponse.success) {
                    setInFavorties(hasInFavoritesResponse.value);
                }
            }
        };
        fetchData();

    }, [contentServiceClient, setInFavorties, userId, content.contentId, contentType, isUserLoggedIn]);

    const showRatings = () => {
       setShowModal(true);
    }

    const addToFavorites = async () => {
        var addToFavoritesResponse = await contentServiceClient.addFavorites(userId, content.contentId, parseInt(contentType));

        if (addToFavoritesResponse.success) {
            setInFavorties(true);
        }
    }

    const deleteToFavorites = async () => {
        var deleteToFavoritesResponse = await contentServiceClient.deleteFavorites(userId, content.contentId, parseInt(contentType));

        if (deleteToFavoritesResponse.success) {
            setInFavorties(false);
        }
    }

    return (
        <ImageContainer className="header" image={content.coverImage.path}>
            <div className="card">
                <div className="tags-favorites">
                    <div className="tags">
                        {content.tags.split(',').map((tag) => (
                            <span key={tag} className={`tag ${parseInt(contentType) === ServiceType.Course ? 'course-tag' : 'resource-tag'}`}>
                                {tag}
                            </span>
                        ))}
                    </div>
                    <div className="favorites-content">
                        {showFavoritesButton && (<button
                            title={`${inFavorities ? 'Quitar de' : 'Agregar a'} favoritos`}
                            className={`button ${inFavorities ? 'delete-favorities' : 'add-favorties'}`}
                            onClick={() => {
                                if (inFavorities) {
                                    deleteToFavorites();
                                } else {
                                    addToFavorites();
                                }
                            }}
                        >
                            <i className="feather-bookmark"></i>
                        </button>
                        )}
                    </div>
                </div>
                <h2 className="title">{content.title}</h2>
                {parseInt(contentType) === ServiceType.Course && content.subtitle !== '' && (
                <p className="subtitle">{content.subtitle}</p>
                )}
                <span className="labels">{content.labels}</span>
                <button onClick={() => showRatings()} className="show-ratings">
                    <div className="qualification">
                        <ReviewStars averageQualifications={content.averageReview}></ReviewStars>
                        <p className="reviews">{content.reviews} Reseña/s.</p>
                        <QualificationModal 
                            showModal={showModal} 
                            serviceId={content.contentId}
                            serviceType={contentType}
                            handleAccept={() => setShowModal(false)}
                            handleClose={() => setShowModal(false)}></QualificationModal>
                    </div>
                </button>
                <span className="acquired-by">{`Adquirido por ${content.acquiredQuantity} usuario/s`}</span>
            </div>
        </ImageContainer>
    )
}