import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import moment from 'moment';
import messages_es from './es';
import messages_en from './en';
import validations_es from './es/validations.json';
import validations_en from './en/validations.json';
import login_es from './es/login.json';
import login_en from './en/login.json';
import landing_page_es from './es/landing_page.json';
import landing_page_en from './en/landing_page.json';
import footer_es from './es/footer.json';
import footer_en from './en/footer.json';
import register_es from './es/register.json';
import register_en from './en/register.json';
import common_es from './es/common.json';
import common_en from './en/common.json';
import terms_and_conditions_es from './es/terms_and_conditions.json';
import terms_and_conditions_en from './en/terms_and_conditions.json';
import menu_es from './es/menu.json';
import menu_en from './en/menu.json';
import home_es from './es/home.json';
import home_en from './en/home.json';
import favorite_es from './es/favorite.json';
import favorite_en from './en/favorite.json';
import manage_services_es from './es/manageServices.json';
import manage_services_en from './en/manageServices.json';
import discount_es from './es/discount.json';
import discount_en from './en/discount.json';
import countries_es from './es/countries.json';
import countries_en from './en/countries.json';
import promocodes_es from './es/promocodes.json';
import promocodes_en from './en/promocodes.json';
import diary_es from './es/diary.json';
import diary_en from './en/diary.json';
import balance_es from './es/balance.json';
import balance_en from './en/balance.json';
import transaction_state_es from './es/transaction_state.json';
import transaction_state_en from './en/transaction_state.json';
import my_purchases_es from './es/my_purchases.json';
import my_purchases_en from './en/my_purchases.json';

const resources = {
    en: {
      translation: messages_en,
      validations: validations_en,
      login: login_en,
      landing_page: landing_page_en,
      footer: footer_en,
      register: register_en,
      common: common_en,
      terms_and_conditions: terms_and_conditions_en,
      menu: menu_en,
      home: home_en,
      favorite: favorite_en,
      manage_services: manage_services_en,
      discount: discount_en,
      countries: countries_en,
      promocodes: promocodes_en,
      diary: diary_en,
      balance: balance_en,
      transaction_state: transaction_state_en,
      my_purchases: my_purchases_en
    },
    es: {
      translation: messages_es,
      validations: validations_es,
      login: login_es,
      landing_page: landing_page_es,
      footer: footer_es,
      register: register_es,
      common: common_es,
      terms_and_conditions: terms_and_conditions_es,
      menu: menu_es,
      home: home_es,
      favorite: favorite_es,
      manage_services: manage_services_es,
      discount: discount_es,
      countries: countries_es,
      promocodes: promocodes_es,
      diary: diary_es,
      balance: balance_es,
      transaction_state: transaction_state_es,
      my_purchases: my_purchases_es
    },
  };

  const supportsMultipleLanguages = process.env.REACT_APP_SUPPORTS_MULTIPLE_LANGUAGE;

  if (supportsMultipleLanguages === 'true') {
    i18n.use(LanguageDetector).use(initReactI18next).init({
      resources,
      fallbackLng: "es",
      debug: true,
      returnObjects: true,
      interpolation: {
          escapeValue: false,
          format: function(value, format, lng) {
              if (format === 'uppercase') return value.toUpperCase();
              if(value instanceof Date) {
                  moment.locale(i18n.language);
                  return moment(value).format(format);
              } 
              return value;
          }
      },
    });
} else {
  i18n.init({
    resources,
    lng: "es",
    fallbackLng: "es",
    debug: true,
    returnObjects: true,
    interpolation: {
        escapeValue: false,
        format: function(value, format, lng) {
            if (format === 'uppercase') return value.toUpperCase();
            if(value instanceof Date) {
                moment.locale(i18n.language);
                return moment(value).format(format);
            } 
            return value;
        }
    },
  });
}

export function setLanguage(language) {
  i18n.changeLanguage(language);
}