import React, { useState, useMemo, useEffect } from 'react';
import './style.scss';
import { activityService } from '../../../../../services/activityService';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { EdowieModal } from '../../../../Shared/Modal/index'
import ReactPlayer from 'react-player'
import { faThumbsUp, faThumbsDown, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { sessionManager } from '../../../../../sessionManager';
import * as Icon from 'react-bootstrap-icons';
import ReactPaginate from 'react-paginate';
import { UserLikesModal } from './UserLikesModal';

export const Item = ({ attachment }) => {
    return (
        <>
        {(attachment.extension !== 'mp4' && attachment.extension !== 'mov') ? ( 
            <div className='my-slide' >
                <img src={attachment.attachment} alt="attachment" />
            </div >
        ) : (
            <div className='my-slide'>
                <ReactPlayer
                    url={attachment.attachment}
                    className='react-player'
                    width='100%'
                    height='100%'
                    controls={true}
                    playsinline={true}
                />
            </div>
            )}
        </>
    );
}

export const PaginatedItems = ({ items,  itemsPerPage }) => {
    const [itemOffset, setItemOffset] = useState(0);

    const endOffset = itemOffset + itemsPerPage;
    const currentItems = items.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(items.length / itemsPerPage);
  

    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % items.length;
        setItemOffset(newOffset);
      };

    return (
      <>
        {currentItems.map((item) => (
            <Item key={item.activityId} attachment={item}></Item>
        ))}

        {items.length > 1 && (
        <ReactPaginate
          nextLabel=">"
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          marginPagesDisplayed={2}
          pageCount={pageCount}
          previousLabel="<"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakLabel="..."
          breakClassName="page-item"
          breakLinkClassName="page-link"
          containerClassName="pagination"
          activeClassName="active"
          renderOnZeroPageCount={null}
        />
        )}
      </>
    );
}

export const PublicationItem = ({publication, handleDeletePublication, handleGiveFeedback, handlePin}) => {
    const manager = new sessionManager();
    const activityServiceClient = useMemo(() => new activityService(),[]);
    const [attachments, setAttachments] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [pin, setPin] = useState(publication.pinned);
    const [showUserLikesModal, setShowUserLikesModal] = useState(false);

    const userLoggedIn = JSON.parse(manager.getUserSession());
    const isUserLoggedIn = manager.isUserLoggedIn();

    var userLoggedInId = isUserLoggedIn ? userLoggedIn.userId : 0;

    useEffect(() => {
        const fetchData = async () => {
            const attachmentsResponse = await activityServiceClient.getAttachmentsByActivityId(publication.activityId);

            if (attachmentsResponse.success) {
                setAttachments(attachmentsResponse.value);
            }
        };
        fetchData();

    }, [publication.activityId, activityServiceClient]);

    const deletePublication = async (publicationId) => {
        var activitiesResponse = await activityServiceClient.delete(publicationId);

        if (activitiesResponse.success) {
            handleDeletePublication();
            setShowModal(false);
        }
    }

    const handleFeedback = async (activityId, hasFeedback) => {
        var activitiesResponse = await activityServiceClient.feedback(activityId, userLoggedInId, hasFeedback);

        if (activitiesResponse.success) {
            handleGiveFeedback();
        }
    }

    const handlePinned = async (activityId, isPinned) => {
        var activitiesResponse = null;
        
        if (isPinned) {
            activitiesResponse = await activityServiceClient.unpin(activityId);
        } else {
            activitiesResponse = await activityServiceClient.pin(activityId);
        }
        

        if (activitiesResponse.success) {
            setPin(!isPinned);
            handlePin();
        }
    }

    return (
        <li className="publication-item">
            <div className="publication-item-header">
                <div className="user">
                    <a href={`${publication.profileUser === 'Edowier' ? '/teacher-profile/' : 'user-profile/'}${publication.userId}`}>
                        <img src={publication.userProfilePhoto} className="user-photo" alt="activity" />
                    </a>
                    <div className="details">
                        <span className="username">{publication.name}</span>
                        <span className="publication-type">{publication.activityType}</span>
                        <span className="time">{publication.time}</span>
                    </div>
                    <div className="publication-buttons-section">
                        {userLoggedInId === publication.userId && (
                        <button onClick={() => { setShowModal(true) }} title="Eliminar">
                            <i><FontAwesomeIcon icon={faTrash} /></i>
                        </button>
                        )}
                        {userLoggedInId === publication.userId ? (
                        <button onClick={() => { handlePinned(publication.activityId, pin) }} title={pin ? 'Desanclar' : 'Anclar'}>
                            {!pin ? (
                                <Icon.PinFill color="#2A2F7A"></Icon.PinFill>
                            ) : (
                                <Icon.PinAngleFill color="#2A2F7A"></Icon.PinAngleFill>
                            )}
                        </button>
                        ) : publication.pinned && (
                            <div className="publication-buttons-section">
                                <Icon.PinAngleFill className='pinned' color="#2A2F7A"></Icon.PinAngleFill>
                            </div>
                        )}
                    </div>
                    <EdowieModal show={showModal} title={"Eliminar publicación"}
                        close={() => { setShowModal(false) }} handleAccept={() => { deletePublication(publication.activityId) }}>
                        <div className="publication-item-content">Seguro desea eliminar la publicación?.</div>
                    </EdowieModal>
                </div>
            </div>
            <div className="publication-item-content">
                <span>{publication.publication}</span>
                <div>
                    <PaginatedItems items={attachments} itemsPerPage={1} totalItems={attachments.length} ></PaginatedItems>
                </div>
            </div>
            <div className="publication-item-like">
                <button onClick={() => setShowUserLikesModal(true)}>
                    <span>{publication.likedQuantity}<span className="label">Me gusta</span></span>
                </button>
                <UserLikesModal 
                    show={showUserLikesModal} 
                    activityId={publication.activityId}
                    handleClose={() => setShowUserLikesModal(false)}
                >
                </UserLikesModal>
            </div>
            <div className="publication-item-footer">
                <div className='publication-item-footer-buttons'>
                <button onClick={() => {
                    handleFeedback(publication.activityId, true)
                }} >
                    <i className={`${publication.like === true ? 'publication-item-footer-buttons-active' : ''}`}><FontAwesomeIcon icon={faThumbsUp} /></i>
                </button>
                <button onClick={() => {
                    handleFeedback(publication.activityId, false)
                }} >
                    <i className={`${publication.like === false ? 'publication-item-footer-buttons-active' : ''}`}><FontAwesomeIcon icon={faThumbsDown} /></i>
                </button>
                </div>
            </div>
        </li>
    )
}