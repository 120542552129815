import React, { useState, useMemo, useEffect } from 'react';
import './style.scss';
import { sessionManager } from '../../../sessionManager'
import { relationService } from '../../../services/relationService';
import { ServiceType } from '../../../Utils/types';
import { QualificationModal } from '../../Shared/QualificationModal/index';
import UserPattern from '../../../general/images/user-pattern.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMoneyBill } from '@fortawesome/free-solid-svg-icons';
import EmabajadorIcon from '../../../general/images/embajador.png'

export const Header = ({ teacherProfile, handleMoreInformationClick }) => {
    const manager = new sessionManager();
    const userLoggedIn = JSON.parse(manager.getUserSession());
    const relationServiceClient = useMemo(() => new relationService(), []);

    const isUserLoggedIn = manager.isUserLoggedIn();

    const [isFollower, setIsFollower] = useState(false);
    const [isFollowing, setIsFollowing] = useState(false);
    const [isPending, setIsPending] = useState(false);
    const [loading, setLoading] = useState(true);
    const [showModal, setShowModal] = useState(false);

    const userId = isUserLoggedIn ? userLoggedIn.userId : 0;

    useEffect(() => {
        const fetchData = async () => {

            if (teacherProfile !== null) {

                if (isUserLoggedIn) {
                    var relationResponse = await relationServiceClient.isFollowing(userId, teacherProfile.teacherId);

                    if (relationResponse.success) {
                        setIsFollowing(relationResponse.value);
                    }

                    relationResponse = await relationServiceClient.isFollowing(teacherProfile.teacherId, userId);

                    if (relationResponse.success) {
                        setIsFollower(relationResponse.value);
                    }

                    relationResponse = await relationServiceClient.isPending(userId, teacherProfile.teacherId);

                    if (relationResponse.success) {
                        setIsPending(relationResponse.value);
                    }
                }

                setLoading(false);
            }
        };
        fetchData();

    }, [relationServiceClient, teacherProfile, userId, isUserLoggedIn]);

    const follow = async () => {
        var folowResponse = await relationServiceClient.follow(userLoggedIn.userId, teacherProfile.teacherId);

        if (folowResponse.success) {
            var relationResponse = await relationServiceClient.isFollowing(userLoggedIn.userId, teacherProfile.teacherId);

            if (relationResponse.success) {
                setIsFollowing(relationResponse.value);
            }

            relationResponse = await relationServiceClient.isPending(userLoggedIn.userId, teacherProfile.teacherId);

            if (relationResponse.success) {
                setIsPending(relationResponse.value);
            }
        }
    }

    const unfollow = async () => {
        var unfollowResponse = await relationServiceClient.unfollow(userLoggedIn.userId, teacherProfile.teacherId);

        if (unfollowResponse.success) {
            setIsFollowing(false);
            setIsPending(false);
        }
    }

    // const showContentRatings = () => {
    //     setShowModal(true);
    //  }

    return (
        <>
            <div className="header">
                <div className="container">
                    <div className='cover-image-container' >
                        <img src={teacherProfile.coverImage} className="cover-image" alt='cover' />
                    </div>
                    {/* <div className="gradiant-bottom"></div> */}
                    <div className="teacher-information">
                        <div className="row">
                            <div className="col-xs-12 col-lg-10">
                                <div className="row">
                                    <div className="col-xs-12 col-lg-2 header-user">
                                        <div className="card-teacher-photo" style={{ backgroundImage: `url(${UserPattern})` }}>
                                            <figure className="avatar">
                                                <img src={teacherProfile.profileImage} alt="user" className="teacher-photo" />
                                            </figure>
                                        </div>
                                        <div className="mt-3">
                                            <div className="relations-teacher-profile">
                                            {!isFollower ? (
                                                <>
                                                    <div className="details ml-4 mr-3">
                                                        <div className="relation-quantity">{teacherProfile.followers}</div>
                                                        <div className="relation-title">Seguidores</div>
                                                    </div>
                                                    <div className="details">
                                                        <div className="relation-quantity">{teacherProfile.followed}</div>
                                                        <div className="relation-title">Seguidos</div>
                                                    </div>
                                                    </>
                                            ) : (
                                                <>
                                                    <a className="details ml-4 mr-3" href={`/relations/${teacherProfile.teacherId}/2`}>
                                                        <div className="relation-quantity">{teacherProfile.followers}</div>
                                                        <div className="relation-title">Seguidores</div>
                                                    </a>
                                                    <a className="details" href={`/relations/${teacherProfile.teacherId}/1`}>
                                                        <div className="relation-quantity">{teacherProfile.followed}</div>
                                                        <div className="relation-title">Seguidos</div>
                                                    </a>
                                                </>
                                            )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xs-12 col-lg-10">
                                        <div className="d-flex">
                                            <h4 className="username">{teacherProfile.username}</h4>
                                            {teacherProfile.ambassador && (
                                                <img src={EmabajadorIcon} className="ambassador" title="Embajador" alt='Embajador'></img>
                                            )}
                                            <div className="country" data-pais="Argentina">
                                                <img className="flag" src={teacherProfile.flagUrl} alt='flag' />
                                            </div>
                                        </div>
                                        <div className="services-details">
                                            <ul>
                                                <li>
                                                    {/* <button onClick={() => showContentRatings()} className="show-rating"> */}
                                                        <div className="qualification">
                                                            <i className="feather-star pr-2"></i>Calificación en contenidos: {teacherProfile.contentsQualification}
                                                            <QualificationModal
                                                                title={'Calificación en contenidos'}
                                                                showModal={showModal} 
                                                                serviceId={0}
                                                                serviceType={ServiceType.AllContents}
                                                                handleAccept={() => setShowModal(false)}
                                                                handleClose={() => setShowModal(false)}></QualificationModal>
                                                        </div>
                                                    {/* </button> */}
                                                </li>
                                                <li>
                                                    {/* <a href="/#"> */}
                                                        <i className="feather-star pr-2"></i>Calificación en experiencias particulares: {teacherProfile.particularExperiencesQualification}
                                                    {/* </a> */}
                                                </li>
                                                <li>
                                                    {/* <a href="j/#"> */}
                                                        <i className="feather-star pr-2"></i>Calificación en experiencias grupales: {teacherProfile.groupExperiencesQualification}
                                                    {/* </a> */}
                                                </li>
                                                <li><i className="feather-award pr-2"></i>{teacherProfile.reviewsQuantity} reseñas</li>
                                                <li><i className="feather-user pr-2"></i>{teacherProfile.usersQuantity} usuarios</li>
                                                <li><i className="feather-play-circle pr-2"></i>{teacherProfile.contentsQuantity} contenidos + {teacherProfile.groupExperiencesQuantity} experiencias grupales + {teacherProfile.percentageParticularExperiencesRealized}% de experiencias particulares concretadas </li>
                                                <li className="more-informarion">
                                                    <button onClick={() => handleMoreInformationClick()}>
                                                        Más información sobre el edowier<i className="feather-arrow-right pl-2"></i>
                                                    </button>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-12 col-lg-2 teacher-profile-buttons">
                                {isUserLoggedIn ? (
                                    <>
                                        {userId !== teacherProfile.teacherId && 
                                        (!teacherProfile.followersOnlyMessagingEnable || (teacherProfile.followersOnlyMessagingEnable && isFollowing)) && (
                                            <div className="teacher-profile-buttons-send-message">
                                                <a href={`/messages/${teacherProfile.teacherId}`} >
                                                    <i className="feather-mail send-message-image" title="Enviar mensaje directo"></i>
                                                </a>
                                            </div>
                                        )}

                                        {!loading && userLoggedIn.userId !== teacherProfile.teacherId && (
                                        <div className="teacher-profile-buttons-relation">
                                            <button className="relation-button"
                                                onClick={() => {
                                                    if (isFollowing || isPending) {
                                                        unfollow();
                                                    } else {
                                                        follow();
                                                    }
                                                }}>{isFollowing ? 'Dejar de seguir' : isPending ? 'Pendiente' : 'Seguir'}
                                            </button>
                                        </div>
                                        )}

                                        {!loading && userLoggedIn.userId !== teacherProfile.teacherId && 
                                          teacherProfile.allowDonations && (
                                        <div className="teacher-profile-buttons-donations">
                                            <a href={`/donations/${teacherProfile.teacherId}`} >
                                            <i title='Donar' className='donations-image'><FontAwesomeIcon icon={faMoneyBill} /></i>
                                            </a>
                                        </div>
                                        )}
                                    </>
                                    ) : (
                                        <></>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}