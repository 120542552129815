import { useTranslation } from 'react-i18next';
import './style.scss';

export const YourUseServiceSection = () => {
    const { t } = useTranslation(['terms_and_conditions']);
    const serviceContentSection = t('yourUseServiceSection.serviceContentSection');
    const permissionsRestrictionsSection = t('yourUseServiceSection.permissionsRestrictionsSection');
    const bookingSection = t('yourUseServiceSection.bookingSection');
    const nonComplianceWithinServiceSection = t('yourUseServiceSection.nonComplianceWithinServiceSection');
    const developmentImprovementsUpdatesServiceSection = t('yourUseServiceSection.developmentImprovementsUpdatesServiceSection');

    return (
        <>
            <p className="section-subtitle">{serviceContentSection.title}</p>
            <p className="section-legend">
                {serviceContentSection.legend}
            </p>

            <p className="section-subtitle pt-15">{permissionsRestrictionsSection.title}</p>
            <p className="section-legend">
                {permissionsRestrictionsSection.legend1}
            </p>
            <p className="section-legend pt-05">
                {permissionsRestrictionsSection.legend2}
            </p>
            <ul className="service-restrictions">
                <li>
                    {permissionsRestrictionsSection.item1}
                </li>
                <li>
                    {permissionsRestrictionsSection.item2}
                </li>
                <li>
                    {permissionsRestrictionsSection.item3}
                </li>
                <li>
                    {permissionsRestrictionsSection.item4}
                </li>
                <li>
                    {permissionsRestrictionsSection.item5}
                </li>
                <li>
                    {permissionsRestrictionsSection.item6}
                </li>
                <li>
                    {permissionsRestrictionsSection.item7}
                </li>
            </ul>

            <p className="section-subtitle pt-15">{bookingSection.title}</p>
            <p className="section-legend">
                {bookingSection.legend}
            </p>

            <p className="section-subtitle pt-15">{nonComplianceWithinServiceSection.title}</p>
            <p className="section-legend">
                {nonComplianceWithinServiceSection.legend1}
            </p>
            <p className="section-legend pt-05">
                {nonComplianceWithinServiceSection.legend2}
            </p>

            <p className="section-subtitle pt-15">{developmentImprovementsUpdatesServiceSection.title}</p>
            <p className="section-legend">
                {developmentImprovementsUpdatesServiceSection.legend1}
            </p>
            <p className="section-legend pt-05">
                {developmentImprovementsUpdatesServiceSection.legend2}
            </p>
            <ul className="sale-digital-products-services">
                <li>
                    {developmentImprovementsUpdatesServiceSection.item1}
                </li>
                <li>
                    {developmentImprovementsUpdatesServiceSection.item2}
                </li>
                <li>
                    {developmentImprovementsUpdatesServiceSection.item3}
                </li>
                <li>
                    {developmentImprovementsUpdatesServiceSection.item4}
                </li>
                <li>
                    {developmentImprovementsUpdatesServiceSection.item5}
                </li>
            </ul>

            <p className="section-legend pt-05">
                {developmentImprovementsUpdatesServiceSection.legend3}
            </p>
            <ul className="withdrawal">
                <li>
                    {developmentImprovementsUpdatesServiceSection.item6}
                </li>
            </ul>

            <p className="section-legend pt-05">
                {developmentImprovementsUpdatesServiceSection.legend4}
            </p>
            <ul className="cancellation-particular-experiences">
                <li>
                    {developmentImprovementsUpdatesServiceSection.item7}
                </li>
            </ul>

            <p className="section-legend pt-05">
                {developmentImprovementsUpdatesServiceSection.legend5}
            </p>
            <p className="section-legend">
                {developmentImprovementsUpdatesServiceSection.legend6}
            </p>
        </>
    )
}