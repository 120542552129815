import { useTranslation } from 'react-i18next';
import './style.scss';

export const OtherLegalTermsSection = () => {
    const { t } = useTranslation(['terms_and_conditions']);
    const warrantyDisclaimerSection = t('otherLegalTerms.warrantyDisclaimerSection');
    const limitationLiabilitiesSection = t('otherLegalTerms.limitationLiabilitiesSection');
    const compensationSection = t('otherLegalTerms.compensationSection');
    const thirdPartyLinksSection = t('otherLegalTerms.thirdPartyLinksSection');

    return (
        <>
            <p className="section-subtitle">{warrantyDisclaimerSection.title}</p>
            <p className="section-legend">
                {warrantyDisclaimerSection.legend}
            </p>

            <p className="section-subtitle pt-15">{limitationLiabilitiesSection.title}</p>
            <p className="section-legend">
                {limitationLiabilitiesSection.legend1}
            </p>
            <ul className="other-legal-terms">
                <li>
                    {limitationLiabilitiesSection.item1}
                </li>
                <li>
                    {limitationLiabilitiesSection.item2}
                </li>
                <li>
                    {limitationLiabilitiesSection.item3}
                </li>
                <li>
                    {limitationLiabilitiesSection.item4}
                </li>
                <li>
                    {limitationLiabilitiesSection.item5}
                </li>
                <li>
                    {limitationLiabilitiesSection.item6}
                </li>
                <li>
                    {limitationLiabilitiesSection.item7}
                </li>
            </ul>
            <p className="section-legend pt-05">
                {limitationLiabilitiesSection.legend2}
            </p>
            <p className="section-legend pt-05">
                {limitationLiabilitiesSection.legend3}
            </p>

            <p className="section-subtitle pt-15">{compensationSection.title}</p>
            <p className="section-legend">
                {compensationSection.legend}
            </p>

            <p className="section-subtitle pt-15">{thirdPartyLinksSection.title}</p>
            <p className="section-legend">
                {thirdPartyLinksSection.legend}
            </p>
        </>
    )
}