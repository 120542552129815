export interface IIpInfoService {
    getCountryCode(): Promise<any>;
  }
  
  export class IpInfoService implements IIpInfoService {
  
    public async getCountryCode(): Promise<any> {
      const defaultCountryCode = 'ar';

      try {
        const response = await fetch('https://ip.nf/me.json');
        const data = await response.json();

        return { success: true, value: data.ip.country_code }
      } catch (error) {
        return { success: true, value: defaultCountryCode };
      }
    }
  }