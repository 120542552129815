import { getToken } from "../Utils/utils";

export class contentService {

    async getContentsByCategoryIdAndText(userId, categoryId, searchText, page, contentsByPage) {
        try {

            const edowieApi = process.env.REACT_APP_EDOWIE_API;
            const token = await getToken();
            const requestOptions = {
                headers: { 
                    'Authorization': `bearer ${token}`,
                }
            };

            const response = await fetch(`${edowieApi}content/${userId}/contents?categoryId=${categoryId}&text=${searchText}&page=${page}&contentsByPage=${contentsByPage}`, requestOptions);
            const data = await response.json();

            return { success: true, value: data }

        } catch (error) {
            return { success: false, error: error };
        }
    }

    async getCourses(teacherProfileId) {
        try {

            const edowieApi = process.env.REACT_APP_EDOWIE_API;
            const token = await getToken();
            const requestOptions = {
                headers: { 'Authorization': `bearer ${token}`, },
            };

            const response = await fetch(edowieApi + 'content/' + teacherProfileId.toString() + '/courses', requestOptions);
            const data = await response.json();

            return { success: true, value: data }

        } catch (error) {
            return { success: false, error: error };
        }
    }

    async getResources(teacherProfileId) {
        try {

            const edowieApi = process.env.REACT_APP_EDOWIE_API;
            const token = await getToken();
            const requestOptions = {
                headers: { 'Authorization': `bearer ${token}`, },
            };

            const response = await fetch(edowieApi + 'content/' + teacherProfileId.toString() + '/resources', requestOptions);
            const data = await response.json();

            return { success: true, value: data }

        } catch (error) {
            return { success: false, error: error };
        }
    }

    async createCourse(values, coverImage, videos, additionals, userId) {
        try {
            const edowieApi = process.env.REACT_APP_EDOWIE_API;
            const token = await getToken();
            const requestOptions = {
                method: 'POST',
                headers: { 
                    'Authorization': `bearer ${token}`,
                    'Content-Type': 'application/json' 
                },
                body: JSON.stringify(
                    {
                        "categoryId": values.category,
                        "subcategoryId": values.subcategory,
                        "title": values.title,
                        "subtitle": values.subtitle,
                        "description": values.description,
                        "detailedDescription": values.detailedDescription,
                        "requirements": values.requirements,
                        "level": values.level,
                        "isFree": values.isFree === "" ? false : values.isFree,
                        "price": values.price === "" ? 0 : values.price,
                        "tags": values.tags,
                        "coverImage": coverImage.length > 0 ? coverImage[0].toString() : "",
                        "videos": videos.map(function (item) {
                            return item.toString();
                        }),
                        "additionals": additionals.map(function (item) {
                            return item.toString();
                        }),
                        "userId": userId
                    })
            };

            const response = await fetch(`${edowieApi}content/${userId}/course`, requestOptions);

            if (response.ok) {
                return { success: true }
            } else {
                return { success: false, error: 'Ha ocurrido un error al intentar guardar el curso.' };
            }

        } catch (error) {
            return { success: false, error: error };
        }
    }

    async getCourseByUserIdAndId(userId, id) {
        try {

            const edowieApi = process.env.REACT_APP_EDOWIE_API;
            const token = await getToken();
            const requestOptions = {
                headers: { 'Authorization': `bearer ${token}`, },
            };

            const response = await fetch(edowieApi + `content/${userId}/course/${id}`, requestOptions);
            const data = await response.json();

            return { success: true, value: data }

        } catch (error) {
            return { success: false, error: error };
        }
    }

    async deleteCourse(userId, id) {
        try {

            const edowieApi = process.env.REACT_APP_EDOWIE_API;
            const token = await getToken();
            const requestOptions = {
                method: 'DELETE',
                headers: { 
                    'Authorization': `bearer ${token}`,
                    'Content-Type': 'application/json' 
                }
            };

            const response = await fetch(edowieApi + `content/${userId}/course/${id}`, requestOptions);

            return { success: response.status === 200 }

        } catch (error) {
            return { success: false, error: error };
        }
    }

    async updateCourse(values, coverImage, videos, additionals, userId, id) {
        try {
            const edowieApi = process.env.REACT_APP_EDOWIE_API;
            const token = await getToken();
            const requestOptions = {
                method: 'PUT',
                headers: { 
                    'Authorization': `bearer ${token}`,
                    'Content-Type': 'application/json' 
                },
                body: JSON.stringify(
                    {
                        "categoryId": values.category,
                        "subcategoryId": values.subcategory,
                        "title": values.title,
                        "subtitle": values.subtitle,
                        "description": values.description,
                        "detailedDescription": values.detailedDescription,
                        "requirements": values.requirements,
                        "level": values.level,
                        "isFree": values.isFree === "" ? false : values.isFree,
                        "price": values.price === "" ? 0 : values.price,
                        "tags": values.tags,
                        "coverImage": coverImage.length > 0 ? coverImage[0].toString() : "",
                        "videos": videos.map(function (item) {
                            return item.toString();
                        }),
                        "additionals": additionals.map(function (item) {
                            return item.toString();
                        }),
                        "userId": userId
                    })
            };

            const response = await fetch(`${edowieApi}content/${userId}/course/${id}`, requestOptions);

            if (response.ok) {
                return { success: true }
            } else {
                return { success: false, error: 'Ha ocurrido un error al intentar guardar el curso.' };
            }

        } catch (error) {
            return { success: false, error: error };
        }
    }

    async createResource(values, coverImage, files, userId) {
        try {
            const edowieApi = process.env.REACT_APP_EDOWIE_API;
            const token = await getToken();
            const requestOptions = {
                method: 'POST',
                headers: { 
                    'Authorization': `bearer ${token}`,
                    'Content-Type': 'application/json' 
                },
                body: JSON.stringify(
                    {
                        "categoryId": values.category,
                        "subcategoryId": values.subcategory,
                        "title": values.title,
                        "description": values.description,
                        "detailedDescription": values.detailedDescription,
                        "language": values.language,
                        "level": values.level,
                        "isFree": values.isFree === "" ? false : values.isFree,
                        "price": values.price === "" ? 0 : values.price,
                        "tags": values.tags,
                        "coverImage": coverImage.length > 0 ? coverImage[0].toString() : "",
                        "files": files.map(function (item) {
                            return item.toString();
                        }),
                        "userId": userId
                    })
            };

            const response = await fetch(`${edowieApi}content/${userId}/resource`, requestOptions);

            if (response.ok) {
                return { success: true }
            } else {
                return { success: false, error: 'Ha ocurrido un error al intentar guardar el recurso.' };
            }

        } catch (error) {
            return { success: false, error: error };
        }
    }

    async getResourceByUserIdAndId(userId, id) {
        try {

            const edowieApi = process.env.REACT_APP_EDOWIE_API;
            const token = await getToken();
            const requestOptions = {
                headers: { 'Authorization': `bearer ${token}`, },
            };

            const response = await fetch(edowieApi + `content/${userId}/resource/${id}`, requestOptions);
            const data = await response.json();

            return { success: true, value: data }

        } catch (error) {
            return { success: false, error: error };
        }
    }

    async deleteResource(userId, id) {
        try {

            const edowieApi = process.env.REACT_APP_EDOWIE_API;
            const token = await getToken();
            const requestOptions = {
                method: 'DELETE',
                headers: { 
                    'Authorization': `bearer ${token}`, 
                    'Content-Type': 'application/json' 
                }
            };

            const response = await fetch(edowieApi + `content/${userId}/resource/${id}`, requestOptions);

            return { success: response.status === 200 }

        } catch (error) {
            return { success: false, error: error };
        }
    }

    async updateResource(values, coverImage, files, userId, id) {
        try {
            const edowieApi = process.env.REACT_APP_EDOWIE_API;
            const token = await getToken();
            const requestOptions = {
                method: 'PUT',
                headers: { 
                    'Authorization': `bearer ${token}`, 
                    'Content-Type': 'application/json' 
                },
                body: JSON.stringify(
                    {
                        "categoryId": values.category,
                        "subcategoryId": values.subcategory,
                        "title": values.title,
                        "description": values.description,
                        "detailedDescription": values.detailedDescription,
                        "language": values.language,
                        "level": values.level,
                        "isFree": values.isFree === "" ? false : values.isFree,
                        "price": values.price === "" ? 0 : values.price,
                        "tags": values.tags,
                        "coverImage": coverImage.length > 0 ? coverImage[0].toString() : "",
                        "files": files.map(function (item) {
                            return item.toString();
                        }),
                        "userId": userId
                    })
            };

            const response = await fetch(`${edowieApi}content/${userId}/resource/${id}`, requestOptions);

            if (response.ok) {
                return { success: true }
            } else {
                return { success: false, error: 'Ha ocurrido un error al intentar guardar el curso.' };
            }

        } catch (error) {
            return { success: false, error: error };
        }
    }

    async getContentDetailsById(id, contentType) {
        try {

            const edowieApi = process.env.REACT_APP_EDOWIE_API;
            const token = await getToken();
            const requestOptions = {
                headers: { 'Authorization': `bearer ${token}`, },
            };

            const response = await fetch(edowieApi + `Content/content-detail/${id}/${contentType}`, requestOptions);
            const data = await response.json();

            return { success: true, value: data }

        } catch (error) {
            return { success: false, error: error };
        }
    }

    async addFavorites(userId, id, serviceType) {
        try {

            const edowieApi = process.env.REACT_APP_EDOWIE_API;
            const token = await getToken();
            const requestOptions = {
                method: 'POST',
                headers: { 
                    'Authorization': `bearer ${token}`, 
                    'Content-Type': 'application/json' 
                },
                body: JSON.stringify(
                    {
                        "contentId": id,
                        "userId": userId,
                        "serviceType": serviceType,
                    })
            };

            const response = await fetch(edowieApi + `content/${id}/favorites`, requestOptions);

            return { success: response.status === 200 }

        } catch (error) {
            return { success: false, error: error };
        }
    }

    async deleteFavorites(userId, id, serviceType) {
        try {

            const edowieApi = process.env.REACT_APP_EDOWIE_API;
            const token = await getToken();
            const requestOptions = {
                method: 'DELETE',
                headers: { 
                    'Authorization': `bearer ${token}`, 
                    'Content-Type': 'application/json' 
                },
                body: JSON.stringify(
                    {
                        "contentId": id,
                        "userId": userId,
                        "serviceType": serviceType,
                    })
            };

            const response = await fetch(edowieApi + `content/${id}/favorites`, requestOptions);

            return { success: response.status === 200 }

        } catch (error) {
            return { success: false, error: error };
        }
    }

    async hasInFavorites(userId, id, serviceType) {
        try {

            const edowieApi = process.env.REACT_APP_EDOWIE_API;
            const token = await getToken();
            const requestOptions = {
                headers: { 'Authorization': `bearer ${token}`, },
            };

            const response = await fetch(edowieApi + `content/${userId}/contents/${id}/${serviceType}/has-in-favorites`, requestOptions);
            const data = await response.json();

            return { success: true, value: data }

        } catch (error) {
            return { success: false, error: error };
        }
    }

    async buyContent(externalPaymentId, amount, userId, contentId, contentType) {
        try {
            const edowieApi = process.env.REACT_APP_EDOWIE_API;
            const token = await getToken();
            const requestOptions = {
                method: 'POST',
                headers: { 
                    'Authorization': `bearer ${token}`,
                    'Content-Type': 'application/json' 
                },
                body: JSON.stringify(
                    {
                        "externalPaymentId": externalPaymentId,
                        "amount": amount,
                        "userId": userId,
                        "contentId": contentId,
                        "serviceType": contentType
                    })
            };

            const response = await fetch(`${edowieApi}Content/${userId}/content/${contentId}/buy`, requestOptions);

            if (response.ok) {
                return { success: true }
            } else {
                return { success: false, error: 'Ha ocurrido un error al intentar guardar el pago.' };
            }

        } catch (error) {
            return { success: false, error: error };
        }
    }

    async wasAdquired(userId, id, serviceType) {
        try {

            const edowieApi = process.env.REACT_APP_EDOWIE_API;
            const token = await getToken();
            const requestOptions = {
                headers: { 'Authorization': `bearer ${token}`, },
            };

            const response = await fetch(edowieApi + `content/${userId}/contents/${id}/${serviceType}/was-adquired`, requestOptions);
            const data = await response.json();

            return { success: true, value: data }

        } catch (error) {
            return { success: false, error: error };
        }
    }

    async getAdquiredContentsByUserIdAndContentType(userId, contentType) {
        try {

            const edowieApi = process.env.REACT_APP_EDOWIE_API;
            const token = await getToken();
            const requestOptions = {
                headers: { 'Authorization': `bearer ${token}`, },
            };

            const response = await fetch(edowieApi + `Content/${userId}/contents/${contentType}/adquired`, requestOptions);
            const data = await response.json();

            return { success: true, value: data }

        } catch (error) {
            return { success: false, error: error };
        }
    }
}
