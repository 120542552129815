import React, { useRef, useEffect, useMemo, useState, useCallback, forwardRef } from 'react';
import './style.scss';
import DatePicker, { registerLocale } from "react-datepicker";
import { particularExperienceService } from '../../../../../services/particularExperienceService';
import moment from 'moment'
import es from "date-fns/locale/es";
import enUS from "date-fns/locale/en-US";
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from "@fullcalendar/interaction";
import allLocales from '@fullcalendar/core/locales-all';
import { DELAY_BEFORE_REDIRECT } from '../../../../../Utils/types';
import useTimeout from '../../../../../hooks/useTimeout/index';
import { EdowieModal } from '../../../../Shared/Modal';
import { getCurrentTime } from '../../../../../Utils/utils';
import { useTranslation } from 'react-i18next';
import i18n from "i18next";
import { InjectAppServices } from '../../../../../services/dependency-injection';

registerLocale(i18n.language, i18n.language === 'es' ? es : enUS);

const DatePickerButton = forwardRef(({ value, onClick, legend }, ref) => (
    <>
        <button className="date-button" onClick={onClick} ref={ref}>
            {value}
        </button>
        <span className="change-week-legend">{legend}</span>
    </>
));

export const AvailabilityStep = InjectAppServices(
    ({
        particularExperience, 
        handleBack, 
        handleSaveAvailabilityStep, 
        handleDeactivate,
        dependencies: { sessionManager, diaryServiceClient }
    }) => {
    const { t } = useTranslation();
    const particularExperienceServiceClient = useMemo(() => new particularExperienceService(), []);
    const calendarComponentRef = useRef(null);
    const userLoggedIn = sessionManager.getUserLoggedIn();
    const [events, setEvents] = useState([]);
    const [startDate, setStartDate] = useState(getCurrentTime(userLoggedIn.offset));
    const [showModal, setShowModal] = useState(false);
    const createTimeout = useTimeout();

    const mapEvent = useCallback((data) => {
        var startEventDate = moment(data.startDate + 'Z').add((-1) * moment().utcOffset(), 'minutes').add(userLoggedIn.offset, 'minutes')._d;
        var endEventDate = moment(data.endDate + 'Z').add((-1) * moment().utcOffset(), 'minutes').add(userLoggedIn.offset, 'minutes')._d;

        return { start: startEventDate, end: endEventDate, title: data.reserved, id: data.eventId, type: data.type };
    }, [userLoggedIn.offset])
    
    const getEvents = useCallback(async (date) => {
        var week = getWeek(date);
        diaryServiceClient.getEventsByUserIdAndDates(userLoggedIn.userId, week[0], week[1])
            .then(response => {
                setEvents(response.map(mapEvent)); 
            });

    }, [diaryServiceClient, userLoggedIn.userId, mapEvent]);

    useEffect(() => {
        const fetchData = async () => {
            getEvents(startDate);
        };
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getEvents]);

    const handleChangeDate = (date) => {
        setStartDate(date);
        //getEvents(date);

        let calendarApi = calendarComponentRef.current.getApi();
        calendarApi.gotoDate(date);
    }

    const handleDateClick = async(arg) => { // bind with an arrow function
        if (!moment(arg.date).isBefore(getCurrentTime(userLoggedIn.offset))) {
            var response = await particularExperienceServiceClient.addAvailability(userLoggedIn.userId, moment(arg.date + 'Z')._d, particularExperience.duration, userLoggedIn.offset);

            if (response.success) {
                getEvents(startDate);
            }
        }
    }

    const getWeek = (date) => {
        var current = date; //new Date(fecha);

        const week = [];
        const weekFormat = [];

        if (current.getDay() === 0) {
            current.setDate(((current.getDate() - 7) + 1));
        } else {
            current.setDate(((current.getDate() - current.getDay()) + 1));
        }

        week.push(new Date(current));
        current.setDate(current.getDate() + 6);
        week.push(new Date(current));

        week.forEach((w) => {
            weekFormat.push(moment(w).format('YYYY-MM-DD'));
        });

        return weekFormat;
    }

    const deleteAvailability = async(eventId) => {
        var response = await particularExperienceServiceClient.deleteAvailability(particularExperience.particularExperienceId, eventId);
        if (response.success) {
            getEvents(startDate);
        }
    }

    const saveAvailabilityStep = async(values) => {
        var response = await particularExperienceServiceClient.saveAvailabilityStep(userLoggedIn.userId);

        if (response.success) {
            createTimeout(() => {
                handleSaveAvailabilityStep();
            }, DELAY_BEFORE_REDIRECT);
        }
    }

    const goToPreviousWeek = () => {
        let calendarApi = calendarComponentRef.current.getApi();
        calendarApi.prev();

        var date = calendarApi.currentData.dateProfile.currentRange.end;
        setStartDate(date);
        getEvents(date);
    }

    const goToNextWeek = () => {
        let calendarApi = calendarComponentRef.current.getApi();
        calendarApi.next();

        var date = calendarApi.currentData.dateProfile.currentRange.end;
        setStartDate(date);
        getEvents(date);
    }

    const deactivate = async () => {
        var response = await particularExperienceServiceClient.deactivate(userLoggedIn.userId);
        if (response.success) {
            createTimeout(() => {
                handleDeactivate();
            }, DELAY_BEFORE_REDIRECT);
        }
    }

    function renderEventContent(eventInfo) {
        var type = eventInfo.event.extendedProps.type;

        return (
            <>
                {moment(eventInfo.event.start).isBefore(getCurrentTime(userLoggedIn.offset)) ? (
                    <div className={'fc-past-event'}>
                        <b>{eventInfo.timeText}</b>
                    </div>
                ) :
                eventInfo.event.title === "true" ? (
                    <div className={'fc-reserved-event'}>
                        <b>{eventInfo.timeText}</b>
                    </div>
                ) : 
                (
                    <div className={'fc-available-event'}>
                        <>
                            <div className='available-event'>
                                {type === 'Experiencia Particular' && (
                                <span onClick={() => deleteAvailability(eventInfo.event.id)} className='remove-event closeon'>X</span>
                                )}
                                <b>{eventInfo.timeText}</b>
                            </div>
                        </>
                    </div>
                )}
            </>
        )
    }

    return (
        <div className="availability-step">
            {particularExperience.stateId === 3 && (
                <h2 className="availability-step-title">
                    {t('manage_services.particular.availability_step.title')}
                </h2>
            )}

            {particularExperience.stateId === 4 && (
                <h2 className="availability-step-title">
                    {t('manage_services.particular.availability_step.without_availability_title')}
                </h2>
            )}

            {particularExperience.stateId === 5 && (
                <h2 className="availability-step-title">
                    {t('manage_services.particular.availability_step.with_availability_title')}
                </h2>
            )}
            <div className="date-picker-container">
                <div className="user-information">
                    <div className="country-logged-user">
                        <div className="container">
                            <img className="flag" src={userLoggedIn.flag} alt='flag' />
                            <p className="country">{userLoggedIn.country}</p>
                        </div>
                    </div>
                    <span className="time-use">({userLoggedIn.timeUse})</span>
                </div>
                <div className='particular-experience-duration'>
                    <span className="label">{t('manage_services.particular.availability_step.duration_experience')}:</span>
                    <label className="duration">{`${particularExperience.duration} min`}</label>
                </div>
                <DatePicker locale={i18n.language} selected={startDate} onChange={(date) => handleChangeDate(date)} customInput={<DatePickerButton legend={t('manage_services.particular.availability_step.change_date_label')} />}
                    portalId="root-portal" showMonthDropdown showYearDropdown yearDropdownItemNumber={15}
                    scrollableYearDropdown adjustDateOnChange />
            </div>
            <div className="details">
                <div className="references">
                    <div className="references-container">
                        <div className="title">
                            <h4>{t('manage_services.particular.availability_step.references.title')}</h4>
                        </div>
                        <div className="reference">
                            <div className="past"></div>
                            <div className="legend">{t('manage_services.particular.availability_step.references.past_legend')}</div>
                        </div>
                        <div className="reference">
                            <div className="available"></div>
                            <div className="legend">{t('manage_services.particular.availability_step.references.available_legend')}</div>
                        </div>
                        <div className="reference">
                            <div className="reserved"></div>
                            <div className="legend">{t('manage_services.particular.availability_step.references.reserved_legend')}</div>
                        </div>
                    </div>
                </div>
                <div className="calendar">
                    <FullCalendar
                        id={'fullCalendar'}
                        plugins={[timeGridPlugin, interactionPlugin]}
                        initialView="timeGridWeek"
                        slotDuration={`00:${particularExperience.duration}`}
                        allDaySlot={false}
                        events={events}
                        dateClick={handleDateClick}
                        locales={allLocales} locale={i18n.language}
                        headerToolbar={{
                            left: 'prev,next',
                            center: 'title',
                            right: "",
                        }}
                        ref={calendarComponentRef}
                        eventContent={renderEventContent}
                        customButtons={{
                            prev: {click: () => goToPreviousWeek()},
                            next: {click: () => goToNextWeek()},
                          }}
                    />
                </div>
            </div>
            <div className='availability-step-buttons'>
                <button className='button back-button' onClick={() => handleBack()}>{t('manage_services.particular.back_button')}</button>
                <div>
                    {(particularExperience.stateId === 4 || particularExperience.stateId === 5) && (
                    <button className='button deactivate-button' onClick={() => setShowModal(true)}>{t('manage_services.particular.deactivate_button')}</button>
                    )}
                    {particularExperience.stateId <= 4 && (
                    <button className='button save-button' onClick={() => saveAvailabilityStep()}>{t('manage_services.particular.finish_button')}</button>
                    )}
                </div>
                <EdowieModal show={showModal} title={t('manage_services.particular.availability_step.deactivate_partcular_experience_modal.title')} modalClass={'availability-step-modal'}
                    close={() => { setShowModal(false) }} handleAccept={() => { deactivate() }}>
                    <div className="activity-item-content">{t('manage_services.particular.availability_step.deactivate_partcular_experience_modal.legend')}</div>
                </EdowieModal>
            </div>
        </div>
    )
})