import { fetchWrapper } from "../Utils/fetchWrapper.js";

const baseUrl = `${process.env.REACT_APP_EDOWIE_API}administrator`;

export interface IAdministratorService {
    generateNonLoggedInUser(): Promise<any>;
    getDashboard(adminId: number): Promise<any>;
    getSalesByYear(userId: number, year: number): Promise<any>;
    getUsersByProfile(profile: number, page: number, itemsByPage: number): Promise<any>;
    changeProfile(userId: number, profile: string): Promise<any>;
}

export class AdministratorService  implements IAdministratorService {

    async generateNonLoggedInUser() {
        return fetchWrapper.post(`${baseUrl}/tokens/non-logged-in-user`);
    }

    async getDashboard(adminId: number) {
        return fetchWrapper.get(`${baseUrl}/${adminId}/dashboard`);
    }

    async getSalesByYear(userId: number, year: number) {
        return fetchWrapper.get(`${baseUrl}/${userId}/sales/${year}`);
    }

    async getUsersByProfile(profile: number, page: number, itemsByPage: number) {
        return fetchWrapper.get(`${baseUrl}/${profile}/users?page=${page}&itemsByPage=${itemsByPage}`);
    }

    async changeProfile(userId: number, profile: string) {
        return fetchWrapper.put(`${baseUrl}/${userId}/change-profile/${profile}`);
    }

    async ambassador(userId: number, isAmbassador: boolean) {
        return fetchWrapper.put(`${baseUrl}/${userId}/ambassador/${isAmbassador}`);
    }

    async updateBalanceInformation(userId: number, commission: number, balance: number, minimumWithdrawal: number) {
        return fetchWrapper.put(`${baseUrl}/${userId}/balance-information` , 
        { 
            commission: commission, 
            balance: balance, 
            minimumWithdrawal: minimumWithdrawal
        })
    }
}
