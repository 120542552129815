import { DateTimeDisplay } from "./DateTimeDisplay";

export const ShowCounter = ({ days, hours, minutes, seconds }) => {
    return (
      <div className="show-counter">
          <DateTimeDisplay value={days} type={'Días'} isDanger={false} />
          <p>:</p>
          <DateTimeDisplay value={hours} type={'Horas'} isDanger={hours <= 1 && hours > 0} />
          <p>:</p>
          <DateTimeDisplay value={minutes} type={'Minutos'} isDanger={false} />
          <p>:</p>
          <DateTimeDisplay value={seconds} type={'Segundos'} isDanger={false} />
      </div>
    );
  };