import { fetchWrapper } from "../Utils/fetchWrapper.js";

const baseUrl = `${process.env.REACT_APP_EDOWIE_API}ServiceDiscount`;

export interface IServiceDiscountService {
    add(serviceId: number, serviceType: number, applyAll:boolean, countryCode: string, discount: number, userId:number): Promise<any>;
    delete(serviceId: number, serviceType: number, userId: number): Promise<any>;
};

export class ServiceDiscountService implements IServiceDiscountService {

    public add(serviceId: number, serviceType: number, applyAll:boolean, countryCode: string, discount: number, userId: number): Promise<string> {
      return fetchWrapper.post(`${baseUrl}/${userId}`, { serviceId: serviceId, serviceType: serviceType, applyAll: applyAll, countryCode: countryCode, discount: discount });
    }

    public delete(serviceId: number, serviceType: number, userId: number): Promise<string> {
      return fetchWrapper.delete(`${baseUrl}/${userId}`, { serviceId: serviceId, serviceType: serviceType });
    }
  }