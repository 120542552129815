import { useTranslation } from "react-i18next"
import { translateCountry } from "../../../../../../../Utils/utils"

export const UserItem = ({registration}) => {
    const { i18n } = useTranslation();
    
    return (
        <div className="row registration-list-section-item">
            <div className="col-lg-2 registration-list-section-item-user-image">
                <figure className="avatar registration-list-section-item-user-image-user-avatar">
                    <img src={registration.profilePhoto.path} alt={registration.username} className='registration-list-section-item-user-image-user-avatar-user-profile' />
                </figure>
            </div>
            <div className="col-lg-10">
                <div className="registration-list-section-item-details">
                    <h6 className="registration-list-section-item-details-username">{registration.username}</h6>
                    <div className="registration-list-section-item-details-timezone">
                        <img src={registration.flag} className="registration-list-section-item-details-timezone-flag" alt="flag"/>
                        <div className="registration-list-section-item-details-timezone-country">
                            <h4 className="registration-list-section-item-details-timezone-country-name">{translateCountry(i18n.language, registration.countryCode.toLowerCase())}</h4>
                            <h4 className="registration-list-section-item-details-timezone-country-timezone">{registration.timeUse}</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}