import React from 'react';
import './style.scss';
import { ServiceType } from '../../../../Utils/types';

export const ContentFiles = ({contentType, files, handleReproduceFile}) => {

    return (
        <>
            <div className="content-files">
                <div className="content-files-header">
                    {contentType === ServiceType.Course ? (
                        <h4 className="font-xs text-white fw-600 ml-4 mb-0 mt-2">Contenido del  curso</h4>
                    ) : (
                        <h4 className="font-xs text-white fw-600 ml-4 mb-0 mt-2">Contenido del  recurso</h4>
                    )}
                </div>
                <div className="content-files-details">
                    <ul>
                    {files.map((file) => (
                        <li key={file.fileId}>
                            <div>
                                <div className="item">
                                    <button title="Reproducir" onClick={() => handleReproduceFile(file)}>
                                        <i className="feather-play-circle"></i>
                                    </button>
                                    <span className="item-title-content " title={file.name}>{file.name}</span>
                                    <span className="separator"></span>
                                    {!file.canDownload ? (
                                    <span>{file.duration}</span>
                                    ) : (
                                    <a title='Descargar' href={file.path} className='download-file'>
                                        <i className='feather-download'></i>
                                    </a>
                                    )}
                                </div>
                            </div>
                        </li>
                    ))}
                    </ul>
                </div>
            </div>
        </>
    )
}