import React, { useEffect, useState, useMemo } from 'react';
import './style.scss';
import { NavMenu } from '../../Menu/NavMenu';
import { FileViewer } from './FileViewer';
import { useParams } from "react-router-dom";
import { contentService } from '../../../services/contentService';
import LoadingSpinner from "../../Shared/LoadingSpinner/index";
import { ContentFiles } from './ContentFiles';
import { TabsSection } from './TabsSection/index';
import { sessionManager } from '../../../sessionManager'

export const ViewContent = () => {
    const contentServiceClient = useMemo(() => new contentService(), []);
    const manager = new sessionManager();
    const userLoggedIn = JSON.parse(manager.getUserSession());

    const { contentType, contentId } = useParams();
    const [content, setContent] = useState(null);
    const [loading, setLoading] = useState(true);
    const [file, setFile] = useState(null);
    const [selectedFile, setSelectedFile] = useState(false);
    const [playTime, setPlayTime] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            var contentResponse = await contentServiceClient.getContentDetailsById(contentId, contentType);

            if (contentResponse.success) {
                setContent(contentResponse.value);
                setLoading(false);
            }
        };
        fetchData();

    }, [contentServiceClient, setLoading, setContent, contentId, contentType]);

    const reproduceFile = (url) => {
        setFile(url);
        setSelectedFile(true);
    }

    const handlePausedVideo = (time) => {
        setPlayTime(time);
    }

    return (
        <>
            <NavMenu showNavigation={true} currentPage={'library'} />
            <div className="view-content">
            {loading ? <LoadingSpinner /> : (
                <>
                    <div className="row">
                    <div className="col-xl-9 col-xxl-9">
                            <FileViewer file={file} handlePausedVideo={handlePausedVideo} userId={userLoggedIn.userId}></FileViewer>
                        </div>
                        <div className="col-xl-3 col-xxl-3">
                            <ContentFiles contentType={parseInt(contentType)} files={content.files} handleReproduceFile={reproduceFile}></ContentFiles>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-9 col-xxl-9">
                            <TabsSection content={content} userId={userLoggedIn.userId} selectedFile={selectedFile}
                                        playTime={playTime} contentType={parseInt(contentType)}>
                            </TabsSection>
                        </div>
                    </div>
                </>
            )}
            </div>
        </>
    )
}