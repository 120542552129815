import React, { useRef, useEffect, useMemo, useState, useCallback, forwardRef } from 'react';
import './style.scss';
import DatePicker, { registerLocale } from "react-datepicker";
import moment from 'moment'
import es from "date-fns/locale/es";
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from "@fullcalendar/interaction";
import allLocales from '@fullcalendar/core/locales-all';
import { DELAY_BEFORE_REDIRECT } from '../../../../../Utils/types';
import useTimeout from '../../../../../hooks/useTimeout/index';
import { getCurrentTime } from '../../../../../Utils/utils';
import { groupExperienceService } from '../../../../../services/groupExperienceService';
import LoadingSpinner from '../../../../Shared/LoadingSpinner';
import { EdowieModal } from '../../../../Shared/Modal';
import { InjectAppServices } from '../../../../../services/dependency-injection';

registerLocale("es", es);

const DatePickerButton = forwardRef(({ value, onClick }, ref) => (
    <>
        <button className="date-button" onClick={onClick} ref={ref}>
            {value}
        </button>
        <span className="change-week-legend">(Haga clic en la fecha para cambiar la semana)</span>
    </>
));

export const ConfigurationClasses = InjectAppServices(
    ({ 
        id, 
        handleBack, 
        handlePublish,
        dependencies: { sessionManager, diaryServiceClient }
    }) => {

    const groupExperienceServiceClient = useMemo(() => new groupExperienceService(), []);
    const calendarComponentRef = useRef(null);
    const userLoggedIn = sessionManager.getUserLoggedIn();
    const [events, setEvents] = useState([]);
    const [startDate, setStartDate] = useState(getCurrentTime(userLoggedIn.offset));
    const [groupExperience, setGroupExperience] = useState(null);
    const [loading, setLoading] = useState(true);
    const [numberOfClasses, setNumberOfClasses] = useState(0);
    const [showModal, setShowModal] = useState(false);

    const createTimeout = useTimeout();

    const mapEvent = useCallback((data) => {
        var startEventDate = moment(data.startDate + 'Z').add((-1) * moment().utcOffset(), 'minutes').add(userLoggedIn.offset, 'minutes')._d;
        var endEventDate = moment(data.endDate + 'Z').add((-1) * moment().utcOffset(), 'minutes').add(userLoggedIn.offset, 'minutes')._d;

        return { start: startEventDate, end: endEventDate, reserved: data.reserved, id: data.eventId, experienceId: data.experienceId,  published: groupExperience !== null ? groupExperience.published : false};
    }, [userLoggedIn.offset, groupExperience])
    
    const getEvents = useCallback(async (date) => {
        var week = getWeek(date);
        diaryServiceClient.getEventsByUserIdAndDates(userLoggedIn.userId, week[0], week[1])
            .then(response => {
                setEvents(response.map(mapEvent));
            })
        
    }, [diaryServiceClient, userLoggedIn.userId, mapEvent]);

    const getGroupExperience = useCallback(async () => {
        if (id !== undefined) {
            var response = await groupExperienceServiceClient.getGroupExperienceById(id);

            if (response.success) {
                setGroupExperience({
                    category: response.value.categoryId,
                    subcategory: response.value.subcategoryId,
                    title: response.value.title,
                    description: response.value.description,
                    detailedDescription: response.value.detailedDescription,
                    requirements: response.value.requirements,
                    level: response.value.level,
                    numberOfClasses: response.value.quantityClasses,
                    minutesDurationClasses: response.value.minutesDurationClasses,
                    registrationAvailableFor: response.value.availableInscriptionFor,
                    noRegistrationLimit: response.value.noRegistrationLimit,
                    registrationLimit: response.value.registrationLimit,
                    isFree: response.value.isFree,
                    price: response.value.price === 0 ? "" : response.value.price,
                    labels: response.value.tags,
                    coverImage: response.value.coverImage,
                    published: response.value.published,
                    classesNumber: response.value.classesNumber
                });

                setNumberOfClasses(response.value.classesNumber);
                setLoading(false);
            }
        }
    }, [groupExperienceServiceClient, id])

    useEffect(() => {
        const fetchData = async () => {
            getEvents(startDate);
        };
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getEvents]);

    useEffect(() => {
        const fetchData = async () => {
            getGroupExperience();
        };
        fetchData();

    }, [getGroupExperience]);

    const handleChangeDate = (date) => {
        setStartDate(date);
        //getEvents(date);

        let calendarApi = calendarComponentRef.current.getApi();
        calendarApi.gotoDate(date);
    }

    const handleDateClick = async(arg) => { // bind with an arrow function
        if (numberOfClasses === groupExperience.numberOfClasses) {
            setShowModal(true);
        } else {

            if (!moment(arg.date).isBefore(getCurrentTime(userLoggedIn.offset))) {
                var response = await groupExperienceServiceClient.addClass(userLoggedIn.userId, id, moment(arg.date + 'Z')._d, groupExperience.minutesDurationClasses, userLoggedIn.offset);

                if (response.success) {
                    getEvents(startDate);
                    getGroupExperience();
                }
            }
        }
    }

    const getWeek = (date) => {
        var current = date; //new Date(fecha);

        const week = [];
        const weekFormat = [];

        if (current.getDay() === 0) {
            current.setDate(((current.getDate() - 7) + 1));
        } else {
            current.setDate(((current.getDate() - current.getDay()) + 1));
        }

        week.push(new Date(current));
        current.setDate(current.getDate() + 6);
        week.push(new Date(current));

        week.forEach((w) => {
            weekFormat.push(moment(w).format('YYYY-MM-DD'));
        });

        return weekFormat;
    }

    const deleteClass = async(eventId) => {
        var response = await groupExperienceServiceClient.deleteClass(userLoggedIn.userId, id, eventId);
        if (response.success) {
            getEvents(startDate);
            getGroupExperience();
        }
    }

    const publish = async(values) => {
        var response = await groupExperienceServiceClient.publish(userLoggedIn.userId, id);

        if (response.success) {
            createTimeout(() => {
                handlePublish();
            }, DELAY_BEFORE_REDIRECT);
        }
    }

    const goToPreviousWeek = () => {
        let calendarApi = calendarComponentRef.current.getApi();
        calendarApi.prev();

        var date = calendarApi.currentData.dateProfile.currentRange.end;
        setStartDate(date);
        getEvents(date);
    }

    const goToNextWeek = () => {
        let calendarApi = calendarComponentRef.current.getApi();
        calendarApi.next();

        var date = calendarApi.currentData.dateProfile.currentRange.end;
        setStartDate(date);
        getEvents(date);
    }

    function renderEventContent(eventInfo) {
        var experienceId = eventInfo.event.extendedProps.experienceId;
        var published = eventInfo.event.extendedProps.published;
        var reserved = eventInfo.event.extendedProps.reserved;

        return (
            <>
                {moment(eventInfo.event.start).isBefore(getCurrentTime(userLoggedIn.offset)) ? (
                    <div className={'fc-past-event'}>
                        <b>{eventInfo.timeText}</b>
                    </div>
                ) :
                reserved === true ? (
                    <div className={'fc-reserved-event'}>
                        <b>{eventInfo.timeText}</b>
                    </div>
                ) : 
                (
                    <>
                    {experienceId === id ? (
                        <div className={'fc-event-group-experience'}>
                            {published === false && experienceId === id && (
                            <span onClick={() => deleteClass(eventInfo.event.id)} className='remove-event closeon'>X</span>
                            )}
                            <b>{eventInfo.timeText}</b>
                        </div>
                    ) : (
                    <div className={'fc-available-event'}>
                        <>
                            <div className='available-event'>
                                <b>{eventInfo.timeText}</b>
                            </div>
                        </>
                    </div>
                    )}
                    </>
                )}
            </>
        )
    }

    return (
        <>
        {loading ? <LoadingSpinner /> : (
        <div className="availability-classes">
            <h2 className="availability-classes-title">
            Configuración de las clases para la experiencia grupal
            </h2>
            <div className="date-picker-container">
                <div className="user-information">
                    <div className="country-logged-user">
                        <div className="container">
                            <img className="flag" src={userLoggedIn.flag} alt='flag' />
                            <p className="country">{userLoggedIn.country}</p>
                        </div>
                    </div>
                    <span className="time-use">({userLoggedIn.timeUse})</span>
                </div>
                <div className='availability-classes-duration'>
                    <span className="label">Duración de la experiencia:</span>
                    <label className="duration">{`${groupExperience.minutesDurationClasses} min`}</label>
                </div>
                <div className='availability-classes-duration'>
                    <span className="label">Cantidad de clases:</span>
                    <label className="duration">{`${numberOfClasses}/${groupExperience.numberOfClasses}`}</label>
                </div>
                <DatePicker locale='es' selected={startDate} onChange={(date) => handleChangeDate(date)} customInput={<DatePickerButton />}
                    portalId="root-portal" showMonthDropdown showYearDropdown yearDropdownItemNumber={15}
                    scrollableYearDropdown adjustDateOnChange />
            </div>
            <div className="details">
                <div className="references">
                    <div className="references-container">
                        <div className="title">
                            <h4>Referencias</h4>
                        </div>
                        <div className="reference">
                            <div className="past"></div>
                            <div className="legend">Pasado</div>
                        </div>
                        <div className="reference">
                            <div className="available"></div>
                            <div className="legend">Disponible</div>
                        </div>
                        <div className="reference">
                            <div className="reserved"></div>
                            <div className="legend">Reservado</div>
                        </div>
                        <div className="reference">
                            <div className="group-experience"></div>
                            <div className="legend">Clases de la Experiencia</div>
                        </div>
                    </div>
                </div>
                <div className="calendar">
                    <FullCalendar
                        id={'fullCalendar'}
                        plugins={[timeGridPlugin, interactionPlugin]}
                        initialView="timeGridWeek"
                        slotDuration={`00:${groupExperience.minutesDurationClasses}`}
                        allDaySlot={false}
                        events={events}
                        dateClick={handleDateClick}
                        locales={allLocales} locale={'es'}
                        headerToolbar={{
                            left: 'prev,next',
                            center: 'title',
                            right: "",
                        }}
                        ref={calendarComponentRef}
                        eventContent={renderEventContent}
                        customButtons={{
                            prev: {click: () => goToPreviousWeek()},
                            next: {click: () => goToNextWeek()},
                          }}
                    />
                </div>
            </div>
            <div className='availability-classes-buttons'>
                <button className='button back-button' onClick={() => handleBack()}>Atrás</button>
                {!groupExperience.published && numberOfClasses === groupExperience.numberOfClasses && (
                <button className='button save-button' onClick={() => publish()}>Publicar</button>
                )}
                <EdowieModal show={showModal} title={"Número de clases excedida"} modalClass={'availability-classes-modal'}
                    close={() => { setShowModal(false) }} handleAccept={() => { setShowModal(false) }}>
                    <div className="activity-item-content">{`Ha alcanzado el número de clases para la experiencia grupal.`}</div>
                </EdowieModal>
            </div>
        </div>)}
        </>
    )
})