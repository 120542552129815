import React from 'react';
import './style.scss';
import styled from 'styled-components';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const ImageContainer = styled.div`
  background-image: url(${props => props.image});background-size: cover;background-position: center center;height: 305px;`;

export const Header = ({ profile }) => {

    return (
        <>
            <ImageContainer className="header" image={profile.coverImage.path}>
                <div className="detalis">
                    <div className="row">
                        <div className="col-lg-12 col-sm-1 row-spacing">
                            <div className="user-copy-clipboard">
                                <div>
                                    <a href={`/${profile.profile === 'Edowier' ? 'teacher-profile' : 'user-profile'}/${profile.userId}`}>
                                        <figure className="user-image-container">
                                            <img src={profile.profilePhoto.path} alt="user" className="user-image" />
                                        </figure>
                                        <h4 className="username">{profile.username}</h4>
                                    </a>
                                    <div className="email">
                                        <span>{profile.email}</span>
                                    </div>
                                    <div>
                                        <span className="profile-title">Perfil:</span>
                                        <span className="profile-value">{profile.profile}</span>
                                    </div>
                                </div>
                                <div className="copy-clipboard">
                                    <CopyToClipboard text={`${process.env.REACT_APP_EDOWIE_APP}/${profile.username}`}
                                        >
                                        <button title="Compartir perfil público"><i className="feather-copy font-xxl text-white"></i></button>
                                    </CopyToClipboard>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </ImageContainer>
        </>
    )
}
