import { useTranslation } from "react-i18next"
import { NavMenu } from "../../Menu/NavMenu";
import './style.scss';
import { Form, Formik } from "formik";
import LoadingSpinner from "../../Shared/LoadingSpinner";
import { getFormInitialValues } from "../../../Utils/utils";
import { useEffect, useMemo, useState } from "react";
import { FieldGroup, FieldItem, SelectFieldItem, SubmitButton } from "../../Shared/FormHelpers";
import { InjectAppServices } from "../../../services/dependency-injection";
import { useParams } from "react-router-dom";
import { Message } from "../../Shared/Message";
import { DELAY_BEFORE_REDIRECT, Status } from "../../../Utils/types";
import useTimeout from "../../../hooks/useTimeout";
import { contentService } from "../../../services/contentService";
import { groupExperienceService } from "../../../services/groupExperienceService";
import { CopyToClipboard } from 'react-copy-to-clipboard';

const fieldNames = {
    promocodes: 'promocodes',
    discount: 'discount',
};

const discounts = [
    { key: '5', value: '5' },
    { key: '10', value: '10' },
    { key: '15', value: '15' },
    { key: '20', value: '20' },
    { key: '25', value: '25' },
    { key: '30', value: '30' },
    { key: '35', value: '35' },
    { key: '40', value: '40' },
    { key: '45', value: '45' },
    { key: '50', value: '50' },
    { key: '55', value: '55' },
    { key: '60', value: '60' },
    { key: '65', value: '65' },
    { key: '70', value: '70' },
    { key: '75', value: '75' },
    { key: '80', value: '80' },
    { key: '85', value: '85' },
    { key: '90', value: '90' },
    { key: '95', value: '95' },
    { key: '100', value: '100' },
]

const promocodes = [
    { key: 5, value: 5 },
    { key: 10, value: 10 },
]

export const Promocodes = InjectAppServices(
    ({
        dependencies: { sessionManager, configurationServiceClient, servicePromocodeServiceClient }
    }) => {

    const contentServiceClient = useMemo(() => new contentService(), []);
    const groupExperienceServiceClient = useMemo(() => new groupExperienceService(), []);
    const { t, i18n } = useTranslation(['promocodes', 'common']);
    const createTimeout = useTimeout();

    const { serviceType, serviceId } = useParams();
    const [loading, setLoading] = useState(true);
    const [configuration, setConfiguration] = useState({});
    const [status, setStatus] = useState('');
    const [error, setError] = useState('');
    const [service, setService] = useState({});
    const [generatedCodes, setGeneratedCodes] = useState([]);
    const [generated, setGenerated] = useState(false);
    const [state, setState] = useState({});
    const [disabled, setDisabled] = useState(false);
    const defaultOption = { key: '', value: t('selectAnOption', {ns: 'common'}) };

    useEffect(() => {
        const fetchData = async () => {

            if (serviceType === "1" || serviceType === "2") {
                var contentResponse = await contentServiceClient.getContentDetailsById(serviceId, serviceType);
                if (contentResponse.success) {
                    setService(contentResponse.value);
                }
            } else {
                var groupExperienceResponse = await groupExperienceServiceClient.getGroupExperienceById(serviceId);
                if (groupExperienceResponse.success) {
                    setService(groupExperienceResponse.value);
                }
            }

            configurationServiceClient.getByKey('DiscountService:MinimumServiceAmount')
                .then(response => {
                    setConfiguration(response);
                })

            setLoading(false);
        };
        fetchData();

    }, [i18n.language, configurationServiceClient, serviceId, serviceType, contentServiceClient, groupExperienceServiceClient]);

    const generateCodes = (values) => {
        setStatus('');

        const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'

        var codes = [];
        for (let code = 0; code < values.promocodes; code++)
        {
            let result = '';
            for (let i = 0; i < 10; i++){
                result += chars.charAt(Math.floor(Math.random() * chars.length));
            }

            codes.push(result);
        }

        setGeneratedCodes(codes);
        setGenerated(true);
        setState({serviceId: serviceId, serviceType: serviceType, discount: values.discount, codes: codes});
    }

    const savePromocodes = () => {
        setDisabled(true);
        servicePromocodeServiceClient.add(state.serviceId, state.serviceType, state.discount, state.codes, sessionManager.getUserLoggedIn().userId)
        .then(() => {
            setStatus(Status.Saved);
                createTimeout(() => {
                    window.location.href = `/manage-services/${serviceType === "1" || serviceType === "2" ? 1 : 3}`;
                }, DELAY_BEFORE_REDIRECT);
        })
        .catch((error) => {
            setStatus(Status.HasError);
            setError(error);
        });
    }

    function validateAmount(value) {
        let error;
        if (!value) {
            error = t('requiredField', {ns: 'promocodes'});
        } else {
            const price = service.price
            const amount = price - ((price *parseInt(value)) / 100);

            if (amount < parseInt(configuration.value)) {
                const minimumAmount = parseFloat(configuration.value).toFixed(2);
                const total = amount.toFixed(2);
                error = t('minimumServiceAmountError', {ns: 'promocodes', minimumAmount, total});
            }
        }

        return error;
    } 

    const showMessage = [Status.Saved, Status.HasError].includes(status);

    return (
        <>
            <NavMenu showNavigation={true} currentPage={'promocodes'} />
            <div className="promocodes">
                <div className="promocodes-card">
                    <div className="promocodes-header">
                        <a href="manage-services" className="promocodes-back">
                            <i className="ti-arrow-left"></i>
                        </a>
                        <h4>
                        {t('title', {ns: 'promocodes'})} - {t(`${serviceType === '1' ? 'courseTitle' : serviceType === '2' ? 'resourceTitle' : 'groupExperienceTitle'}`, {ns: 'promocodes'})}: {service.title}
                        </h4>
                    </div>
                    {loading ? <LoadingSpinner /> : (
                        <>
                            <div className="promocodes-legend ">
                                <p>{t('subtitle', {ns: 'promocodes'})}</p>
                            </div>
                            <div className="promocodes-form ">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <Formik
                                            onSubmit={generateCodes}
                                            initialValues={getFormInitialValues(fieldNames)}
                                        >
                                            {({ isSubmitting }) => (
                                                <Form>
                                                    <fieldset className="form-fields">
                                                        <FieldGroup>
                                                            <FieldItem className="field-item">
                                                                <FieldGroup>
                                                                    <SelectFieldItem
                                                                        fieldName={fieldNames.promocodes}
                                                                        id={fieldNames.promocodes}
                                                                        label={`*${t('numberOfCodesLabel', {ns: 'promocodes'})}`}
                                                                        defaultOption={defaultOption}
                                                                        values={promocodes}
                                                                        className="field-item--100"
                                                                        required
                                                                    />
                                                                </FieldGroup>
                                                            </FieldItem>
                                                            <FieldItem className="field-item">
                                                                <FieldGroup>
                                                                    <SelectFieldItem
                                                                        fieldName={fieldNames.discount}
                                                                        id={fieldNames.discount}
                                                                        label={`*${t('offPercentageLabel', {ns: 'promocodes'})}`}
                                                                        defaultOption={defaultOption}
                                                                        values={discounts}
                                                                        className="field-item--100"
                                                                        required={true}
                                                                        validate={validateAmount}
                                                                    />
                                                                </FieldGroup>
                                                            </FieldItem>
                                                            <FieldItem className="field-item">
                                                                <FieldGroup>
                                                                    <div className="save-button">
                                                                        <SubmitButton isSubmitting={isSubmitting}>
                                                                            {t('generateButton', {ns: 'promocodes'})}
                                                                        </SubmitButton>
                                                                    </div>
                                                                </FieldGroup>
                                                            </FieldItem>
                                                        </FieldGroup>
                                                    </fieldset>
                                                </Form>
                                            )}
                                        </Formik>
                                    </div>
                                </div>
                            </div>
                            { generated && (
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className='promocodes-grid'>
                                            <div className="promocodes-grid-header">
                                                <h4 className="promocodes-grid-header-title">{t('promotionCodesLegend', {ns: 'promocodes'})}</h4>
                                            </div>
                                            <div className="promocodes-tags">
                                                {generatedCodes.map((code) => (
                                                    <CopyToClipboard key={code} text={code}>
                                                    <span key={code} title={t('copyCodeTooltip', {ns: 'promocodes'})} className={'promocodes-tags-tag'}>
                                                        {code}
                                                    </span>
                                                    </CopyToClipboard>
                                                ))}
                                            </div>
                                            <div className="promocodes-buttons">
                                                <button onClick={() => savePromocodes()}
                                                        disabled={disabled}
                                                        className={
                                                            'save-code-button' +
                                                            ((disabled && ' button--loading') || '')
                                                        }>
                                                    {t('saveButton', {ns: 'promocodes'})}
                                                </button>
                                            </div>
                                            {showMessage && (
                                                <Message
                                                    type={status === Status.Saved ? 'success' : 'error'}
                                                    message={
                                                        status === Status.Saved
                                                            ? t('successfullyMessage', {ns: 'common'})
                                                            : error}
                                                    />
                                            )}
                                        
                                        </div>
                                    </div>
                                </div>
                            )}
                        </>
                    )}
                </div>
            </div>
        </>
    )
},
)