import { useState } from 'react';
import './style.scss';
import { NavMenu } from "../../../Menu/NavMenu"
import { Steper } from "../../../Shared/Steper"
import { ConfigurationForm } from "./ConfigurationForm"
import { useNavigate, useParams } from 'react-router-dom';
import { ConfigurationClasses } from './ConfigurationClasses';

export const GroupExperience = () =>
{
    const { id } = useParams();

    const [groupExperienceId, setGroupExperienceId] = useState(id);
    const [activeStep, setActiveStep] = useState(1);
    const navigate = useNavigate();

    const steps = [
        {
            label: 'Configuración',
            icon: '',
            canClick: false,
            active: activeStep === 1,
            completed: activeStep > 1
        },
        {
            label: 'Clases',
            icon: '',
            canClick: false,
            active: activeStep === 2
        },
      ];

    const saveConfiguration = (value) => {
        setGroupExperienceId(value);
        setActiveStep(2);
    }

    const published = () => {
        navigate(`/manage-services/3`);
    }
    
    return (
        <>
            <NavMenu showNavigation={true} currentPage={'group-experience'} />
            <div className='group-experience-section'>
                <Steper steps={steps}></Steper>
                {activeStep === 1 && (
                    <ConfigurationForm id={groupExperienceId} handleSave={saveConfiguration}></ConfigurationForm>
                )}
                {activeStep === 2 && (
                    <ConfigurationClasses id={groupExperienceId}
                                          handleBack={() => setActiveStep(1)}
                                          handlePublish={() => published()}></ConfigurationClasses>
                )}
            </div>
            
        </>
    )
}