import React, { useState } from 'react';
import './style.scss';
import DocViewer from "@cyntler/react-doc-viewer";
import Reproduccion from '../../../../general/images/reproduccion-contenido.png'
import ReactPlayer from 'react-player'
import { NotesSection } from '../NotesSection';

export const FileViewer = ({file, userId, handlePausedVideo}) => {
  const [playTime, setPlayTime] = useState('');

    const docs = file === null ? [] :[
        { uri: file.path}, 
      ];

    const pad = (string) => {
      return ('0' + string).slice(-2)
    }

    const onPaused = (currentTime) => {
      const date = new Date(currentTime * 1000)
      const hh = date.getUTCHours()
      const mm = date.getUTCMinutes()
      const ss = pad(date.getUTCSeconds())
      if (hh) {
        setPlayTime(`${hh}:${pad(mm)}:${ss}`);
      }

      setPlayTime(`${mm}:${ss}`);
    }

    return (
        <>
          <div className="file-viewer">
            {file === null ? (
            <img className='image-without-file' src={Reproduccion} alt='reproduce'></img>) :
            (
              <div className='row'>
                  <div className="col-xl-9 col-xxl-9">
                  {file.canDownload ? (
                    <DocViewer documents={docs} prefetchMethod="GET"
                      config={{
                          header: {
                            disableHeader: true,
                          },
                        }}
                      style={{ width: '100%', height: 500 }} 
                    />) : (
                    file.isVideo ? (
                    <ReactPlayer
                      url={file.path}
                      className='react-player'
                      width='100%'
                      height='100%'
                      disabled={true}
                      controls={true}
                      onPause={e => onPaused(e.target.currentTime)}
                      onSeek={e => console.log('onSeek', e)}
                      config={{
                        file: { 
                          attributes: { 
                            poster: {Reproduccion}
                          } 
                        } 
                      }}
                    />) : (
                      <>
                        <img className='image-audio-file' src={Reproduccion} alt='reproduce'></img>
                        <ReactPlayer
                        url={file.path}
                        className='react-player'
                        width='100%'
                        height='50px'
                        disabled={true}
                        controls={true}
                        onPause={e => onPaused(e.target.currentTime)}
                        onSeek={e => console.log('onSeek', e)}
                        config={{
                          file: { 
                            attributes: { 
                              poster: {Reproduccion}
                            } 
                          } 
                        }}
                      />
                      </>
                    )
                      )}
                  </div>
                  <div className="col-xl-3 col-xxl-3">
                    <NotesSection playTime={playTime} isVideo={!file.canDownload} contentFileId={file.fileId} userId={userId}></NotesSection>
                  </div>
              </div>
            )}
          </div>
        </>
    );
}