import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { ReviewStars } from '../../../../Shared/ReviewStars/index';
import './style.scss';
import { groupExperienceService } from '../../../../../services/groupExperienceService';
import { ServiceType } from '../../../../../Utils/types';
import { QualificationModal } from '../../../../Shared/QualificationModal';
import { getUserLoggedIn } from '../../../../../Utils/utils';

const ImageContainer = styled.div`
  background-image: url(${props => props.image});background-size: cover;background-position: center center;height: 322px;`;

export const Header = ({ groupExperience, userId, wasEnrolled }) => {
    const groupExperienceServiceClient = useMemo(() => new groupExperienceService(), []);
    
    const [inFavorities, setInFavorties] = useState(false);
    const [showModal, setShowModal] = useState(false);

    const userLoggedIn = getUserLoggedIn();
    const showFavoritesButton = !wasEnrolled && (userLoggedIn.userId !== groupExperience.userId) && userLoggedIn.userId > 0;

    useEffect(() => {
        const fetchData = async () => {
            if (userLoggedIn.userId > 0) {
                var hasInFavoritesResponse = await groupExperienceServiceClient.hasInFavorites(userLoggedIn.userId, groupExperience.groupExperienceId);

                if (hasInFavoritesResponse.success) {
                    setInFavorties(hasInFavoritesResponse.value);
                }
            }
        };
        fetchData();

    }, [groupExperienceServiceClient, setInFavorties, userLoggedIn.userId, groupExperience.groupExperienceId]);

    const showRatings = () => {
       setShowModal(true);
    }

    const addToFavorites = async () => {
        var addToFavoritesResponse = await groupExperienceServiceClient.addFavorites(userLoggedIn.userId, groupExperience.groupExperienceId);

        if (addToFavoritesResponse.success) {
            setInFavorties(true);
        }
    }

    const deleteToFavorites = async () => {
        var deleteToFavoritesResponse = await groupExperienceServiceClient.deleteFavorites(userLoggedIn.userId, groupExperience.groupExperienceId);

        if (deleteToFavoritesResponse.success) {
            setInFavorties(false);
        }
    }

    return (
        <ImageContainer className="header" image={groupExperience.coverImage.path}>
            <div className="card">
                <div className="tags-favorites">
                    <div className="tags">
                        {groupExperience.tags.split(',').map((tag) => (
                            <span key={tag} className={`tag group-experience-tag`}>
                                {tag}
                            </span>
                        ))}
                    </div>
                    <div className="favorites-group-experience">
                        {showFavoritesButton && (<button
                            title={`${inFavorities ? 'Quitar de' : 'Agregar a'} favoritos`}
                            className={`button ${inFavorities ? 'delete-favorities' : 'add-favorties'}`}
                            onClick={() => {
                                if (inFavorities) {
                                    deleteToFavorites();
                                } else {
                                    addToFavorites();
                                }
                            }}
                        >
                            <i className="feather-bookmark"></i>
                        </button>
                        )}
                    </div>
                </div>
                <h2 className="title">{groupExperience.title}</h2>
                <p className="description">{groupExperience.description}</p>
                <span className="labels">{groupExperience.labels}</span>
                <button onClick={() => showRatings()} className="show-ratings">
                    <div className="qualification">
                        <ReviewStars averageQualifications={groupExperience.averageReview}></ReviewStars>
                        <p className="reviews">{groupExperience.reviews} Reseña/s en Experiencias Grupales.</p>
                        <QualificationModal 
                            showModal={showModal} 
                            serviceId={groupExperience.groupExperienceId}
                            serviceType={ServiceType.GrupalExperience}
                            handleAccept={() => setShowModal(false)}
                            handleClose={() => setShowModal(false)}></QualificationModal>
                    </div>
                </button>
                <span className="enrolled-by">{`${groupExperience.numberOfRegistrations} usuario/s inscriptos`}</span>
            </div>
        </ImageContainer>
    )
}