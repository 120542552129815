import React, { useEffect , useState} from 'react';
import './style.scss'
import LoadingSpinner from "../../Shared/LoadingSpinner/index";
import moment from 'moment'
import 'moment/locale/es';
import { Trans, useTranslation } from 'react-i18next';
import { InjectAppServices } from '../../../services/dependency-injection';

export const UpcomingEvents = InjectAppServices(
    ({
        dependencies: { sessionManager, diaryServiceClient }
    }) => {
    const [nextEvent, setNextEvent] = useState({});
    const [loading, setLoading] = useState(true);
    const userLoggedIn = sessionManager.getUserLoggedIn();

    const { t } = useTranslation(['home']);
    const nextEventTranslations = t('nextEvent');

    useEffect(() => {
        const fetchData = async () => {
            if (userLoggedIn.userId > 0) {
                diaryServiceClient.getNextEventByUserId(userLoggedIn.userId)
                    .then(response => {
                        setNextEvent(mapEvent(response));
                        setLoading(false);
                })
            }
        };
        fetchData();

    }, [userLoggedIn.userId, diaryServiceClient]);

    useEffect(() => {
        const interval = setInterval(async () => {
            if (userLoggedIn.userId > 0) {
                const response = await diaryServiceClient.getNextEventByUserId(userLoggedIn.userId);

                if (response.success) {
                    setNextEvent(mapEvent(response.value));
                    setLoading(false);
                }
            }
          },60*1000);
          return () => clearInterval(interval);
          // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);

    const mapEvent = (nextEvent) => {
        if (nextEvent !== null && nextEvent !== '') {
            var counter = '';
           //var date = '';

            if (!nextEvent.isInProgress) {
                var startDate = moment(nextEvent.startDate).add(nextEvent.offset, 'm').toDate();
                var endDate = moment(nextEvent.endDate).add(nextEvent.offset, 'm').toDate();

                var dayNow = moment()._d.getUTCDate();
                var yearNow = moment()._d.getUTCFullYear();
                var monthNow = moment()._d.getUTCMonth();
                var hoursNow = moment()._d.getUTCHours();
                var minutesNow = moment()._d.getUTCMinutes();
    
                var nowDate = new Date(yearNow, monthNow, dayNow, hoursNow, minutesNow);
                var difference = startDate - moment(nowDate).add(nextEvent.offset, 'm').toDate();

                var days = moment.duration(difference).days() > 0 ? moment.duration(difference).days().toString() + "d " : '';
                var hours = moment.duration(difference).hours() > 0 ? moment.duration(difference).hours().toString() + "h " : '';
                var minutes = moment.duration(difference).minutes() > 0 ? moment.duration(difference).minutes().toString() + "m " : '';

                counter = days + hours + minutes;
            }

            return {
                eventId: nextEvent.eventId,
                offset: nextEvent.offset,
                type: nextEvent.type,
                videoConferenceLink: nextEvent.videoConferenceLink,
                isInProgress: nextEvent.isInProgress,
                counter: counter,
                date: startDate,
                time: !nextEvent.isInProgress ? moment(startDate).format('LT') + ' - ' + moment(endDate).format('LT') : ''
            }
        } else {
            return null;
        }
    }

    return (
        <>
        {loading ? <LoadingSpinner /> : (
            <>
                <div className="upcoming-events">
                    <div className="upcoming-events-title-section">
                        <h4 className="upcoming-events-title">{nextEventTranslations.title}</h4>
                    </div>
                    {nextEvent !== null ? (
                        <>
                            <div className="upcoming-events-counter-section">
                                <span className={`upcoming-events-counter ${nextEvent.isInProgress ? 'in-progress' : ''}`}>
                                    {nextEvent.isInProgress ? nextEventTranslations.inProgressMessage : nextEvent.counter}
                                </span>
                            </div>
                            {!nextEvent.isInProgress && (
                            <div className="upcoming-events-date-section">
                                <span className='upcoming-events-date'>
                                    <Trans
                                        t={t}
                                        i18nKey={'nextEvent.date'}
                                        values={{  
                                            date: nextEvent.date,
                                            time: nextEvent.time
                                        }}
                                    />
                                </span>
                            </div>
                            )}
                            <div className="upcoming-events-type-section">
                                <span className='upcoming-events-type'>
                                    {nextEvent.type === 'Experiencia Particular' ? 
                                        nextEventTranslations.particularExperienceMessage :
                                        nextEventTranslations.groupExperienceMessage}
                                </span>
                            </div>
                            {nextEvent.isInProgress && (
                                <div className="upcoming-events-connect-section">
                                    <div className="">
                                        <a className='upcoming-events-connect-button' href={nextEvent.videoConferenceLink} target='_blank' rel="noreferrer">
                                            <i className="feather-video upcoming-events-connect-button-icon"></i>
                                        </a>
                                    </div>
                                </div>
                            )}
                        </>
                    ) : (
                        <span>No tienes ningún evento programado</span>
                    )}
                </div>
            </>
        )}
        </>
    )
})