import { useCallback, useEffect, useState } from "react";
import { InjectAppServices } from "../../../../services/dependency-injection";
import './style.scss';
import LoadingSpinner from "../../../Shared/LoadingSpinner";
import { userService } from "../../../../services/userService";
import { Trans, useTranslation } from "react-i18next";
import { Transactions } from "./Transactions";
import { WithdrawalBankTransferModal } from "./Transactions/PaginatedItems/Item/WithdrawalBankTransferModal";
import { WithdrawalPayoneerModal } from "./Transactions/PaginatedItems/Item/WithdrawalPayoneerModal";
import { WithdrawalCryptocurrencyModal } from "./Transactions/PaginatedItems/Item/WithdrawalCryptocurrencyModal";

export const Balance = InjectAppServices(
    ({
        dependencies: { sessionManager }
    }) => {
        const { t } = useTranslation(['balance']);
        const userLoggedIn = sessionManager.getUserLoggedIn();
        const [balance, setBalance] = useState();
        const [loading, setLoading] = useState(true);
        const [showWithdrawalBankTransferModal, setShowWithdrawalBankTransferModal] = useState(false);
        const [showWithdrawalPayoneerModal, setShowWithdrawalPayoneerModal] = useState(false);
        const [showWithdrawalCryptocurrencyModal, setShowWithdrawalCryptocurrencyModal] = useState(false);

        const getBalance = useCallback(async () => {
            userService.balance(userLoggedIn.userId)
                .then(response => {
                    setBalance(response);
                    setLoading(false);
                })
                .catch(() => {
                    setLoading(false);
                });;

        }, [userLoggedIn.userId]);

        useEffect(() => {
            const fetchData = async () => {
                getBalance();
            };
            fetchData();
        }, [getBalance]);

        const handleWithdrawal = () => {
            setLoading(true);
            getBalance();
        }

        return (
            <>
                {loading ? 
                    <LoadingSpinner/> : (
                    <div className="balance">
                        <div className="row">
                            <div className="col-xl-9">
                                <div className="balance-total-section">
                                    <h2 className="balance-total-section-total">USD {balance.balance.toFixed(2)}</h2>
                                </div>
                            </div>
                            <div className="col-xl-3">
                                <div className="balance-retirement-section">
                                    <h3 className="balance-retirement-section-title">
                                        <Trans
                                            t={t}
                                            i18nKey={`withdraw`}
                                            values={{ minimumToWithdraw: balance.minimumToWithdraw.toFixed(2) }}
                                        />
                                    </h3>
                                    <div className="balance-retirement-section-buttons">
                                        <button className="balance-retirement-section-buttons-button"
                                                disabled={(!balance.blockWithdrawals && Number(balance.balance) < Number(balance.minimumToWithdraw)) || 
                                                            balance.blockWithdrawals}
                                                onClick={() => { setShowWithdrawalBankTransferModal(true) }}>
                                            {t('transfer',{ns: 'balance'})}
                                        </button>
                                        <WithdrawalBankTransferModal 
                                            show={showWithdrawalBankTransferModal}
                                            userId={userLoggedIn.userId}
                                            minimumToWithdraw={balance.minimumToWithdraw}
                                            balance={balance.balance}
                                            handleClose={() => setShowWithdrawalBankTransferModal(false)}
                                            handleWithdrawakBankTransfer={() => { setShowWithdrawalBankTransferModal(false); handleWithdrawal();}}>
                                        </WithdrawalBankTransferModal>
                                        <button className="balance-retirement-section-buttons-button"
                                                disabled={(!balance.blockWithdrawals && Number(balance.balance) < Number(balance.minimumToWithdraw)) || 
                                                            balance.blockWithdrawals}
                                                onClick={() => { setShowWithdrawalPayoneerModal(true) }}>
                                            {t('payoneer',{ns: 'balance'})}
                                        </button>
                                        <WithdrawalPayoneerModal 
                                            show={showWithdrawalPayoneerModal}
                                            userId={userLoggedIn.userId}
                                            minimumToWithdraw={balance.minimumToWithdraw}
                                            balance={balance.balance}
                                            handleClose={() => setShowWithdrawalPayoneerModal(false)}
                                            handleWithdrawalPayoneer={() => { setShowWithdrawalPayoneerModal(false); handleWithdrawal();}}>
                                        </WithdrawalPayoneerModal>
                                        <button className="balance-retirement-section-buttons-button"
                                                disabled={(!balance.blockWithdrawals && Number(balance.balance) < Number(balance.minimumToWithdraw)) || 
                                                            balance.blockWithdrawals}
                                                onClick={() => { setShowWithdrawalCryptocurrencyModal(true) }}>
                                            {t('cryptocurrency',{ns: 'balance'})}
                                        </button>
                                        <WithdrawalCryptocurrencyModal 
                                            show={showWithdrawalCryptocurrencyModal}
                                            userId={userLoggedIn.userId}
                                            minimumToWithdraw={balance.minimumToWithdraw}
                                            balance={balance.balance}
                                            handleClose={() => setShowWithdrawalCryptocurrencyModal(false)}
                                            handleWithdrawalCryptocurrency={() => { setShowWithdrawalCryptocurrencyModal(false); handleWithdrawal();}}>
                                        </WithdrawalCryptocurrencyModal>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-12">
                                <h3 className="balance-commission-section">
                                    {t('commission',{ns: 'balance'})}: <b>USD 0.30 + {balance.commission}%</b>
                                </h3>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-12">
                                <h3 className="balance-balance-history">{t('balanceHistory',{ns: 'balance'})}</h3>
                            </div>
                        </div>
                        <Transactions></Transactions>
                    </div>
                )}
            </>
        )
    }
)