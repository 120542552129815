import { Trans, useTranslation } from "react-i18next";

export const WelcomeEdowieSection = () => {
    const { t } = useTranslation(['terms_and_conditions']);
    const introductionSection = t('welcomeEdowieSection.introductionSection');
    const ourServiceSection = t('welcomeEdowieSection.ourServiceSection');
    const yourServiceProviderSection = t('welcomeEdowieSection.yourServiceProviderSection');
    const applicableConditionsSection = t('welcomeEdowieSection.applicableConditionsSection');

    return (
        <>
            <p className="section-subtitle">{introductionSection.title}</p>
            <p className="section-legend">
                {introductionSection.legend}
            </p>
            <p className="section-subtitle pt-15">{ourServiceSection.title}</p>
            <p className="section-legend">
                {ourServiceSection.legend}
            </p>
            <p className="section-subtitle pt-15">{yourServiceProviderSection.title}</p>
            <p className="section-legend">
                {yourServiceProviderSection.legend}
            </p>
            <p className="section-subtitle pt-15">{applicableConditionsSection.title}</p>
            <p className="section-legend">
                <Trans i18nKey={applicableConditionsSection.legend} components={{ 1: <br /> }} />
            </p>
        </>
    )
}