
export class sessionManager {

    setUserSession = (userLoggedIn) => {
        localStorage.setItem("UserLoggedIn", userLoggedIn);
    };

    getUserSession = () => {
        return localStorage.getItem("UserLoggedIn");
    };

    isUserLoggedIn = () => {
        return localStorage.getItem("UserLoggedIn")  !== null && localStorage.getItem("UserLoggedIn") !== "null";
    }

    getUserLoggedIn = () => {
        return localStorage.getItem("UserLoggedIn")  !== null && localStorage.getItem("UserLoggedIn") !== "null" ? 
                    JSON.parse(localStorage.getItem("UserLoggedIn")) : 
                    {userId: 0, profile: ''};
    };

    setNonLoggedInUserToken = (nonLoggedInUserToken) => {
        localStorage.setItem("NonLoggedInUserToken", nonLoggedInUserToken);
    };

    getNonLoggedInUserToken = () => {
        return localStorage.getItem("NonLoggedInUserToken");
    };
};