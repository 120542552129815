export const es = {
    donations: {
        title: 'Donación a {{username}}',
        choose_amount: 'Elegir el monto',
        custom_amount: 'Monto personalizado',
        amount_required: 'El monto es requerido',
        donate_button: 'Donar',
        successful_message: '¡Operación procesada con éxito!',
    },
    manage_services: {
        contents: {
            course: {
                title: 'Creá tu curso',
                subtitle: 'Dentro del contenido asincrónico, los cursos estan compuestos por 1 o más videos, que podrán ser visualizados dentro de la plataforma las veces que quieran sus compradores. Se podrá eliminar la oferta del curso pero lo seguirán teniendo en su Biblioteca quienes lo adquirieron.',
                header: 'Contenido: Cursos',
                fields: {
                    category: {
                        label: 'Categoría en Edowie',
                    },
                    subcategory: {
                        label: 'Subcategoría en Edowie',
                    },
                    title: {
                        label: 'Título',
                        placeholder: 'Título',
                    },
                    subtitle: {
                        label: 'Subtítulo',
                        placeholder: 'Subtítulo',
                    },
                    short_description: {
                        label: 'Descripción corta',
                        placeholder: 'Descripción corta',
                    },
                    detailed_description: {
                        label: 'Descripción detallada',
                        placeholder: 'Descripción detallada de lo que aprenderá quien adquiera el curso',
                    },
                    requirements: {
                        label: 'Requisitos',
                        placeholder: 'Requisitos',
                    },
                    level: {
                        label: 'Nivel',
                        placeholder: 'Nivel',
                    },
                    is_free: {
                        label: `Es gratis`,
                        placeholder: `Es gratis`,
                    },
                    price: {
                        label: 'Precio en USD',
                        placeholder: 'Precio',
                    },
                    tags: {
                        label: 'Etiquetas',
                        placeholder: 'Etiquetas',
                    },
                    cover_image: {
                        label: 'Imagen de Portada',
                        placeholder: 'Imagen de Portada',
                    },
                    videos: {
                        label: 'Videos',
                        placeholder: 'Videos',
                        required: 'Debe cargar al menos un video.',
                    },
                    additional_files: {
                        label: 'Archivos Adicionales',
                        placeholder: 'Archivos Adicionales',
                    },
                },
                save_button: 'Guardar',
                success_messages: '¡Operación procesada con éxito!',
            },
            resource: {
                title: 'Creá tu recurso',
                subtitle: 'Dentro del contenido asincrónico, los recursos estan compuestos por 1 o más archivos, pudiendo ser del tipo pdf, audiolibro u otros, que podrán ser visualizados dentro de la plataforma las veces que quieran sus compradores. Se podrá eliminar la oferta del recurso pero lo seguirán teniendo en su Biblioteca quienes lo adquirieron.',
                header: 'Contenido: Recursos',
                fields: {
                    category: {
                        label: 'Categoría en Edowie',
                    },
                    subcategory: {
                        label: 'Subcategoría en Edowie',
                    },
                    title: {
                        label: 'Título',
                        placeholder: 'Título',
                    },
                    short_description: {
                        label: 'Descripción corta',
                        placeholder: 'Descripción corta',
                    },
                    detailed_description: {
                        label: 'Descripción detallada',
                        placeholder: 'Descripción detallada de lo que aprenderá quien adquiera el recurso',
                    },
                    language: {
                        label: 'Idioma',
                        placeholder: 'Idioma',
                    },
                    level: {
                        label: 'Nivel',
                        placeholder: 'Nivel',
                    },
                    is_free: {
                        label: `Es gratis`,
                        placeholder: `Es gratis`,
                    },
                    price: {
                        label: 'Precio en USD',
                        placeholder: 'Precio',
                    },
                    tags: {
                        label: 'Etiquetas',
                        placeholder: 'Etiquetas',
                    },
                    cover_image: {
                        label: 'Imagen de Portada',
                        placeholder: 'Imagen de Portada',
                    },
                    resource_type: {
                        label: 'Tipo de recurso',
                        placeholder: 'Tipo de recurso',
                    },
                    files: {
                        label: 'Archivos',
                        placeholder: 'Archivos',
                        required: 'Debe cargar al menos un archivo.',
                    },
                },
                save_button: 'Guardar',
                success_messages: '¡Operación procesada exitosamente!',
            },
        },
        particular: {
            title: 'Particular',
            following_button: 'Siguiente',
            back_button: 'Atrás',
            continue_button: 'Continuar',
            deactivate_button: 'Desactivar',
            finish_button: 'Finalizar',
            stepper: {
                activate_step: 'Activar',
                general_conditions_step: 'Condiciones generales',
                themes_step: 'Temáticas',
                availability_step: 'Disponibilidad',
            },
            activate_step: {
                title: 'Configura tu servicio de experiencia particular en simples pasos',
                activate_button: 'Activar',
            },
            general_conditions_step: {
                title: 'Condiciones generales para tu oferta online de experiencia particular',
                about_me: {
                    label: 'Sobre mi experiencia particular online',
                    placeholder: 'Detalle de los conocimientos que ofrezco para ser contratado a una experiencia particular'
                },
                price: {
                    label: 'Precio experiencia particular en USD',
                    placeholder: 'Precio',
                },
                offer_your_first_free: {
                    label: 'Ofrecer primera experiencia particular gratis online a',
                },
                cancel_particular_contracted: {
                    label: '¿Cuánto tiempo de antelación tengo para cancelar una experiencia particular contratada evitando posibles denuncias?',
                },
                duration: {
                    label: 'Duración de la experiencia particular online',
                },
                minimum_notice: {
                    label: '¿Con cuánto tiempo mínimo de antelación establezco que puedan contratarme?',
                },
                offer_my_service: {
                    label: 'Voy a ofrecer mi servicio de experiencia particular online a',
                },
            },
            themes_step: {
                title: 'Completa la información necesaria para comunicar el conocimiento que quieres ofrecer online',
                themes_label: 'TEMÁTICAS',
                edit_theme_button: 'Editar tématica',
                delete_theme_button: 'Eliminar tématica',
                category: {
                    label: 'Categoría en Edowie',
                },
                subcategory: {
                    label: 'Subcategoría en Edowie',
                },
                topic_legend: 'Topicos',
                your_topics_legend: 'Tus tópicos',
                topic_description: {
                    label: 'Descripción del tópico',
                    placeholder: 'Ingrese la descripción del tópico',
                },
                topic_details: {
                    label: 'Detalle del tópico',
                    placeholder: 'Ingrese el detalle del tópico',
                },
                add_topic_button: 'Agregar tópico',
                delete_theme_modal: {
                    title: 'Eliminar temática',
                    legend: 'Se eliminará la temática. Esta seguro?.',
                },
            },
            availability_step: {
                title: 'Comunica tu disponibilidad horaria haciendo clic en las celdas (no olvides presionar el boton de Finalizar)',
                without_availability_title: 'Tu Estado: edowier contratable sin disponibilidad',
                with_availability_title: 'Tu Estado: edowier contratable con disponibilidad',
                duration_experience: 'Duración de la experiencia',
                references: {
                    title: 'Referencias',
                    past_legend: 'Pasado',
                    available_legend: 'Disponible',
                    reserved_legend: 'Reservado',
                },
                change_date_label: '(Haga clic en la fecha para cambiar la semana)',
                deactivate_partcular_experience_modal: {
                    title: 'Desactivar Experiencia Particular',
                    legend: 'Esta seguro de desactivar su experiencia particular?.',
                },
            },
        },
        group: {
            title: 'Grupal',
        },
        channels: {
            title: 'Canales',
        },
        donations: {
            title: 'Donaciones',
            activate_legend: 'Activar donaciones para que otro usuario te pueda enviar dinero.',
            deactivate_legend: 'Desactivar las donaciones',
            activate_button: 'Activar',
            deactivate_button: 'Desactivar',
            successful_message: '¡Operación procesada con éxito!',
        },
        promocodes: {
            title: 'Códigos',
        },
    },
    stripe: {
        pay_button: 'Pagar',
        cancel_button: 'Cancelar',
    },
    my_notifications: {
        title: 'Mis notificaciones',
        agreed_particular_experience: '<bold>{{username}}</bold> y <bold>{{edowierusername}}</bold> acordaron una experiencia particular para el dia <bold>{{date, DD/MM/YYYY}}</bold> a las <bold>{{time}}</bold>.. Minutos antes de comenzar se enviará una notificación con el link para conectar.',
        registered_grupal_experience: 'Te inscribiste en la experiencia grupal <bold>{{groupExperience}}</bold>. Unos minutos antes del inicio de cada clase se habilitará la conexión.',
        user_registered_your_grupal_experience: 'El usuario <bold>{{username}}</bold> se inscribió a la experiencia grupal <bold>{{groupExperience}}</bold>',
        already_edowier: '¡Felicitaciones! Ya sos edowier.',
        requested_follow_you: '<bold>{{username}}</bold> solicitó seguirte.',
        started_following_you: 'El {{profile}} <bold>{{username}}</bold> comenzó a seguirte.',
        acquired_course: '<bold>{{username}}</bold> adquirió el curso <bold>{{contentName}}</bold>',
        rated_course: '<bold>{{username}}</bold> calificó con un {{rate}} el curso <bold>{{contentName}}</bold>',
        acquired_resource: '<bold>{{username}}</bold> adquirió el recurso <bold>{{contentName}}</bold>',
        rated_resource: '<bold>{{username}}</bold> calificó con un {{rate}} el recurso <bold>{{contentName}}</bold>',
        updated_course: '<bold>{{username}}</bold> actualizó el curso <bold>{{contentName}}</bold>',
        updated_resource: '<bold>{{username}}</bold> actualizó el recurso <bold>{{contentName}}</bold>',
        published_course: '<bold>{{username}}</bold> publicó el curso <bold>{{contentName}}</bold> en <bold>{{category}}</bold>',
        published_resource: '<bold>{{username}}</bold> publicó el recurso <bold>{{contentName}}</bold> en <bold>{{category}}</bold>',
        donated: 'Donaste <bold>USD {{amount}}</bold> al edowier <bold>{{username}}</bold>',
        donation: 'El {{profile}} <bold>{{username}}</bold> te donó <bold>USD {{amount}}</bold>',
    },
    messages: {
        active_today: 'Activo/a hoy',
        active_yesterday: 'Activo/a ayer',
        active_days_ago: 'Activo/a hace {{days}} días',
        date_message: '{{date, dddd, DD MMMM YYYY}}',
        required_message: 'El mensaje es requerido.',
        placeholder_message: 'Escribir mensaje',
        send_message_tooltip: 'Enviar mensaje',
    },
    // validations: {
    //     required_field: 'El campo es requerido',
    //     invalid_user: 'El usuario es inválido',
    //     invalid_email: 'El email es inválido',
    // }
};

export default es;