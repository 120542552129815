import { useTranslation } from "react-i18next";

export const YourContentConductSection = () => {
    const { t } = useTranslation(['terms_and_conditions']);
    const contentUploadSection = t('yourContentConductSection.contentUploadSection');
    const rightsYouGrantSection  = t('yourContentConductSection.rightsYouGrantSection');
    const licenseForEdowieSection = t('yourContentConductSection.licenseForEdowieSection');
    const licenseForOtherUsers = t('yourContentConductSection.licenseForOtherUsers');
    const licenseDurationSection = t('yourContentConductSection.licenseDurationSection');
    const deletionOfYourContentSection = t('yourContentConductSection.deletionOfYourContentSection');
    const removalOfContentByEdowieSection = t('yourContentConductSection.removalOfContentByEdowieSection');
    const suspensionTerminationAccountsSection = t('yourContentConductSection.suspensionTerminationAccountsSection');
    const terminationsSuspensionsEdowieSection = t('yourContentConductSection.terminationsSuspensionsEdowieSection');
    const noticeTerminationSuspensionSection = t('yourContentConductSection.noticeTerminationSuspensionSection');

    return (
        <>
            <p className="section-subtitle">{contentUploadSection.title}</p>
            <p className="section-legend">
                {contentUploadSection.legend}
            </p>

            <p className="section-subtitle pt-05">{rightsYouGrantSection.title}</p>
            <p className="section-legend">
                {rightsYouGrantSection.legend}
            </p>

            <p className="section-subtitle pt-05">{licenseForEdowieSection.title}</p>
            <p className="section-legend">
                {licenseForEdowieSection.legend}
            </p>

            <p className="section-subtitle pt-05">{licenseForOtherUsers.title}</p>
            <p className="section-legend">
                {licenseForOtherUsers.legend}
            </p>

            <p className="section-subtitle pt-05">{licenseDurationSection.title}</p>
            <p className="section-legend">
                {licenseDurationSection.legend}
            </p>

            <p className="section-subtitle pt-05">{deletionOfYourContentSection.title}</p>
            <p className="section-legend">
                {deletionOfYourContentSection.legend}
            </p>

            <p className="section-subtitle pt-05">{removalOfContentByEdowieSection.title}</p>
            <p className="section-legend">
                {removalOfContentByEdowieSection.legend}
            </p>

            <p className="section-subtitle pt-05">{suspensionTerminationAccountsSection.title}</p>
            <p className="section-legend">
                {suspensionTerminationAccountsSection.legend1}.<br/>{suspensionTerminationAccountsSection.legend2} 
            </p>

            <p className="section-subtitle pt-05">{terminationsSuspensionsEdowieSection.title}</p>
            <p className="section-legend">
                {terminationsSuspensionsEdowieSection.legend}
            </p>

            <p className="section-subtitle pt-05">{noticeTerminationSuspensionSection.title}</p>
            <p className="section-legend">
                {noticeTerminationSuspensionSection.legend}
            </p>
        </>
    )
}