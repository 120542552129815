import './style.scss';
import NoUser from '../../../../general/images/no-user.jpg';
import { groupExperienceService } from '../../../../services/groupExperienceService';
import { sessionManager } from '../../../../sessionManager';
import { EdowieModal } from '../../../Shared/Modal';
import { useMemo, useState } from 'react';
import { InjectAppServices } from '../../../../services/dependency-injection';
import { useTranslation } from 'react-i18next';
import { ServiceType } from '../../../../Utils/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare, faPercent, faTag, faTrash } from '@fortawesome/free-solid-svg-icons';

export const GroupExperienceItem = InjectAppServices(
    ({  groupExperience, handleDelete,
        dependencies: {serviceDiscountServiceClient} 
    }) => {
        const { t } = useTranslation(['manage_services']);
        const groupExperienceServiceClient = useMemo(() => new groupExperienceService(), []);
        const manager = new sessionManager();

        const userLoggedIn = JSON.parse(manager.getUserSession());
        const [showModal, setShowModal] = useState(false);
        const [deletedGroup, setDeletedGroup] = useState(true);

        const deleteGroupExperience = async() => {
            var deleteResponse = await groupExperienceServiceClient.deleteGroupExperience(userLoggedIn.userId, groupExperience.groupExperienceId);
            if (deleteResponse.success) {
                handleDelete();
                setShowModal(false);
            }
        }

        const deleteDiscount = (serviceId, serviceType) => {
            serviceDiscountServiceClient.delete(serviceId, serviceType, userLoggedIn.userId)
            .then(() => {
                handleDelete();
                setShowModal(false);
            });
        }

        return (
            <>
            <div className="group-experience-item">
                <div className="group-experience-item-card">
                    <div className="group-experience-item-card-cover-image">
                        <a href={`/enroll-group-experience/${groupExperience.groupExperienceId}`}>
                            <img src={groupExperience.coverImage.path} alt="test" />
                        </a>
                    </div>
                    <div className="group-experience-item-card-details">
                        <span className="group-experience-item-card-details-subcategory">{groupExperience.subcategory}</span>
                        {!groupExperience.isFree && (
                        <span className="group-experience-item-card-details-price">
                            <span>$</span>{groupExperience.price}
                        </span>
                        )}
                        {groupExperience.isFree && (
                        <span className="group-experience-item-card-details-price">
                            <span>{t('freeMessage')}</span>
                        </span>
                        )}
                        <h4 className="group-experience-item-card-details-title">
                            <a href={`/enroll-group-experience/${groupExperience.groupExperienceId}`}>{groupExperience.title}</a>
                        </h4>
                        <h6 className="group-experience-item-card-details-description">{groupExperience.description}</h6>
                        <ul className="group-experience-item-card-details-registrations">
                            <li>
                                <a href={`/enroll-group-experience/${groupExperience.groupExperienceId}`}>
                                    <img src={NoUser} alt="user" className="no-user"/>
                                </a>
                            </li>
                            <li className="last-member">
                                <a href={`/enroll-group-experience/${groupExperience.groupExperienceId}`}>{groupExperience.numberOfRegistered}</a>
                            </li>
                            <li className="registered">
                                <a href={`/enroll-group-experience/${groupExperience.groupExperienceId}`}>{t('groupExperiences.registeredLabel', {ns: 'manage_services'})}</a>
                            </li>
                        </ul>
                        <div className="group-experience-item-card-details-buttons">
                            {!groupExperience.isFree && groupExperience.published && (
                                <a href={`/promocodes/4/${groupExperience.groupExperienceId}`} className="group-experience-item-card-details-buttons-button" title={t('generateCodesTooltip', {ns: 'manage_services'})}>
                                    <i>
                                        <FontAwesomeIcon icon={faTag} />
                                    </i>
                                </a>
                            )}
                            {!groupExperience.isFree && groupExperience.published ? groupExperience.hasDiscount ? (
                                <button onClick={() => { setDeletedGroup(false); setShowModal(true); }}
                                        title={t('deleteDiscountTooltip', {ns: 'manage_services'})} className='delete-discount-button'>
                                    <i><FontAwesomeIcon icon={faPercent} /></i>
                                </button>
                            ) : (
                                <a href={`/discount/${ServiceType.GrupalExperience}/${groupExperience.groupExperienceId}`} className="group-experience-item-card-details-buttons-button" title={t('addDiscountTooltip', {ns: 'manage_services'})}>
                                    <i><FontAwesomeIcon icon={faPercent} /></i>
                                </a>
                            ) : null}

                            <a href={`/manage-services/group-experience/${groupExperience.groupExperienceId}`} className="group-experience-item-card-details-buttons-button" title={t('editTooltip')}>
                                <i><FontAwesomeIcon icon={faPenToSquare} /></i>
                            </a>
                            {groupExperience.numberOfRegistered === 0 && (
                                <>
                                    <button title={t('removeTooltip')} className="group-experience-item-card-details-buttons-button remove-button" onClick={() => { setDeletedGroup(true); setShowModal(true); }}>
                                        <i><FontAwesomeIcon icon={faTrash} /></i>
                                    </button>
                                </>
                            )}
                            <EdowieModal show={showModal} title={`${deletedGroup ? t('groupExperiences.deleteGroupModalTitle', {ns: 'manage_services'}) : t('groupExperiences.deleteDiscountModalTitle', {ns: 'manage_services'})}`}
                                        modalClass={'group-experience-modal'}
                                        close={() => { setShowModal(false) }} 
                                        handleAccept={() => { 
                                            if (deletedGroup) {
                                                deleteGroupExperience();
                                            } else {
                                                deleteDiscount(groupExperience.groupExperienceId, ServiceType.GrupalExperience)
                                            }
                                        }}>
                                            <>
                                            {deletedGroup ? (
                                                <div className="activity-item-content">{t('groupExperiences.deleteGroupModalLegend', {ns: 'manage_services'})}</div>
                                            ) : (
                                                <div className="activity-item-content">{t('groupExperiences.deleteDiscountModalLegend', {ns: 'manage_services'})}</div>
                                            )}
                                            </>
                                        
                                    </EdowieModal>
                        </div>
                    </div>
                </div>
            </div>
            </>
        )
    }
)