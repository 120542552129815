import moment from 'moment';
import { getCurrentTime, getUserLoggedIn } from '../../../Utils/utils';
import './style.scss';
import { Trans } from 'react-i18next';

export const Recipient = ({recipient, handleChangeRecipient}) => {
    const userLoggedIn = getUserLoggedIn();
    var currentDate = getCurrentTime(userLoggedIn.offset);

    var lastAccess = moment(recipient.lastAccess + 'Z').add((-1) * moment().utcOffset(), 'minutes').add(userLoggedIn.offset, 'minutes')._d;
    lastAccess.setTime(lastAccess.getTime() + userLoggedIn.offset * 60 * 1000);

    var days = Math.round((currentDate - lastAccess) / (1000 * 60 * 60 * 24));

    var lastAccessTime = '';
    if (days === 0)
    {
        lastAccessTime = 'messages.active_today';
    }
    else
    {
        if (days === 1)
        {
            lastAccessTime = "messages.active_yesterday";
        }
        else
        {
            lastAccessTime = `messages.active_days_ago`;
        }
    }

    return (
        <>
            <li key={recipient.userId} className='message-recipient'>
                <div className="row">
                    <div className="col-sm-11">
                        <div className="message-recipient-user">
                            <button className='message-recipient-user-button' onClick={() => handleChangeRecipient(recipient)}>
                                <img src={recipient.profileImage.path}  alt='user'/>
                                <div className="message-recipient-user-details">
                                    <div>{recipient.username}</div>
                                    <div className='message-recipient-user-details-last-access'>
                                        <Trans
                                            i18nKey={lastAccessTime}
                                            values={{ days: days}}
                                        />
                                    </div>
                                </div>
                            </button>
                        </div>
                    </div>
                    <div className="col-sm-1 p-0 pt-2">
                        {recipient.unreadMessages > 0 && (
                            <div className="message-recipient-unread-messages">{recipient.unreadMessages}</div>
                        )}
                    </div>
                </div>
            </li>
        </>
    )
}