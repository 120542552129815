import React from 'react';
import './style.scss';
import { ThemeForm } from './ThemeForm';

export const Theme = ({ canDelete, theme, isEditable, handleSave, handleCancel, handleEditing }) => {

    return (
        <>
            <div className='theme'>
                <ThemeForm canDelete={canDelete} theme={theme} isEditable={isEditable} handleSave={() => handleSave()} 
                           handleCancel={() => handleCancel()} handleEditing={() => handleEditing()}></ThemeForm>
            </div>
        </>
    )
}