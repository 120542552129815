import { Form, Formik } from "formik"
import { FieldGroup, FieldItem, SelectFieldItem, TextAreaFieldItem } from "../../../../../../Shared/FormHelpers"
import { Message } from "../../../../../../Shared/Message"
import { useTranslation } from "react-i18next";
import { EdowieModal } from "../../../../../../Shared/Modal";
import { DefaultOption, DELAY_BEFORE_REDIRECT, Qualifications, Status } from "../../../../../../../Utils/types";
import { useRef, useState } from "react";
import { getFormInitialValues } from "../../../../../../../Utils/utils";
import { QualificationService } from '../../../../../../../services/qualificationService';
import useTimeout from "../../../../../../../hooks/useTimeout";

const fieldNames = {
    qualification: 'qualification',
    description: 'description',
};

export const QualifyModal = (
    { 
        show, 
        userId, 
        serviceType, 
        serviceId, 
        handleSaveQualifications,
        handleClose
    }) => {
    const { t } = useTranslation(['diary', 'validations', 'common']);
    const qualificationServiceClient = new QualificationService();
    const createTimeout = useTimeout();
    const formRef = useRef();
    const [status, setStatus] = useState('');
    const [error, setError] = useState('');

    const saveQualification = async(values) => {
        var saveQualificationResponse = await qualificationServiceClient.addQualification(userId, serviceId, serviceType, values);

        if (saveQualificationResponse.success) {
            setStatus(Status.Saved);
            createTimeout(async() => {
                setStatus('');
                handleSaveQualifications();
            }, DELAY_BEFORE_REDIRECT);
        }
        else {
            setStatus(Status.HasError);
            setError(saveQualificationResponse.error);
        }
    }

    const handleSubmit = () => {
        if (formRef.current) {
            formRef.current.handleSubmit()
        }
    }

    const showMessage = [Status.Saved, Status.HasError].includes(status);
    
    return (
        <>
            <EdowieModal 
                show={show} 
                handleAccept={() => handleSubmit()} 
                close={() => handleClose()}
                title={t('qualifyModal.title', {ns: 'diary'})}
                modalClass={'diary-qualify-modal'}>
                <div className="form">
                    <div className="row">
                        <div className="col-lg-12">
                            <Formik innerRef={formRef}
                                onSubmit={saveQualification}
                                initialValues={getFormInitialValues(fieldNames)}
                            >
                                {({ isSubmitting, handleSubmit }) => (
                                    <Form>
                                        <fieldset className="form-fields">
                                            <FieldGroup>
                                                <FieldItem className="field-item">
                                                    <FieldGroup>
                                                        <TextAreaFieldItem
                                                            type="textarea"
                                                            fieldName={fieldNames.description}
                                                            id={fieldNames.description}
                                                            label={`*${t('qualifyModal.descriptionLabel', {ns: 'diary'})}`}
                                                            required
                                                            placeholder={t('qualifyModal.descriptionPlaceholder', {ns: 'diary'})}
                                                            className="field-item--100"
                                                        />
                                                    </FieldGroup>
                                                </FieldItem>
                                                <FieldItem className="field-item">
                                                    <FieldGroup>
                                                        <SelectFieldItem
                                                            fieldName={fieldNames.qualification}
                                                            id={fieldNames.qualification}
                                                            label={`*${t('qualifyModal.qualificationLabel', {ns: 'diary'})}`}
                                                            defaultOption={DefaultOption}
                                                            values={Qualifications}
                                                            required
                                                            className="field-item--100"
                                                        />
                                                    </FieldGroup>
                                                </FieldItem>
                                            </FieldGroup>
                                        </fieldset>
                                        {showMessage && (
                                            <fieldset>
                                                <Message
                                                    type={status === Status.Saved ? 'success' : 'error'}
                                                    message={
                                                        status === Status.Saved
                                                            ? t(`successfullyMessage`, {ns: 'common'})
                                                            : error}
                                                />
                                            </fieldset>
                                        )}
                                    </Form>
                                )}
                            </Formik>
                        </div>

                    </div>
                </div>
            </EdowieModal>
        </>
    )
}