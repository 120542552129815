import './style.scss';
import { ReviewStars } from '../../../../Shared/ReviewStars/index';
import { useEffect, useState } from 'react';
import { InjectAppServices } from '../../../../../services/dependency-injection';

export const ContentItem = InjectAppServices((
    { 
        id, 
        coverImage, 
        subcategory, 
        title, 
        isFree, 
        price, 
        description, 
        reviews, 
        averageReview, 
        contentType,
        discount,
        dependencies: { ipInfoServiceClient } 
    }) => {
    
    const [userCountryCode, setUserCountryCode] = useState('');
    const [state, setState] = useState({price: price, total: price, discount: 0});

    useEffect(() => {
        const fetchData = async () => {
            ipInfoServiceClient.getCountryCode()
                .then(response => {
                    setUserCountryCode(response.value.toLowerCase());

                    if (discount && (discount.applyAll || discount.countryDiscountCode === response.value.toLowerCase())) {
                        const discountAmout = (parseFloat(price * discount.discount))/parseFloat(100);
                        const amount = price - discountAmout;
                        setState({price: price, total: amount, discount: discount.discount});
                    }

                });
        };
        fetchData();

    }, [ipInfoServiceClient, discount, price]);

    return (
        <>
            <div className="content-item">
                <div className="card">
                    <div className="card-image">
                        <a href={`buy-content/${id}/${contentType}`}>
                            <img src={coverImage.path} alt={title} />
                        </a>
                    </div>
                    <div className="card-details">
                        <span className={`tag ${contentType === 1 ? 'course-tag' : 'resource-tag'}`}>{subcategory}</span>
                        <div className='teacher-profile-price-card-section'>
                            <div className='teacher-profile-price-card'>
                                {isFree ? (
                                    <h3 className="teacher-profile-free">{'Gratis!'}</h3>
                                ) : (
                                    discount && (discount.applyAll || discount.countryDiscountCode === userCountryCode) ? (
                                        <>
                                            <span className="teacher-profile-price-without-discount">{"USD "  + state.price.toFixed(2)}</span>
                                            <h3 className="teacher-profile-price-with-discount">{`USD ${state.total.toFixed(2)} - ${state.discount}% OFF`}</h3>
                                        </>
                                    ) : (
                                        <h3 className="teacher-profile-price-with-discount">{"USD "  + state.price.toFixed(2)}</h3>
                                    )
                                )}
                            </div>
                        </div>
                        <h4>
                            <a href={`buy-content/${id}/${contentType}`}>{title}</a>
                        </h4>
                        <h6>{description}</h6>
                        <div className="qualification">
                        <div className="qualification">
                                    <ReviewStars averageQualifications={averageReview}></ReviewStars>
                                    <p className="reviews">{reviews} Reseña/s.</p>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
})