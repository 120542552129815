import React, { useState, useEffect, useMemo } from 'react';
import './style.scss';
import { NavMenu } from '../Menu/NavMenu';
import { Header } from './Header/index';
import { profileService } from '../../services/profileService';
import { sessionManager } from '../../sessionManager'
import LoadingSpinner from "../Shared/LoadingSpinner/index";
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { EditProfile } from './Tabs/EditProfile/index';
import { EditAccount } from './Tabs/EditAccount/index';
import { Configuration } from './Tabs/Configuration/index';
import { useParams } from "react-router-dom";
import { Balance } from './Tabs/Balance';
import { ProfileType } from '../../Utils/types';
import { MyPurchases } from './Tabs/MyPurchases';

export const MyProfile = () => {
    const profileServiceClient = useMemo(() => new profileService(), []);
    const manager = new sessionManager();
    const userLoggedIn = JSON.parse(manager.getUserSession());
    const { selectedTab } = useParams();

    const [loading, setLoading] = useState(true);
    const [myProfile, setMyProfile] = useState({});
    const [activeTab, setActiveTab] = useState(selectedTab === undefined ? "1" : selectedTab);

    const getMyProfile = async () => {
        const profileResponse = await profileServiceClient.getMyProfile(userLoggedIn.userId);

        if (profileResponse.success) {
            setMyProfile(profileResponse.value);
            setLoading(false);
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            const profileResponse = await profileServiceClient.getMyProfile(userLoggedIn.userId);

            if (profileResponse.success) {
                setMyProfile(profileResponse.value);
                setLoading(false);
            }
        };
        fetchData();

    }, [profileServiceClient, userLoggedIn.userId]);

    const selectTab = (tabId) => {
        if (activeTab !== tabId) {
            setActiveTab(tabId);
        }
    }

    return (
        <>
            {loading ? <LoadingSpinner /> : (
                <>
                <NavMenu showNavigation={true} currentPage={'teacher-profile'} />

                <div className="my-profile">
                    <Header profile={myProfile}></Header>
                    <div className="tabs">
                        <Nav tabs>
                            <NavItem>
                                <NavLink className={(activeTab === "1" ? "active nav-link" : "nav-link")} href="#"
                                        onClick={() => selectTab("1")}>
                                    Mí Perfíl
                                </NavLink>
                            </NavItem>
                            {(myProfile.profile !== ProfileType.Administrator &&
                            <NavItem>
                                <NavLink className={(activeTab === "5" ? "active nav-link" : "nav-link")} href="#"
                                        onClick={() => selectTab("5")}>
                                    Mis Compras
                                </NavLink>
                            </NavItem>)}
                            {(myProfile.profile === ProfileType.Edowier || 
                              (myProfile.profile === ProfileType.User && myProfile.balance > 0)) && (
                            <NavItem>
                                <NavLink className={(activeTab === "2" ? "active nav-link" : "nav-link")} href="#"
                                        onClick={() => selectTab("2")}>
                                    Balance
                                </NavLink>
                            </NavItem>
                            )}
                            <NavItem>
                                <NavLink className={(activeTab === "3" ? "active nav-link" : "nav-link")} href="#"
                                        onClick={() => selectTab("3")}>
                                    Mí Cuenta
                                </NavLink>
                            </NavItem>
                            {(myProfile.profile !== ProfileType.Administrator &&
                            <NavItem>
                                <NavLink className={(activeTab === "4" ? "active nav-link" : "nav-link")} href="#"
                                        onClick={() => selectTab("4")}>
                                    Configuración
                                </NavLink>
                            </NavItem>)}
                        </Nav>
                    </div>
                    <div className="tab-content">
                        <TabContent activeTab={activeTab}>
                            <TabPane tabId="1">
                                {activeTab === "1" && (
                                    <EditProfile profile={myProfile}></EditProfile>
                                )}
                            </TabPane>
                            <TabPane tabId="2">
                                {activeTab === "2" && (
                                    <Balance></Balance>
                                )}
                            </TabPane>
                            <TabPane tabId="3">
                                {activeTab === "3" && (
                                    <EditAccount profile={myProfile} handleChangeAccount={() => getMyProfile()}></EditAccount>
                                )}
                            </TabPane>
                            <TabPane tabId="4">
                                {activeTab === "4" && (
                                    <Configuration profile={myProfile}></Configuration>
                                )}
                            </TabPane>
                            <TabPane tabId="5">
                                {activeTab === "5" && (
                                    <MyPurchases></MyPurchases>
                                )}
                            </TabPane>
                        </TabContent>
                    </div>
                </div>
                </>
            )}
        </>
    )
}
