import { useEffect, useMemo, useState } from 'react';
import { getUserLoggedIn } from '../../Utils/utils';
import { messageService } from '../../services/messageService';
import { NavMenu } from '../Menu/NavMenu';
import LoadingSpinner from '../Shared/LoadingSpinner';
import './style.scss';
import { Recipient } from './Recipient';
import { Chat } from './Chat';
import { useParams } from 'react-router-dom';
import { userService } from '../../services/userService';


export const Messages = () => {
    const messageServiceClient = useMemo(() => new messageService(), []);
    const userLoggedIn = getUserLoggedIn();

    const { userId } = useParams();
    const [loading, setLoading] = useState(true);
    const [recipients, setRecipients] = useState([]);
    const [selectedRecipient, setSelectedRecipient] = useState();

    useEffect(() => {
        const fetchData = async () => {
            const response = await messageServiceClient.getRecipientsByUserId(userLoggedIn.userId);

            if (response.success) {
                var destinations = response.value;

                var currentRecipient = destinations.filter(d => d.userId.toString() === userId)[0];
                if (!currentRecipient) {
                    userService.card(userId)
                        .then(response => {
                            destinations.push(
                                {   userId: response.userId, 
                                    lastAccess: response.lastAccess,
                                    unreadMessages: 0,
                                    profileImage: {
                                        path: response.profilePhoto
                                    },
                                    username: response.username
                                });
    
                            setSelectedRecipient({   userId: response.userId, 
                                lastAccess: response.lastAccess,
                                unreadMessages: 0,
                                profileImage: {
                                    path: response.profilePhoto
                                },
                                username: response.username
                            });
                        })
                    
                }
                else {
                    setSelectedRecipient(currentRecipient);
                }

                setRecipients(destinations);
                setLoading(false);
            }
        };
        fetchData();

    }, [messageServiceClient, userLoggedIn.userId, userId]);

    const changeRecipient = async(value) => {
        var response = await messageServiceClient.markAsRead(value.userId);
        if (response.success) {
            value.unreadMessages = 0;
            setSelectedRecipient(value);
        }
    }

    return (
        <>
        {loading ? <LoadingSpinner /> : (
            <>
                <NavMenu showNavigation={true} currentPage={'messages'} />
                <div className="send-messages">
                    <div className='row'>
                        <div className='col-lg-6'>
                            <div className="send-messages-current-user-section">
                                <div className="send-messages-current-user-section-user-photo">
                                        <img src={userLoggedIn.profilePhoto} alt="user"/>
                                </div>
                                <div className="send-messages-current-user-section-username">
                                    <b>{userLoggedIn.username}</b>
                                </div>
                            </div>
                            <ul className='send-messages-recipients'>
                                {recipients && recipients.map((recipient) => (
                                    <Recipient key={recipient.userId} recipient={recipient} handleChangeRecipient={(value) => changeRecipient(value)}></Recipient>
                                ))}
                            </ul>
                        </div>
                        <div className='col-lg-6 p-r-50'>
                            <Chat recipient={selectedRecipient}></Chat>
                        </div>
                    </div>
                </div>
            </>
        )}
        </>
    )
}