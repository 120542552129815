import React from 'react';
import './style.scss';

export const RequirementsSection = ({ requirements }) => {

    return (
        <div className="requirements-section">
            <h2>Requerimientos</h2>
            <p>{requirements}</p>
        </div>
    )
}
