import React, { useState, useMemo, useEffect} from 'react';
import './style.scss';
import { FieldGroup, FieldItem, TextAreaFieldItem, SubmitButton, SelectFieldItem, PriceInputFieldItem } from '../../../../Shared/FormHelpers/index';
import { Form, Formik } from 'formik';
import { getFormInitialValues } from '../../../../../Utils/utils';
import { DELAY_BEFORE_REDIRECT, DefaultOption, Durations, OfferFirstFreeTo, OfferServiceTo, Status, TimeAdvanceCancel, TimeAdvanceContract } from '../../../../../Utils/types';
import useTimeout from '../../../../../hooks/useTimeout/index';
import { particularExperienceService } from '../../../../../services/particularExperienceService';
import { Message } from '../../../../Shared/Message/index';
import { useTranslation } from 'react-i18next';
import { InjectAppServices } from '../../../../../services/dependency-injection';

const fieldNames = {
    aboutMyParticularExperience: 'aboutMyParticularExperience',
    price: 'price',
    duration: 'duration',
    offerFirstFreeTo: 'offerFirstFreeTo',
    advanceCancellationPeriod: 'advanceCancellationPeriod',
    advanceHirePeriod: 'advanceHirePeriod',
    offerServiceTo: 'offerServiceTo',
};

export const GeneralConditionsStep = InjectAppServices(
    ({ 
        particularExperience, 
        handleSaveGeneralConditions,
        dependencies: { sessionManager, configurationServiceClient }
     }) => {
    const { t } = useTranslation();
    const particularExperienceServiceClient = useMemo(() => new particularExperienceService(), []);
    const [status, setStatus] = useState('');
    const [error, setError] = useState('');
    const [configuration, setConfiguration] = useState({});

    const createTimeout = useTimeout();

    const userLoggedIn = sessionManager.getUserLoggedIn();

    const showMessage = [Status.Saved, Status.HasError].includes(status);

    useEffect(() => {
        const fetchData = async () => {
            configurationServiceClient.getByKey('DiscountService:MinimumServiceAmount')
                .then(response => {
                    setConfiguration(response);
                });
        };
        fetchData();

    }, [configurationServiceClient]);

    const _getFormInitialValues = () => {
        let initialValues = getFormInitialValues(fieldNames);

        if (particularExperience !== null) {
            initialValues = { ...particularExperience };
        }

        return initialValues;
    };

    const save = async(values) => {
        setStatus(Status.Saving);

        var response = await particularExperienceServiceClient.saveGeneralConditions(userLoggedIn.userId, values);

        if (response.success) {
            setStatus(Status.Saved);
            createTimeout(() => {
                handleSaveGeneralConditions();
            }, DELAY_BEFORE_REDIRECT);
        } else {
            setStatus(Status.HasError);
            setError(response.error);
        }
    }

    return (
        <div className="general-conditions-step">
            <h2 className="general-conditions-step-title">
                {t('manage_services.particular.general_conditions_step.title')}
            </h2>
            <div className='general-conditions-step-form'>
                <div className="row">
                    <div className="col-lg-12">
                        <Formik
                            onSubmit={save}
                            initialValues={_getFormInitialValues()}
                        >
                            {({ setFieldValue, isSubmitting, isValid, dirty }) => (
                                <Form>
                                    <fieldset className="form-fields">
                                        <FieldGroup>
                                            <FieldItem className="field-item">
                                                <FieldGroup>
                                                    <TextAreaFieldItem
                                                        type="textarea"
                                                        fieldName={fieldNames.aboutMyParticularExperience}
                                                        id={fieldNames.aboutMyParticularExperience}
                                                        label={`*${t('manage_services.particular.general_conditions_step.about_me.label')}`}
                                                        placeholder={t('manage_services.particular.general_conditions_step.about_me.placeholder')}
                                                        className="field-item--100"
                                                        required={true}
                                                        maxLength="500"
                                                    />
                                                </FieldGroup>
                                            </FieldItem>
                                            <FieldItem className="field-item">
                                                <ul className="row general-conditions-step-form-padding-0">
                                                    <div className="col-lg-6">
                                                        <PriceInputFieldItem
                                                            fieldName={fieldNames.price}
                                                            id={fieldNames.price}
                                                            label={`*${t('manage_services.particular.general_conditions_step.price.label')}`}
                                                            required={true}
                                                            placeholder={t('manage_services.particular.general_conditions_step.price.placeholder')}
                                                            className="field-item--100"
                                                            min={parseInt(configuration.value)}
                                                        />
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <SelectFieldItem
                                                            fieldName={fieldNames.duration}
                                                            id={fieldNames.duration}
                                                            label={`*${t('manage_services.particular.general_conditions_step.duration.label')}`}
                                                            defaultOption={DefaultOption}
                                                            values={Durations}
                                                            required={true}
                                                            className="field-item--100"
                                                        />
                                                    </div>
                                                </ul>
                                            </FieldItem>
                                            <FieldItem className="field-item">
                                                <ul className="row general-conditions-step-form-padding-0">
                                                    <div className="col-lg-6">
                                                        <SelectFieldItem
                                                            fieldName={fieldNames.offerFirstFreeTo}
                                                            id={fieldNames.offerFirstFreeTo}
                                                            label={`*${t('manage_services.particular.general_conditions_step.offer_your_first_free.label')}`}
                                                            defaultOption={DefaultOption}
                                                            values={OfferFirstFreeTo}
                                                            required={true}
                                                            className="field-item--100"
                                                        />
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <SelectFieldItem
                                                            fieldName={fieldNames.advanceHirePeriod}
                                                            id={fieldNames.advanceHirePeriod}
                                                            label={`*${t('manage_services.particular.general_conditions_step.minimum_notice.label')}`}
                                                            defaultOption={DefaultOption}
                                                            values={TimeAdvanceContract}
                                                            required={true}
                                                            className="field-item--100"
                                                        />
                                                    </div>
                                                </ul>
                                            </FieldItem>
                                            <FieldItem className="field-item">
                                                <ul className="row general-conditions-step-form-padding-0">
                                                    <div className="col-lg-6">
                                                        <SelectFieldItem
                                                            fieldName={fieldNames.advanceCancellationPeriod}
                                                            id={fieldNames.advanceCancellationPeriod}
                                                            label={`*${t('manage_services.particular.general_conditions_step.cancel_particular_contracted.label')}`}
                                                            values={TimeAdvanceCancel}
                                                            required={true}
                                                            className="field-item--100"
                                                        />
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <SelectFieldItem
                                                            fieldName={fieldNames.offerServiceTo}
                                                            id={fieldNames.offerServiceTo}
                                                            label={`*${t('manage_services.particular.general_conditions_step.offer_my_service.label')}`}
                                                            defaultOption={DefaultOption}
                                                            values={OfferServiceTo}
                                                            required={true}
                                                            className="field-item--100"
                                                        />
                                                    </div>
                                                </ul>
                                            </FieldItem>
                                            <FieldItem className="field-item">
                                                <FieldGroup>
                                                    <div className="save-button">
                                                        <SubmitButton isSubmitting={isSubmitting}>
                                                            {t('manage_services.particular.following_button')}
                                                        </SubmitButton>
                                                    </div>
                                                </FieldGroup>
                                            </FieldItem>
                                        </FieldGroup>
                                        {showMessage && (
                                            <Message
                                                type={status === Status.Saved ? 'success' : 'error'}
                                                message={
                                                    status === Status.Saved
                                                        ? t('manage_services.successfully_message')
                                                        : error}
                                            />
                                        )}
                                    </fieldset>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        </div>
    )
})