import React, { useState, useEffect, useMemo } from 'react';
import './style.scss';
import { NavMenu } from '../../../Menu/NavMenu';
import { FieldGroup, FieldItem, InputFieldItem, TextAreaFieldItem, SelectFieldItem, CheckboxFieldItem, SubmitButton, PriceInputFieldItem } from '../../../Shared/FormHelpers/index';
import { Form, Formik } from 'formik';
import { getFormInitialValues } from '../../../../Utils/utils';
import FileUpload from '../../../Shared/FileUpload/FileUplodad';
import FileList from '../../../Shared/FileList/FileList';
import { faFileImage, faPlayCircle, faPaperclip } from '@fortawesome/free-solid-svg-icons'
import { categoryService } from '../../../../services/categoryService';
import { subcategoryService } from '../../../../services/subcategoryService';
import { contentService } from '../../../../services/contentService';
import { Message } from '../../../Shared/Message/index';
import useTimeout from '../../../../hooks/useTimeout/index';
import { useParams } from "react-router-dom";
import LoadingSpinner from "../../../Shared/LoadingSpinner/index";
import { useTranslation } from 'react-i18next';
import { InjectAppServices } from '../../../../services/dependency-injection';

const HAS_ERROR = 'HAS_ERROR';
const SAVED = 'SAVED';
export const DELAY_BEFORE_REDIRECT = 3000;

const fieldNames = {
    category: 'category',
    subcategory: 'subcategory',
    title: 'title',
    subtitle: 'subtitle',
    description: 'description',
    detailedDescription: 'detailedDescription',
    requirements: 'requirements',
    level: 'level',
    isFree: 'isFree',
    price: 'price',
    tags: 'tags',
};

const defaultOption = { key: '', value: 'Selecciona una opción' };

const levels = [
    { key: 'Introductorio', value: 'Introductorio' },
    { key: 'Intermedio', value: 'Intermedio' },
    { key: 'Avanzado', value: 'Avanzado' },
    { key: 'Experto', value: 'Experto' },
]
export const Course = InjectAppServices(
    ({
        dependencies: { sessionManager, configurationServiceClient }
    }) => {

    const { t } = useTranslation(['validations', 'translation']);
    const categoryServiceClient = useMemo(() => new categoryService(), []);
    const subcategoryServiceClient = useMemo(() => new subcategoryService(), []);
    const contentServiceClient = useMemo(() => new contentService(), []);

    const userLoggedIn = sessionManager.getUserLoggedIn();
    const { id } = useParams();

    const [course, setCourse] = useState(null);
    const [loading, setLoading] = useState(true);

    const [categories, setCategories] = useState([]);
    const [subcategories, setSubcategories] = useState([]);
    const [isFree, setIsFree] = useState(false);

    const [imageFiles, setImageFiles] = useState([]);
    const [imageFileIds, setImageFileIds] = useState([]);
    const [isImageFileLoading, setIsImageFileLoading] = useState(false);

    const [videoFiles, setVideoFiles] = useState([]);
    const [videoFileIds, setVideoFileIds] = useState([]);
    const [videoError, setVideoError] = useState(false);
    const [isVideoFileLoading, setIsVideoFileLoading] = useState(false);

    const [additionalFiles, setAdditionalFiles] = useState([]);
    const [additionalFileIds, setAdditionalFileIds] = useState([]);

    const [isAddtionalFileLoading, setIsAdditionalFileLoading] = useState(false);

    const [status, setStatus] = useState('');
    const [error, setError] = useState('');
    const [configuration, setConfiguration] = useState({});

    const createTimeout = useTimeout();

    useEffect(() => {
        const fetchData = async () => {
            if (id !== undefined) {
                var courseResponse = await contentServiceClient.getCourseByUserIdAndId(userLoggedIn.userId, id);

                if (courseResponse.success) {
                    setCourse({
                        category: courseResponse.value.categoryId,
                        subcategory: courseResponse.value.subcategoryId,
                        title: courseResponse.value.title,
                        subtitle: courseResponse.value.subtitle !== null ? courseResponse.value.subtitle : "",
                        description: courseResponse.value.description,
                        detailedDescription: courseResponse.value.detailedDescription,
                        requirements: courseResponse.value.requirements,
                        level: courseResponse.value.level,
                        isFree: courseResponse.value.isFree,
                        price: courseResponse.value.price === 0 ? "" : courseResponse.value.price,
                        tags: courseResponse.value.tags,
                        coverImage: courseResponse.value.coverImage,
                        videos: courseResponse.value.videos,
                        additionalFiles: courseResponse.value.additionalFiles,
                        acquired: courseResponse.value.acquired,
                        hasDiscount: courseResponse.value.hasDiscount,
                        hasPromocodesAvailable: courseResponse.value.hasPromocodesAvailable
                    });

                    setIsFree(courseResponse.value.isFree);

                    initializesFiles(courseResponse.value);

                    const result = await subcategoryServiceClient.getAllByCategoryId(courseResponse.value.categoryId);
                    setSubcategories(result.success ? result.value.map(function (item) {
                        return { key: item.subcategoryId, value: item.name };
                    }) : []);
                }
            }

            configurationServiceClient.getByKey('DiscountService:MinimumServiceAmount')
                .then(response => {
                    setConfiguration(response);
                });

            setLoading(false);
        };
        fetchData();

    }, [categoryServiceClient, contentServiceClient, subcategoryServiceClient, id, userLoggedIn.userId, configurationServiceClient]);

    useEffect(() => {
        const fetchData = async () => {
            var categoriesResponse = await categoryServiceClient.getAll();

            if (categoriesResponse.success) {
                const values = categoriesResponse.value.map(function (item) {
                    return { key: item.categoryId, value: item.name };
                });

                setCategories(values);
            }
        };
        fetchData();

    }, [categoryServiceClient]);

    const handleIsFreeChange = async (e, setFieldValue) => {
        setIsFree(!isFree);
        setFieldValue(fieldNames.price, '');
    };

    const removeImageFile = (fileToRemove) => {
        setImageFiles(imageFiles.filter(file => file.name !== fileToRemove.name));
        setImageFileIds(imageFileIds.filter(file => file !== fileToRemove.id));
    }

    const removeVideoFile = (fileToRemove) => {
        var videos = videoFiles.filter(file => file.name !== fileToRemove.name);
        setVideoFiles(videos);
        setVideoError(videos.length === 0);
        setVideoFileIds(videoFileIds.filter(file => file !== fileToRemove.id));
    }

    const removeAddtionalFile = (fileToRemove) => {
        setAdditionalFiles(additionalFiles.filter(file => file.name !== fileToRemove.name));
        setAdditionalFileIds(additionalFileIds.filter(file => file !== fileToRemove.id));
    }

    const changeCategory = async (e, setFieldValue) => {
        const category = e.target.value;
        setFieldValue(fieldNames.category, category);
        const result = await subcategoryServiceClient.getAllByCategoryId(category);
        setSubcategories(result.success ? result.value.map(function (item) {
            return { key: item.subcategoryId, value: item.name };
        }) : []);
        setFieldValue(fieldNames.subcategory, '');
    };

    const saveCourse = async(values) => {
        setVideoError(videoFiles.length === 0);
        setStatus('');

        if (videoFiles.length > 0) {
            var response;

            if (id === undefined) {
                response = await contentServiceClient.createCourse(values, imageFileIds, videoFileIds, additionalFileIds, userLoggedIn.userId);
            } else {
                response = await contentServiceClient.updateCourse(values, imageFileIds, videoFileIds, additionalFileIds, userLoggedIn.userId, id);
            }

            if (response.success) {
                setStatus(SAVED);
                createTimeout(() => {
                    window.location.href = `/manage-services`;
                }, DELAY_BEFORE_REDIRECT);
            } else {
                setStatus(HAS_ERROR);
                setError(response.error);
            }
        }
    }

    const _getFormInitialValues = () => {
        let initialValues = getFormInitialValues(fieldNames);

        if (course !== null) {
            initialValues = { ...course };
        }

        return initialValues;
    };

    const initializesFiles = (course) => {
        /* Cover Image */
        if (course.coverImage.fileId > 0) {
            setImageFiles([{
                name: course.coverImage.name,
                isUploading: false,
                id: course.coverImage.fileId,
                canDelete: !course.acquired
            }]);

            setImageFileIds([course.coverImage.fileId]);
        }

        /* Videos */
        setVideoFiles(course.videos.map(function (video) {
            return {
                name: video.name,
                isUploading: false,
                id: video.fileId,
                canDelete: !course.acquired
            };
        }));

        setVideoFileIds(course.videos.map(function (video) {
            return video.fileId;
        }));

        /* Additionals Files */
        setAdditionalFiles(course.additionalFiles.map(function (additionalFile) {
            return {
                name: additionalFile.name,
                isUploading: false,
                id: additionalFile.fileId,
                canDelete: !course.acquired
            };
        }));

        setAdditionalFileIds(course.additionalFiles.map(function (additionalFile) {
            return additionalFile.fileId;
        }));
    }

    

    const showMessage = [SAVED, HAS_ERROR].includes(status);

    return (
        <>
            <NavMenu showNavigation={true} currentPage={'course'} />
            <div className="middle-wrap">
                <div className="course-card">
                    <div className="header">
                        <a href="manage-services" className="back">
                            <i className="ti-arrow-left"></i>
                        </a>
                        <h4>{t(`manage_services.contents.course.header`, {ns: 'translation'})}</h4>
                    </div>
                    <div className="legend">
                        <p><strong>{t(`manage_services.contents.course.title`, {ns: 'translation'})}</strong></p>
                        <p>{t(`manage_services.contents.course.subtitle`, {ns: 'translation'})}</p>
                    </div>
                    {loading ? <LoadingSpinner /> : (
                    <div className="form">
                        <div className="row">
                            <div className="col-lg-12">
                                <Formik
                                    onSubmit={saveCourse}
                                    initialValues={_getFormInitialValues()}
                                >
                                    {({ setFieldValue, isSubmitting, isValid, dirty }) => (
                                        <Form>
                                            <fieldset className="form-fields">
                                                <FieldGroup>
                                                    <FieldItem className="field-item">
                                                        <FieldGroup>
                                                            <SelectFieldItem
                                                                fieldName={fieldNames.category}
                                                                id={fieldNames.category}
                                                                label={`*${t('manage_services.contents.course.fields.category.label', {ns: 'translation'})}`}
                                                                required
                                                                defaultOption={defaultOption}
                                                                values={categories}
                                                                className="field-item--100"
                                                                onChange={(e) => {
                                                                    changeCategory(e, setFieldValue);
                                                                }}
                                                            />
                                                        </FieldGroup>
                                                    </FieldItem>
                                                    <FieldItem className="field-item">
                                                        <FieldGroup>
                                                            <SelectFieldItem
                                                                fieldName={fieldNames.subcategory}
                                                                id={fieldNames.subcategory}
                                                                label={`*${t('manage_services.contents.course.fields.subcategory.label', {ns: 'translation'})}`}
                                                                defaultOption={defaultOption}
                                                                required
                                                                values={subcategories}
                                                                className="field-item--100"
                                                            />
                                                        </FieldGroup>
                                                    </FieldItem>
                                                    <FieldItem className="field-item">
                                                        <FieldGroup>
                                                            <InputFieldItem
                                                                type="text"
                                                                fieldName={fieldNames.title}
                                                                id="title"
                                                                label={`*${t('manage_services.contents.course.fields.title.label', {ns: 'translation'})}`}
                                                                required
                                                                placeholder={t('manage_services.contents.course.fields.title.placeholder', {ns: 'translation'})}
                                                                className="field-item--100"
                                                                maxLength="150"
                                                            />
                                                        </FieldGroup>
                                                    </FieldItem>
                                                    <FieldItem className="field-item">
                                                        <FieldGroup>
                                                            <InputFieldItem
                                                                type="text"
                                                                fieldName={fieldNames.subtitle}
                                                                id="subtitle"
                                                                label={t('manage_services.contents.course.fields.subtitle.label', {ns: 'translation'})}
                                                                placeholder={t('manage_services.contents.course.fields.subtitle.placeholder', {ns: 'translation'})}
                                                                className="field-item--100"
                                                                maxLength="150"
                                                            />
                                                        </FieldGroup>
                                                    </FieldItem>
                                                    <FieldItem className="field-item">
                                                        <FieldGroup>
                                                            <InputFieldItem
                                                                type="text"
                                                                fieldName={fieldNames.description}
                                                                id="description"
                                                                label={`*${t('manage_services.contents.course.fields.short_description.label', {ns: 'translation'})}`}
                                                                required
                                                                placeholder={t('manage_services.contents.course.fields.short_description.placeholder', {ns: 'translation'})}
                                                                className="field-item--100"
                                                                maxLength="250"
                                                            />
                                                        </FieldGroup>
                                                    </FieldItem>
                                                    <FieldItem className="field-item">
                                                        <FieldGroup>
                                                            <TextAreaFieldItem
                                                                type="textarea"
                                                                fieldName={fieldNames.detailedDescription}
                                                                id="detailedDescription"
                                                                label={`*${t('manage_services.contents.course.fields.detailed_description.label', {ns: 'translation'})}`}
                                                                required
                                                                placeholder={t('manage_services.contents.course.fields.detailed_description.placeholder', {ns: 'translation'})}
                                                                className="field-item--100"
                                                                maxLength="5000"
                                                            />
                                                        </FieldGroup>
                                                    </FieldItem>
                                                    <FieldItem className="field-item">
                                                        <FieldGroup>
                                                            <TextAreaFieldItem
                                                                type="textarea"
                                                                fieldName={fieldNames.requirements}
                                                                id="requirements"
                                                                label={t('manage_services.contents.course.fields.requirements.label', {ns: 'translation'})}
                                                                placeholder={t('manage_services.contents.course.fields.requirements.placeholder', {ns: 'translation'})}
                                                                className="field-item--100"
                                                                maxLength="5000"
                                                            />
                                                        </FieldGroup>
                                                    </FieldItem>
                                                    <FieldItem className="field-item">
                                                        <FieldGroup>
                                                            <SelectFieldItem
                                                                fieldName={fieldNames.level}
                                                                id="country"
                                                                label={`*${t('manage_services.contents.course.fields.level.label', {ns: 'translation'})}`}
                                                                defaultOption={defaultOption}
                                                                values={levels}
                                                                className="field-item--100"
                                                            />
                                                        </FieldGroup>
                                                    </FieldItem>
                                                    <FieldItem className="field-item">
                                                        <FieldGroup>
                                                            <CheckboxFieldItem
                                                                fieldName={fieldNames.isFree}
                                                                key={fieldNames.isFree}
                                                                label={t('manage_services.contents.course.fields.is_free.label', {ns: 'translation'})}
                                                                id={fieldNames.isFree}
                                                                onChange={(e) => {
                                                                    handleIsFreeChange(e, setFieldValue);
                                                                }}
                                                            />
                                                        </FieldGroup>
                                                    </FieldItem>
                                                    <FieldItem className="field-item">
                                                        <FieldGroup>
                                                            <PriceInputFieldItem
                                                                fieldName={fieldNames.price}
                                                                id={fieldNames.price}
                                                                label={`${isFree ? '' : '*'} ${t('manage_services.contents.course.fields.price.label', {ns: 'translation'})}`}
                                                                required={!isFree}
                                                                placeholder={t('manage_services.contents.course.fields.price.placeholder', {ns: 'translation'})}
                                                                className="field-item--100"
                                                                min={parseInt(configuration.value)}
                                                                disabled={isFree || (course !== null && (course.hasDiscount || course.hasPromocodesAvailable))}
                                                            />
                                                        </FieldGroup>
                                                    </FieldItem>
                                                    <FieldItem className="field-item">
                                                        <FieldGroup>
                                                            <InputFieldItem
                                                                type="text"
                                                                fieldName={fieldNames.tags}
                                                                id={fieldNames.tags}
                                                                label={t('manage_services.contents.course.fields.tags.label', {ns: 'translation'})}
                                                                placeholder={t('manage_services.contents.course.fields.tags.placeholder', {ns: 'translation'})}
                                                                className="field-item--100"
                                                                maxLength="250"
                                                            />
                                                        </FieldGroup>
                                                    </FieldItem>
                                                    <FieldItem className="field-item">
                                                        <FieldGroup>
                                                            <div className="row">
                                                                <div className="col-lg-4">
                                                                    <FileUpload files={imageFiles} setFiles={setImageFiles}
                                                                        removeFile={removeImageFile} fileIcon={faFileImage}
                                                                        uploadTitle={t('manage_services.contents.course.fields.cover_image.label', {ns: 'translation'})} 
                                                                        allowedExtensions={".png, .jpg, .jpeg"}
                                                                        allowMultipleFile={false}
                                                                        handleUploadedFile={(fileId) => { 
                                                                            setImageFileIds([...imageFileIds, fileId]);
                                                                        }}
                                                                        handleIsLoadingFile={(isUploading) => {
                                                                            setIsImageFileLoading(isUploading);
                                                                        }}
                                                                    />
                                                                    <FileList files={imageFiles} removeFile={removeImageFile} />
                                                                </div>
                                                                <div className="col-lg-4">
                                                                    <FileUpload files={videoFiles} setFiles={setVideoFiles}
                                                                        removeFile={removeVideoFile} fileIcon={faPlayCircle}
                                                                        uploadTitle={t('manage_services.contents.course.fields.videos.label', {ns: 'translation'})} 
                                                                        allowedExtensions={".mp4"}
                                                                        allowMultipleFile={true}
                                                                        handleUploadedFile={(fileId) => {
                                                                            setVideoFileIds([...videoFileIds, fileId]);
                                                                            setVideoError(false);
                                                                            
                                                                        }}
                                                                        handleIsLoadingFile={(isUploading) => {
                                                                            setIsVideoFileLoading(isUploading);
                                                                        }}
                                                                    />
                                                                    <FileList files={videoFiles} removeFile={removeVideoFile} />
                                                                    {videoError &&
                                                                        <div className="video-required error-form"><p>{t('manage_services.contents.course.fields.videos.required', {ns: 'translation'})}</p></div>
                                                                    }
                                                                </div>
                                                                <div className="col-lg-4">
                                                                    <FileUpload files={additionalFiles} setFiles={setAdditionalFiles}
                                                                        removeFile={removeAddtionalFile} fileIcon={faPaperclip}
                                                                        uploadTitle={t('manage_services.contents.course.fields.additional_files.label', {ns: 'translation'})} 
                                                                        allowedExtensions={"*"}
                                                                        allowMultipleFile={true}
                                                                        handleUploadedFile={(fileId) => {
                                                                            setAdditionalFileIds([...additionalFileIds, fileId]);
                                                                        }}
                                                                        handleIsLoadingFile={(isUploading) => {
                                                                            setIsAdditionalFileLoading(isUploading);
                                                                        }}
                                                                    />
                                                                    <FileList files={additionalFiles} removeFile={removeAddtionalFile} />
                                                                </div>
                                                            </div>

                                                        </FieldGroup>
                                                    </FieldItem>
                                                    <FieldItem className="field-item">
                                                        <FieldGroup>
                                                            <div className="save-button">
                                                                <SubmitButton isSubmitting={isSubmitting && !videoError} disabled={isAddtionalFileLoading || isImageFileLoading || isVideoFileLoading}>
                                                                    {t('manage_services.contents.course.save_button', {ns: 'translation'})}
                                                                </SubmitButton>
                                                            </div>
                                                        </FieldGroup>
                                                    </FieldItem>
                                                </FieldGroup>
                                            </fieldset>
                                            {showMessage && (
                                                <fieldset>
                                                <Message
                                                    type={status === SAVED ? 'success' : 'error'}
                                                    message={
                                                        status === SAVED
                                                            ? t('manage_services.contents.course.success_messages', {ns: 'translation'})
                                                            : error}
                                                    />
                                                </fieldset>
                                            )}
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                    
                        </div>
                    </div>
                    )}
                </div>
            </div>
        </>
    )
})