import { useTranslation } from "react-i18next";
import { InjectAppServices } from "../../../../services/dependency-injection";
import './style.scss';
import { Transactions } from "./Transactions";

export const MyPurchases = InjectAppServices(() => {
        const { t } = useTranslation(['my_purchases']);

        return (
            <div className="my-purchases">
                <div className="row">
                    <div className="col-xl-12">
                        <h3 className="my-purchases-history">{t('title',{ns: 'my_purchases'})}</h3>
                    </div>
                </div>
                <Transactions></Transactions>
            </div>
        )
})