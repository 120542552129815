import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import 'bootstrap/dist/css/bootstrap.css';
import './style.scss';
import { ReviewStars } from '../../../Shared/ReviewStars/index';
import NoUser from '../../../../general/images/no-user.jpg';
import { InjectAppServices } from '../../../../services/dependency-injection';
import { useTranslation } from 'react-i18next';
import EmabajadorIcon from '../../../../general/images/embajador.png'

const Price = InjectAppServices(
    (
        { isFree, price, discount, groupExperienceId, dependencies: { ipInfoServiceClient } }
    ) => {

    const { t } = useTranslation(['menu']);
    const [userCountryCode, setUserCountryCode] = useState('');

    const [state, setState] = useState({price: price, total: price, discount: 0});

    useEffect(() => {
        const fetchData = async () => {
            ipInfoServiceClient.getCountryCode()
                .then(response => {
                    setUserCountryCode(response.value.toLowerCase());

                    if (discount && (discount.applyAll || discount.countryDiscountCode === response.value.toLowerCase())) {
                        const discountAmout = (parseFloat(price * discount.discount))/parseFloat(100);
                        const amount = price - discountAmout;
                        setState({price: price, total: amount, discount: discount.discount});
                    }

                });
        };
        fetchData();

    }, [ipInfoServiceClient, discount, price]);

    return (
        <div className='price-card'>
            {isFree ? (
                <h3 className="free">{t('groupExperiences.freeMessage')}</h3>
            ) : (
                discount && (discount.applyAll || discount.countryDiscountCode === userCountryCode) ? (
                    <>
                        <span className="price-without-discount">{"USD "  + state.price.toFixed(2)}</span>
                        <h3 className="price-with-discount">{`USD ${state.total.toFixed(2)} - ${state.discount}% OFF`}</h3>
                    </>
                ) : (
                    <h3 className="price-with-discount">{"USD "  + state.price.toFixed(2)}</h3>
                )
            )}
            <a href={`/enroll-group-experience/${groupExperienceId}`}>
                <div className="button">
                    {t('groupExperiences.enrollButton')}
                </div>
            </a>
        </div>
    )
})

function Items({ currentItems }) {
    return (
        <>
            {currentItems.length > 0 ? 
            (currentItems.map((item) => (
              <div key={item.groupExperienceId} className="col-xl-6 col-lg-12">
                  <div className="card-group-experience-body">
                      <div className="row">
                            <div className="col-sm-4">
                              <div className="card-group-experience-image">
                                  <a href={`/enroll-group-experience/${item.groupExperienceId}`}>
                                      <img src={item.coverImage.path} alt='cover' />
                                  </a>
                              </div>
                            </div>
                            <div className="col-sm-8">
                                <div className="card-group-experience-details">
                                    <div className="row">
                                        <div className="col-sm-9 pr-0">
                                            <div className="tags">
                                                {item.tags.split(',').map((tag) => (
                                                    <span key={tag} className={`tag group-experience-tag`}>
                                                        {tag}
                                                    </span>
                                                ))}
                                            </div>
                                        </div>
                                        <div className="col-sm-3">
                                            <div className="qualification">
                                                <ReviewStars averageQualifications={item.averageReview}></ReviewStars>
                                                <p className="reviews">({item.reviews})</p>
                                            </div>
                                        </div>
                                    </div>
                                    <h4 className="title">
                                        <a href={`/enroll-group-experience/${item.groupExperienceId}`}>{item.title}</a>
                                    </h4>
                                    <div className="row">
                                        <div className="col-12">
                                            <span className="item">{`${item.numberOfClasses} ${item.numberOfClasses === 1 ? 'clase' : 'clases'}`}</span>
                                            <span className="dot"></span>
                                            <span className="item">Nivel: {item.level}</span>
                                            <span className="dot"></span>
                                            <span className="item">{item.startIn}</span>
                                        </div>
                                    </div>
                                    <h6 className="description">{item.description}</h6>
                                    <a href={`/teacher-profile/${item.userId}`} className="card-details-user-profile">
                                        <img src={item.profileImage.path} alt='user' />
                                        <span>{item.username}</span>
                                        {item.userIsAmbassador && (
                                            <img src={EmabajadorIcon} className="ambassador" title="Embajador" alt='Embajador'></img>
                                        )}
                                    </a>
                                    <div className="row">
                                        <div className="col-7">
                                            <ul className="group-experience-item-card-details-registrations">
                                                <li>
                                                    <a href={`/enroll-group-experience/${item.groupExperienceId}`}>
                                                        <img src={NoUser} alt="user" className="no-user"/>
                                                    </a>
                                                </li>
                                                <li className="last-member">
                                                    <a href={`/enroll-group-experience/${item.groupExperienceId}`} >{item.numberOfRegistrations}</a>
                                                </li>
                                                <li className="registered">
                                                    <a href={`/enroll-group-experience/${item.groupExperienceId}`}>Inscriptos</a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="col-5 free-price">
                                            <Price isFree={item.isFree} price={item.price} 
                                                discount={item.discount} groupExperienceId={item.groupExperienceId}></Price>
                                      </div>
                                    </div>
                                </div>
                            </div>
                      </div>
                  </div>
              </div>
            ))) : (
                <div className="card-body">
                    <span className='no-data-to-show'>No hay información para mostrar</span>
                </div>
          )}
        </>
    );
  }

export const PaginatedItems = ({ items, totalItems, itemsPerPage, currentPage, handlePageClick }) => {
    const pageCount = Math.ceil(totalItems / itemsPerPage);
  
    return (
      <>
        <Items currentItems={items} />
        <ReactPaginate
          nextLabel=">"
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          marginPagesDisplayed={2}
          pageCount={pageCount}
          previousLabel="<"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakLabel="..."
          breakClassName="page-item"
          breakLinkClassName="page-link"
          containerClassName="pagination"
          activeClassName="active"
          renderOnZeroPageCount={null}
          forcePage={currentPage}
        />
      </>
    );
  }