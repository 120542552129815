
export const Status = {
    HasError: 'HAS_ERROR',
    Saving: 'SAVING',
    Saved: 'SAVED',
}

export const DELAY_BEFORE_REDIRECT = 3000;

export const DefaultOption = { key: '', value: 'Selecciona una opción' };

export const ResponsiveCarouselOption = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 4,
        slidesToSlide: 4 
    },
    tablet: {
        breakpoint: { max: 1024, min: 768 },
        items: 3,
        slidesToSlide: 3
    },
    mobile: {
        breakpoint: { max: 767, min: 464 },
        items: 2,
        slidesToSlide: 1
    },
    mobile2: {
        breakpoint: { max: 463, min: 300 },
        items: 1,
        slidesToSlide: 1
    }
};

export const ProfileType = {
    Administrator: 'Administrador',
    Edowier: 'Edowier',
    User: 'Usuario',
}

export const ServiceType = {
    Course: 1,
    Resource: 2,
    ParticularExperience: 3,
    GrupalExperience: 4,
    AllContents: 5
}

export const RelationType = {
    Followers: 0,
    Following: 1,
    Pending: 2
}

export const Responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 1,
        slidesToSlide: 1 // optional, default to 1.
    },
    tablet: {
        breakpoint: { max: 1024, min: 768 },
        items: 1,
        slidesToSlide: 1 // optional, default to 1.
    },
    mobile: {
        breakpoint: { max: 767, min: 464 },
        items: 1,
        slidesToSlide: 1 // optional, default to 1.
    }
};

export const ParticularExperienceStep = {
    Inactive: 0,
    GeneralConditions: 1,
    Themes: 2,
    Availability: 3
}

export const Durations = [
    { key: '60', value: '1 hr' },
    { key: '45', value: '45 min' },
    { key: '30', value: '30 min' },
]

export const OfferFirstFreeTo = [
    { key: '1', value: 'Nadie' },
    { key: '2', value: 'Seguidores' },
    { key: '3', value: 'Todos' },
]

export const TimeAdvanceCancel = [
    { key: '30', value: '30 min' },
    { key: '60', value: '1 hr' },
    { key: '120', value: '2 hr' },
    { key: '240', value: '4 hr' },
    { key: '360', value: '6 hr' },
    { key: '480', value: '8 hr' },
    { key: '600', value: '10 hr' },
    { key: '720', value: '12 hr' },
    { key: '1440', value: '24 hr' },
    { key: '2880', value: '48 hr' },
    { key: '4320', value: '72 hr' },
]

export const TimeAdvanceContract = [
    { key: '30', value: '30 min' },
    { key: '60', value: '1 hr' },
    { key: '120', value: '2 hr' },
    { key: '240', value: '4 hr' },
    { key: '360', value: '6 hr' },
    { key: '480', value: '8 hr' },
    { key: '600', value: '10 hr' },
    { key: '720', value: '12 hr' },
    { key: '1440', value: '24 hr' },
    { key: '2880', value: '48 hr' },
    { key: '4320', value: '72 hr' },
]

export const OfferServiceTo = [
    { key: '1', value: 'Todos' },
    { key: '2', value: 'Seguidores' },
]

export const SeacherType = {
    contents: 'Contenidos',
    particularExperiences: 'Experiencias Particulares',
    groupExperiences: 'Experiencias Grupales'
}

export const Levels = [
    { key: 'Introductorio', value: 'Introductorio' },
    { key: 'Intermedio', value: 'Intermedio' },
    { key: 'Avanzado', value: 'Avanzado' },
    { key: 'Experto', value: 'Experto' },
]

export const NumberOfClasses = [
    { key: '1', value: '1' },
    { key: '2', value: '2' },
    { key: '3', value: '3' },
    { key: '4', value: '4' },
    { key: '5', value: '5' },
    { key: '6', value: '6' },
]

export const MinutesDurationClass = [
    { key: '30', value: '30' },
    { key: '45', value: '45' },
    { key: '60', value: '60' },
]

export const RegistrationAvailableFor = [
    { key: '1', value: 'Todos' },
    { key: '2', value: 'Sólo seguidores' },
]

export const PeriodEvent = {
    pastEvents: 'EventosPasados',
    futureEvents: 'EventosFuturos',
}

export const EventType = {
    particular: 'particular',
    group: 'group',
}

export const Qualifications = [
    { key: 1, value: '1' },
    { key: 2, value: '2' },
    { key: 3, value: '3' },
    { key: 4, value: '4' },
    { key: 5, value: '5' },
]

export const CryptocurrencyNetwork = [
    { key: 1, value: 'BNB Chain (BEP20)' },
    { key: 2, value: 'Ethereum (ERC20)' },
    { key: 3, value: 'Tron (TRC20)' },
]

export const DashboardItemType = {
    Users: 1,
    Edowiers: 2,
    Contents: 3,
    Buys: 4
}