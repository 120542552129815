import React, { useState, useMemo, useEffect } from 'react';
import './style.scss'
import '../../../general/styles/general.css'
import { activityService } from '../../../services/activityService'
import { sessionManager } from '../../../sessionManager'
import { faThumbsUp, faThumbsDown, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { EdowieModal } from '../../Shared/Modal/index'
import ReactPlayer from 'react-player'
import ReactPaginate from 'react-paginate';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { UserLikesModal } from './UserLikesModal';

export const Item = ({ attachment }) => {
    return (
        <>
        {(attachment.extension !== 'mp4' && attachment.extension !== 'mov') ? ( 
            <div className='my-slide' >
                <img src={attachment.attachment} alt="attachment" />
            </div >
        ) : (
            <div className='my-slide'>
                <ReactPlayer
                    url={attachment.attachment}
                    className='react-player'
                    width='100%'
                    height='100%'
                    controls={true}
                    playsinline={true}
                />
            </div>
            )}
        </>
    );
}

export const PaginatedItems = ({ items,  itemsPerPage }) => {
    const [itemOffset, setItemOffset] = useState(0);

    const endOffset = itemOffset + itemsPerPage;
    const currentItems = items.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(items.length / itemsPerPage);
  

    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % items.length;
        setItemOffset(newOffset);
      };

    return (
      <>
        {currentItems.map((item) => (
            <Item key={item.activityId} attachment={item}></Item>
        ))}

        {items.length > 1 && (
        <ReactPaginate
          nextLabel=">"
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          marginPagesDisplayed={2}
          pageCount={pageCount}
          previousLabel="<"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakLabel="..."
          breakClassName="page-item"
          breakLinkClassName="page-link"
          containerClassName="pagination"
          activeClassName="active"
          renderOnZeroPageCount={null}
        />
        )}
      </>
    );
}

export const Publication = ({ activity }) => {
    
    const activityServiceClient = useMemo(() => new activityService(),[]);
    const [state, setState] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const attachmentsResponse = await activityServiceClient.getAttachmentsByActivityId(activity.activityId);

            if (attachmentsResponse.success) {
                setState(attachmentsResponse.value);
            }
        };
        fetchData();

    }, [activity.activityId, activityServiceClient]);

    return (
        <>
            <span dangerouslySetInnerHTML={{ __html: activity.publication }}></span>
            <div>
                <PaginatedItems items={state} itemsPerPage={1} totalItems={state.length} ></PaginatedItems>
            </div>
        </>
    );
}

export const Activity = ({ activity }) => {
    const { t } = useTranslation(['home']);

    return (
        <>
            {activity.type === 'publico-curso' && (
                <>
                    publicó  el curso <b><a href={activity.link}>{activity.title}</a></b> en categoría <b>{activity.category} </b> y subcategoría <b>{activity.subcategory}</b>
                </>
            )}
            {activity.type === 'publico-recurso' && (
                <>
                    publicó  el recurso <b><a href={activity.link}>{activity.title}</a></b> en categoría <b>{activity.category} </b> y subcategoría <b>{activity.subcategory}</b>
                </>
            )}

            {activity.type === 'publico-experiencia-grupal' && (
                <>
                    publicó una experiencia grupal <b><a href={activity.link}>{activity.title}</a></b> en categoría <b>{activity.category} </b> y subcategoría <b>{activity.subcategory}</b>
                </>
            )}

            {activity.type === 'adquirio-curso' && (
                <>
                    adquirió el curso <b><a href={activity.link}>{activity.title}</a></b> del edowier <a href={`/teacher-profile/${activity.contentUserId}`} className={activity.profileContentUser} data={activity.contentUserId}>{activity.contentUser}</a>
                </>
            )}

            {activity.type === 'adquirio-recurso' && (
                <>
                    adquirió el recurso <b><a href={activity.link}>{activity.title}</a></b> del edowier <a href={`/teacher-profile/${activity.contentUserId}`} className={activity.profileContentUser} data={activity.contentUserId}>{activity.contentUser}</a>
                </>
            )}

            {(activity.type === 'calificacion-curso' || activity.type === 'calificacion-recurso') && (
                <>
                    calificó con un <b>{activity.qualification}</b> el {activity.contentQualification} <b><a href={activity.link}>{activity.title}</a></b> del edowier <a href={`/teacher-profile/${activity.contentUserId}`} className={activity.profileContentUser} data={activity.contentUserId}>{activity.contentUser}</a>
                    <p className="review-publication">{activity.reviewQualification}</p>
                </>
            )}

            {(activity.type === 'siguiendo') && (
                <>
                    comenzó a seguir al {activity.profileContentUser} <a href={`${activity.profileContentUser === 'Edowier' ? '/teacher-profile/' : 'user-profile/'}${activity.contentUserId}`} className={activity.profileContentUser}>{activity.contentUser}</a>
                </>
            )}

            {(activity.type === 'activo-experiencia-particular') && (
                <>
                    <Trans
                        t={t}
                        i18nKey={"activities.activatedParticularExperience"}
                        //values={{ link: "myapp.com" }}
                        components={{ anchor: <Link to={`/teacher-profile/${activity.contentUserId}`} /> }}
                    />
                    {/* Activó su experiencia particular, visita su <a href={`teacher-profile/${activity.contentUserId}`} className={activity.profileContentUser} data={activity.contentUserId}>perfil</a> para saber más */}
                </>
            )}

            {(activity.type === 'califico-experiencia-particular') && (
                <>
                    calificó con un <b>{activity.qualification}</b> la experiencia particular del edowier <a href={`${activity.profileContentUser === 'Edowier' ? '/teacher-profile/' : 'user-profile/'}${activity.contentUserId}`} className={activity.profileContentUser} data={activity.contentUserId}>{activity.contentUser}</a>
                    <p className="review-publication">{activity.reviewQualification}</p>
                </>
            )}

            {(activity.type === 'califico-experiencia-grupal') && (
                <>
                    calificó con un <b>{activity.qualification}</b> la experiencia grupal <a href={activity.link}><b>{activity.title}</b></a> del edowier <a href={`${activity.profileContentUser === 'Edowier' ? '/teacher-profile/' : 'user-profile/'}${activity.contentUserId}`} className={activity.profileContentUser} data={activity.contentUserId}>{activity.contentUser}</a>
                    <p className="review-publication">{activity.reviewQualification}</p>
                </>
            )}

            {(activity.type === 'donacion') && (
                <>
                    Realizó una donación al edowier <a href={`/teacher-profile/${activity.contentUserId}`} className={activity.profileContentUser} data={activity.contentUserId}>{activity.contentUser}</a>
                </>
            )}

            {(activity.type !== 'califico-experiencia-particular' &&
              activity.type !== 'califico-experiencia-grupal' &&
              activity.type !== 'calificacion-curso' &&
              activity.type !== 'calificacion-recurso' &&
              activity.type !== 'siguiendo' &&
              activity.type !== 'activo-experiencia-particular' && 
              activity.type !== 'donacion') && (
                <ul className="activity-image">
                    <li>
                        <div className="activity-image-panel">
                            <a href={activity.link} ><img src={activity.contentImage} alt={activity.title} /></a>
                        </div>
                    </li>
                </ul>
            )}
        </>
    );
}

export const ActivityItemContent = ({ activity }) => {
    return (
        <>
            {(activity.activityType === 'Publicación') ? (
                <Publication activity={activity}></Publication>
            ) : (
                <Activity activity={activity}></Activity>
            )}
        </>
    );
}

export const ActivityItem = ({ activity, handleGiveFeedback, handleDeleteActivity }) => {
    const { t } = useTranslation(['home']);
    const manager = new sessionManager();
    const activityServiceClient = new activityService();
    const userLoggedIn = JSON.parse(manager.getUserSession());

    const [showModal, setShowModal] = useState(false);
    const [showUserLikesModal, setShowUserLikesModal] = useState(false);

    const handleFeedback = async (activityId, like) => {
        var activitiesResponse = await activityServiceClient.feedback(activityId, userLoggedIn.userId, like);

        if (activitiesResponse.success) {
            handleGiveFeedback();
        }
    }

    const deleteActivity = async (activityId) => {
        var activitiesResponse = await activityServiceClient.delete(activityId);

        if (activitiesResponse.success) {
            handleDeleteActivity();
            setShowModal(false);
        }
    }

    return (
        <li className="activity-item">
            <div className="activity-item-header">
                <div className="user">
                    <a href={`${activity.profileUser === 'Edowier' ? '/teacher-profile/' : 'user-profile/'}${activity.userId}`}>
                        <img src={activity.userProfilePhoto} className="user-photo" alt="activity" />
                    </a>
                    <div className="details">
                        <span className="username">{activity.name}</span>
                        <span className="activity-type">{activity.activityType === 'Publicación' ? 
                            t('activities.publicationType', {ns: 'home'}) : 
                            t('activities.activityType', {ns: 'home'})}</span>
                        <span className="time">{`${activity.time.split(' ')[0]} ${t('activities.' + activity.time.split(' ')[1])}`}</span>
                    </div>
                    {(activity.activityType === 'Publicación' && userLoggedIn.userId === activity.userId) && (
                        <>
                            <div className="delete-publication">
                                <button onClick={() => { setShowModal(true) }} title="Eliminar">
                                    <i><FontAwesomeIcon icon={faTrash} /></i>
                                </button>
                            </div>
                            <EdowieModal show={showModal} title={t('activities.deletePublicationTitle', {ns:'home'})}
                                close={() => { setShowModal(false) }} handleAccept={() => { deleteActivity(activity.activityId) }}>
                                <div className="activity-item-content">{t('activities.deletePublicationLegend', {ns:'home'})}</div>
                            </EdowieModal>
                        </>
                    )}
                </div>
            </div>
            <div className="activity-item-content">
                <ActivityItemContent activity={activity}></ActivityItemContent>
            </div>
            <div className="activity-item-like">
                <button onClick={() => setShowUserLikesModal(true)}>
                    <span>{activity.likedQuantity}<span className="label">{t('activities.iLike', {ns: 'home'})}</span></span>
                </button>
                <UserLikesModal 
                    show={showUserLikesModal} 
                    activityId={activity.activityId}
                    handleClose={() => setShowUserLikesModal(false)}
                >
                </UserLikesModal>
            </div>
            <div className="activity-item-footer">
                <div className='activity-item-footer-buttons'>
                <button onClick={() => {
                    handleFeedback(activity.activityId, true)
                }} >
                    <i className={`${activity.like === true ? 'activity-item-footer-buttons-active' : ''}`}><FontAwesomeIcon icon={faThumbsUp} /></i>
                </button>
                <button onClick={() => {
                    handleFeedback(activity.activityId, false)
                }} >
                    <i className={`${activity.like === false ? 'activity-item-footer-buttons-active' : ''}`}><FontAwesomeIcon icon={faThumbsDown} /></i>
                </button>
                </div>
            </div>
        </li>
    );
}

export const Activities = ({ activities, handleDelete, handleFeedback }) => {
    const [state, setState] = useState(activities);

    useEffect(() => {
        setState(activities);

    }, [activities]);

    const handleGiveFeedback = async () => {
        handleFeedback();
    }

    const handleDeleteActivity = async () => {
        handleDelete();
    }

    return (
        <div className="activities">
            <div className="container">
                <ul className="activity-list">
                    {state && state.map((activity) => (
                        <ActivityItem activity={activity} key={activity.activityId} handleGiveFeedback={() => handleGiveFeedback()}
                            handleDeleteActivity={() => handleDeleteActivity()}></ActivityItem>
                    ))}
                </ul>
                
            </div>
        </div>
    );
}
