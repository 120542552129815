import React from 'react';
import './style.scss';
import { particularExperienceService } from '../../../../../services/particularExperienceService';
import { sessionManager } from '../../../../../sessionManager';
import { useTranslation } from 'react-i18next';

export const InactiveStep = ({ handleActivate }) => {
    const { t } = useTranslation();
    const particularExperienceServiceClient = new particularExperienceService();
    const manager = new sessionManager();
    const userLoggedIn = JSON.parse(manager.getUserSession());

    const activate = async () => {
        var response = await particularExperienceServiceClient.activate(userLoggedIn.userId);

        if (response.success) {
            handleActivate();
        }
    }

    return (
        <div className="inactive-step">
            <h2 className="inactive-step-title">
                {t('manage_services.particular.activate_step.title')}
            </h2>
            <div className="inactive-step-activar">
                <button className="inactive-step-activar-button" onClick={activate}>
                {t('manage_services.particular.activate_step.activate_button')}
                </button>
            </div>
        </div>
    )
}