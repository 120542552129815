import { Trans, useTranslation } from "react-i18next";
import { InjectAppServices } from "../../../../../../../../services/dependency-injection";
import { Form, Formik } from "formik";
import { FieldGroup, FieldItem, InputFieldItem, SelectFieldItem } from "../../../../../../../Shared/FormHelpers";
import { Message } from "../../../../../../../Shared/Message";
import { getFormInitialValues } from "../../../../../../../../Utils/utils";
import { useRef, useState } from "react";
import { CryptocurrencyNetwork, DELAY_BEFORE_REDIRECT, Status } from "../../../../../../../../Utils/types";
import { combineValidations, createMaximumAmountValidation, createMinimumAmountValidation } from "../../../../../../../../Utils/validations";
import useTimeout from "../../../../../../../../hooks/useTimeout";
import { EdowieDialog } from "../../../../../../../Shared/Dialog";

const fieldNames = {
    networkUsed: 'networkUsed',
    address: 'address',
    amountToWithdraw: 'amountToWithdraw'
};

export const WithdrawalCryptocurrencyModal = InjectAppServices(
    ({
        show, 
        userId,
        minimumToWithdraw, 
        balance,
        handleWithdrawalCryptocurrency,
        handleClose,
        dependencies: { transactionServiceClient }
    }) => {

        const { t } = useTranslation(['balance', 'validations', 'common']);
        const defaultOption = { key: '', value: t('selectAnOption', {ns: 'common'}) };

        const formRef = useRef();
        const [status, setStatus] = useState('');
        const [error, setError] = useState('');
        const [isSaving, setIsSaving] = useState(false);
        const createTimeout = useTimeout();

        const withdrawalCryptocurrency = (values) => {
            setIsSaving(true);
            transactionServiceClient.withdrawal(userId, 3, values.amountToWithdraw, '', '', '', '', Number(values.networkUsed), values.address)
                .then(response => {
                    setStatus(Status.Saved);
                    createTimeout(async() => {
                        setStatus('');
                        handleWithdrawalCryptocurrency();
                    }, DELAY_BEFORE_REDIRECT);
                })
                .catch(error => {
                    setStatus(Status.HasError);
                    setError(error);
            });
        }

        const handleSubmit = () => {
            if (formRef.current) {
                formRef.current.handleSubmit()
            }
        }

        const showMessage = [Status.Saved, Status.HasError].includes(status);

        return (
            <>
                <EdowieDialog 
                    show={show} 
                    handleAccept={() => handleSubmit()} 
                    handleClose={() => handleClose()}
                    title={t('withdrawalCryptocurrencyModal.title', {ns: 'balance'})}
                    dialogClass={'withdrawal-bank-transfer-modal'}
                    disabledButtons={isSaving}>
                        <div className="form">
                            <div className="row">
                                <div className="col-lg-12">
                                    <Formik innerRef={formRef}
                                        onSubmit={withdrawalCryptocurrency}
                                        initialValues={getFormInitialValues(fieldNames)}
                                    >
                                        {({ isSubmitting, handleSubmit }) => (
                                            <Form>
                                                <fieldset className="form-fields">
                                                    <h4 className="withdrawal-bank-transfer-modal-legend">
                                                        <Trans
                                                            i18nKey={`withdrawalCryptocurrencyModal.legend`}
                                                            t={t}
                                                            components={{ bold: <strong /> }}
                                                        />
                                                    </h4>
                                                    <FieldGroup>
                                                        <FieldItem className="field-item">
                                                            <FieldGroup>
                                                                <SelectFieldItem
                                                                    fieldName={fieldNames.networkUsed}
                                                                    id={fieldNames.networkUsed}
                                                                    label={`*${t('withdrawalCryptocurrencyModal.networkUsedLabel', {ns: 'balance'})}`}
                                                                    defaultOption={defaultOption}
                                                                    values={CryptocurrencyNetwork}
                                                                    className="field-item--100"
                                                                    required
                                                                />
                                                            </FieldGroup>
                                                        </FieldItem>
                                                        <FieldItem className="field-item">
                                                            <FieldGroup>
                                                                <InputFieldItem
                                                                    type="text"
                                                                    fieldName={fieldNames.address}
                                                                    id={fieldNames.address}
                                                                    label={`*${t('withdrawalCryptocurrencyModal.addressLabel', {ns: 'balance'})}`}
                                                                    required
                                                                    maxLength="150"
                                                                    placeholder={t('withdrawalCryptocurrencyModal.addressPlaceholder', {ns: 'balance'})}
                                                                    className="field-item--100"
                                                                />
                                                            </FieldGroup>
                                                        </FieldItem>
    
                                                        <FieldItem  className="field-item">
                                                            <FieldGroup>
                                                                <InputFieldItem
                                                                    type="number"
                                                                    fieldName={fieldNames.amountToWithdraw}
                                                                    id={fieldNames.amountToWithdraw}
                                                                    label={`*${t('withdrawalCryptocurrencyModal.amountToWithdrawLabel', {ns: 'balance'})}`}
                                                                    placeholder={t('withdrawalCryptocurrencyModal.amountToWithdrawPlaceholder', {ns: 'balance'})}
                                                                    className="field-item--100"
                                                                    required
                                                                    maxLength="150"
                                                                    validator={combineValidations(createMinimumAmountValidation(minimumToWithdraw), createMaximumAmountValidation(balance))}
                                                                />
                                                            </FieldGroup>
                                                        </FieldItem>
                                                    </FieldGroup>
                                                </fieldset>
                                                {showMessage && (
                                                    <fieldset>
                                                        <Message
                                                            type={status === Status.Saved ? 'success' : 'error'}
                                                            message={
                                                                status === Status.Saved
                                                                    ? t(`successfullyMessage`, {ns: 'common'})
                                                                    : error}
                                                        />
                                                    </fieldset>
                                                )}
                                            </Form>
                                        )}
                                    </Formik>
                                </div>

                            </div>
                        </div>
                </EdowieDialog>
            </>
        )
    }
)