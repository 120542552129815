
export class categoryService {

    async getAll() {
        try {

            const edowieApi = process.env.REACT_APP_EDOWIE_API;

            const response = await fetch(`${edowieApi}category/categories`);

            const data = await response.json();

            return { success: true, value: data }

        } catch (error) {
            return { success: false, error: error };
        }
    }
}
