import { getToken } from "../Utils/utils";

export class groupExperienceService {

    async getGroupsExperiences(edowierId) {
        try {

            const edowieApi = process.env.REACT_APP_EDOWIE_API;
            const token = await getToken();
            const requestOptions = {
                headers: { 'Authorization': `bearer ${token}`, },
            };

            const response = await fetch(`${edowieApi}groupexperience/${edowierId}/groups-experiences`, requestOptions);
            const data = await response.json();

            return { success: true, value: data }

        } catch (error) {
            return { success: false, error: error };
        }
    }

    async getGroupExperienceById(id) {
        try {

            const edowieApi = process.env.REACT_APP_EDOWIE_API;
            const token = await getToken();
            const requestOptions = {
                headers: { 'Authorization': `bearer ${token}`, },
            };

            const response = await fetch(`${edowieApi}groupexperience/${id}`, requestOptions);
            const data = await response.json();

            return { success: true, value: data }

        } catch (error) {
            return { success: false, error: error };
        }
    }

    async saveGroupExperience(edowierId, groupExperienceId, coverImage, values) {
        try {

            const edowieApi = process.env.REACT_APP_EDOWIE_API;
            const token = await getToken();
            const requestOptions = {
                method: 'POST',
                headers: { 
                    'Authorization': `bearer ${token}`,
                    'Content-Type': 'application/json' 
                },
                body: JSON.stringify(
                    {
                        "groupExperienceId": groupExperienceId === undefined ? 0 : groupExperienceId,
                        "categoryId": values.category,
                        "subcategoryId": values.subcategory,
                        "title": values.title,
                        "description": values.description,
                        "detailedDescription": values.detailedDescription,
                        "requirements": values.requirements,
                        "level": values.level,
                        "quantityClasses": values.numberOfClasses,
                        "minutesDurationClasses": values.minutesDurationClass,
                        "isFree": values.isFree === "" ? false : values.isFree,
                        "price": values.price === "" ? 0 : values.price,
                        "availableInscriptionFor": values.registrationAvailableFor,
                        "noRegistrationLimit": values.noRegistrationLimit === "" ? false : values.noRegistrationLimit,
                        "registrationLimit": values.registrationLimit === "" ? 0 : values.registrationLimit,
                        "tags": values.labels,
                        "coverImage": coverImage.length > 0 ? coverImage[0].toString() : "",
                        "userId": edowierId
                    })
            };

            const response = await fetch(`${edowieApi}groupexperience/${edowierId}/save-group-experience`, requestOptions);

            const data = await response.json();

            return { success: response.status === 200, value: data }

        } catch (error) {
            return { success: false, error: error };
        }
    }

    async addClass(edowierId, id, startDate, duration, offset) {
        try {

            const edowieApi = process.env.REACT_APP_EDOWIE_API;
            const token = await getToken();
            const requestOptions = {
                method: 'POST',
                headers: { 
                    'Authorization': `bearer ${token}`,
                    'Content-Type': 'application/json' 
                },
                body: JSON.stringify(
                    {
                        "startDate": startDate,
                        "duration": duration,
                        "offset": offset,
                    }),
            };

            const response = await fetch(`${edowieApi}groupExperience/${edowierId}/group-experience/${id}/class`, requestOptions);

            return { success: response.status === 200 }

        } catch (error) {
            return { success: false, error: error };
        }
    }

    async deleteClass(edowierId, groupExperienceId, classId) {
        try {

            const edowieApi = process.env.REACT_APP_EDOWIE_API;
            const token = await getToken();
            const requestOptions = {
                method: 'DELETE',
                headers: { 
                    'Authorization': `bearer ${token}`,
                    'Content-Type': 'application/json' 
                },
            };

            const response = await fetch(`${edowieApi}groupExperience/${edowierId}/group-experience/${groupExperienceId}/class/${classId}`, requestOptions);

            return { success: response.status === 200 }

        } catch (error) {
            return { success: false, error: error };
        }
    }

    async publish(edowierId, groupExperienceId) {
        try {

            const edowieApi = process.env.REACT_APP_EDOWIE_API;
            const token = await getToken();
            const requestOptions = {
                method: 'POST',
                headers: { 
                    'Authorization': `bearer ${token}`,
                    'Content-Type': 'application/json' 
                },
            };

            const response = await fetch(`${edowieApi}groupExperience/${edowierId}/group-experience/${groupExperienceId}/publish`, requestOptions);

            return { success: response.status === 200 }

        } catch (error) {
            return { success: false, error: error };
        }
    }

    async deleteGroupExperience(edowierId, groupExperienceId) {
        try {

            const edowieApi = process.env.REACT_APP_EDOWIE_API;
            const token = await getToken();
            const requestOptions = {
                method: 'DELETE',
                headers: { 
                    'Authorization': `bearer ${token}`,
                    'Content-Type': 'application/json' 
                }
            };

            const response = await fetch(`${edowieApi}groupExperience/${edowierId}/group-experience/${groupExperienceId}`, requestOptions);

            return { success: response.status === 200 }

        } catch (error) {
            return { success: false, error: error };
        }
    }

    async getGroupExperiencesByCategoryIdAndTex(userId, categoryId, searchText, page, countsByPage) {
        try {

            const edowieApi = process.env.REACT_APP_EDOWIE_API;
            const token = await getToken();
            const requestOptions = {
                headers: { 'Authorization': `bearer ${token}`, },
            };

            const response = await fetch(`${edowieApi}groupExperience/${userId}/group-experiences?categoryId=${categoryId}&text=${searchText}&page=${page}&countsByPage=${countsByPage}`, requestOptions);
            const data = await response.json();

            return { success: true, value: data }

        } catch (error) {
            return { success: false, error: error };
        }
    }

    async addFavorites(userId, id) {
        try {

            const edowieApi = process.env.REACT_APP_EDOWIE_API;
            const token = await getToken();
            const requestOptions = {
                method: 'POST',
                headers: { 
                    'Authorization': `bearer ${token}`, 
                    'Content-Type': 'application/json' 
                }
            };

            const response = await fetch(`${edowieApi}groupExperience/${userId}/group-experiences/${id}/favorites`, requestOptions);

            return { success: response.status === 200 }

        } catch (error) {
            return { success: false, error: error };
        }
    }

    async deleteFavorites(userId, id) {
        try {

            const edowieApi = process.env.REACT_APP_EDOWIE_API;
            const token = await getToken();
            const requestOptions = {
                method: 'DELETE',
                headers: { 
                    'Authorization': `bearer ${token}`, 
                    'Content-Type': 'application/json' 
                },
            };

            const response = await fetch(`${edowieApi}groupExperience/${userId}/group-experiences/${id}/favorites`, requestOptions);

            return { success: response.status === 200 }

        } catch (error) {
            return { success: false, error: error };
        }
    }

    async hasInFavorites(userId, id) {
        try {

            const edowieApi = process.env.REACT_APP_EDOWIE_API;
            const token = await getToken();
            const requestOptions = {
                headers: { 'Authorization': `bearer ${token}`, },
            };

            const response = await fetch(`${edowieApi}groupExperience/${userId}/group-experiences/${id}/has-in-favorites`, requestOptions);
            const data = await response.json();

            return { success: true, value: data }

        } catch (error) {
            return { success: false, error: error };
        }
    }

    async wasEnrolled(userId, id) {
        try {

            const edowieApi = process.env.REACT_APP_EDOWIE_API;
            const token = await getToken();
            const requestOptions = {
                headers: { 'Authorization': `bearer ${token}`, },
            };

            const response = await fetch(`${edowieApi}groupExperience/${userId}/group-experiences/${id}/was-enrolled`, requestOptions);
            const data = await response.json();

            return { success: true, value: data }

        } catch (error) {
            return { success: false, error: error };
        }
    }

    async getDetailsById(userId, id) {
        try {

            const edowieApi = process.env.REACT_APP_EDOWIE_API;
            const token = await getToken();
            const requestOptions = {
                headers: { 'Authorization': `bearer ${token}`, },
            };

            const response = await fetch(`${edowieApi}groupexperience/${userId}/group-experiences/${id}/details`, requestOptions);
            const data = await response.json();

            return { success: true, value: data }

        } catch (error) {
            return { success: false, error: error };
        }
    }

    async enroll(externalPaymentId, amount, userId, groupExperienceId) {
        try {
            const edowieApi = process.env.REACT_APP_EDOWIE_API;
            const token = await getToken();
            const requestOptions = {
                method: 'POST',
                headers: { 
                    'Authorization': `bearer ${token}`,
                    'Content-Type': 'application/json' 
                },
                body: JSON.stringify(
                    {
                        "externalPaymentId": externalPaymentId,
                        "amount": amount,
                    })
            };

            const response = await fetch(`${edowieApi}groupexperience/${userId}/group-experiences/${groupExperienceId}/enroll`, requestOptions);

            if (response.ok) {
                return { success: true }
            } else {
                return { success: false, error: 'Ha ocurrido un error al intentar inscribirse a la experiencia grupal.' };
            }

        } catch (error) {
            return { success: false, error: error };
        }
    }

    async getRegistrationsByGroupExperienceId(groupExperienceId) {
        try {

            const edowieApi = process.env.REACT_APP_EDOWIE_API;
            const token = await getToken();
            const requestOptions = {
                headers: { 'Authorization': `bearer ${token}`, },
            };

            const response = await fetch(`${edowieApi}groupexperience/${groupExperienceId}/registrations`, requestOptions);
            const data = await response.json();

            return { success: true, value: data }

        } catch (error) {
            return { success: false, error: error };
        }
    }

    async denunciate(groupExperienceId, denunciatedUserId, userId, message) {
        try {

            const edowieApi = process.env.REACT_APP_EDOWIE_API;
            const token = await getToken();
            const requestOptions = {
                method: 'POST',
                headers: { 
                    'Authorization': `bearer ${token}`,
                    'Content-Type': 'application/json' 
                },
                body: JSON.stringify(
                    {
                        "denunciatedUserId": denunciatedUserId,
                        "message": message,
                    }),
            };

            const response = await fetch(`${edowieApi}groupexperience/${userId}/${groupExperienceId}/denunciate`, requestOptions);

            return { success: response.status === 200 }

        } catch (error) {
            return { success: false, error: error };
        }
    }
}
